import { UpdateVppTransactionInput, VppTransactionPayload, AddressInput } from '@buy-viasat/types/build/bv';
import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';
import { ApolloQueryResult } from '@apollo/client';

const CREATE_VPP_TRANSACTION = gql`
  query createVPPTransaction($input: UpdateVPPTransactionInput!) {
    createVPPTransaction(input: $input) {
      transactionId
    }
  }
`;

export default async function createVPPTransactionAsync(options: {
  relnId: string;
  email: string;
  billingAddress: AddressInput;
  spbBillingAccountId: string;
  oneTimeTotal: number;
  userAgent: string;
}): Promise<ApolloQueryResult<VppTransactionPayload>> {
  return client.query({
    query: CREATE_VPP_TRANSACTION,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        email: options.email,
        billingAddress: options.billingAddress,
        customerRef: options.relnId,
        spbBillingAccountId: options.spbBillingAccountId,
        txnAmount: options.oneTimeTotal,
        userAgent: options.userAgent,
      } as UpdateVppTransactionInput,
    },
  });
}
