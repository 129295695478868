import { createSelector } from 'reselect';
import { RootState } from '../types';
import { initialBusinessInformationState } from './';
import { BusinessInformationState } from './types';

export const selectBusinessInformationDomain = (state: RootState): BusinessInformationState => {
  return state?.businessInformation || initialBusinessInformationState;
};

export const selectAccountHolderInformation = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => {
    return {
      firstName: state.accountHolder.firstName,
      lastName: state.accountHolder.lastName,
      email: state.accountHolder.email,
      phoneNumber: state.accountHolder.phone,
      mobileNumber: state.accountHolder.mobileNumber,
    };
  },
);

export const selectBusinessInformationName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.name,
);

export const selectOrganizationPartyId = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.organizationPartyId,
);

export const selectBusinessInformationTaxID = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.taxID,
);

export const selectBusinessInformationSSN = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.SSN,
);

export const selectShouldUseTaxId = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.shouldUseTaxid,
);

export const selectAccountHolderIsMobileNumberChecked = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.isMobileNumberChecked,
);
export const selectBillingContactIsMobileNumberChecked = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.isMobileNumberChecked,
);
export const selectOnSiteContactIsMobileNumberChecked = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.isMobileNumberChecked,
);

export const selectAccountHolderEmail = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.email,
);
export const selectBillingContactEmail = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.email,
);
export const selectOnSiteContactEmail = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.email,
);

export const selectAccountHolderFirstName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.firstName,
);
export const selectBillingContactFirstName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.firstName,
);
export const selectOnSiteContactFirstName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.firstName,
);

export const selectAccountHolderFullName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => `${state.accountHolder.firstName} ${state.accountHolder.lastName}`,
);
export const selectBillingContactFullName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => `${state.billingContact.firstName} ${state.billingContact.lastName}`,
);
export const selectOnSiteContactFullName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => `${state.onSiteContact.firstName} ${state.onSiteContact.lastName}`,
);

export const selectAccountHolderLastName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.lastName,
);
export const selectBillingContactLastName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.lastName,
);
export const selectOnSiteContactLastName = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.lastName,
);

export const selectAccountHolderPhone = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.phone,
);
export const selectBillingContactPhone = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.phone,
);
export const selectOnSiteContactPhone = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.phone,
);

export const selectAccountHolderMobileNumber = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.mobileNumber,
);
export const selectBillingContactMobileNumber = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.mobileNumber,
);
export const selectOnSiteContactMobileNumber = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.mobileNumber,
);

export const selectAccountHolderPhoneCountryCode = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.accountHolder.phoneCountryCode,
);
export const selectBillingContactPhoneCountryCode = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.billingContact.phoneCountryCode,
);
export const selectOnSiteContactPhoneCountryCode = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.onSiteContact.phoneCountryCode,
);

export const selectIsTaxExempt = createSelector(
  [selectBusinessInformationDomain],
  (state: BusinessInformationState) => state.isTaxExempt,
);
