import { CustomerInfoMarketing, SendLeadToMarketoResponse } from '@buy-viasat/types/build/bv';
import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';

const SEND_MARKETING_LEAD_DETAILS = gql`
  mutation sendLeadToMarketo($input: CustomerInfoMarketing) {
    sendLeadToMarketo(input: $input) {
      sendLeadToMarketo
    }
  }
`;

export default async function sendLeadToMarketoAsync(input: CustomerInfoMarketing): Promise<SendLeadToMarketoResponse> {
  const { firstName, email, lastName, serviceAddress, phoneNumber } = input;
  return client.mutate({
    mutation: SEND_MARKETING_LEAD_DETAILS,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        firstName,
        lastName,
        email,
        serviceAddress,
        phoneNumber,
      },
    },
  }) as SendLeadToMarketoResponse;
}
