import { Col, Container, Icon, Row, Surface, Txt } from '@vst/beam';
import styles from './checkoutCartHeader.module.scss';
import { KeyboardArrowDown, KeyboardArrowUp, ShoppingCart } from '@vst/beam-icons/icons';
import { calculateDueTodayTotal, priceDecimalFormat, noPriceHandler, isDefined } from '@buy-viasat/utils';
import { useSelector } from 'react-redux';
import {
  selectMonthlyTotalWithoutPromo,
  selectMonthlyTotalWithPromo,
  selectOnetimeDiscount,
  selectOnetimeTotalWithoutPromo,
} from '@buy-viasat/redux/src/cart';
import { selectVppPaymentType } from '@buy-viasat/redux/src/payment-information';
import { selectLocale, selectSalesFlowDefinition } from '@buy-viasat/redux/src/app';
import { useTotalPriceVisibility } from '@buy-viasat/react/src/components/CheckoutCart/hooks';
import { SkeletonLoader } from '@buy-viasat/react/src/components/SkeletonLoader';
import { selectIsUpsertPlanLoading } from '@buy-viasat/redux/src/plan';

type CheckoutCartHeaderProps = {
  onClick: () => void;
  displayMobileCart: boolean;
  phraseDueToday: string;
  phraseDueMonthly: string;
};

export const CheckoutCartHeader = ({
  onClick,
  displayMobileCart,
  phraseDueMonthly,
  phraseDueToday,
}: CheckoutCartHeaderProps) => {
  //Due today data
  const onetimeTotalWithoutPromo = useSelector(selectOnetimeTotalWithoutPromo);
  const oneTimeDiscount = useSelector(selectOnetimeDiscount);
  const paymentType = useSelector(selectVppPaymentType);
  const locale = useSelector(selectLocale);
  const isUpsertPlanLoading = useSelector(selectIsUpsertPlanLoading);
  const { currencySymbol } = useSelector(selectSalesFlowDefinition);
  const { visible: priceValueDisplayed } = useTotalPriceVisibility();

  const { dueTodayTotal, dueTodayTotalValue } = calculateDueTodayTotal(
    onetimeTotalWithoutPromo || 0,
    paymentType,
    oneTimeDiscount || 0,
    locale,
    currencySymbol,
  );

  //Due monthly data
  const monthlyTotalWithPromo = useSelector(selectMonthlyTotalWithPromo) ?? 0;
  const monthlyTotalWithPromoValue = priceDecimalFormat(monthlyTotalWithPromo, locale, currencySymbol);
  const monthlyTotalWithoutPromo = useSelector(selectMonthlyTotalWithoutPromo) ?? 0;
  const monthlyTotalWithoutPromoValue = priceDecimalFormat(monthlyTotalWithoutPromo, locale, currencySymbol);
  const noPriceValue = noPriceHandler(currencySymbol, locale);

  return (
    <Container p="16px" className={styles['cartHeaderContainer']} fluidUntil={'md'} onClick={onClick}>
      <Row>
        <Col>
          <Surface className={styles['cartHeaderWrapper']} data-testid="mobileCartHeaderContainer">
            <Surface className={styles['leftSide']} data-testid="dueTodayContainer">
              <Icon icon={ShoppingCart} color="gray_600" />
              <Txt variant="smallRegular" pl="16px">
                {phraseDueToday}:{' '}
                <Txt variant="smallBold">
                  {' '}
                  {isDefined(dueTodayTotal) && priceValueDisplayed ? dueTodayTotalValue : noPriceValue}
                </Txt>
              </Txt>
            </Surface>
            <Surface className={styles['rightSide']}>
              <Txt variant="smallRegular" pr="16px" className={styles['cartDueMonthly']}>
                {phraseDueMonthly}:{' '}
                <Txt variant="smallBold">
                  {isUpsertPlanLoading ? (
                    <Txt style={{ marginLeft: '2px' }}>
                      <SkeletonLoader characters={4} height={'sm'} />
                    </Txt>
                  ) : (
                    <>
                      {monthlyTotalWithPromo < monthlyTotalWithoutPromo
                        ? monthlyTotalWithPromoValue
                        : monthlyTotalWithoutPromoValue}
                    </>
                  )}
                </Txt>
              </Txt>
              {displayMobileCart ? <Icon icon={KeyboardArrowUp} /> : <Icon icon={KeyboardArrowDown} />}
            </Surface>
          </Surface>
        </Col>
      </Row>
    </Container>
  );
};
