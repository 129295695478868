import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import enUS from './translations/en-US/translations.json';
import enBR from './translations/en-BR/translations.json';
import ptBR from './translations/pt-BR/translations.json';
import itIT from './translations/it-IT/translations.json';

i18n.addResourceBundle('en-US', 'translation', enUS);
i18n.addResourceBundle('en-BR', 'translation', enBR);
i18n.addResourceBundle('pt-BR', 'translation', ptBR);
i18n.addResourceBundle('it-IT', 'translation', itIT);

export default i18n;
