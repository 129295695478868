import { addonActions } from '@buy-viasat/redux/src/addons';
import { Promo } from '@buy-viasat/types/build/bv';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkActions, DataLayerEventType, addAddonsPayload } from './types';
import { DataLayerEvent, DataLayerEventName } from '@buy-viasat/redux/src/analytics';

export const trackEvent = createAsyncThunk(ThunkActions.trackEvent, async (input: DataLayerEvent) => {
  (window.adobeDataLayer || []).push(input);
});

export const addAddon = createAsyncThunk(
  ThunkActions.addAddons,
  async ({ id, name, price, promo, categoryName, displayOrder }: addAddonsPayload, thunkAPI: any) => {
    thunkAPI.dispatch({
      type: addonActions.addAddons.type,
      payload: { id, name, price, promo, categoryName, displayOrder },
    });

    thunkAPI.dispatch(
      trackEvent({
        event: DataLayerEventName.AA_EVENT,
        eventData: {
          event: DataLayerEventType.ADDON_SELECTED,
          addons: { id, selected: true, selectedAddOn: name },
        },
      }),
    );
  },
);

export const removeAddon = createAsyncThunk(
  ThunkActions.removeAddons,
  async ({ id, name, price, promo }: { id: string; name: string; price: number; promo: Promo }, thunkAPI: any) => {
    thunkAPI.dispatch({
      type: addonActions.removeAddons.type,
      payload: { id, name, price, promo },
    });
    thunkAPI.dispatch(
      trackEvent({
        event: DataLayerEventName.AA_EVENT,
        eventData: {
          event: DataLayerEventType.ADDON_SELECTED,
          addons: { id, selected: false, selectedAddOn: name },
        },
      }),
    );

    thunkAPI.dispatch({
      type: addonActions.removeAddons.type,
      payload: { id, name, price, promo },
    });
  },
);
