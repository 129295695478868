import gql from 'graphql-tag';
import { FetchResult } from '@apollo/client';
import { client } from 'shared/containers/apollo';
import { PaymentOnFile } from '@buy-viasat/redux/src/payment-information';

const GET_PAYMENT_ON_FILE = gql`
  query getPaymentOnFile($input: GetPaymentOnFileInput!) {
    getPaymentOnFile(input: $input) {
      last4Digits
      paymentMethod
      ccExpYear
      ccExpMonth
    }
  }
`;

type GetPaymentOnFileInput = {
  systemName: string;
  customerRef: string;
};

type GetPaymentOnFileResponse = FetchResult<{
  getPaymentOnFile: PaymentOnFile;
}>;

export default async function getPaymentOnFile(input: GetPaymentOnFileInput): Promise<GetPaymentOnFileResponse> {
  return client.query({
    query: GET_PAYMENT_ON_FILE,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        systemName: input.systemName,
        customerRef: input.customerRef,
      },
    },
  });
}
