import { Country } from '@buy-viasat/types/build/bv';
import { createSelector } from 'reselect';
import { RootState } from '../types';
import { initialPersonalInformationState } from './';
import { PersonalInformationState } from './types';

export const selectPersonalInformationDomain = (state: RootState): PersonalInformationState => {
  return state?.personalInformation || initialPersonalInformationState;
};

export const selectCustomerInformation = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => ({
    firstName: state.firstName.value,
    lastName: state.lastName.value,
    email: state.email.value,
    phoneNumber: state.phone.value,
    mobileNumber: state.mobileNumber.value,
  }),
);

export const selectIsMarketingCheckboxChecked = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.isMarketingCheckboxChecked,
);

export const selectIsMobileNumberChecked = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.isMobileNumberChecked,
);

export const selectPreviousCustomerInfo = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => ({
    firstName: state.previousCustomerInfo?.firstName,
    lastName: state.previousCustomerInfo?.lastName,
    email: state.previousCustomerInfo?.email,
    phoneNumber: state.previousCustomerInfo?.phoneNumber,
    serviceAddress: state.previousCustomerInfo.serviceAddress,
  }),
);

export const validatePersonalInformation = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) =>
    state.email.valid &&
    state.firstName.valid &&
    state.lastName.valid &&
    state.phone.valid &&
    state.mobileNumber.valid &&
    (!state.isSubsidyCheckboxChecked || state.subsidyApplicationId.valid),
);

export const selectEmail = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.email,
);
export const selectFirstName = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.firstName,
);
export const selectFullName = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => `${state.firstName.value} ${state.lastName.value}`,
);
export const selectLastName = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.lastName,
);

export const selectPhone = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.phone,
);
export const selectPhoneCountryCode = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.phoneCountryCode,
);
export const selectMobileNumber = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.mobileNumber,
);

export const selectIsPersonalInformationLoading = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.isPersonalInformationLoading,
);
export const selectShowConfirmBillingAddress = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.showConfirmBillingAddress,
);
export const selectIsDisclaimerCheckboxChecked = createSelector(
  [selectPersonalInformationDomain, (state) => state.app],
  (state: PersonalInformationState, appState) => state.isDisclaimerCheckboxChecked || appState.country !== Country.BR,
);
export const selectIsSubsidyCheckboxChecked = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.isSubsidyCheckboxChecked,
);
export const selectSubsidyApplicationId = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.subsidyApplicationId,
);
export const selectIsPersonalInformationRequestComplete = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => state.isPersonalInformationRequestComplete,
);

export const selectIsPersonalInformationButtonDisabled = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) =>
    !(state.firstName.valid && state.lastName.valid && state.email.valid && state.phone.valid),
);
