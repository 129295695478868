import { ApolloQueryResult, gql } from '@apollo/client';
import { GetAvailableProductsInputR0, Product } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type GetAvailableProductsPayload = ApolloQueryResult<{ getAvailableProducts: Product[] }>;

const GET_AVAILABLE_PRODUCTS = gql`
  query getAvailableProducts($input: GetAvailableProductsInputR0) {
    getAvailableProducts(input: $input) {
      id
      name
      characteristics {
        dataCap
        dataCapUnits
        uploadSpeed
        uploadUnits
        downloadSpeed
        downloadSpeedText
        downloadUnits
        displayOrder
        freeZone
        resolution
        productFamily
        dataAllowanceText
        textGeneral
        textGeneral01
        inflectionPointText
        isRegulated
        bannerColor
        uploadSpeedText
      }
      offerId
      price
      extensionTypes
      promo {
        price
        duration
      }
      bestFor
      isCafII
      totalDiscount {
        price
        duration
      }
    }
  }
`;

export default async function getPlansAsync({
  location,
  dealerId,
  partnerId,
  salesAgreementId,
  customerType,
}: GetAvailableProductsInputR0): Promise<GetAvailableProductsPayload> {
  return client.query({
    query: GET_AVAILABLE_PRODUCTS,
    variables: { input: { location, dealerId, partnerId, salesAgreementId, customerType } },
    fetchPolicy: 'no-cache',
  });
}
