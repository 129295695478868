const app = 'buy';
const DefaultSelectors = '.min.syndicated';
const NoBeamSelector = '.nobeam';
const NutritionLabelSelector = '.pidi';
const ExtensionPart = '.html';

export enum AemFeature {
  PLANS = 'plan-features',
  ADDONS = 'plan-addons',
  DISCLAIMERS = 'plan-disclaimers',
  FAQ = 'plan-faqs',
  MARKETING_AD_TOP = 'plan-banner-top',
  MARKETING_AD_BOTTOM = 'plan-banner-bottom',
  DIGITAL_SERVICES = 'digital-services',
  PAYMENT_DISCLOSURE = 'payment-disclosure',
  PLAN_BOTTOM_HERO = 'plan-hero-bottom',
  NEXT_STEPS = 'order-confirmation-next-steps',
  CART_AD = 'cart-ad',
  DISH_FRAGMENT = 'review-place-order-disclosure',
  MAINTENANCE_MODE = 'maintenance-mode',
  ERRORS = 'errors',
  MFE_PLAN_PRE_ADDRESS = 'plan-pre-address',
  PLAN_LABEL = 'plan-label',
  CLOSING_OFFERS = 'closing-offers',
}

export enum AemProductFamily {
  CAF = 'caf',
  BUSINESS_CAF = 'business-caf',
  CHOICE = 'choice-plan',
  CHOICE_PLUS_STREAM = 'choice-plus-stream',
  DIAMOND = 'diamond-plan',
  VS2_LARGE = 'vs2-large-plan',
  DEFAULT = 'master',
  NEXT_GEN = 'next-gen',
  NEXT_GEN_CAFII = 'next-gen-caf',
  UNLEASHED = 'unleashed',
  UNLIMITED = 'unlimited',
  BUSINESS_CHOICE = 'business-choice',
}

export enum AemPlanErrors {
  noAvailablePlans = 'no-available-plans',
  noService = 'no-service',
  error = 'master',
}

export type CustomerMap = {
  r: string;
  b: string;
};

export const createAemUrl = (
  baseUrl: string,
  locale: string,
  aemPage: AemFeature,
  prefix: string,
  customerType = 'residential',
  noBeam = false,
  upi?: string,
  serviceAreaId?: string,
  offerId?: string,
) => {
  const customerTypeMapping: CustomerMap = { r: 'residential', b: 'business' };
  const correctedCustomerType = customerTypeMapping[customerType as keyof typeof customerTypeMapping] || customerType;

  if (!baseUrl) return '';

  const prefixPart = prefix === '' ? '' : `/${prefix}`;
  const commonUrlPart = `${baseUrl}/${locale.split('-')[1].toLowerCase()}/${
    locale.split('-')[0]
  }/${app}/${correctedCustomerType}/${aemPage}${prefixPart}`;

  // default URL parts
  let selectorPart = `${noBeam ? NoBeamSelector : ''}${DefaultSelectors}`;
  let suffixPart = '';

  // Handle plan-label parameters
  if (aemPage === AemFeature.PLAN_LABEL) {
    if (upi === '') {
      upi = 'none';
    }

    if (serviceAreaId === '') {
      serviceAreaId = 'none';
    }

    if (offerId === '') {
      offerId = 'none';
    }

    selectorPart = `${NutritionLabelSelector}${selectorPart}`;
    suffixPart = `/${upi}/${serviceAreaId}/${offerId}/`;
  }

  // Handle closing offer XP on serviceability page
  if (aemPage === AemFeature.CLOSING_OFFERS) {
    if (offerId) {
      return `${commonUrlPart}/${offerId}${selectorPart}${ExtensionPart}`;
    }
  }

  return `${commonUrlPart}${selectorPart}${ExtensionPart}${suffixPart}`;
};

export const createHeroUrl = (baseUrl: string, imageOneUrl: string, customerType = 'residential'): string => {
  if (!baseUrl) return '';
  const imageOneUrlArray = imageOneUrl.split('/');
  if (customerType == 'r') customerType = 'residential';
  else if (customerType === 'b') {
    customerType = 'business';
    imageOneUrlArray[4] = customerType;
  }

  return `${baseUrl}/${imageOneUrlArray.join('/')}${DefaultSelectors}${ExtensionPart}`;
};
