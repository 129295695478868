import { FormFieldState } from '../types';
import { Maybe } from '@buy-viasat/types/build/bv';

export const defaultInstallationDate: InstallationDate = {
  month: null,
  year: null,
  day: null,
};

export const defaultInstallation: SelectedInstallation = {
  ...defaultInstallationDate,
  timezone: '',
  arrivalWindow: '',
  note: '',
};

export interface ScheduleInstallationState {
  availableInstallationDates: AvailableInstallationDates;
  calendarDate: CalendarDate;
  isButtonDisabled: boolean;
  loadingInstallationDates: boolean;
  rentalPropertiesModalSeen: boolean | null;
  selectedInstallation: FormFieldState<SelectedInstallation>;
  showCalendar: boolean;
  today: InstallationDate;
  hasSubmittedScheduleInstall: boolean;
  editInstallationSuccess: boolean | null;
}
export const initialScheduleInstallationState: ScheduleInstallationState = {
  availableInstallationDates: {} as AvailableInstallationDates,
  calendarDate: { month: new Date().getMonth() + 1, year: new Date().getFullYear() },
  isButtonDisabled: false,
  loadingInstallationDates: true,
  rentalPropertiesModalSeen: null,
  selectedInstallation: { value: defaultInstallation, valid: null, hintText: '' },
  showCalendar: true,
  today: {
    month: null,
    year: null,
    day: null,
  },
  hasSubmittedScheduleInstall: false,
  editInstallationSuccess: null,
};

export type AvailableInstallationDates = Record<
  string,
  {
    timezone: string;
    day: number;
    month: MonthsOfYear;
    year: number;
    timeSlots: Record<string, { from: string; to: string; selected: boolean }>;
  }
>;

export type CalendarDate = {
  month: MonthsOfYear;
  year: number;
};

export interface CalendarDateWithDay extends CalendarDate {
  day: Maybe<number>;
}

export interface InstallationDate {
  month: number | null;
  day: number | null;
  year: number | null;
}

export interface SelectedInstallation extends InstallationDate {
  timezone: string;
  arrivalWindow: string;
  note: string;
}

export enum MonthsOfYear {
  JANUARY = 1,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}
