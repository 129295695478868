import Close from '@vst/beam-icons/icons/Close';
import ContactUs from '@vst/beam-icons/illustrative-icons/ContactUs';
import { IllustrativeIcon, Txt, Button, Icon, IconComponentType, Surface, StandAloneLink } from '@vst/beam';

import styles from './modal.module.scss';

export type ModalFunction = () => void;

export interface SecondaryButtonModal {
  label: string;
  handleClick: ModalFunction;
}

interface PrimaryButtonModal {
  label: string;
  handleClick: ModalFunction;
}

interface CloseButton {
  handleClick: ModalFunction;
}

export interface ContactUsModalProps {
  iconName?: IconComponentType;
  heading: string | JSX.Element;
  bodyText: string | JSX.Element;
  tertiaryLink?: string;
  primaryButton: PrimaryButtonModal;
  secondaryButton?: SecondaryButtonModal;
  overlay?: string;
  closeButton?: CloseButton;
}

export const ContactUsModal = ({
  iconName = ContactUs,
  heading,
  bodyText,
  tertiaryLink,
  primaryButton,
  secondaryButton,
  overlay = 'rgba(17, 25, 32, 0.7)',
  closeButton,
}: ContactUsModalProps) => {
  return (
    <Surface className={styles['modal']} style={{ backgroundColor: overlay }}>
      <Surface className={styles['content']}>
        <Surface className={styles['header']}>
          {closeButton && (
            <Button
              variant="tertiary"
              className={styles['close']}
              onClick={closeButton.handleClick}
              data-testid="closeButtonModal"
            >
              <Icon icon={Close} className={styles['icon']} data-testid="closeIconModal" />
            </Button>
          )}
        </Surface>

        {iconName && <IllustrativeIcon icon={iconName} color="teal_600" data-testid={`${iconName}IconModal`} />}

        <Surface className={styles['main']}>
          <Txt variant="heading4" mt="24px" mb="16px" color="regular" data-testid="headingModal">
            {heading}
          </Txt>
          <Surface className={styles['text-container']}>
            <Txt variant="bodyLargeRegular" color="subtle" mb="24px" data-testid="bodyModal">
              {bodyText}
            </Txt>
          </Surface>
        </Surface>

        <Surface className={styles['footer']}>
          {tertiaryLink && (
            <StandAloneLink href={tertiaryLink} linkSize="medium" data-testid="tertiaryLinkModal">
              {tertiaryLink}
            </StandAloneLink>
          )}
          <Surface className={styles['primaryButton']}>
            <Button variant="primary" onClick={primaryButton.handleClick} data-testid={`${primaryButton.label}Modal`}>
              <Txt variant="labelMedium">{primaryButton.label}</Txt>
            </Button>

            {secondaryButton && (
              <Button
                variant="secondary"
                onClick={secondaryButton.handleClick}
                data-testid={`${secondaryButton.label}Modal`}
              >
                <Txt variant="labelMedium">{secondaryButton.label}</Txt>
              </Button>
            )}
          </Surface>
        </Surface>
      </Surface>
    </Surface>
  );
};
