import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';
import { FetchResult } from '@apollo/client';

const MODIFY_VPP_TRANSACTION = gql`
  mutation modifyVPPTransaction($input: UpdateVPPTransactionInput!) {
    modifyVPPTransaction(input: $input) {
      message
    }
  }
`;

type ModifyVppTransactionPayload = FetchResult<{
  message: string;
}>;

export default async function modifyVPPTransactionAsync(options: {
  transactionId: string;
  oneTimeTotal: number;
}): Promise<ModifyVppTransactionPayload> {
  return client.mutate({
    mutation: MODIFY_VPP_TRANSACTION,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        transactionId: options.transactionId,
        txnAmount: options.oneTimeTotal,
      },
    },
  });
}
