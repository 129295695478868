import { Surface, Txt } from '@vst/beam';
import { SellingPoint } from '@buy-viasat/utils';
import styles from './checkoutCartSpeedDetails.module.scss';

interface CheckoutCartSpeedDetailsProps {
  downloadSpeed: string;
  downloadUnits: string;
  sellingPoint: string;
  textGeneral: string;
  speedDetails: {
    above: string;
    below: string;
  };
}

export const CheckoutCartSpeedDetails = ({
  downloadSpeed,
  downloadUnits,
  textGeneral,
  sellingPoint,
  speedDetails,
}: CheckoutCartSpeedDetailsProps) => {
  return (
    <>
      {speedDetails.above && (
        <Txt color="subtle" data-testid={'upToSpeedDetails'} variant="smallRegular">
          {`${speedDetails.above}`}
        </Txt>
      )}
      <Surface className={styles['flex']}>
        <Txt color="subtle" data-testid={downloadSpeed} mr="4px" variant="heading2">
          {downloadSpeed}
        </Txt>
        <Txt color="subtle" data-testid={downloadUnits} variant="labelMedium">
          <span className={styles['downloadUnits']}>{downloadUnits}</span>
        </Txt>
      </Surface>
      <Txt
        color="subtle"
        data-testid={sellingPoint === SellingPoint.Speed ? 'maxDownloadSpeed' : 'highSpeedData'}
        variant="smallRegular"
      >
        {sellingPoint === SellingPoint.Speed ? `${speedDetails.below}` : textGeneral}
      </Txt>
    </>
  );
};
