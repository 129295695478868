import gql from 'graphql-tag';
import { SubmitFullOrderInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

const SUBMIT_FULL_ORDER = gql`
  mutation submitFullOrder($input: SubmitFullOrderInput!) {
    submitFullOrder(input: $input) {
      orderId
      externalWorkOrderId
    }
  }
`;

export default async function submitFullOrderAsync(options: SubmitFullOrderInput): Promise<any> {
  return client.mutate({
    mutation: SUBMIT_FULL_ORDER,
    variables: {
      input: options,
    },
  });
}
