import { AddBillingAccountInput } from '@buy-viasat/types/build/bv';
import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';

const ADD_BILLING_ACCOUNT = gql`
  mutation addBillingAccount($input: AddBillingAccountInput) {
    addBillingAccount(input: $input) {
      accountNumber
    }
  }
`;

export default async function addBillingAccountAsync(options: AddBillingAccountInput): Promise<any> {
  return client.mutate({
    mutation: ADD_BILLING_ACCOUNT,
    variables: {
      input: options,
    },
  });
}
