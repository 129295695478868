import { Txt } from '@vst/beam';
import { topperColor } from '@buy-viasat/utils';
import styles from './checkoutCartBadge.module.scss';

interface CheckoutCartBadgeProps {
  variant: string;
  tag: string;
  badgeColor: string;
}

export const CheckoutCartBadge = ({ tag, variant, badgeColor }: CheckoutCartBadgeProps) => {
  const badgeBg = badgeColor && styles[`${topperColor[badgeColor as keyof typeof topperColor]}Badge`];

  return (
    <Txt
      className={`${styles['planBadge']} ${badgeBg}`}
      data-testid={`${`planBadge${variant}`}`}
      px="8px"
      py="4px"
      variant="labelSmall"
    >
      {tag}
    </Txt>
  );
};
