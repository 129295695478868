declare global {
  interface Window {
    rg4js: (type: string, option: any) => void;
  }
}
export const scriptLoader = (
  a: any,
  b: any,
  c: any,
  d: any,
  e: any,
  scriptId: string,
  resolve: (value: typeof window.rg4js | PromiseLike<typeof window.rg4js>) => void,
  reject: (reason?: any) => void,
) => {
  a.RaygunObject = e;
  a[e] =
    a[e] ||
    function () {
      (a[e].o = a[e].o || []).push(arguments);
    };

  const existingScript = b.getElementById(scriptId);
  if (existingScript) {
    resolve(a[e]); // Resolve immediately if the script is already loaded
    return;
  }

  if (typeof document !== 'undefined') {
    const scriptElement = b.createElement(c);
    scriptElement.id = scriptId;
    scriptElement.defer = true;
    scriptElement.src = d;

    const headElement = b.head || b.getElementsByTagName('head')[0];

    if (headElement) {
      headElement.insertBefore(scriptElement, headElement.firstChild);

      const originalOnError = a.onerror;
      a.onerror = function (a: any, b: any, c: any, d: any, e: any) {
        if (originalOnError) {
          originalOnError(b, c, d);
          reject(originalOnError);
        }
      };

      scriptElement.onload = () => {
        resolve(a[e]); // Resolve with rg4js object
      };
    } else {
      console.warn('Head element not found. Cannot load script.');
      resolve(a[e]);
    }
  } else {
    console.warn('Cannot load script on the server.');
    resolve(a[e]);
  }
};
