import {
  createSlice as createSliceOriginal,
  CreateSliceOptions,
  Reducer,
  Slice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { RootState } from './types';

export const mergeReducers = <State>(reducers: Reducer<State>[]): Reducer<State> => {
  return (prevState: any, value) => {
    return reducers.reduce((newState, reducer) => reducer(newState, value), prevState);
  };
};

export const createSlice = <State, CaseReducers extends SliceCaseReducers<State>, Name extends keyof RootState>(
  options: CreateSliceOptions<State, CaseReducers, Name>,
): Slice<State, CaseReducers, Name> => {
  return createSliceOriginal(options);
};

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function isDefinedString<T>(value: T | undefined | null | string): value is T {
  return value !== undefined && value !== null && value !== '';
}

export const validateUrlParam = (param: string, maxLength: number): string | undefined => {
  if (!isDefined(param) || param.length > maxLength) {
    return undefined;
  }
  return param;
};

export const emptyOrRedacted = (value?: string | null) => (typeof value === 'string' && value !== '' ? '****' : value);
