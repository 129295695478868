import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { Routes, navActions } from '@buy-viasat/redux/src/navigator';
import { Serviceability } from '@buy-viasat/serviceability';
import { Product } from '@buy-viasat/types/build/bv';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '@buy-viasat/redux/src/auth';
import { BeamModalSelector } from '@buy-viasat/react';
import { BeamProvider, ToastContainer, useBreakpoints } from '@vst/beam';

// Lazy load routes after the home page
const PlanGrid = React.lazy(() => import('@buy-viasat/plans').then((module) => ({ default: module.Plans })));
const PlanDetails = React.lazy(() => import('@buy-viasat/plans').then((module) => ({ default: module.PlansDetails })));
const PersonalInformation = React.lazy(() =>
  import('@buy-viasat/account-information').then((module) => ({ default: module.AccountInformation })),
);
const CreditVerification = React.lazy(() =>
  import('@buy-viasat/credit-verification').then((module) => ({ default: module.CreditVerification })),
);
const PaymentInformation = React.lazy(() =>
  import('@buy-viasat/payment-information').then((module) => ({ default: module.PaymentInformation })),
);
const OrderReview = React.lazy(() =>
  import('@buy-viasat/order-review').then((module) => ({ default: module.OrderReview })),
);
const OrderConfirmation = React.lazy(() =>
  import('@buy-viasat/order-confirmation').then((module) => ({ default: module.OrderConfirmation })),
);
const Acp = React.lazy(() => import('@buy-viasat/acp').then((module) => ({ default: module.Acp })));
const OptionalServices = React.lazy(() => import('@buy-viasat/addons').then((module) => ({ default: module.Addons })));

const ScheduleInstallation = React.lazy(() =>
  import('@buy-viasat/schedule-installation').then((module) => ({ default: module.ScheduleInstallation })),
);

export type BeamFlowNavigatorProps = {
  baseURL: string;
  displayTooltipPlanGrid: boolean;
  onPlanEdit: () => void;
  onPlanDetails: () => void;
  onPlanSelect: (product: Product) => void;
};

export const BeamFlowNavigator = ({
  baseURL,
  displayTooltipPlanGrid,
  onPlanEdit,
  onPlanDetails,
  onPlanSelect,
}: BeamFlowNavigatorProps): JSX.Element => {
  const dispatch = useDispatch();

  const pathname = useLocation();

  const toastContainerDistance = useBreakpoints().viewport.smallerOrWithin.sm ? 22 : 95;

  const onPlanDetailsReturn = () => {
    dispatch(navActions.routeUserTo(Routes.PLAN_GRID));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <BeamProvider withToastProvider>
      <ToastContainer position="top-right" distance={toastContainerDistance} />
      <Switch>
        <Route exact path={`${baseURL}/${Routes.SERVICEABILITY}`} component={Serviceability} />
        <Route
          path={`${baseURL}/${Routes.PLAN}`}
          component={() => (
            <PlanGrid
              displayTooltipPlanGrid={displayTooltipPlanGrid || false}
              onPlanEdit={onPlanEdit}
              onPlanSelect={onPlanSelect}
              onPlanDetails={onPlanDetails}
            />
          )}
        />
        <Route
          path={`${baseURL}/${Routes.PLAN_GRID}`}
          component={() => (
            <PlanGrid
              displayTooltipPlanGrid={displayTooltipPlanGrid}
              onPlanEdit={onPlanEdit}
              onPlanSelect={onPlanSelect}
              onPlanDetails={onPlanDetails}
            />
          )}
        />
        <Route
          path={`${baseURL}/${Routes.PLAN_DETAILS}`}
          component={() => <PlanDetails onPlanSelect={onPlanSelect} onReturn={onPlanDetailsReturn} />}
        />
        <Route path={`${baseURL}/${Routes.PERSONAL_INFORMATION}`} component={PersonalInformation} />
        <Route path={`${baseURL}/${Routes.ADDONS}`} component={OptionalServices} />
        <Route path={`${baseURL}/${Routes.CREDIT_CHECK}`} component={CreditVerification} />
        <Route path={`${baseURL}/${Routes.SCHEDULE_INSTALLATION}`} component={ScheduleInstallation} />
        <Route path={`${baseURL}/${Routes.SUBSIDY_PROGRAM}`} component={Acp} />
        <Route path={`${baseURL}/${Routes.PAYMENT_INFORMATION}`} component={PaymentInformation} />
        <Route path={`${baseURL}/${Routes.ORDER_REVIEW}`} component={OrderReview} />
        <Route path={`${baseURL}/${Routes.ORDER_SUMMARY}`} component={OrderConfirmation} />
        <Route component={Serviceability} />
      </Switch>
      <BeamModalSelector />
    </BeamProvider>
  );
};
