export interface SubsidyProgramState {
  isReceivingMonthlyDiscount: boolean | null;
  qualifiedForTribalLands: boolean | null;
  isTermsAndConditionsChecked: boolean | null;
  isTransferCheckboxChecked: boolean;
  questionOneAnswer: string;
  questionTwoAnswer: string;
  isQuestionOneInvalid: boolean;
  isQuestionTwoInvalid: boolean;
  isTransferAgreementInvalid: boolean;
  isTermsAndConditionsInvalid: boolean;
  isFormValid: boolean | null;
  acpDetailsLater: boolean | null;
  isVerifyButtonLoading: boolean;
  responseView: string;
  verifyAttempts: number;
}

export const initialSubsidyProgramState: SubsidyProgramState = {
  isReceivingMonthlyDiscount: null,
  qualifiedForTribalLands: null,
  isTermsAndConditionsChecked: false,
  isTransferCheckboxChecked: false,
  questionOneAnswer: '',
  questionTwoAnswer: '',
  isQuestionOneInvalid: false,
  isQuestionTwoInvalid: false,
  isTransferAgreementInvalid: false,
  isTermsAndConditionsInvalid: false,
  isFormValid: null,
  acpDetailsLater: null,
  isVerifyButtonLoading: false,
  responseView: '',
  verifyAttempts: 0,
};

export enum RadioButtonTypes {
  FIRST = 'first',
  SECOND = 'second',
}
