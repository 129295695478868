import { TextField, validators } from '@vst/beam';
import { ComponentProps, useMemo } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export interface ZipCodeFieldProps extends NumberFormatProps<ComponentProps<typeof TextField>> {
  value?: string;
  regex: RegExp;
  format?: string;
  mask?: string;
  validation?: {
    required: string;
    invalid: string;
  };
}

const ZipCodeFieldInput = (props: ZipCodeFieldProps) => {
  const { regex, validation, format, mask = 'x', ...textFieldProps } = props;

  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(validators.required({ message: validation.required }));
    }
    if (validation?.invalid) {
      validationRules.push(validators.pattern({ pattern: regex, message: validation.invalid }));
    }
    return validationRules;
  }, [validation, regex]);

  return format ? (
    <NumberFormat
      mask={mask}
      format={format}
      customInput={TextField}
      data-testid={textFieldProps.name}
      required={!!validation?.required}
      validationRules={validationRules}
      {...textFieldProps}
      labelProps={{ labelText: textFieldProps.labelProps?.labelText, showStar: false }}
    />
  ) : (
    <TextField
      data-testid={textFieldProps.name}
      validationRules={validationRules}
      labelProps={{ showStar: false, labelText: textFieldProps.labelProps?.labelText }}
      {...textFieldProps}
    />
  );
};

export default ZipCodeFieldInput;
