import { benchmark } from 'shared/containers/App/saga';
import { call, put, select, takeLatest, CallEffect, SelectEffect, PutEffect, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { scheduleInstallationActions } from '@buy-viasat/redux/src/schedule-installation';
import { selectCoordinates, selectServiceAddressValues } from '@buy-viasat/redux/src/serviceability';
import clientLogger from '../../utils/clientLogger';
import getInstallationDatesAsync, {
  GetInstallationDatesPayload,
} from '../../providers/requests/fulfillment/getInstallationDates';
import { dateStringToObj } from './utils';
import { GET_WORK_ORDER_RETRY_COUNT } from './types';
import { AvailableInstallationDates, SelectedInstallation } from '@buy-viasat/redux/src/schedule-installation';
import { selectCartId } from '@buy-viasat/redux/src/cart';
import {
  selectFulfillmentAgreementId,
  selectSalesAgreementId,
  selectUrlParams,
  selectWorkOrderId,
  Modals,
  QueryParams,
} from '@buy-viasat/redux/src/app';
import { AddressType } from '@buy-viasat/redux/src/address';
import { FormFieldState } from '../Checkout/types';
import {
  selectNoAvailableInstallationDates,
  selectSelectedInstallation,
} from '@buy-viasat/redux/src/schedule-installation';
import { Schedule, WorkOrderInformation } from '@buy-viasat/types/build/bv';
import { constructWorkOrderInformation } from 'shared/Navigator/utils';
import editInstallationDatesAsync from 'shared/providers/requests/fulfillment/editInstallationDates';
import { appActions } from '@buy-viasat/redux/src/app';
import { isUnscheduledWorkOrderAsync } from './utils';
import { adobeTargetDataLayer } from 'shared/containers/Analytics';
import { DataLayerEvents } from 'shared/containers/Analytics/types';

export function* getInstallationDatesSaga(
  action: PayloadAction<string>,
): Generator<
  CallEffect | SelectEffect | PutEffect,
  void,
  GetInstallationDatesPayload & AddressType & string & QueryParams
> {
  const serviceAddress = yield select(selectServiceAddressValues);
  const coordinates = yield select(selectCoordinates);
  const cartId = yield select(selectCartId);
  const { dealerId, partnerId } = yield select(selectUrlParams);
  const salesAgreementId = yield select(selectSalesAgreementId);
  const fulfillmentAgreementId = yield select(selectFulfillmentAgreementId);
  // Dispatch an action to indicate that the installation dates are loading
  yield put(scheduleInstallationActions.setInstallationDatesLoading());

  try {
    const { data } = yield call<any>(getInstallationDatesAsync, {
      cartId,
      location: { address: serviceAddress, coordinates },
      from: action.payload,
      dealerId,
      partnerId,
      salesAgreementId,
      fulfillmentAgreementId,
    });
    const results = data.getInstallationDates;
    if (results.schedules) {
      const formattedInstallationDates: AvailableInstallationDates = dateStringToObj(
        results.schedules,
        results?.timezone ?? '',
      );
      yield put(scheduleInstallationActions.setAvailableInstallationDates(formattedInstallationDates));
      const noAvailableInstallationDates = yield select(selectNoAvailableInstallationDates);
      adobeTargetDataLayer.push({
        event: DataLayerEvents.AA_EVENT,
        eventData: {
          event: 'noAvailableInstallationDates',
          scheduleInstallation: {
            noAvailableInstallationDates: noAvailableInstallationDates,
          },
        },
      });
    }
  } catch (e) {
    clientLogger('Error in loadInstallation saga', e);
    yield put(scheduleInstallationActions.getInstallationDatesError());
  } finally {
    // Dispatch an action to indicate that the installation dates loading has completed
    yield put(scheduleInstallationActions.installationDatesLoadingComplete());
  }
}

export function* editInstallationDatesSaga() {
  const workOrderId: string = yield select(selectWorkOrderId);
  const installationDate: FormFieldState<SelectedInstallation> = yield select(selectSelectedInstallation);
  const workOrderInformation: WorkOrderInformation | null = yield call(
    constructWorkOrderInformation,
    installationDate.value,
  );
  const schedule: Schedule = {
    from: workOrderInformation?.appointment?.startTime,
    to: workOrderInformation?.appointment?.endTime,
  };
  try {
    yield put(appActions.setIsCheckoutButtonDisabled(false));
    const selectedInstallation: FormFieldState<SelectedInstallation> = yield select(selectSelectedInstallation);
    const {
      value: { month, day, year, arrivalWindow },
    } = selectedInstallation;

    const dateIsSelected = Boolean(month && day && year);
    const timeIsSelected = arrivalWindow !== '';

    const isUnscheduled: boolean = yield call<any>(isUnscheduledWorkOrderAsync, { externalWorkOrderId: workOrderId });
    if (!isUnscheduled) {
      yield put(appActions.setModalVisible(Modals.ERROR));
      yield put(scheduleInstallationActions.installationDatesLoadingComplete());
    } else if (dateIsSelected && timeIsSelected) {
      yield put(appActions.setIsScheduleInstallationComplete(true));
      const { data } = yield call<any>(editInstallationDatesAsync, { externalWorkOrderId: workOrderId, schedule });
      yield put(scheduleInstallationActions.setEditInstallationSuccess(data.editInstallationDate));
    } else {
      yield put(scheduleInstallationActions.setHasSubmittedScheduleInstall(true));
      yield put(scheduleInstallationActions.installationDatesLoadingComplete());
    }
  } catch (err: any) {
    yield put(scheduleInstallationActions.setEditInstallationSuccess(false));
  }
}

export function* editInstallationDatesLoadingSaga() {
  let isSuccessful = false;
  let count = 0;
  while (!isSuccessful && count < GET_WORK_ORDER_RETRY_COUNT) {
    yield delay(5000);
    const workOrderId: string = yield select(selectWorkOrderId);
    isSuccessful = yield call<any>(isUnscheduledWorkOrderAsync, { externalWorkOrderId: workOrderId });
    count++;
  }

  yield put(scheduleInstallationActions.installationDatesLoadingComplete());
}

/**
 * Root saga manages watcher lifecycle
 */
export function* scheduleInstallationSaga(): Generator {
  yield takeLatest(
    scheduleInstallationActions.editInstallationDatesLoading.type,
    benchmark(editInstallationDatesLoadingSaga),
  );
  yield takeLatest(scheduleInstallationActions.editInstallationDates.type, benchmark(editInstallationDatesSaga));
  yield takeLatest(
    scheduleInstallationActions.getInstallationDates.type,
    benchmark<string, CallEffect | SelectEffect | PutEffect, void, GetInstallationDatesPayload & AddressType & string>(
      getInstallationDatesSaga,
    ),
  );
}
