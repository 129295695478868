export enum Routes {
  SERVICEABILITY = '',
  PLAN = 'pln',
  ADDONS = 'adds',
  PERSONAL_INFORMATION = 'prsnlInf',
  CREDIT_CHECK = 'cc',
  SCHEDULE_INSTALLATION = 'schdInst',
  PAYMENT_INFORMATION = 'pmntInf',
  ORDER_REVIEW = 'ordRvw',
  ORDER_SUMMARY = 'ordSum',
  SCHEDULE_INSTALLATION_POST_ORDER = 'schdInstPO',
  ERROR = 'err',
  SUBSIDY_PROGRAM = 'sbsdyPrgm',
  PLAN_GRID = 'plnGrd',
  PLAN_DETAILS = 'plnDtl',
}

type RouteState = {
  Next?: Routes;
  Previous?: Routes;
  Reset?: Routes;
  order: number;
};

export type RouteStates = Record<Routes, RouteState>;

export const routeStates: RouteStates = {
  [Routes.SERVICEABILITY]: {
    order: 0,
    Next: Routes.PLAN,
  },
  [Routes.PLAN]: {
    order: 1,
    Next: Routes.PERSONAL_INFORMATION,
    Previous: Routes.SERVICEABILITY,
  },
  [Routes.PERSONAL_INFORMATION]: {
    order: 2,
    Next: Routes.CREDIT_CHECK,
    Previous: Routes.PLAN,
  },
  [Routes.CREDIT_CHECK]: {
    order: 3,
    Next: Routes.ADDONS,
    Previous: Routes.PERSONAL_INFORMATION,
  },
  [Routes.ADDONS]: {
    order: 4,
    Next: Routes.SCHEDULE_INSTALLATION,
    Previous: Routes.CREDIT_CHECK,
  },
  [Routes.SUBSIDY_PROGRAM]: {
    order: 5,
    Next: Routes.SCHEDULE_INSTALLATION,
    Previous: Routes.ADDONS,
  },
  [Routes.SCHEDULE_INSTALLATION]: {
    order: 6,
    Next: Routes.PAYMENT_INFORMATION,
    Previous: Routes.ADDONS,
  },
  [Routes.PAYMENT_INFORMATION]: {
    order: 7,
    Next: Routes.ORDER_REVIEW,
    Previous: Routes.SCHEDULE_INSTALLATION,
  },
  [Routes.ORDER_REVIEW]: {
    order: 8,
    Next: Routes.ORDER_SUMMARY,
    Previous: Routes.PAYMENT_INFORMATION,
  },
  [Routes.ORDER_SUMMARY]: {
    order: 9,
    Reset: Routes.SERVICEABILITY,
  },
  [Routes.SCHEDULE_INSTALLATION_POST_ORDER]: {
    order: 10,
    Reset: Routes.SERVICEABILITY,
    Previous: Routes.ORDER_SUMMARY,
  },
  [Routes.ERROR]: { order: NaN },
  [Routes.PLAN_GRID]: {
    Next: Routes.PERSONAL_INFORMATION,
    Previous: Routes.SERVICEABILITY,
    Reset: undefined,
    order: 0,
  },
  [Routes.PLAN_DETAILS]: {
    Next: Routes.PERSONAL_INFORMATION,
    Previous: Routes.PLAN_GRID,
    Reset: undefined,
    order: 0,
  },
};

export type NavState = {
  currentAppRoute: Routes;
  routeStates: RouteStates;
  maxRoute: Routes;
};

export const initialNavState: NavState = {
  currentAppRoute: Routes.SERVICEABILITY,
  routeStates: routeStates,
  maxRoute: Routes.SERVICEABILITY,
};

export const routesIntlMapping: Record<string, string> = {
  [Routes.PLAN]: 'plan',
  [Routes.ADDONS]: 'addons',
  [Routes.PERSONAL_INFORMATION]: 'personalInformation',
  [Routes.CREDIT_CHECK]: 'creditCheck',
  [Routes.SCHEDULE_INSTALLATION]: 'scheduleInstallation',
  [Routes.PAYMENT_INFORMATION]: 'paymentInformation',
  [Routes.ORDER_REVIEW]: 'orderReview',
  [Routes.ORDER_SUMMARY]: 'orderSummary',
  [Routes.SCHEDULE_INSTALLATION_POST_ORDER]: 'scheduleInstallationPostOrder',
  [Routes.ERROR]: 'error',
  [Routes.SUBSIDY_PROGRAM]: 'subsidyProgram',
};

export enum ScheduleInstallLocations {
  CreditCheck = 'CreditCheck',
  OrderSummary = 'OrderSummary',
  Payments = 'Payments',
  None = 'None',
}

export enum COOKIE_NAME {
  dealerId = 'DID',
  partnerId = 'PAID',
  userLDKey = 'LDKEY',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  postalCode = 'postalCode',
}
