import { AddonsState } from './addons';
import { AddressState } from './address';
import { AppState } from './app';
import { AuthState } from './auth';
import { BusinessInformationState } from './business-information';
import { CartState } from './cart';
import { ClosingOffersState } from './closingOffers';
import { CreditVerificationState } from './credit-verification';
import { EnvState } from './env';
import { NavState } from './navigator';
import { PaymentInformationState } from './payment-information';
import { PersonalInformationState } from './personal-information';
import { PlanState } from './plan';
import { ScheduleInstallationState } from './schedule-installation';
import { ServiceabilityState } from './serviceability';
import { SubsidyProgramState } from './subsidy-program';

export interface RootState {
  addons: AddonsState;
  closingOffers: ClosingOffersState;
  address: AddressState;
  app: AppState;
  auth: AuthState;
  businessInformation: BusinessInformationState;
  cart: CartState;
  creditVerification: CreditVerificationState;
  env: EnvState;
  navigator: NavState;
  paymentInformation: PaymentInformationState;
  personalInformation: PersonalInformationState;
  plan: PlanState;
  scheduleInstallation: ScheduleInstallationState;
  serviceability: ServiceabilityState;
  subsidyProgram: SubsidyProgramState;
}

export type FormFieldState<T> = {
  value: T;
  valid: boolean | null;
  hintText: string;
};

export const RESET_AUTH_ACTION = { type: 'resetAuth' };
export const RESET_STATE_ACTION = { type: 'resetState' };
