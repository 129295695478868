import { apolloClient } from '@buy-viasat/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FEATURE_FLAGS, ThunkActions } from './types';
import { FeatureFlagsInput } from '@buy-viasat/types/build/bv';
import { appActions } from '@buy-viasat/redux/src/app';

export const getFeatureFlags = createAsyncThunk(
  ThunkActions?.getFeatureFlags,
  async (input: FeatureFlagsInput, thunkAPI: any) => {
    try {
      const result = await apolloClient.query({
        query: FEATURE_FLAGS,
        variables: {
          input,
        },
      });
      thunkAPI.dispatch(appActions.setFeatureFlag(result?.data.getFeatureFlags ?? {}));
    } catch (error) {
      thunkAPI.dispatch(appActions.setFeatureFlag({}));
      console.error(error);
    }
  },
);
