import { Col, Container, Row, Surface, Txt } from '@vst/beam';
import styles from './checkoutCartDueToday.module.scss';
import { selectCartDomain, selectOnetimeDiscount, selectOnetimeTotalWithoutPromo } from '@buy-viasat/redux/src/cart';
import { useSelector } from 'react-redux';
import { calculateDueTodayTotal, isDefined, priceDecimalFormat } from '@buy-viasat/utils';
import {
  CustomerType,
  selectCustomerType,
  selectFeatureFlags,
  selectLocale,
  selectSalesFlowDefinition,
} from '@buy-viasat/redux/src/app';
import { selectVppPaymentType } from '@buy-viasat/redux/src/payment-information';
import { useTotalPriceVisibility } from '@buy-viasat/react/src/components/CheckoutCart/hooks';

type CheckoutCartDueTodayProps = {
  dueToday: string;
  totalDueToday: string;
  calculatedLaterLabel?: string;
  isBackgroundAPICallLoading: boolean;
};

export const CheckoutCartDueToday = ({
  dueToday,
  totalDueToday,
  calculatedLaterLabel,
  isBackgroundAPICallLoading,
}: CheckoutCartDueTodayProps) => {
  const cart = useSelector(selectCartDomain);
  const onetimeTotalWithoutPromo = useSelector(selectOnetimeTotalWithoutPromo);
  const paymentType = useSelector(selectVppPaymentType);
  const oneTimeDiscount = useSelector(selectOnetimeDiscount);
  const locale = useSelector(selectLocale);
  const { feesAndDiscounts } = cart;
  const customerType = useSelector(selectCustomerType);
  const isBusinessUser = customerType === CustomerType.BUSINESS;

  const { currencySymbol } = useSelector(selectSalesFlowDefinition);
  const { shouldDisplayDueToday: shouldDisplayDueTodayFlag } = useSelector(selectFeatureFlags);
  const { visible: priceValueDisplayed } = useTotalPriceVisibility(isBackgroundAPICallLoading);
  const shouldDisplayDueToday = shouldDisplayDueTodayFlag && priceValueDisplayed;
  const { dueTodayTotal, dueTodayTotalValue } = calculateDueTodayTotal(
    onetimeTotalWithoutPromo || 0,
    paymentType,
    oneTimeDiscount || 0,
    locale,
    currencySymbol,
  );
  const leaseFeeValue = priceDecimalFormat(cart.oneTimeLeaseFee ?? '--', locale, currencySymbol);
  const shouldDisplayTotalDueToday = shouldDisplayDueTodayFlag && !isBusinessUser;

  return (
    <Container fluidUntil={'md'} pb="24px" data-testid="dueTodayContainer">
      <Row edge2edge>
        <Col>
          <Surface pt={'16px'} className={styles['container']} data-testid="dueTodaySurface">
            {shouldDisplayDueToday && (
              <>
                <Txt
                  variant="headerCapsMedium"
                  pb="16px"
                  color="subtle"
                  className={styles['dueToday']}
                  data-testid="dueToday"
                >
                  {dueToday}
                </Txt>
                {feesAndDiscounts?.map((feeDiscount) => {
                  const hasDiscount = (feeDiscount.discount ?? 0) < 0;
                  return (
                    <Surface
                      className={styles['additionalFees']}
                      data-testid="installationFee"
                      pb="12px"
                      key={`additionalFees-${feeDiscount.discount}`}
                    >
                      <Txt variant="bodySmallRegular" color="subtle">
                        {feeDiscount.name}
                      </Txt>
                      <Surface
                        className={hasDiscount ? styles['planPrice'] : styles['noPromo']}
                        data-testid="planPriceSurface"
                      >
                        <Txt
                          variant="bodySmallBold"
                          color={hasDiscount ? 'success' : 'info'}
                          data-testid="installationFeeWithPromo"
                          className={styles['installationFeeWithPromo']}
                        >
                          {priceDecimalFormat(feeDiscount.total ?? '--', locale, currencySymbol)}
                        </Txt>
                        {hasDiscount && (
                          <Txt variant={'bodySmallItalic'} className={styles['strikethrough']} color={'subtle'}>
                            {priceDecimalFormat(feeDiscount.fee ?? '--', locale, currencySymbol)}
                          </Txt>
                        )}
                      </Surface>
                    </Surface>
                  );
                })}
              </>
            )}
            {cart.leaseFeeShortName && (
              <Surface className={styles['additionalFees']} data-testid="dueTodayLeaseFee" pb="8px">
                <Txt variant="bodySmallRegular" color="subtle" data-testid="dueTodayLeaseFeeShortName">
                  {cart.leaseFeeShortName}
                </Txt>
                <Txt variant="bodySmallRegular" color="subtle" data-testid="dueTodayLeaseFeePrice">
                  {leaseFeeValue}
                </Txt>
              </Surface>
            )}
            <Surface
              className={shouldDisplayDueToday ? styles['dueTodayTotal'] : styles['dueTodayTotalNoBorderTop']}
              data-testid="dueTodayTotal"
              pt="12px"
              pb="32px"
            >
              <Surface className={styles['dueTodayHeadline']}>
                <Txt variant="heading6">{totalDueToday}</Txt>
                <Txt
                  variant={priceValueDisplayed ? 'paragraphBold' : 'bodySmallItalic'}
                  className={styles[priceValueDisplayed ? 'dueTodayValue' : 'priceCalculatedLater']}
                >
                  {isDefined(dueTodayTotal) && priceValueDisplayed
                    ? dueTodayTotalValue
                    : shouldDisplayTotalDueToday
                      ? calculatedLaterLabel
                      : '--'}
                </Txt>
              </Surface>
            </Surface>
          </Surface>
        </Col>
      </Row>
    </Container>
  );
};
