import {
  selectFeatureFlags,
  selectLocale,
  selectSalesFlowDefinition,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import { selectCartDomain } from '@buy-viasat/redux/src/cart';
import { Icon, Surface, Txt } from '@vst/beam';
import { Offer } from '@vst/beam-icons/icons';
import { SkeletonLoader } from '../../SkeletonLoader';
import { useSelector } from 'react-redux';
import styles from './checkoutCartClosingOffers.module.scss';
import { priceDecimalFormat } from '@buy-viasat/utils';

type CheckoutCartClosingOffersProps = {
  shouldDisplayClosingOffer: boolean;
  isBackgroundAPICallLoading: boolean;
};

export const CheckoutCartClosingOffers = ({
  shouldDisplayClosingOffer,
  isBackgroundAPICallLoading,
}: CheckoutCartClosingOffersProps) => {
  const { offerId: closingOfferIdURLParam } = useSelector(selectUrlParams);
  const { enablingClosingOffersInBV } = useSelector(selectFeatureFlags);
  const cart = useSelector(selectCartDomain);
  const checkForExistingClosingOffers = cart.cartItems?.closingOffers?.find(
    (closingOffer) => closingOffer?.productTypeId === closingOfferIdURLParam,
  );
  const locale = useSelector(selectLocale);
  const { currencySymbol } = useSelector(selectSalesFlowDefinition);
  const closingOfferPlanPrice = priceDecimalFormat(checkForExistingClosingOffers?.price ?? '', locale, currencySymbol);

  return (
    <Surface variant={'secondary'} data-testid={'closingOffer'}>
      <div className={styles['closingOfferContainer']}>
        {closingOfferIdURLParam && enablingClosingOffersInBV && isBackgroundAPICallLoading ? (
          <>
            <SkeletonLoader characters={15} height={'sm'} />
            <SkeletonLoader characters={5} height={'sm'} />
          </>
        ) : (
          shouldDisplayClosingOffer && (
            <>
              <Txt color="success" variant="bodySmallBold" className={styles['offerAndTag']}>
                {checkForExistingClosingOffers?.name}
                <Icon icon={Offer} size="16px" color="green_700" ml="8px" />
              </Txt>

              <Txt color="success" variant="bodySmallBold">
                {`${closingOfferPlanPrice} `}
              </Txt>
            </>
          )
        )}
      </div>
    </Surface>
  );
};
