export enum Country {
  US = 'US',
  BR = 'BR',
  IT = 'IT',
}

export enum CustomerType {
  RESIDENTIAL = 'residential',
  BUSINESS = 'business',
}

export enum InputCustomerType {
  RESIDENTIAL = 'r',
  BUSINESS = 'b',
}
