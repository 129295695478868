import { ApolloQueryResult, gql } from '@apollo/client';
import { EditInstallationDateInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

const EDIT_INSTALLATION_DATE = gql`
  mutation editInstallationDate($input: EditInstallationDateInput!) {
    editInstallationDate(input: $input)
  }
`;

export default async function editInstallationDatesAsync(input: EditInstallationDateInput): Promise<any> {
  return client.mutate({
    mutation: EDIT_INSTALLATION_DATE,
    variables: {
      input: input,
    },
  });
}
