import { appActions, selectTimer } from '@buy-viasat/redux/src/app';

import { delay, fork, put, select } from 'redux-saga/effects';
import { sendRaygunMessage } from 'shared/utils/raygun';

export function* clearTimer() {
  yield delay(5 * 1000);
  yield put(appActions.setTimerValue(0));
}

export function* triggerSetCallingSaga(payload: string) {
  const timer: ReturnType<typeof setTimeout> = yield select(selectTimer);
  yield fork(clearTimer);
  if (timer) {
    sendRaygunMessage(payload);
  } else {
    yield put(appActions.setTimerValue(1));
  }
}
