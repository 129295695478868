import { createAsyncThunk } from '@reduxjs/toolkit';
import { SCRUB_ADDRESS, ScrubAddressWithCoordinatesPayload, ThunkActions } from './types';
import { AddressInputR0, ScrubAddressInput } from '@buy-viasat/types/build/bv';
import { apolloClient, isDefined } from '@buy-viasat/utils';
import { serviceabilityActions } from './';
import { AddressType } from '../address/types';

export const scrubAddress = createAsyncThunk(ThunkActions.scrubAddress, async (input: AddressType, thunkAPI: any) => {
  const sanitizeAddressLines = (address: AddressInputR0) => {
    const { addressLines } = address;
    if (addressLines[1] === null || addressLines[1] === undefined)
      return { ...address, addressLines: [addressLines[0], ''] };
    return address;
  };

  const sanitizeAddress = sanitizeAddressLines(input as AddressInputR0);
  const scrubAddressInput: ScrubAddressInput = {
    address: input as AddressInputR0,
  };

  const addressWithCoordinates: ScrubAddressWithCoordinatesPayload = await apolloClient.query({
    query: SCRUB_ADDRESS,
    variables: { input: { ...scrubAddressInput, address: sanitizeAddress } },
  });

  const {
    data: { scrubAddress },
  } = addressWithCoordinates;

  const { addressLines } = scrubAddress;
  const { region, municipality, postalCode, countryCode } = scrubAddress;
  thunkAPI.dispatch(
    serviceabilityActions.setCoordinates({
      latitude: scrubAddress.latitude ?? 0,
      longitude: scrubAddress.longitude ?? 0,
    }),
  );
  if (!isDefined(addressLines[1])) {
    addressLines[1] = '';
  }
  thunkAPI.dispatch(
    serviceabilityActions.setServiceAddress({ addressLines, municipality, region, postalCode, countryCode }),
  );
});
