import { useState } from 'react';
import { DateOfBirthInputProps } from '.';
import { FormValue, IValidationResult } from '@vst/shared/src/lib/form';

function isValidDate(year: number, month: number, day: number): boolean {
  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

export function calculateAge(dateOfBirth: Date) {
  const dateDifference = Date.now() - dateOfBirth.getTime();
  const ageDifference = new Date(dateDifference);

  return Math.abs(ageDifference.getUTCFullYear() - 1970);
}

export const useDateOfBirthValidator = (validation: DateOfBirthInputProps['validation']) => {
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const validator = (
    _value: FormValue,
    formField: HTMLInputElement | HTMLElement | HTMLSelectElement,
    resetError?: boolean,
  ): IValidationResult => {
    if (resetError) {
      setError(false);
      setMessage('');
      return { error: false, message: '' };
    }

    const { form } = formField as HTMLInputElement;
    const day = form?.['dob-day'];
    const month = form?.['dob-month'];
    const year = form?.['dob-year'];

    const dayValue = isNaN(Number(day.value)) ? 0 : Number(day.value);
    const monthValue = isNaN(Number(month.value)) ? 0 : Number(month.value);
    const yearValue = isNaN(Number(year.value)) ? 0 : Number(year.value);

    const inputDate = new Date(yearValue, monthValue - 1, dayValue);
    const age = calculateAge(inputDate);

    if (dayValue === 0) {
      setError(true);
      setMessage(validation.day.required);
      return { message: '', error: true };
    } else if (dayValue < 1 || dayValue > 31) {
      setError(true);
      setMessage(validation.day.invalid);
      return { message: '', error: true };
    }

    if (monthValue === 0) {
      setError(true);
      setMessage(validation.month.required);
      return { message: '', error: true };
    } else if (monthValue < 1 || monthValue > 12) {
      setError(true);
      setMessage(validation.month.invalid);
      return { message: '', error: true };
    }

    if (yearValue === 0) {
      setError(true);
      setMessage(validation.year.required);
      return { message: '', error: true };
    } else if (age > 0 && age < 18) {
      setError(true);
      setMessage(validation.year.min18);
      return { message: '', error: true };
    } else if (age >= 18 && age < 200) {
      if (isValidDate(yearValue, monthValue, dayValue)) {
        setError(false);
        setMessage('');
        return { error: false, message: '' };
      } else {
        setError(true);
        setMessage(validation.day.invalid);
        return { message: '', error: true };
      }
    } else {
      setError(true);
      setMessage(validation.year.invalid);
      return { message: '', error: true };
    }
  };

  return { error, message, validator };
};
