import { PayloadAction } from '@reduxjs/toolkit';
import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { Country } from '@buy-viasat/types/build/bv';
import { zipCodeRegex } from '@buy-viasat/utils';
import { createSlice } from '../utils';
import { RESET_STATE_ACTION } from '../types';
import {
  AddressFieldStateType,
  AddressQueryParams,
  AddressState,
  AddressType,
  initialAddressState,
  LocationType,
} from './types';
import {
  validateHouseNumber,
  validateMunicipality,
  validateRegion,
  validateStreetAddress,
  validateZip,
} from './validations';

export const addressSlice = createSlice({
  name: 'address',
  initialState: initialAddressState,
  reducers: {
    setBillingAddress(state, action: PayloadAction<AddressType>): void {
      let addressLine = `${action.payload.addressLines[0].trim()}`;
      state.billingAddress.addressLines.value[0] = action.payload.addressLines[0].split(', ')[0];
      state.billingAddress.addressLines.value[1] =
        action.payload.addressLines[1] || addressLine.split(',')[1]?.trim() || '';
      state.billingAddress.municipality.value = action.payload.municipality;
      state.billingAddress.region.value = action.payload.region;
      state.billingAddress.postalCode.value = action.payload.postalCode;
      state.billingAddress.countryCode.value = action.payload.countryCode;
      state.billingAddress.houseNumber.value = action.payload.houseNumber ?? undefined;
    },
    setPreviousBillingAddress(state, action: PayloadAction<AddressFieldStateType>): void {
      state.previousBillingAddress.addressLines.value[0] = action.payload?.addressLines.value[1]
        ? action.payload.addressLines.value[0].split(',')[0] +
          i18n.t('serviceability:common.address.appendApt') +
          action.payload.addressLines.value[1]
        : action.payload.addressLines.value[0];
      state.previousBillingAddress.addressLines.value[1] = action.payload.addressLines.value[1];
      state.previousBillingAddress.municipality = action.payload.municipality;
      state.previousBillingAddress.region = action.payload.region;
      state.previousBillingAddress.postalCode = action.payload.postalCode;
      state.previousBillingAddress.countryCode = action.payload.countryCode;
      state.previousBillingAddress.houseNumber = action.payload.houseNumber ?? undefined;
    },
    setPreviousShippingAddress(state, action: PayloadAction<AddressFieldStateType>): void {
      state.previousShippingAddress.addressLines.value[0] = action.payload?.addressLines.value[1]
        ? action.payload.addressLines.value[0].split(',')[0] +
          i18n.t('serviceability:common.address.appendApt') +
          action.payload.addressLines.value[1]
        : action.payload.addressLines.value[0];
      state.previousShippingAddress.addressLines.value[1] = action.payload.addressLines.value[1];
      state.previousShippingAddress.municipality = action.payload.municipality;
      state.previousShippingAddress.region = action.payload.region;
      state.previousShippingAddress.postalCode = action.payload.postalCode;
      state.previousShippingAddress.countryCode = action.payload.countryCode;
      state.previousShippingAddress.houseNumber = action.payload.houseNumber ?? undefined;
    },
    setBillingAddressHousingNumber(state, action: PayloadAction<string>): void {
      state.billingAddress.houseNumber.value = action.payload;
    },
    setBillingAddressLine1(state, action: PayloadAction<string>): void {
      state.billingAddress.addressLines.value[0] = action.payload;
    },
    setBillingAddressLine2(state, action: PayloadAction<string>): void {
      state.billingAddress.addressLines.value[1] = action.payload;
    },
    setBillingAddressLinesValid({ billingAddressEqualsServiceAddress, billingAddress: { addressLines } }): void {
      const validation = validateStreetAddress(addressLines.value[0]);
      addressLines.valid = billingAddressEqualsServiceAddress || validation.valid;
      addressLines.hintText = validation.hintText;
    },
    setBillingCountryCode(state, action: PayloadAction<string>): void {
      state.billingAddress.countryCode.value = action.payload;
    },
    setBillingEqualsServiceAddress(state): void {
      state.billingAddressEqualsServiceAddress = !state.billingAddressEqualsServiceAddress;
    },
    setBillingMunicipality(state, action: PayloadAction<string>): void {
      state.billingAddress.municipality.value = action.payload;
    },
    setBillingMunicipalityValid({ billingAddressEqualsServiceAddress, billingAddress: { municipality } }): void {
      const validation = validateMunicipality(municipality.value);
      municipality.valid = billingAddressEqualsServiceAddress || validation.valid;
      municipality.hintText = validation.hintText;
    },
    setBillingNotEqualToServiceAddress(state): void {
      state.billingAddressEqualsServiceAddress = false;
    },
    setBillingPostalCode(state, action: PayloadAction<string>): void {
      state.billingAddress.postalCode.value = action.payload;
    },
    setBillingRegion(state, action: PayloadAction<string>): void {
      state.billingAddress.region.value = action.payload;
    },
    setBillingRegionValid({ billingAddressEqualsServiceAddress, billingAddress: { region } }): void {
      const validation = validateRegion(region.value);
      region.valid = billingAddressEqualsServiceAddress || validation.valid;
      region.hintText = validation.hintText;
    },
    setBillingZipcodeValid(
      { billingAddressEqualsServiceAddress, billingAddress: { postalCode } },
      action: PayloadAction<Country>,
    ): void {
      const validation = validateZip(postalCode.value, zipCodeRegex[action.payload]);
      postalCode.valid = billingAddressEqualsServiceAddress || validation.valid;
      postalCode.hintText = validation.hintText;
    },
    setScrubBillingAddress(state, action: PayloadAction<LocationType>): void {
      state.scrubBillingAddress = action.payload;
    },
    setScrubShippingAddress(state, action: PayloadAction<LocationType>): void {
      state.scrubShippingAddress = action.payload;
    },
    setShippingAddress(state, action: PayloadAction<AddressType>): void {
      let addressLine = `${action.payload.addressLines[0].trim()}`;
      state.shippingAddress.addressLines.value[0] = action.payload.addressLines[0].split(', ')[0];
      state.shippingAddress.addressLines.value[1] =
        action.payload.addressLines[1] || addressLine.split(',')[1]?.trim() || '';
      state.shippingAddress.municipality.value = action.payload.municipality;
      state.shippingAddress.region.value = action.payload.region;
      state.shippingAddress.postalCode.value = action.payload.postalCode;
      state.shippingAddress.countryCode.value = action.payload.countryCode;
    },
    setShippingAddressLine1(state, action: PayloadAction<string>): void {
      state.shippingAddress.addressLines.value[0] = action.payload;
    },
    setShippingAddressLine2(state, action: PayloadAction<string>): void {
      state.shippingAddress.addressLines.value[1] = action.payload;
    },
    setShippingAddressLinesValid({ shippingAddressEqualsServiceAddress, shippingAddress: { addressLines } }): void {
      const validation = validateStreetAddress(addressLines.value[0]);
      addressLines.valid = shippingAddressEqualsServiceAddress || validation.valid;
      addressLines.hintText = validation.hintText;
    },
    setShippingCountryCode(state, action: PayloadAction<Country>): void {
      state.shippingAddress.countryCode.value = action.payload;
    },
    setShippingEqualsServiceAddress(state): void {
      state.shippingAddressEqualsServiceAddress = !state.shippingAddressEqualsServiceAddress;
    },
    setShippingMunicipality(state, action: PayloadAction<string>): void {
      state.shippingAddress.municipality.value = action.payload;
    },
    setShippingMunicipalityValid({ shippingAddressEqualsServiceAddress, shippingAddress: { municipality } }): void {
      const validation = validateMunicipality(municipality.value);
      municipality.valid = shippingAddressEqualsServiceAddress || validation.valid;
      municipality.hintText = validation.hintText;
    },
    setShippingNotEqualToServiceAddress(state): void {
      state.shippingAddressEqualsServiceAddress = false;
    },
    setShippingPostalCode(state, action: PayloadAction<string>): void {
      state.shippingAddress.postalCode.value = action.payload;
    },
    setShippingRegion(state, action: PayloadAction<string>): void {
      state.shippingAddress.region.value = action.payload;
    },
    setShippingRegionValid({ shippingAddressEqualsServiceAddress, shippingAddress: { region } }): void {
      const validation = validateRegion(region.value);
      region.valid = shippingAddressEqualsServiceAddress || validation.valid;
      region.hintText = validation.hintText;
    },
    setShippingZipcodeValid(
      { shippingAddressEqualsServiceAddress, shippingAddress: { postalCode } },
      action: PayloadAction<Country>,
    ): void {
      const validation = validateZip(postalCode.value, zipCodeRegex[action.payload]);
      postalCode.valid = shippingAddressEqualsServiceAddress || validation.valid;
      postalCode.hintText = validation.hintText;
    },
    validateBillingAddress({ billingAddress }, action: PayloadAction<Country>): void {
      billingAddress.addressLines = {
        ...validateStreetAddress(billingAddress.addressLines.value[0]),
        value: billingAddress.addressLines.value,
      };
      billingAddress.municipality = {
        ...validateMunicipality(billingAddress.municipality.value),
        value: billingAddress.municipality.value,
      };
      billingAddress.region = {
        ...validateRegion(billingAddress.region.value),
        value: billingAddress.region.value,
      };
      billingAddress.postalCode = {
        ...validateZip(billingAddress.postalCode.value, zipCodeRegex[action.payload]),
        value: billingAddress.postalCode.value,
      };
      if (billingAddress.houseNumber) {
        billingAddress.houseNumber = {
          ...validateHouseNumber(billingAddress.houseNumber.value),
          value: billingAddress.houseNumber.value,
        };
      }
    },
    validateShippingAddress({ shippingAddress }, action: PayloadAction<Country>): void {
      shippingAddress.addressLines = {
        ...validateStreetAddress(shippingAddress.addressLines.value[0]),
        value: shippingAddress.addressLines.value,
      };
      shippingAddress.municipality = {
        ...validateMunicipality(shippingAddress.municipality.value),
        value: shippingAddress.municipality.value,
      };
      shippingAddress.region = {
        ...validateRegion(shippingAddress.region.value),
        value: shippingAddress.region.value,
      };
      shippingAddress.postalCode = {
        ...validateZip(shippingAddress.postalCode.value, zipCodeRegex[action.payload]),
        value: shippingAddress.postalCode.value,
      };
    },
    resetBillingAddress(state, action: PayloadAction<Country>): void {
      state.billingAddress.addressLines.value = ['', ''];
      state.billingAddress.municipality.value = '';
      state.billingAddress.region.value = '';
      state.billingAddress.postalCode.value = '';
      state.billingAddress.countryCode.value = action.payload;
      state.billingAddress.houseNumber.value = '';
    },
    resetShippingAddress(state, action: PayloadAction<Country>): void {
      state.shippingAddress.addressLines.value = ['', ''];
      state.shippingAddress.municipality.value = '';
      state.shippingAddress.region.value = '';
      state.shippingAddress.postalCode.value = '';
      state.shippingAddress.countryCode.value = action.payload;
    },
    setAddressQueryParams(state, action: PayloadAction<AddressQueryParams>): void {
      Object.keys(action.payload).forEach((key: string) => {
        if ((action.payload as Record<string, string | null | undefined>)[key] !== undefined)
          (state.addressUrlParams as Record<string, string | null | undefined>)[key] = (
            action.payload as Record<string, string | null | undefined>
          )[key];
      });
    },
    resetAddressQueryParams(state): void {
      state.addressUrlParams = {};
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (state: AddressState): AddressState => ({
      ...initialAddressState,
    }),
  },
});

export const { actions: addressActions, reducer: addressReducer, name: addressKey } = addressSlice;
