import { CountryCode } from 'libphonenumber-js';

export type CountryOption = {
  label: string;
  value: CountryCode;
  prefix: string;
  order?: number;
};

export const countryOptions = [
  { label: 'Albania', value: 'AL', prefix: '+355' },
  { label: 'Andorra', value: 'AD', prefix: '+376' },
  { label: 'Armenia', value: 'AM', prefix: '+374' },
  { label: 'Austria', value: 'AT', prefix: '+43' },
  { label: 'Belarus', value: 'BY', prefix: '+375' },
  { label: 'Belgium', value: 'BE', prefix: '+32' },
  { label: 'Bosnia and Herzegovina', value: 'BA', prefix: '+387' },
  { label: 'Brazil', value: 'BR', prefix: '+55' },
  { label: 'Bulgaria', value: 'BG', prefix: '+359' },
  { label: 'Croatia', value: 'HR', prefix: '+385' },
  { label: 'Cyprus', value: 'CY', prefix: '+357' },
  { label: 'Czech Republic', value: 'CZ', prefix: '+420' },
  { label: 'Denmark', value: 'DK', prefix: '+45' },
  { label: 'Estonia', value: 'EE', prefix: '+372' },
  { label: 'Finland', value: 'FI', prefix: '+358' },
  { label: 'France', value: 'FR', prefix: '+33' },
  { label: 'Georgia', value: 'GA', prefix: '+995' },
  { label: 'Germany', value: 'DE', prefix: '+49' },
  { label: 'Greece', value: 'GR', prefix: '+30' },
  { label: 'Holy See', value: 'VA', prefix: '+379' },
  { label: 'Hungary', value: 'HU', prefix: '+36' },
  { label: 'Iceland', value: 'IS', prefix: '+354' },
  { label: 'Ireland', value: 'IE', prefix: '+353' },
  { label: 'Italy', value: 'IT', prefix: '+39', order: -2 },
  { label: 'Latvia', value: 'LV', prefix: '+371' },
  { label: 'Liechtenstein', value: 'LI', prefix: '+423' },
  { label: 'Lithuania', value: 'LT', prefix: '+370' },
  { label: 'Luxembourg', value: 'LU', prefix: '+352' },
  { label: 'North Macedonia', value: 'MK', prefix: '+389' },
  { label: 'Malta', value: 'MT', prefix: '+356' },
  { label: 'Mexico', value: 'MX', prefix: '+52' },
  { label: 'Moldova', value: 'MD', prefix: '+373' },
  { label: 'Monaco', value: 'MC', prefix: '+377' },
  { label: 'Montenegro', value: 'ME', prefix: '+382' },
  { label: 'Netherlands', value: 'NL', prefix: '+31' },
  { label: 'Norway', value: 'NO', prefix: '+47' },
  { label: 'Poland', value: 'PL', prefix: '+48' },
  { label: 'Portugal', value: 'PT', prefix: '+351' },
  { label: 'Qatar', value: 'QA', prefix: '+974' },
  { label: 'Romania', value: 'RO', prefix: '+40' },
  { label: 'Russia', value: 'RU', prefix: '+7' },
  { label: 'San Marino', value: 'SM', prefix: '+378' },
  { label: 'Serbia', value: 'RS', prefix: '+381' },
  { label: 'Slovakia', value: 'SK', prefix: '+421' },
  { label: 'Slovenia', value: 'SI', prefix: '+386' },
  { label: 'Spain', value: 'ES', prefix: '+34' },
  { label: 'Sweden', value: 'SE', prefix: '+46' },
  { label: 'Switzerland', value: 'CH', prefix: '+41' },
  { label: 'Ukraine', value: 'UA', prefix: '+380' },
  { label: 'United Kingdom', value: 'UK', prefix: '+44', order: -1 },
  { label: 'United States of America', value: 'US', prefix: '+1' },
] as CountryOption[];
