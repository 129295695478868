import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@buy-viasat/react';
import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Txt, Surface, IllustrativeIcon, Spacer } from '@vst/beam';
import PdlLeave from '@vst/beam-icons/illustrative-icons/PdlLeave';

import styles from './scheduleLaterModal.module.scss';

type ScheduleLaterModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const ScheduleLaterModal: FC<ScheduleLaterModalProps> = ({ primaryAction, secondaryAction }) => {
  const { t } = useTranslation(['scheduleInstallation']);
  const header = (
    <Surface
      className={styles['scheduleInstallationTop']}
      pt={{ sm: undefined, md: '24px' }}
      px={{ sm: undefined, md: '24px' }}
    >
      <Txt variant={'bodyLargeBold'} color={'regular'} data-testid="heading">
        {t('scheduleInstallation.subheading.title.scheduleLater')}
      </Txt>
    </Surface>
  );
  return (
    <Modal
      headerTitle={header as unknown as string}
      primaryLabelText={t('scheduleInstallation.confirmButtonText')}
      secondaryLabelText={t('scheduleInstallation.backButtonText')}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      showSecondaryButtonAsLink={true}
      hideCloseButton={true}
      className={styles['wrapper']}
      data-testid="ScheduleLaterPromptModal"
    >
      <Surface px={{ md: '24px' }} className={styles['scheduleLaterModalContainer']}>
        <Divider my={'24px'} mt={{ sm: undefined, xs: '32px' }} />
        <Surface
          radius={'16px'}
          data-testid="ScheduleLaterModalContainer"
          className={styles['scheduleInstallationMiddle']}
        >
          <IllustrativeIcon
            icon={PdlLeave}
            color={'teal_600'}
            style={{ width: '88px', height: '88px', display: 'inline-block' }}
            data-testid="ScheduleLaterModalIcon"
            mb={'24px'}
          />

          <Txt variant={'heading6'} data-testid="ScheduleLaterModalHeaderTitle" mb={'16px'}>
            {t('scheduleInstallation.scheduleLaterModal.title')}
          </Txt>

          <Txt
            variant={'smallRegular'}
            data-testid="ScheduleLatertModalDescription"
            className={styles['modalDescription']}
          >
            {t('scheduleInstallation.scheduleLaterModal.description')}
          </Txt>
          <Spacer y={'24px'} />
        </Surface>
      </Surface>
    </Modal>
  );
};
