import { ClosingOffer } from '@buy-viasat/types/build/bv';

export enum ThunkActions {
  trackEvent = 'trackEvent',
}

export interface ClosingOffersState {
  availableClosingOffers: ClosingOffer[] | null;
  isClosingOffersLoading: boolean;
  isUpsertClosingOffersLoading: boolean;
  isUpsertWithClosingOffersError: boolean;
  isClosingOfferError: boolean;
  skipClosingOffer: boolean;
}

export const initialClosingOffersState: ClosingOffersState = {
  availableClosingOffers: [],
  isClosingOffersLoading: false,
  isUpsertClosingOffersLoading: false,
  isUpsertWithClosingOffersError: false,
  isClosingOfferError: false,
  skipClosingOffer: false,
};
