import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Surface, Txt } from '@vst/beam';
import React from 'react';

type ClosingOfferNotEligibleModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const ClosingOfferNotEligibleModal = (props: ClosingOfferNotEligibleModalProps) => {
  const { primaryAction, secondaryAction } = props;
  const { t } = useTranslation();

  return (
    <Modal
      headerTitle={t('common.closingOfferNotEligibleModal.headerTitle')}
      showSecondaryButtonFullWidth={true}
      hideMarginBottomFromText={true}
      alignTextToLeft={true}
      primaryLabelText={t('common.closingOfferNotEligibleModal.primaryButton')}
      secondaryLabelText={t('common.closingOfferNotEligibleModal.secondaryButton')}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      onClose={secondaryAction}
    >
      <Surface data-testid="ClosingOffersErrorModalTextWrapper">
        <Txt variant={'bodySmallRegular'} data-testid="ClosingOffersErrorModalText">
          {t('common.closingOfferNotEligibleModal.description')}
        </Txt>
      </Surface>
    </Modal>
  );
};
