/*
 * PersonalInformation Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 */
import { RESET_STATE_ACTION } from '@buy-viasat/redux/src/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@buy-viasat/redux/src/utils';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobileNumber,
  validatePhone,
  validateSubsidyApplicationId,
} from '../Checkout/validations';
import { PhoneRegexes } from './types';
import {
  initialPersonalInformationState,
  PersonalInformationState,
  personalInformationReducer as sharedPersonalInformationReducer,
  personalInformationActions as sharedPersonalInformationActions,
} from '@buy-viasat/redux/src/personal-information';
import { mergeReducers } from '@buy-viasat/redux/src/utils';

const personalInformationSlice = createSlice({
  name: 'personalInformation',
  initialState: initialPersonalInformationState,
  reducers: {
    setMarketPreferences(): void {},
    setEmailValid(state: PersonalInformationState): void {
      const validation = validateEmail(state.email.value);
      state.email.valid = validation.valid;
      state.email.hintText = validation.hintText;
    },
    setFirstNameValid(state: PersonalInformationState): void {
      const validation = validateFirstName(state.firstName.value);
      state.firstName.valid = validation.valid;
      state.firstName.hintText = validation.hintText;
    },
    setLastNameValid(state: PersonalInformationState): void {
      const validation = validateLastName(state.lastName.value);
      state.lastName.valid = validation.valid;
      state.lastName.hintText = validation.hintText;
    },
    setPhoneValid(state: PersonalInformationState, action: PayloadAction<RegExp>): void {
      const validation = validatePhone(state.phone.value, action.payload);
      state.phone.valid = validation.valid;
      state.phone.hintText = validation.hintText;
    },
    setMobileNumberValid(state: PersonalInformationState, action: PayloadAction<RegExp>): void {
      const validation = validateMobileNumber(state.mobileNumber.value, action.payload);
      state.mobileNumber.valid = validation.valid;
      state.mobileNumber.hintText = validation.hintText;
    },
    setSubsidyApplicationIdValid(state: PersonalInformationState): void {
      const validation = validateSubsidyApplicationId(state.subsidyApplicationId.value);
      state.subsidyApplicationId.valid = validation.valid;
      state.subsidyApplicationId.hintText = validation.hintText;
    },
    validatePersonalInformation(state: PersonalInformationState, action: PayloadAction<PhoneRegexes>): void {
      state.email = { ...validateEmail(state.email.value), value: state.email.value };
      state.firstName = { ...validateFirstName(state.firstName.value), value: state.firstName.value };
      state.lastName = { ...validateLastName(state.lastName.value), value: state.lastName.value };
      state.phone = { ...validatePhone(state.phone.value, action.payload.phoneRegex), value: state.phone.value };
      state.mobileNumber = {
        ...validateMobileNumber(state.mobileNumber.value, action.payload.mobileRegex),
        value: state.mobileNumber.value,
      };
      if (state.isSubsidyCheckboxChecked) {
        state.subsidyApplicationId = {
          ...validateSubsidyApplicationId(state.subsidyApplicationId.value),
          value: state.subsidyApplicationId.value,
        };
      }
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): PersonalInformationState => initialPersonalInformationState,
  },
});

export const personalInformationKey = personalInformationSlice.name;
export const personalInformationActions = { ...sharedPersonalInformationActions, ...personalInformationSlice.actions };
export const personalInformationReducer = mergeReducers<PersonalInformationState>([
  sharedPersonalInformationReducer,
  personalInformationSlice.reducer,
]);
