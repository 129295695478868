import { TextField, validators } from '@vst/beam';
import { FocusEventHandler, FormEventHandler } from 'react';

type ApplicationIdInputProps = {
  name: string;
  value: string;
  applicationIdLabel: string;
  applicationIdPlaceholder: string;
  errorText: { required: string; invalidPattern: string };
  onChange?: FormEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
};

export const ApplicationIdInput = ({
  name,
  value,
  applicationIdLabel,
  applicationIdPlaceholder,
  errorText,
  onChange,
  onBlur,
  onFocus,
}: ApplicationIdInputProps) => {
  return (
    <TextField
      name={name}
      value={value}
      labelProps={{ labelText: applicationIdLabel, showStar: false }}
      placeholder={applicationIdPlaceholder}
      fluid={true}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      maxLength={12}
      validationRules={[
        validators.required({ message: errorText.required }),
        validators.pattern({ pattern: /^[A-Z]{1}[\d]{5}-[\d]{5}$/, message: errorText.invalidPattern }),
      ]}
    />
  );
};
