import { RESET_STATE_ACTION } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../utils';
import { initialSubsidyProgramState, RadioButtonTypes, SubsidyProgramState } from './types';

const subsidyProgramSlice = createSlice({
  name: 'subsidyProgram',
  initialState: initialSubsidyProgramState,
  reducers: {
    resetForm(state: SubsidyProgramState): void {
      state.isReceivingMonthlyDiscount = false;
      state.qualifiedForTribalLands = false;
      state.isTermsAndConditionsChecked = false;
      state.isTransferCheckboxChecked = false;
      state.questionOneAnswer = '';
      state.questionTwoAnswer = '';
      state.isQuestionOneInvalid = false;
      state.isQuestionTwoInvalid = false;
      state.isTransferAgreementInvalid = false;
      state.isTermsAndConditionsInvalid = false;
      state.isFormValid = null;
    },
    setReceivingMonthlyDiscount(state: SubsidyProgramState, action: PayloadAction<boolean | null>): void {
      state.isReceivingMonthlyDiscount = action.payload;
    },
    setQualifiedForTribalLands(state: SubsidyProgramState, action: PayloadAction<boolean | null>): void {
      state.qualifiedForTribalLands = action.payload;
    },
    setTermsAndConditions(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isTermsAndConditionsChecked = action.payload;
    },
    setTransferCheckbox(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isTransferCheckboxChecked = action.payload;
    },
    setQuestionOneAnswer(state: SubsidyProgramState, action: PayloadAction<string>): void {
      state.questionOneAnswer = action.payload;
    },
    setQuestionTwoAnswer(state: SubsidyProgramState, action: PayloadAction<string>): void {
      state.questionTwoAnswer = action.payload;
    },
    setIsQuestionOneInvalid(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isQuestionOneInvalid = action.payload;
    },
    setIsQuestionTwoInvalid(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isQuestionTwoInvalid = action.payload;
    },
    setIsTransferAgreementInvalid(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isTransferAgreementInvalid = action.payload;
    },
    setIsTermsAndConditionsInvalid(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isTermsAndConditionsInvalid = action.payload;
    },
    validateForm(state: SubsidyProgramState): void {
      if (state.questionOneAnswer === '') {
        state.isQuestionOneInvalid = true;
      }
      if (state.questionTwoAnswer === '') {
        state.isQuestionTwoInvalid = true;
      }
      if (state.questionOneAnswer && !state.isTransferCheckboxChecked) {
        state.isTransferAgreementInvalid = true;
      }
      if (!state.isTermsAndConditionsChecked) {
        state.isTermsAndConditionsInvalid = true;
      }
      if (
        (state.questionOneAnswer === RadioButtonTypes.FIRST &&
          state.questionTwoAnswer &&
          state.isTransferCheckboxChecked &&
          state.isTermsAndConditionsChecked) ||
        (state.questionOneAnswer === RadioButtonTypes.SECOND &&
          state.questionTwoAnswer &&
          state.isTermsAndConditionsChecked)
      ) {
        state.isFormValid = true;
      } else {
        state.isFormValid = false;
      }
    },
    setACPDetailsLater(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.acpDetailsLater = action.payload;
    },
    setIsVerifyButtonLoading(state: SubsidyProgramState, action: PayloadAction<boolean>): void {
      state.isVerifyButtonLoading = action.payload;
    },
    setResponseView(state: SubsidyProgramState, action: PayloadAction<string>): void {
      state.responseView = action.payload;
    },
    incrementVerifyAttempts(state: SubsidyProgramState): void {
      state.verifyAttempts++;
    },
    resetVerifyAttempts(state: SubsidyProgramState): void {
      state.verifyAttempts = 0;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): SubsidyProgramState => initialSubsidyProgramState,
  },
});

export const {
  actions: subsidyProgramActions,
  reducer: subsidyProgramReducer,
  name: subsidyProgramKey,
} = subsidyProgramSlice;
