import { scriptLoader } from './scriptLoader';

const loadRaygunInstance = (): Promise<typeof window.rg4js> => {
  return new Promise<typeof window.rg4js>((resolve, reject) => {
    scriptLoader(
      window,
      document,
      'script',
      'https://cdn.raygun.io/raygun4js/raygun.min.js',
      'rg4js',
      'raygun',
      resolve,
      reject,
    );
  });
};

export const enableRaygun = async (apiKey: string) => {
  const rg4js = await loadRaygunInstance();
  rg4js('apiKey', apiKey);
  rg4js('enableCrashReporting', true);
  rg4js('enablePulse', true);
};

export const setUser = async (flowId: string) => {
  const rg4js = await loadRaygunInstance();
  rg4js('setUser', {
    identifier: flowId,
    isAnonymous: false,
  });
};

export const trackCustomTiming = async (name: string, time: number, identifier: string) => {
  const rg4js = await loadRaygunInstance();
  rg4js('trackEvent', {
    type: 'customTiming',
    name,
    duration: time,
    identifier,
  });
};

export const trackPageView = async (path: string) => {
  const rg4js = await loadRaygunInstance();
  rg4js('trackEvent', { type: 'pageView', path });
};

export const sendRaygunMessage = async (message: string) => {
  const rg4js = await loadRaygunInstance();
  rg4js('send', { message });
};

export const sendRaygunError = async (message: { error: string; customData?: any; tags?: string }) => {
  const rg4js = await loadRaygunInstance();
  const { error, customData, tags } = message;
  rg4js('send', { error, customData, tags });
};

export const sendRaygunNotification = async (tags: string, message: string) => {
  const rg4js = await loadRaygunInstance();
  rg4js('send', { tags, message });
};
