import { TextField, validators } from '@vst/beam';
import { ComponentProps, useMemo } from 'react';

interface HouseNumberFieldInputProps extends ComponentProps<typeof TextField> {
  validation?: {
    required: string;
    invalid: string;
  };
  regex?: RegExp;
}

const sanitizeInputValue = (value: string, regex: RegExp): string => {
  return value
    .split('')
    .filter((char) => regex.test(char))
    .join('');
};

const HouseNumberFieldInput = (props: HouseNumberFieldInputProps) => {
  const { regex, validation, onChange, ...textFieldProps } = props;
  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(validators.required({ message: validation.required }));
    }

    return validationRules;
  }, [validation]);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (regex) {
      const allowedPattern = new RegExp(regex);
      const originalValue = e.currentTarget.value;
      const sanitizedValue = sanitizeInputValue(originalValue, allowedPattern);

      if (originalValue !== sanitizedValue) {
        e.currentTarget.value = sanitizedValue;
      }
    }

    onChange?.(e);
  };

  return (
    <TextField
      data-testid={textFieldProps.name}
      validationRules={validationRules}
      required={!!validation?.required}
      labelProps={{ showStar: false }}
      onChange={handleOnChange}
      {...textFieldProps}
    />
  );
};

export default HouseNumberFieldInput;
