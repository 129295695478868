import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Surface, Txt } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectHouseNumber,
  selectIsServiceabilityLoading,
  selectServiceAddressValues,
  serviceabilityActions,
} from '@buy-viasat/redux/src/serviceability';
import {
  appActions,
  CustomerType,
  selectCountry,
  selectCustomerType,
  selectFeatureFlags,
  selectLocale,
  selectSalesFlowDefinition,
} from '@buy-viasat/redux/src/app';
import { Divider, Navigator } from '@buy-viasat/react';
import { adobeTargetDataLayer, getDateFnsLocale } from '@buy-viasat/utils';
import { DataLayerEvents } from '@buy-viasat/redux/src/analytics';
import { planActions } from '@buy-viasat/redux/src/plan';

import MultiFieldAddress from '../MultiFieldAddress';
import SingleFieldAddress from '../SingleFieldAddress';
import { Loader } from '@googlemaps/js-api-loader';
import env from 'env';

const FormAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['serviceability']);

  const locale = useSelector(selectLocale);
  const customerType = useSelector(selectCustomerType);
  const { houseNumber } = useSelector(selectHouseNumber);
  const streetAddress = useSelector(selectServiceAddressValues);
  const { singleFieldAddress, displaySmbLink } = useSelector(selectFeatureFlags);
  const { shouldDisplaySmbLink } = useSelector(selectSalesFlowDefinition);
  const isServiceabilityLoading = useSelector(selectIsServiceabilityLoading);

  const isBusinessUser = customerType === CustomerType.BUSINESS;
  const country = useSelector(selectCountry);

  const googleLoader = useCallback(async () => {
    try {
      if (!window?.google?.maps?.Geocoder) {
        await new Loader({
          apiKey: env.googleApiKey,
          ...{ libraries: ['places'], apiOptions: { language: getDateFnsLocale(locale), region: country } },
        }).load();
      }
    } catch (error) {
      console.error('Error loading Google Maps:', error);
    }
  }, [country, locale]);

  const loadGoogleMapsIfNeeded = useCallback(async () => {
    try {
      if (!singleFieldAddress) {
        await googleLoader();
      }
    } catch (error) {
      console.error('Error loading googleLoader', error);
    }
  }, [googleLoader, singleFieldAddress]);

  const handleServiceabilitySubmit = useCallback(async () => {
    dispatch(serviceabilityActions.scrubServiceAddress());
    try {
      await loadGoogleMapsIfNeeded();
    } catch (error) {
      console.error('Error loading Google Maps in loadGoogleMapsIfNeeded:', error);
    }

    dispatch(planActions.setAvailableProducts([]));
  }, [dispatch, loadGoogleMapsIfNeeded]);

  const handleConfirmButtonClick = useCallback(() => {
    adobeTargetDataLayer.push({
      event: DataLayerEvents.AA_EVENT,
      eventData: {
        event: 'findPlans',
      },
    });
  }, []);

  const handleBackButtonClick = () => {
    const newCustomerType = customerType === CustomerType.BUSINESS ? CustomerType.RESIDENTIAL : CustomerType.BUSINESS;
    history.push(`/${locale}/${newCustomerType}/`);

    dispatch(appActions.setSmbLinkResidentialRedirect(true));
    dispatch(appActions.resetPartyId());
  };

  const backButtonText =
    shouldDisplaySmbLink && displaySmbLink
      ? isBusinessUser
        ? t('serviceability.internetPlansLink')
        : t('serviceability.businessInternetPlanLink')
      : '';

  return (
    <Form
      name="serviceabilityForm"
      data-testid="serviceabilityForm"
      onSubmit={handleServiceabilitySubmit}
      initialValues={{
        streetAddress: houseNumber
          ? streetAddress.addressLines[0].split(houseNumber)[0]
          : streetAddress.addressLines[0].split(',')[0],
        aptAddress: streetAddress.addressLines[1],
        postalCodeAddress: streetAddress.postalCode,
        houseNumber: houseNumber ?? '',
      }}
    >
      <Surface radius="16px" px={{ xs: '16px', md: '48px' }} pt={{ xs: '24px', md: '48px' }}>
        <Txt variant="heading6" data-testid="serviceabilityHeadlineSubtitle">
          {isBusinessUser ? t('serviceability.headlineSmb.subtitle') : t('serviceability.headline.subtitle')}
        </Txt>
        <Divider my={{ xs: '24px', md: '40px' }} />
      </Surface>

      <Surface px={{ md: '24px' }} mt="40px" mb={{ xs: '16px', md: '24px' }}>
        {singleFieldAddress ? <SingleFieldAddress /> : <MultiFieldAddress />}
      </Surface>

      <Divider />

      <Navigator
        onConfirmButtonClick={handleConfirmButtonClick}
        disabled={false}
        loading={isServiceabilityLoading}
        onBackButtonClick={handleBackButtonClick}
        backButtonHref={
          isBusinessUser ? `/${locale}/${CustomerType.RESIDENTIAL}/` : `/${locale}/${CustomerType.BUSINESS}/`
        }
        backButtonText={backButtonText}
        loadingText={t('serviceability.button.findingPlans.label')}
        confirmButtonText={t('serviceability.button.findPlans.label')}
      />
    </Form>
  );
};

export default FormAddress;
