import { QueryParams } from '@buy-viasat/types/build/bv';
import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';

const LOG_URL_PARAMS = gql`
  query logUrlParams($input: QueryParams) {
    logUrlParams(input: $input)
  }
`;

export default async function logUrlParamsAsync(input: QueryParams): Promise<boolean> {
  return client.query({
    query: LOG_URL_PARAMS,
    fetchPolicy: 'no-cache',
    variables: {
      input,
    },
  }) as unknown as boolean;
}
