/*
 * CreditVerification Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 */
import { DateFormat } from '@buy-viasat/utils';
import {
  creditVerificationActions as sharedCreditVerificationActions,
  creditVerificationReducer as sharedCreditVerificationReducer,
  CreditVerificationState,
  initialCreditVerificationState,
} from '@buy-viasat/redux/src/credit-verification';
import { RESET_STATE_ACTION } from '@buy-viasat/redux/src/types';
import { createSlice, mergeReducers } from '@buy-viasat/redux/src/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  dateOfBirthValidation,
  validateDay,
  validateLastFour,
  validateMonth,
  validateSSN,
  validateTaxId,
  validateYear,
} from '../Checkout/validations';

const creditVerificationSlice = createSlice({
  name: 'creditVerification',
  initialState: initialCreditVerificationState,
  reducers: {
    validateDateOfBirth(state: CreditVerificationState): void {
      const monthValidation = validateMonth(state.dateOfBirth.month.value);
      const dayValidation = validateDay(state.dateOfBirth.day.value);
      const yearValidation = validateYear(state.dateOfBirth.year.value);

      const validation = dateOfBirthValidation(
        state.dateOfBirth.month.value,
        state.dateOfBirth.day.value,
        state.dateOfBirth.year.value,
        new Date(),
      );

      state.dateOfBirth.month.valid = monthValidation.valid;
      state.dateOfBirth.day.valid = dayValidation.valid;
      state.dateOfBirth.year.valid = yearValidation.valid && validation.valid;

      state.dateOfBirth.month.hintText = monthValidation.hintText;
      state.dateOfBirth.day.hintText = dayValidation.hintText;

      if (state.dateOfBirth.year.valid === false) {
        if (yearValidation.valid === false) {
          state.dateOfBirth.year.hintText = yearValidation.hintText;
        } else {
          state.dateOfBirth.year.hintText = validation.hintText;
        }
      } else {
        state.dateOfBirth.year.hintText = validation.hintText;
      }
    },
    validateMonth(state: CreditVerificationState): void {
      const validation = validateMonth(state.dateOfBirth.month.value);
      state.dateOfBirth.month.valid = validation.valid;
      state.dateOfBirth.month.hintText = validation.hintText;
    },
    validateDay(state: CreditVerificationState): void {
      const validation = validateDay(state.dateOfBirth.day.value);
      state.dateOfBirth.day.valid = validation.valid;
      state.dateOfBirth.day.hintText = validation.hintText;
    },
    validateYear(state: CreditVerificationState): void {
      const validation = validateYear(state.dateOfBirth.year.value);
      state.dateOfBirth.year.valid = validation.valid;
      state.dateOfBirth.year.hintText = validation.hintText;
    },
    validateLastFourOfSSN(state: CreditVerificationState): void {
      const validation = validateLastFour(state.lastFourOfSSN.value);
      state.lastFourOfSSN.valid = validation.valid;
      state.lastFourOfSSN.hintText = validation.hintText;
    },
    validateTaxId(state: CreditVerificationState): void {
      const validation = validateTaxId(state.taxId.value);
      state.taxId.valid = validation.valid;
      state.taxId.hintText = validation.hintText;
    },
    // VALIDATION SETTERS END
    validateCreditVerification(
      state: CreditVerificationState,
      action: PayloadAction<{ dateFormat: DateFormat; isTaxId: boolean }>,
    ): void {
      state.dateOfBirth = {
        month: { ...validateMonth(state.dateOfBirth.month.value), value: state.dateOfBirth.month.value },
        day: { ...validateDay(state.dateOfBirth.day.value), value: state.dateOfBirth.day.value },
        year: { ...validateYear(state.dateOfBirth.year.value), value: state.dateOfBirth.year.value },
      };
      state.lastFourOfSSN = state.isSSNMandatory
        ? { ...validateSSN(state.lastFourOfSSN.value), value: state.lastFourOfSSN.value }
        : {
            ...validateLastFour(state.lastFourOfSSN.value),
            value: state.lastFourOfSSN.value,
          };
      if (action.payload.isTaxId) {
        state.taxId = { ...validateTaxId(state.taxId.value), value: state.taxId.value };
      }
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): CreditVerificationState => initialCreditVerificationState,
  },
});

export const creditVerificationKey = creditVerificationSlice.name;
export const creditVerificationActions = { ...sharedCreditVerificationActions, ...creditVerificationSlice.actions };
export const creditVerificationReducer = mergeReducers<CreditVerificationState>([
  sharedCreditVerificationReducer,
  creditVerificationSlice.reducer,
]);
