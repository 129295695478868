import { CalendarDate } from './types';

export const CALENDAR_MONTHS = 12;

export const incrementCalendarMonth = (
  currentMonth: number,
  currentYear: number,
  incrementValue: number,
): CalendarDate => {
  if (incrementValue < 0)
    throw new Error(
      'incrementValue supplied to incrementCalendarMonth utility needs to be positive, use decrementCalendarMonth for decrement values.',
    );
  let newMonth = currentMonth + incrementValue;
  let newYear = currentYear;
  if (newMonth > CALENDAR_MONTHS) {
    newYear += Math.floor(newMonth / CALENDAR_MONTHS);
    newMonth = newMonth % CALENDAR_MONTHS;
  }
  return { month: newMonth, year: newYear };
};

export const decrementCalendarMonth = (
  currentMonth: number,
  currentYear: number,
  decrementValue: number,
): CalendarDate => {
  if (decrementValue < 0)
    throw new Error(
      'decrementValue supplied to decrementCalendarMonth utility needs to be positive, use incrementCalendarMonth for incremental values.',
    );
  let newMonth = currentMonth - decrementValue;
  let newYear = currentYear;
  if (newMonth < 1) {
    const excessiveMonths = CALENDAR_MONTHS + Math.abs(newMonth);
    newYear -= Math.floor(excessiveMonths / CALENDAR_MONTHS);
    newMonth = CALENDAR_MONTHS + newMonth;
  }
  return { month: newMonth, year: newYear };
};
