export const USStates = [
  {
    label: 'AL - Alabama',
    value: 'AL',
  },
  {
    label: 'AK - Alaska',
    value: 'AK',
  },
  {
    label: 'AZ - Arizona',
    value: 'AZ',
  },
  {
    label: 'AR - Arkansas',
    value: 'AR',
  },
  {
    label: 'CA - California',
    value: 'CA',
  },
  {
    label: 'CO - Colorado',
    value: 'CO',
  },
  {
    label: 'CT - Connecticut',
    value: 'CT',
  },
  {
    label: 'DE - Delaware',
    value: 'DE',
  },
  {
    label: 'FL - Florida',
    value: 'FL',
  },
  {
    label: 'GA - Georgia',
    value: 'GA',
  },
  {
    label: 'HI - Hawaii',
    value: 'HI',
  },
  {
    label: 'ID - Idaho',
    value: 'ID',
  },
  {
    label: 'IL - Illinois',
    value: 'IL',
  },
  {
    label: 'IN - Indiana',
    value: 'IN',
  },
  {
    label: 'IA - Iowa',
    value: 'IA',
  },
  {
    label: 'KS - Kansas',
    value: 'KS',
  },
  {
    label: 'KY - Kentucky',
    value: 'KY',
  },
  {
    label: 'LA - Louisiana',
    value: 'LA',
  },
  {
    label: 'ME - Maine',
    value: 'ME',
  },
  {
    label: 'MD -  Maryland',
    value: 'MD',
  },
  {
    label: 'MA - Massachusetts',
    value: 'MA',
  },
  {
    label: 'MI - Michigan',
    value: 'MI',
  },
  {
    label: 'MN - Minnesota',
    value: 'MN',
  },
  {
    label: 'MS - Mississippi',
    value: 'MS',
  },
  {
    label: 'MO - Missouri',
    value: 'MO',
  },
  {
    label: 'MT - Montana',
    value: 'MT',
  },
  {
    label: 'NE - Nebraska',
    value: 'NE',
  },
  {
    label: 'NV - Nevada',
    value: 'NV',
  },
  {
    label: 'NH - New Hampshire',
    value: 'NH',
  },
  {
    label: 'NJ - New Jersey',
    value: 'NJ',
  },
  {
    label: 'NM - New Mexico',
    value: 'NM',
  },
  {
    label: 'NY - New York',
    value: 'NY',
  },
  {
    label: 'NC - North Carolina',
    value: 'NC',
  },
  {
    label: 'ND - North Dakota',
    value: 'ND',
  },
  {
    label: 'OH - Ohio',
    value: 'OH',
  },
  {
    label: 'OK - Oklahoma',
    value: 'OK',
  },
  {
    label: 'OR - Oregon',
    value: 'OR',
  },
  {
    label: 'PA - Pennsylvania',
    value: 'PA',
  },
  {
    label: 'PR - Puerto Rico',
    value: 'PR',
  },
  {
    label: 'RI - Rhode Island',
    value: 'RI',
  },
  {
    label: 'SC - South Carolina',
    value: 'SC',
  },
  {
    label: 'SD - South Dakota',
    value: 'SD',
  },
  {
    label: 'TN - Tennessee',
    value: 'TN',
  },
  {
    label: 'TX - Texas',
    value: 'TX',
  },
  {
    label: 'UT - Utah',
    value: 'UT',
  },
  {
    label: 'VT - Vermont',
    value: 'VT',
  },
  {
    label: 'VI - Virgin Islands',
    value: 'VI',
  },
  {
    label: 'VA - Virginia',
    value: 'VA',
  },
  {
    label: 'WA - Washington',
    value: 'WA',
  },
  {
    label: 'DC - District of Columbia',
    value: 'DC',
  },
  {
    label: 'WV - West Virginia',
    value: 'WV',
  },
  {
    label: 'WI - Wisconsin',
    value: 'WI',
  },
  {
    label: 'WY - Wyoming',
    value: 'WY',
  },
];

export const BRStates = [
  {
    label: 'AC - Acre',
    value: 'AC',
  },
  {
    label: 'AL - Alagoas',
    value: 'AL',
  },
  {
    label: 'AM - Amazonas',
    value: 'AM ',
  },
  {
    label: 'AP - Amapá',
    value: 'AP',
  },
  {
    label: 'BA - Bahia',
    value: 'BA',
  },
  {
    label: 'CE - Ceará',
    value: 'CE',
  },
  {
    label: 'DF - Distrito Federal',
    value: 'DF',
  },
  {
    label: 'ES - Espírito Santo',
    value: 'ES',
  },
  {
    label: 'GO - Goiás',
    value: 'GO',
  },
  {
    label: 'MA - Maranhão',
    value: 'MA',
  },
  {
    label: 'MG - Minas Gerais',
    value: 'MG',
  },
  {
    label: 'MS - Mato Grosso do Sul',
    value: 'MS',
  },
  {
    label: 'MT - Mato Grosso',
    value: 'MT',
  },
  {
    label: 'PA - Pará',
    value: 'PA',
  },
  {
    label: 'PB - Paraíba',
    value: 'PB',
  },
  {
    label: 'PE - Pernambuco',
    value: 'PE',
  },
  {
    label: 'PI - Piauí',
    value: 'PI',
  },
  {
    label: 'PR - Paraná',
    value: 'PR',
  },
  {
    label: 'RJ - Rio de Janeiro',
    value: 'RJ',
  },
  {
    label: 'RN - Rio Grande do Norte',
    value: 'RN',
  },
  {
    label: 'RO - Rondônia',
    value: 'RO',
  },
  {
    label: 'RR - Roraima',
    value: 'RR',
  },
  {
    label: 'RS - Rio Grande do Sul',
    value: 'RS',
  },
  {
    label: 'SC - Santa Catarina',
    value: 'SC',
  },
  {
    label: 'SE - Sergipe',
    value: 'SE',
  },
  {
    label: 'SP - São Paulo',
    value: 'SP',
  },
  {
    label: 'TO - Tocantins',
    value: 'TO',
  },
];

export const ITStates = [
  {
    label: 'AG - Agrigento',
    value: 'AG',
  },
  {
    label: 'AL - Alessandria',
    value: 'AL',
  },
  {
    label: 'AN - Ancona',
    value: 'AN',
  },
  {
    label: 'AO - Aosta',
    value: 'AO',
  },
  {
    label: "AQ - L'Aquila",
    value: 'AQ',
  },
  {
    label: 'AR - Arezzo',
    value: 'AR',
  },
  {
    label: 'AP - Ascoli-Piceno',
    value: 'AP',
  },
  {
    label: 'AT - Asti',
    value: 'AT',
  },
  {
    label: 'AV - Avellino',
    value: 'AV',
  },
  {
    label: 'BA - Bari',
    value: 'BA',
  },
  {
    label: 'BT - Barletta-Andria-Trani',
    value: 'BT',
  },
  {
    label: 'BL - Belluno',
    value: 'BL',
  },
  {
    label: 'BN - Benevento',
    value: 'BN',
  },
  {
    label: 'BG - Bergamo',
    value: 'BG',
  },
  {
    label: 'BI - Biella',
    value: 'BI',
  },
  {
    label: 'BO - Bologna',
    value: 'BO',
  },
  {
    label: 'BZ - Bolzano',
    value: 'BZ',
  },
  {
    label: 'BS - Brescia',
    value: 'BS',
  },
  {
    label: 'BR - Brindisi',
    value: 'BR',
  },
  {
    label: 'CA - Cagliari',
    value: 'CA',
  },
  {
    label: 'CL - Caltanissetta',
    value: 'CL',
  },
  {
    label: 'CB - Campobasso',
    value: 'CB',
  },
  {
    label: 'CI - Carbonia Iglesias',
    value: 'CI',
  },
  {
    label: 'CE - Caserta',
    value: 'CE',
  },
  {
    label: 'CT - Catania',
    value: 'CT',
  },
  {
    label: 'CZ - Catanzaro',
    value: 'CZ',
  },
  {
    label: 'CH - Chieti',
    value: 'CH',
  },
  {
    label: 'CO - Como',
    value: 'CO',
  },
  {
    label: 'CS - Cosenza',
    value: 'CS',
  },
  {
    label: 'CR - Cremona',
    value: 'CR',
  },
  {
    label: 'KR - Crotone',
    value: 'KR',
  },
  {
    label: 'CN - Cuneo',
    value: 'CN',
  },
  {
    label: 'EN - Enna',
    value: 'EN',
  },
  {
    label: 'FM - Fermo',
    value: 'FM',
  },
  {
    label: 'FE - Ferrara',
    value: 'FE',
  },
  {
    label: 'FI - Firenze',
    value: 'FI',
  },
  {
    label: 'FG - Foggia',
    value: 'FG',
  },
  {
    label: 'FC - Forli-Cesena',
    value: 'FC',
  },
  {
    label: 'FR - Frosinone',
    value: 'FR',
  },
  {
    label: 'GE - Genova',
    value: 'GE',
  },
  {
    label: 'GO - Gorizia',
    value: 'GO',
  },
  {
    label: 'GR - Grosseto',
    value: 'GR',
  },
  {
    label: 'IM - Imperia',
    value: 'IM',
  },
  {
    label: 'IS - Isernia',
    value: 'IS',
  },
  {
    label: 'SP - La-Spezia',
    value: 'SP',
  },
  {
    label: 'LT - Latina',
    value: 'LT',
  },
  {
    label: 'LE - Lecce',
    value: 'LE',
  },
  {
    label: 'LC - Lecco',
    value: 'LC',
  },
  {
    label: 'LI - Livorno',
    value: 'LI',
  },
  {
    label: 'LO - Lodi',
    value: 'LO',
  },
  {
    label: 'LU - Lucca',
    value: 'LU',
  },
  {
    label: 'MC - Macerata',
    value: 'MC',
  },
  {
    label: 'MN - Mantova',
    value: 'MN',
  },
  {
    label: 'MS - Massa-Carrara',
    value: 'MS',
  },
  {
    label: 'MT - Matera',
    value: 'MT',
  },
  {
    label: 'VS - Medio Campidano',
    value: 'VS',
  },
  {
    label: 'ME - Messina',
    value: 'ME',
  },
  {
    label: 'MI - Milano',
    value: 'MI',
  },
  {
    label: 'MO - Modena',
    value: 'MO',
  },
  {
    label: 'MB - Monza-Brianza',
    value: 'MB',
  },
  {
    label: 'NA - Napoli',
    value: 'NA',
  },
  {
    label: 'NO - Novara',
    value: 'NO',
  },
  {
    label: 'NU - Nuoro',
    value: 'NU',
  },
  {
    label: 'OG - Ogliastra',
    value: 'OG',
  },
  {
    label: 'OT - Olbia Tempio',
    value: 'OT',
  },
  {
    label: 'OR - Oristano',
    value: 'OR',
  },
  {
    label: 'PD - Padova',
    value: 'PD',
  },
  {
    label: 'PA - Palermo',
    value: 'PA',
  },
  {
    label: 'PR - Parma',
    value: 'PR',
  },
  {
    label: 'PV - Pavia',
    value: 'PV',
  },
  {
    label: 'PG - Perugia',
    value: 'PG',
  },
  {
    label: 'PU - Pesaro-Urbino',
    value: 'PU',
  },
  {
    label: 'PE - Pescara',
    value: 'PE',
  },
  {
    label: 'PC - Piacenza',
    value: 'PC',
  },
  {
    label: 'PI - Pisa',
    value: 'PI',
  },
  {
    label: 'PT - Pistoia',
    value: 'PT',
  },
  {
    label: 'PN - Pordenone',
    value: 'PN',
  },
  {
    label: 'PZ - Potenza',
    value: 'PZ',
  },
  {
    label: 'PO - Prato',
    value: 'PO',
  },
  {
    label: 'RG - Ragusa',
    value: 'RG',
  },
  {
    label: 'RA - Ravenna',
    value: 'RA',
  },
  {
    label: 'RC - Reggio-Calabria',
    value: 'RC',
  },
  {
    label: 'RE - Reggio-Emilia',
    value: 'RE',
  },
  {
    label: 'RI - Rieti',
    value: 'RI',
  },
  {
    label: 'RN - Rimini',
    value: 'RN',
  },
  {
    label: 'RM - Roma',
    value: 'RM',
  },
  {
    label: 'RO - Rovigo',
    value: 'RO',
  },
  {
    label: 'SA - Salerno',
    value: 'SA',
  },
  {
    label: 'SS - Sassari',
    value: 'SS',
  },
  {
    label: 'SV - Savona',
    value: 'SV',
  },
  {
    label: 'SI - Siena',
    value: 'SI',
  },
  {
    label: 'SR - Siracusa',
    value: 'SR',
  },
  {
    label: 'SO - Sondrio',
    value: 'SO',
  },
  {
    label: 'TA - Taranto',
    value: 'TA',
  },
  {
    label: 'TE - Teramo',
    value: 'TE',
  },
  {
    label: 'TR - Terni',
    value: 'TR',
  },
  {
    label: 'TO - Torino',
    value: 'TO',
  },
  {
    label: 'TP - Trapani',
    value: 'TP',
  },
  {
    label: 'TN - Trento',
    value: 'TN',
  },
  {
    label: 'TV - Treviso',
    value: 'TV',
  },
  {
    label: 'TS - Trieste',
    value: 'TS',
  },
  {
    label: 'UD - Udine',
    value: 'UD',
  },
  {
    label: 'VA - Varese',
    value: 'VA',
  },
  {
    label: 'VE - Venezia',
    value: 'VE',
  },
  {
    label: 'VB - Verbania',
    value: 'VB',
  },
  {
    label: 'VC - Vercelli',
    value: 'VC',
  },
  {
    label: 'VR - Verona',
    value: 'VR',
  },
  {
    label: 'VV - Vibo-Valentia',
    value: 'VV',
  },
  {
    label: 'VI - Vicenza',
    value: 'VI',
  },
  {
    label: 'VT - Viterbo',
    value: 'VT',
  },
];

export const states = {
  US: USStates,
  BR: BRStates,
  IT: ITStates,
};
