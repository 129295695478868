export enum ProcessStatus {
  A = 'A',
  V = 'V',
  C = 'C',
  I = 'I',
  N = 'N',
  F = 'F',
}

export enum RecommendationStatus {
  NEEDS_VERIFICATION = 'NEEDS_VERIFICATION',
  RECOMMEND = 'RECOMMEND',
  REJECT = 'REJECT',
}

export enum TaxCodeAttribute {
  CITY = 'City',
  COUNTY = 'County',
  STATE = 'State',
}

export interface TaxCodeLabelObject {
  cityName: string;
  countyName: string;
  stateName: string;
  zipStart: string;
  zipEnd: string;
}
