import { Country } from '@buy-viasat/types/build/bv';
import { createSelector } from 'reselect';
import { AppState, initialAppState } from '../app';
import { RootState } from '../types';
import { CreditVerificationState, initialCreditVerificationState } from './types';

export const selectCreditVerificationDomain = (state: RootState): CreditVerificationState => {
  return state?.creditVerification || initialCreditVerificationState;
};
export const selectAppDomain = (state: RootState): AppState => {
  return state?.app || initialAppState;
};
export const selectDateOfBirth = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.dateOfBirth,
);
export const selectDobMonth = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.dateOfBirth.month,
);
export const selectDobDay = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.dateOfBirth.day,
);
export const selectDobYear = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.dateOfBirth.year,
);
export const selectLastFourOfSSN = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.lastFourOfSSN,
);
export const selectTaxId = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.taxId,
);
export const selectObscuredLastFourOfSSN = createSelector(
  [selectCreditVerificationDomain],
  ({ lastFourOfSSN, isLastFourObscured }: CreditVerificationState) =>
    isLastFourObscured ? lastFourOfSSN.value.replace(/\w/g, '*') : lastFourOfSSN.value,
);
export const selectIsLastFourObscured = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.isLastFourObscured,
);
export const selectIsLegalCheckboxChecked = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.isLegalCheckboxChecked,
);
export const selectIsCreditVerificationCheckoutButtonDisabled = createSelector(
  [selectCreditVerificationDomain, selectAppDomain],
  (state, appState) => appState.country !== Country.BR && !state.isLegalCheckboxChecked,
);
export const selectCreditVerificationIsLoading = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.isLoading,
);
export const selectUpdateCreditVerification = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.updateCreditVerification,
);
export const selectCreditCheckComplete = createSelector(
  [selectCreditVerificationDomain],
  (state: CreditVerificationState) => state.isCreditCheckComplete,
);
export const selectIsSsnMandatory = createSelector([selectCreditVerificationDomain], (state) => state.isSSNMandatory);
export const selectDisplaySSNMandatoryModal = createSelector(
  [selectCreditVerificationDomain],
  (state) => state.displaySSNMandatoryModal,
);
