import { Product } from '@buy-viasat/types/build/bv';
import { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE_ACTION } from '../types';
import { createSlice, isDefined } from '../utils';
import { initialPlanState } from './';
import { PlanState, SetPlanAction } from './types';

const planSlice = createSlice({
  name: 'plan',
  initialState: initialPlanState,
  reducers: {
    getPlans(state: PlanState): void {
      state.isPlanLoading = true;
    },
    getAvailableClosingOffers(): void {},
    planOnNext(state: PlanState, action: PayloadAction<SetPlanAction>): void {},
    setIsPlanLoading(state: PlanState, action: PayloadAction<boolean>): void {
      state.isPlanLoading = action.payload;
    },
    setSelectedPlan(state, action: PayloadAction<Product>) {
      state.selectedPlan = action.payload;
    },
    setViewDetailsPlan(state, action: PayloadAction<Product>) {
      state.viewDetailsPlan = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setHasChoicePlusStream(state, action: PayloadAction<boolean>) {
      state.hasChoicePlusStream = action.payload;
    },
    setHasNextGenCafIIPlans(state, action: PayloadAction<boolean>) {
      state.hasNextGenCafIIPlans = action.payload;
    },
    setHasNextGenPlans(state, action: PayloadAction<boolean>) {
      state.hasNextGenPlans = action.payload;
    },
    setHasVS2LargePlans(state, action: PayloadAction<boolean>) {
      state.hasVS2LargePlans = action.payload;
    },
    setSkipApReload(state, action: PayloadAction<boolean>) {
      state.skipApReload = action.payload;
    },
    setHasOnlyCafPlans(state, action: PayloadAction<boolean>) {
      state.hasOnlyCafPlans = action.payload;
    },
    setDisplayingVoicePlans(state, action: PayloadAction<boolean>) {
      state.displayingVoicePlans = action.payload;
    },
    setIsUpsertPlanLoading(state: PlanState, action: PayloadAction<boolean>): void {
      state.isUpsertPlanLoading = action.payload;
    },
    setAvailableProducts(state: PlanState, action: PayloadAction<Array<Product>>): void {
      state.availableProducts = action.payload;
      state.isPlanLoading = false;
    },
    setPlanId(
      state: PlanState,
      {
        payload: {
          id,
          extensionProducts,
          name,
          offerId,
          planPrice,
          planPromo,
          inflectionPointText,
          productFamily,
          isCafII,
          isRegulated,
          dataCap,
        },
      }: PayloadAction<SetPlanAction>,
    ): void {
      state.planName = name;
      state.planId = id;
      state.offerId = offerId;
      state.extensionProducts = extensionProducts;
      state.planPrice = planPrice;
      state.planPromo = planPromo;
      state.inflectionPointText = inflectionPointText;
      state.productFamily = productFamily;
      state.isRegulated = isRegulated;
      state.isCafII = isCafII;
      state.dataCap = dataCap;
    },
    setHavePlansLoaded(state: PlanState, action: PayloadAction<boolean>): void {
      state.havePlansLoaded = action.payload;
    },
    setPreassignedPlanId(state: PlanState, action: PayloadAction<string>): void {
      state.preassignedPlanId = action.payload;
    },
    setHasCafII(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasCafII = action.payload;
    },
    setHasUnleashedPlan(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasUnleashedPlan = action.payload;
    },
    setHasUnlimitedPlans(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasUnlimitedPlans = action.payload;
    },
    setHasChoicePlans(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasChoicePlans = action.payload;
    },
    setHasBusinessChoicePlans(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasBusinessChoicePlans = action.payload;
    },
    setHasDiamondPlans(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasDiamondPlans = action.payload;
    },
    setHasDiscoveryPlans(state: PlanState, action: PayloadAction<boolean>): void {
      state.hasDiscoveryPlans = action.payload;
    },
    toggleACPBanner(state: PlanState, action: PayloadAction<boolean>): void {
      state.isACPBannerShowing = action.payload;
    },
    setImageOneUrl(state, action: PayloadAction<string>) {
      state.imageOneUrl = action.payload;
      state.hasImageOneUrl = isDefined(state.imageOneUrl);
    },
    isFragmentUnpublished(state: PlanState, action: PayloadAction<string>): void {
      const id = action.payload;
      state.isFragmentUnpublished = {
        ...state.isFragmentUnpublished,
        [id]: true,
      };
    },
    resetExperienceFragments(state: PlanState): void {
      state.isFragmentUnpublished = {};
    },
    resetImageOneUrl(state) {
      state.imageOneUrl = '';
      state.hasImageOneUrl = false;
    },
    resetPlans(state) {
      state.hasChoicePlans = false;
      state.hasDiamondPlans = false;
      state.hasDiscoveryPlans = false;
      state.hasVS2LargePlans = false;
      state.hasNextGenPlans = false;
      state.hasNextGenCafIIPlans = false;
      state.hasUnlimitedPlans = false;
      state.hasUnleashedPlan = false;
      state.hasCafII = false;
      state.hasBusinessChoicePlans = false;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): PlanState => initialPlanState,
  },
});

export const { actions: planActions, reducer: planReducer, name: planKey } = planSlice;
