import { ApolloQueryResult, gql } from '@apollo/client';
import { AddressType, Coordinates } from '@buy-viasat/redux/src/address';
import { client } from 'shared/containers/apollo';
import { DateOfBirth, FormFieldState } from '../../../views/Checkout/types';
import { PerformCreditCheckResponse } from '@buy-viasat/types/build/bv';

export type PerformCreditCheckPayload = ApolloQueryResult<{ performCreditCheck: PerformCreditCheckResponse }>;

interface PerformCreditCheckInput {
  partyId: string;
  cartId: string;
  productId: string;
  lastFourOfSSN: FormFieldState<string>;
  dateOfBirth: DateOfBirth<string, string, string>;
  serviceAddress: AddressType;
  coordinates: Coordinates;
  network: boolean;
  dealerId?: string;
  partnerId?: string;
  taxId?: FormFieldState<string>;
  taxIdExternalName?: string;
  subsidyApplicationId: string;
  salesAgreementId: string;
  offerId: string;
}

const PERFORM_CREDIT_CHECK = gql`
  query performCreditCheck($input: PerformCreditCheckInput!) {
    performCreditCheck(input: $input) {
      isEligible
      equifaxTransactionId
      creditTag
      installationFee
      feesAndDiscounts {
        fee
        discount
        total
        name
      }
    }
  }
`;

const performCreditCheckAsync = async (input: PerformCreditCheckInput): Promise<PerformCreditCheckPayload> => {
  const {
    partyId,
    cartId,
    productId,
    lastFourOfSSN,
    dateOfBirth,
    serviceAddress,
    coordinates,
    network,
    dealerId,
    partnerId,
    taxId,
    taxIdExternalName,
    subsidyApplicationId,
    salesAgreementId,
    offerId,
  } = input;
  return client.query({
    fetchPolicy: network ? 'network-only' : 'cache-first',
    query: PERFORM_CREDIT_CHECK,
    variables: {
      input: {
        partyId,
        cartId,
        productId,
        ssn: lastFourOfSSN.value,
        taxId: taxId?.value,
        taxIdExternalName,
        dob: {
          month: dateOfBirth.month.value,
          day: dateOfBirth.day.value,
          year: dateOfBirth.year.value,
        },
        serviceLocation: {
          address: {
            addressLines: serviceAddress.addressLines,
            municipality: serviceAddress.municipality,
            region: serviceAddress.region,
            postalCode: serviceAddress.postalCode,
            countryCode: serviceAddress.countryCode,
          },
          coordinates,
        },
        dealerId,
        partnerId,
        subsidyApplicationId,
        salesAgreementId,
        offerId,
      },
    },
  });
};

export default performCreditCheckAsync;
