import { useEffect, useState } from 'react';
import Phone from '@vst/beam-icons/icons/Phone';
import CareAgent from '@vst/beam-icons/icons/CareAgent';
import { ViasatLogoDefault } from '@vst/beam-icons/logos/viasat';
import ContactUs from '@vst/beam-icons/illustrative-icons/ContactUs';
import { Button, Icon, Logo, Surface, Txt, StandAloneLink } from '@vst/beam';

import { pnApiRun } from '@buy-viasat/utils';

import styles from './header.module.scss';
import { ContactUsModal } from '../Modal/ContactUsModal';

export interface HeaderProps {
  phoneNumber: string;
  linkUrl: string;
  linkPrefix: string;
  modalTitle: string;
  modalDescription: string;
  modalPrimaryButtonText: string;
  displayPhoneNumber: boolean;
}

export const Header = (props: HeaderProps) => {
  const { phoneNumber, linkUrl, linkPrefix, modalTitle, modalDescription, modalPrimaryButtonText, displayPhoneNumber } =
    props;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      pnApiRun();
    });
  }, []);

  const openModal = () => {
    setShowModal(true);
    setTimeout(() => {
      pnApiRun();
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const href = `tel:${phoneNumber}`;

  return (
    <Surface variant="primary" responsive className={styles['headerWrapper']}>
      <StandAloneLink href={linkUrl} openInNewLocation data-testid="headerLogoLink">
        <Logo logo={ViasatLogoDefault} display="inline" size={96} alt="Viasat" data-testid="headerLogo" />
      </StandAloneLink>
      {displayPhoneNumber ? (
        <Surface className={styles['contactUsContainer']}>
          <Txt variant="bodyLargeBold" color="subtle" mr="24px" data-testid="contactUs" className={styles['hidden']}>
            {linkPrefix}
          </Txt>
          <Txt component="span" mr="12px">
            <Icon icon={Phone} color="gray_600" data-testid="phoneIconDesktop" />
          </Txt>
          <Txt variant="bodyLargeBold" color="subtle" className={styles['linkContainer']}>
            <StandAloneLink href={href} linkSize="large" variant="primary" data-testid="linkNumberDesktop">
              {phoneNumber}
            </StandAloneLink>
          </Txt>
        </Surface>
      ) : (
        <Button variant="tertiary" onClick={openModal} className={styles['buttonIcon']}>
          <Icon icon={CareAgent} color="gray_600" data-testid="careAgentIcon" />
        </Button>
      )}
      {showModal && (
        <ContactUsModal
          iconName={ContactUs}
          heading={modalTitle}
          bodyText={
            <>
              {modalDescription}
              <Txt mt="24px">
                <StandAloneLink variant="primary" href={href} linkSize="large">
                  {phoneNumber}
                </StandAloneLink>
              </Txt>
            </>
          }
          primaryButton={{
            label: modalPrimaryButtonText,
            handleClick: closeModal,
          }}
          closeButton={{
            handleClick: closeModal,
          }}
        />
      )}
    </Surface>
  );
};
