import { useSelector } from 'react-redux';
import {
  CustomerType,
  FlowName,
  selectCustomerType,
  selectDisplayCalculatedLaterCartLabel,
  selectFeatureFlags,
  selectSalesFlowDefinition,
} from '@buy-viasat/redux/src/app';
import { selectCreditCheckComplete } from '@buy-viasat/redux/src/credit-verification';
import {
  ScheduleInstallLocations,
  selectIsMaxRouteGreaterThanScheduleInstallation,
} from '@buy-viasat/redux/src/navigator';

export const useTotalPriceVisibility = (isBackgroundAPICallLoading?: boolean) => {
  const customerType = useSelector(selectCustomerType);
  const isBusinessUser = customerType === CustomerType.BUSINESS;
  const { flowName } = useSelector(selectSalesFlowDefinition);
  const isUSResidential = customerType === CustomerType.RESIDENTIAL && flowName === FlowName.US_RES;

  const isCreditCheckComplete = useSelector(selectCreditCheckComplete);
  const { displayCreditCheckPage, scheduleInstallLocation } = useSelector(selectFeatureFlags);
  const isCurrentRouteGreaterThanScheduleInstallation = useSelector(selectIsMaxRouteGreaterThanScheduleInstallation);

  const displayScheduleInstallationPage = scheduleInstallLocation === ScheduleInstallLocations.CreditCheck;
  const displayCalculatedLaterCartLabel = useSelector(selectDisplayCalculatedLaterCartLabel);

  const passesCreditVerificationCheck = displayCreditCheckPage
    ? isCreditCheckComplete && !displayCalculatedLaterCartLabel
    : false;
  const passesScheduleInstallationCheck = displayScheduleInstallationPage
    ? isCurrentRouteGreaterThanScheduleInstallation
    : true;

  const visible = isUSResidential
    ? passesCreditVerificationCheck
    : (isBusinessUser && !isBackgroundAPICallLoading) ||
      (passesCreditVerificationCheck && passesScheduleInstallationCheck);

  return { visible };
};
