export const servicePlanMap = {
  'Viasat Voice': 'viasatVoice',
};

export const SellingPoint = {
  DataCap: 'DataCap',
  Speed: 'Speed',
};

export const DataCap = {
  Unlimited: 'Unlimited',
};

export const topperColor = {
  BLUE: 'blue',
  WARM: 'warm',
  GREEN: 'green',
  VIOLET: 'violet',
  PINK: 'pink',
  TEAL: 'teal',
  ORANGE: 'orange',
  RED: 'red',
  GRAY: 'gray',
};
