import { Surface, Txt } from '@vst/beam';
import styles from './selectedPlansName.module.scss';

type SelectedPlansNameProps = {
  name: string;
};

export const SelectedPlansName = ({ name }: SelectedPlansNameProps) => {
  return (
    <Surface pb="24px" pt="8px" className={styles['planNameContainer']} data-testid="CheckoutCartSelectedPlanName">
      <Txt variant="heading5">{name}</Txt>
    </Surface>
  );
};
