import { Surface, Txt } from '@vst/beam';
import styles from './checkoutCartDescription.module.scss';
import { PlanCharacteristics } from '@buy-viasat/types/build/bv';
import { servicePlanMap, SellingPoint, DataCap, checkProp } from '@buy-viasat/utils';
import { CheckoutCartSpeedDetails } from '../CheckoutCartSpeedDetails';
import { CheckoutCartBadge } from '../CheckoutCartBadge';
import { CheckoutCartAvailableAddons } from '../CheckoutCartAvailableAddons';

type CheckoutCartDescriptionProps = {
  characteristics: PlanCharacteristics | null;
  sellingPoint: string;
  headerPhraseKeys: {
    speedDetails: {
      above: string;
      below: string;
    };
    availableAddons: string;
  };
};

export const CheckoutCartDescription = ({
  characteristics,
  sellingPoint,
  headerPhraseKeys,
}: CheckoutCartDescriptionProps) => {
  const {
    dataCap,
    dataCapUnits,
    downloadSpeed,
    downloadUnits,
    shortName,
    tag,
    titleAddOns,
    textGeneral01,
    bannerColor,
  } = characteristics ?? {};
  const dataCapVariant = dataCap ? dataCap : shortName;

  return (
    <Surface className={styles['cartDescriptionContainer']} data-testid="cartDescriptionContainer">
      <Surface className={styles['flex']}>
        {dataCap ? (
          <Surface>
            <CheckoutCartSpeedDetails
              downloadSpeed={sellingPoint === SellingPoint.Speed ? checkProp(downloadSpeed) : dataCap}
              downloadUnits={
                sellingPoint === SellingPoint.Speed
                  ? checkProp(downloadUnits)
                  : dataCap === DataCap.Unlimited
                    ? ''
                    : checkProp(dataCapUnits)
              }
              sellingPoint={sellingPoint}
              textGeneral={checkProp(textGeneral01)}
              speedDetails={headerPhraseKeys.speedDetails}
            />
          </Surface>
        ) : (
          <Txt
            className={styles[`${servicePlanMap[shortName as keyof typeof servicePlanMap]}`]}
            color="subtle"
            data-testid={shortName}
            pb="24px"
            variant="heading3"
          >
            {shortName}
          </Txt>
        )}
        {tag && <CheckoutCartBadge tag={tag} variant={checkProp(dataCapVariant)} badgeColor={bannerColor || ''} />}
      </Surface>
      {titleAddOns ? (
        <CheckoutCartAvailableAddons
          titleAddOns={titleAddOns}
          variant={checkProp(dataCap)}
          lineColor={bannerColor || ''}
          phraseAvailableAddons={headerPhraseKeys.availableAddons}
        />
      ) : null}
    </Surface>
  );
};
