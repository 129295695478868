import { Col, Container, Row, Surface } from '@vst/beam';
import { Header, LoadingSpinner } from '@buy-viasat/react';
import styles from './orderSubmittingAnimation.module.scss';

export type OrderSubmittingAnimationProps = {
  loadingSpinnerSubheadingText: string;
  messagesArraySpinner: string[];
  displayPhoneNumber: boolean;
  phoneNumber: string;
  linkUrl: string;
  linkPrefix: string;
  modalTitle: string;
  modalDescription: string;
  modalPrimaryButtonText: string;
};

export function OrderSubmittingAnimation(props: OrderSubmittingAnimationProps) {
  return (
    <Surface variant="secondary" className={styles['root']}>
      <Surface className={styles['header']} data-testid="header">
        <Container fluidUntil={'xl'} className={styles['headerContainer']}>
          <Row edge2edge>
            <Col>
              <Header
                linkUrl={props.linkUrl}
                linkPrefix={props.linkPrefix}
                phoneNumber={props.phoneNumber}
                displayPhoneNumber={props.displayPhoneNumber}
                modalTitle={props.modalTitle}
                modalDescription={props.modalDescription}
                modalPrimaryButtonText={props.modalPrimaryButtonText}
              />
            </Col>

            <Surface className={styles['loadingSpinner']}>
              <LoadingSpinner
                size={'large'}
                text={props.loadingSpinnerSubheadingText}
                heading={props.messagesArraySpinner}
              />
            </Surface>
          </Row>
        </Container>
      </Surface>
    </Surface>
  );
}
