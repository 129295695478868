import { ActionEnum } from './generated/schema-types';
import { Maybe, Price } from './index';

export interface CartAction {
  action: ActionEnum;
  productTypeId: string;
}

export type CartActions = Array<CartAction>;

export interface OFMCartItem {
  name: Maybe<string>;
  kind: Maybe<string>;
  productCandidateId: Maybe<string>;
  productTypeId: Maybe<string>;
  prices: Maybe<Array<Price>>;
}

export enum OFMKind {
  PLAN = 'bep.ofm.product-types.fixed-satellite-internet',
  PROMO = 'bep.ofm.product-types.discount-promotion',
  LEASE = 'bep.ofm.product-types.lease-fee',
  EASY_CARE = 'bep.ofm.product-types.easy-care',
  STREAMON = 'bep.ofm.product-types.streamon',
  VOICE = 'bep.ofm.product-types.satellite-internet-add-on',
  SHIELD = 'bep.ofm.package-types.shield',
  LAYER = 'bep.ofm.package-types.package-layer',
  DISCOUNT = 'DISCOUNT',
}

export enum AccountSetup {
  PACKAGE_TYPES = 'bep.ofm.package-types.account-setup-fee',
  PRODUCT_TYPE = 'bep.ofm.product-types.account-setup-fee',
}

export enum Recurrence {
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
}

export enum Characteristics {
  DataCap = 'DATA_CAP',
  DataCapUnits = 'DATA_CAP_UNITS',
  DataPolicyDescription = 'DATA_POLICY_DESCRIPTION',
  DisplayOrder = 'DISPLAY_ORDER',
  DownloadSpeed = 'DOWNLOAD_SPEED',
  DownloadUnits = 'DOWNLOAD_UNITS',
  FreeZone = 'FREE_ZONE_TIME',
  OfferName = 'OFFER_NAME',
  Positioning = 'POSITIONING',
  ProductGroup = 'PRODUCT_GROUP',
  PromoText = 'PROMO_TEXT',
  UploadSpeed = 'UPLOAD_SPEED',
  UploadUnits = 'UPLOAD_UNITS',
}

export enum ProductTypeId {
  VOICE = '9fbdac0d-bc2a-468c-b5ce-52bd3e0bd916',
  EASY_CARE = '52df7cdd-5678-43f0-89fd-74445d69b9e0',
  CLOSING_OFFER = '7c7a64ea-2334-4b1c-ac0f-cecaeeb15028',
}

export enum BrazilOffers {
  CATALOG_SEGMENTS = 'Residential Internet',
  CHARACTERISTIC_VALUE = 'FIXED_SATELLITE_INTERNET',
  CHARACTERISTIC_NAME = 'PSM_PRODUCT_KIND',
}
