import ZipCodeFieldInput from '@buy-viasat/react/src/components/ZipCodeFieldInput';
import {
  CustomerType,
  selectCountry,
  selectCustomerType,
  selectFeatureFlags,
  selectSalesFlowDefinition,
} from '@buy-viasat/redux/src/app';
import { selectServiceAddressValues, serviceabilityActions } from '@buy-viasat/redux/src/serviceability';
import { houseNumberRegex, zipCodeRegex } from '@buy-viasat/utils';
import { Col, Container, Row, Txt } from '@vst/beam';
import { FormEventHandler, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddressFieldInput from '../AddressFieldInput';
import AptUnitFieldInput from '../AptUnitFieldInput';
import HouseNumberFieldInput from '../HouseNoFieldInput';
import styles from './multiFieldAddress.module.scss';

const MultiFieldAddressForm = () => {
  const dispatch = useDispatch();
  const country = useSelector(selectCountry);
  const customerType = useSelector(selectCustomerType);
  const streetAddress = useSelector(selectServiceAddressValues);
  const { zipFormat } = useSelector(selectSalesFlowDefinition);
  const { t } = useTranslation(['serviceability']);
  const { displayHouseNumber } = useSelector(selectFeatureFlags);
  const handleOnAddressChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(serviceabilityActions.setServiceAddressLine1(e.currentTarget.value));
    },
    [dispatch],
  );

  const handleOnAptUnitChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(serviceabilityActions.setServiceAddressLine2(e.currentTarget.value));
    },
    [dispatch],
  );

  const handleOnHouseNumberChanged = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(serviceabilityActions.setServiceAddressHouseNumber(e.currentTarget.value));
    },
    [dispatch],
  );

  const handleOnZipCodeChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(serviceabilityActions.setServiceAddressPostalCode(e.currentTarget.value));
    },
    [dispatch],
  );

  const addressLabel = (
    <Trans>
      {customerType === CustomerType.BUSINESS
        ? t('serviceability.field.unit/Suite.label')
        : t('serviceability.field.aptUnit.label')}
      <Txt variant={'smallRegular'} color={'subtle'}>
        {t('serviceability.optionalLabel')}
      </Txt>
    </Trans>
  );

  return (
    <Container fluid data-test-id="multiFieldAddressForm" className={styles['root']}>
      <Row>
        <Col>
          <Row>
            <Col>
              <AddressFieldInput
                fluid
                mb="24px"
                name="streetAddress"
                data-testid="streetAddress"
                labelProps={{ labelText: t('serviceability.field.streetAddress.label'), showStar: false }}
                validation={{
                  required: t('serviceability.field.streetAddress.validationRequired'),
                }}
                onChange={handleOnAddressChange}
              />
            </Col>
          </Row>
          <Row>
            {displayHouseNumber ? (
              <Col xs={6}>
                <HouseNumberFieldInput
                  fluid
                  mb="24px"
                  name="houseNumber"
                  regex={houseNumberRegex}
                  labelProps={{ labelText: t('serviceability.field.houseNumber.label'), showStar: false }}
                  onChange={handleOnHouseNumberChanged}
                  validation={{
                    required: t('serviceability.field.houseNumber.validationRequired'),
                    invalid: t('serviceability.field.houseNumber.validationFormat'),
                  }}
                  maxLength={5}
                />
              </Col>
            ) : (
              <Col xs={6}>
                <AptUnitFieldInput
                  fluid
                  mb="24px"
                  name="aptAddress"
                  labelProps={{
                    labelText: addressLabel as unknown as string,
                    showStar: false,
                  }}
                  onChange={handleOnAptUnitChange}
                />
              </Col>
            )}
            <Col xs={6}>
              <ZipCodeFieldInput
                fluid
                mb="24px"
                regex={zipCodeRegex[country]}
                name="postalCodeAddress"
                data-testid="zipCodeField"
                defaultValue={streetAddress.postalCode}
                format={zipFormat || undefined}
                labelProps={{ labelText: t('serviceability.field.zipCode.label'), showStar: false }}
                validation={{
                  required: t('serviceability.field.zipCode.validationRequired'),
                  invalid: t('serviceability.field.zipCode.validationFormat'),
                }}
                onChange={handleOnZipCodeChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MultiFieldAddressForm;
