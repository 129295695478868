import { ApolloQueryResult, gql } from '@apollo/client';
import { AddOn, GetAddOnsInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type GetAddOnsPayload = ApolloQueryResult<{ getAddOns: AddOn[] }>;

export const GET_ADD_ONS = gql`
  query getAddOns($input: GetAddOnsInput!) {
    getAddOns(input: $input) {
      id
      name
      kind
      description
      subHeading
      price
      promo {
        price
        duration
        unit
      }
      selected
      attributes
      noAddAttributes
      shortName
      annualSavings
      tag
      crossOuts
      disclosure
      offerId
      displayOrder
    }
  }
`;

export default async function getAddonsAsync(input: GetAddOnsInput): Promise<GetAddOnsPayload> {
  return client.query({
    query: GET_ADD_ONS,
    variables: { input },
  });
}
