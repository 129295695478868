import { CountryCode } from 'libphonenumber-js';
import {
  CreateIndividualInput,
  CustomerInfoMarketing,
  UpdateOrganizationInput,
  CreateOrganizationInput,
} from '@buy-viasat/types/build/bv';
import { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE_ACTION } from '../types';
import { createSlice } from '../utils';
import { initialPersonalInformationState } from './';
import { ErrorPayload, NavigationGeneratorAction, PartyId, PersonalInformationState } from './types';

const personalInformationSlice = createSlice({
  name: 'personalInformation',
  initialState: initialPersonalInformationState,
  reducers: {
    createOrUpdateCustomer(): void {},
    onEditPostalCodeClick(state: PersonalInformationState): void {
      state.isPersonalInformationLoading = false;
    },
    onSagaError(state: PersonalInformationState, action: PayloadAction<ErrorPayload>): void {
      state.isPersonalInformationLoading = false;
    },
    scrubBillingAddress(): void {},
    scrubShippingAddress(): void {},
    navigationGenerator(state: PersonalInformationState, action: PayloadAction<NavigationGeneratorAction>): void {},
    updateCustomer(state: PersonalInformationState, action: PayloadAction<CreateIndividualInput & PartyId>): void {},
    createCustomer(state: PersonalInformationState, action: PayloadAction<CreateIndividualInput>): void {},
    updateOrganization(state: PersonalInformationState, action: PayloadAction<UpdateOrganizationInput>): void {},
    createOrganization(state: PersonalInformationState, action: PayloadAction<CreateOrganizationInput>): void {},
    /* eslint-enable @typescript-eslint/no-empty-function */
    // SETTERS START
    setEmail(state: PersonalInformationState, action: PayloadAction<string>): void {
      state.email.value = action.payload;
    },

    setFirstName(state: PersonalInformationState, action: PayloadAction<string>): void {
      state.firstName.value = action.payload;
    },
    setLastName(state: PersonalInformationState, action: PayloadAction<string>): void {
      state.lastName.value = action.payload;
    },
    setPhone(state: PersonalInformationState, action: PayloadAction<string>): void {
      state.phone.value = action.payload;
    },
    setPhoneCountryCode(state: PersonalInformationState, action: PayloadAction<CountryCode>): void {
      state.phoneCountryCode = action.payload;
    },
    setMobileNumber(state: PersonalInformationState, action: PayloadAction<string>): void {
      state.mobileNumber.value = action.payload;
    },
    setShowConfirmBillingAddress(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.showConfirmBillingAddress = action.payload;
    },
    setIsPersonalInformationLoading(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.isPersonalInformationLoading = action.payload;
    },
    setIsDisclaimerCheckboxChecked(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.isDisclaimerCheckboxChecked = action.payload;
    },
    setIsSubsidyCheckboxChecked(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.isSubsidyCheckboxChecked = action.payload;
      if (!action.payload) {
        state.subsidyApplicationId = initialPersonalInformationState.subsidyApplicationId;
      }
    },
    setIsMobileNumberChecked(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.isMobileNumberChecked = action.payload;
    },
    setSubsidyApplicationId(state: PersonalInformationState, action: PayloadAction<string>): void {
      state.subsidyApplicationId.value = action.payload;
    },
    setIsMarketingCheckboxChecked(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.isMarketingCheckboxChecked = action.payload;
    },

    setIsPersonalInformationRequestComplete(state: PersonalInformationState, action: PayloadAction<boolean>): void {
      state.isPersonalInformationRequestComplete = action.payload;
    },

    setCustomerInfoToCompare(state: PersonalInformationState, action: PayloadAction<CustomerInfoMarketing>): void {
      state.previousCustomerInfo.email = action.payload.email;
      state.previousCustomerInfo.firstName = action.payload.firstName;
      state.previousCustomerInfo.lastName = action.payload.lastName;
      state.previousCustomerInfo.phoneNumber = action.payload.phoneNumber;
      state.previousCustomerInfo.serviceAddress = action.payload.serviceAddress;
    },
    // VALIDATION SETTERS END
    validateBillingAddress(): void {},
    validateShippingAddress(): void {},
    validateAndCreateOrUpdateCustomer(): void {},
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): PersonalInformationState => initialPersonalInformationState,
  },
});

export const {
  actions: personalInformationActions,
  reducer: personalInformationReducer,
  name: personalInformationKey,
} = personalInformationSlice;
