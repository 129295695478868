export enum ErrorCode {
  ENV_FILE_ERROR = 'EnvFileError',
  INTERNAL_SERVER_ERROR = 'InternalServerError',
  TARGET_ENDPOINT_ERROR = 'TargetEndpointError',
  NETWORK_ERROR = 'NetworkError',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN_ERROR = 'UnknownError',
  BAD_ARGUMENTS_ERROR = 'BadArgumentsError',
  TOKEN_RETRIEVAL_ERROR = 'TokenRetrievalError',
  FORBIDDEN_ERROR = 'ForbiddenError',
}
