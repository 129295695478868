import { Maybe } from '@buy-viasat/types/build/bv';

export const checkForLength = (cartText: string): boolean => {
  return cartText.length > 35;
};

export const splitText = (cartText: string) => {
  const splitWords = cartText.split(' ');
  const firstHalf = splitWords?.slice(0, Math.ceil(splitWords.length / 2)).join(' ');
  const secondHalf = splitWords?.slice(Math.ceil(splitWords.length / 2)).join(' ');

  return { firstHalf, secondHalf };
};

export const checkProp = (prop: Maybe<string> | undefined) => {
  return prop ?? '';
};
