import { FetchResult, gql } from '@apollo/client';
import { UpdateIndividualInput, UpdateIndividualResponse } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type UpdateIndividualPayload = FetchResult<{ updateIndividual: UpdateIndividualResponse }>;

const UPDATE_INDIVIDUAL = gql`
  mutation updateIndividual($input: UpdateIndividualInput!) {
    updateIndividual(input: $input) {
      updateCreditCheck
    }
  }
`;

export default async function updateIndividualAsync(input: UpdateIndividualInput): Promise<UpdateIndividualPayload> {
  return client.mutate({
    mutation: UPDATE_INDIVIDUAL,
    variables: {
      input,
    },
  });
}
