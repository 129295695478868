import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BillingAddressNotVerifiedModal } from '@buy-viasat/account-information/src/components/BillingAddressNotVerifiedModal';
import { ConfirmBillingAddressModal } from '@buy-viasat/account-information/src/components/ConfirmBillingAddressModal';
import { ConfirmShippingAddressModal } from '@buy-viasat/account-information/src/components/ConfirmShippingAddressModal';
import { ShippingAddressNotVerifiedModal } from '@buy-viasat/account-information/src/components/ShippingAddressNotVerifiedModal';
import { SystemDownModal } from '@buy-viasat/acp/src/components/SystemDownModal';
import { GeneralAcpModal } from '@buy-viasat/acp/src/components/GeneralAcpModal';
import { AcpSuccessModal } from '@buy-viasat/acp/src/components/AcpSuccessModal';
import { AcpApplicationNotFoundModal } from '@buy-viasat/acp/src/components/AcpApplicationNotFoundModal';
import { BackButtonPromptModal } from '@buy-viasat/order-confirmation/src/components/BackButtonPromptModal';
import { ScheduleLaterModal } from '@buy-viasat/schedule-installation/src/components/ScheduleLaterModal';
import {
  appActions,
  Modals,
  selectCountry,
  selectCurrentModal,
  selectHasVisitedOrderReview,
  selectIsModalVisible,
} from '@buy-viasat/redux/src/app';
import {
  selectInlineBillingStreetAndPostalCode,
  selectInlineScrubBillingAddress,
  selectInlineShippingAddress,
  selectInlineShippingStreetAndPostalCode,
} from '@buy-viasat/redux/src/address';
import { personalInformationActions } from '@buy-viasat/redux/src/personal-information';
import { paymentInformationActions, selectSpbBillingAccountId } from '@buy-viasat/redux/src/payment-information';
import {
  selectInlineScrubAddress,
  selectInlineServiceStreetAndPostalCode,
  selectScrubLocation,
  serviceabilityActions,
} from '@buy-viasat/redux/src/serviceability';
import { ConfirmServiceAddressModal } from '@buy-viasat/serviceability/src/components/ConfirmServiceAddressModal';
import { AddressNotVerifiedModal } from '@buy-viasat/serviceability/src/components/AddressNotVerifiedModal';
import { NoAddressFoundModal } from '@buy-viasat/serviceability/src/components/NoAddressFoundModal';
import { NoAvailablePlansModal } from '@buy-viasat/serviceability/src/components/NoAvailablePlansModal';
import { BackgroundApiErrorModal } from '@buy-viasat/react/src/components/BackgroundApiErrorModal';
import { UpdateBillingAddressModal } from '@buy-viasat/payment-information/src/components/UpdateBillingAddressModal';
import { PaymentErrorModal } from '@buy-viasat/payment-information/src/components/PaymentErrorModal';
import { SSNMandatoryModal } from '@buy-viasat/credit-verification/src/components/SSNMandatoryModal';
import { CAEModal } from '@buy-viasat/credit-verification/src/components/CAEModal';
import { GeneralErrorModal } from '../GeneralErrorModal';
import { ACPModal } from '../ACPModal';
import { RemoveOptionalServiceModal } from '@buy-viasat/addons/src/components/RemoveOptionalServiceModal';
import { GeneralAcpModalVariant } from '@buy-viasat/acp/src/components/GeneralAcpModal/types';
import { navActions, Routes, selectCurrentAppRoute } from '@buy-viasat/redux/src/navigator';
import { subsidyProgramActions } from '@buy-viasat/redux/src/subsidy-program';
import { cartActions } from '@buy-viasat/redux/src/cart';
import { addonActions } from '@buy-viasat/redux/src/addons';
import { ClosingOfferRetryErrorModal } from '../ClosingOfferRetryErrorModal';
import { ClosingOfferNotEligibleModal } from '../ClosingOfferNotEligibleModal';
import { planActions, selectSelectedPlan } from '@buy-viasat/redux/src/plan';
import { closingOffersActions } from '@buy-viasat/redux/src/closingOffers';

export const BeamModalSelector = () => {
  const isModalVisible = useSelector(selectIsModalVisible);
  const currentModal = useSelector(selectCurrentModal);
  const scrubAddress = useSelector(selectScrubLocation);
  const country = useSelector(selectCountry);
  const inlineScrubAddress = useSelector(selectInlineScrubAddress);
  const inlineBillingScrubAddress = useSelector(selectInlineScrubBillingAddress);
  const inlineShippingScrubAddress = useSelector(selectInlineShippingAddress);
  const serviceAddressStreetAndZip = useSelector(selectInlineServiceStreetAndPostalCode);
  const shippingAddressStreetAndZip = useSelector(selectInlineShippingStreetAndPostalCode);
  const billingAddressStreetAndZip = useSelector(selectInlineBillingStreetAndPostalCode);
  const spbBillingAccountId = useSelector(selectSpbBillingAccountId);
  const currentRoute = useSelector(selectCurrentAppRoute);
  const hasVisitedOrderReview = useSelector(selectHasVisitedOrderReview);
  const dispatch = useDispatch();

  const selectedPlan = useSelector(selectSelectedPlan);

  const { id, name, price, offerId, promo, isCafII, characteristics, extensionTypes } = selectedPlan || {};
  const { isRegulated, dataCap, productFamily, inflectionPointText } = characteristics || {};
  const handleUpsertWithClosingOfferRetryErrorModalPrimaryAction = () => {
    dispatch(closingOffersActions.setUpsertWithClosingOffersError(false));
    dispatch(appActions.setIsModalVisible(false));
  };
  const handleUpsertWithClosingOfferRetryErrorModalSecondaryAction = () => {
    dispatch(closingOffersActions.setUpsertWithClosingOffersError(false));
    dispatch(closingOffersActions.setSkipClosingOffer(true));

    dispatch(
      planActions.planOnNext({
        isRegulated: isRegulated === 'true',
        id: id || '',
        name: name || '',
        planPrice: price || 0,
        offerId: offerId || '',
        planPromo: price || 0,
        isCafII: isCafII || false,
        dataCap: dataCap || '',
        extensionProducts: extensionTypes as string[],
        productFamily: productFamily || '',
        inflectionPointText: inflectionPointText || '',
      }),
    );
    dispatch(appActions.setIsModalVisible(false));
    dispatch(closingOffersActions.setSkipClosingOffer(false));
  };

  const handleGenericErrorModalSecondaryAction = () => {
    if (currentRoute === Routes.PERSONAL_INFORMATION) {
      dispatch(navActions.routeUserTo(Routes.PLAN));
    }
    dispatch(appActions.setIsModalVisible(false));
  };
  const handleGenericErrorModalPrimaryAction = () => {
    dispatch(appActions.onModalConfirm(Modals.ERROR));
    dispatch(appActions.setIsModalVisible(false));
  };

  const handleBackgroundAPIErrorModalPrimaryAction = () => {
    dispatch(appActions.onModalConfirm(Modals.BACKGROUND_API_ERROR));
  };
  const handleBackgroundAPIErrorModalSecondaryAction = () => {
    dispatch(navActions.routeUserTo(Routes.PLAN));
    dispatch(appActions.onModalCancel(Modals.DEFAULT));
  };

  const handleClosingOfferNotEligiblePrimaryAction = () => {
    dispatch(closingOffersActions.setUpsertWithClosingOffersError(false));
    dispatch(appActions.setIsModalVisible(false));
    dispatch(closingOffersActions.setSkipClosingOffer(false));
  };
  const handleClosingOfferNotEligibleSecondaryAction = () => {
    dispatch(closingOffersActions.setUpsertWithClosingOffersError(false));
    dispatch(appActions.setIsModalVisible(false));
    dispatch(navActions.routeUserTo(Routes.PLAN));
  };

  const handleModalAdressNotFound = (modal: Modals | string) => {
    dispatch(personalInformationActions.setIsPersonalInformationLoading(false));

    if (modal) {
      dispatch(appActions.setModalVisible(modal as Modals));
    } else {
      dispatch(appActions.setIsModalVisible(false));
    }
  };

  React.useEffect(() => {
    if (!isModalVisible) return;
    const listener = (event: KeyboardEvent): void => {
      if (event.code !== 'Enter') return;
      dispatch(appActions.onModalConfirm(currentModal));
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [dispatch, isModalVisible, currentModal]);

  if (!isModalVisible) return <></>;
  else {
    switch (currentModal) {
      //Confirm Address Modal #2
      case Modals.ADDRESS_NOT_VERIFIED:
        return (
          <AddressNotVerifiedModal
            primaryAction={() => dispatch(serviceabilityActions.useInlineServiceAddress())}
            secondaryAction={() => dispatch(appActions.setIsModalVisible(false))}
            suggestedAddressText={inlineScrubAddress}
          />
        );
      case Modals.CONFIRM_SERVICE_ADDRESS:
        if (country !== scrubAddress.address.countryCode) {
          return (
            //No Address Found Modal
            <NoAddressFoundModal
              primaryAction={() => dispatch(appActions.onModalCancel(Modals.NO_ADDRESS_FOUND))}
              secondaryAction={() => dispatch(appActions.onModalCancel(Modals.DEFAULT))}
            />
          );
        }
        return (
          //Confirm Address Modal #1
          <ConfirmServiceAddressModal
            radioModalAddresses={{
              inlineScrubAddress: inlineScrubAddress,
              serviceAddress: serviceAddressStreetAndZip,
            }}
            primaryAction={{
              scrubbedAddressRadioButton: () => dispatch(appActions.onModalConfirm(Modals.CONFIRM_SERVICE_ADDRESS)),
              serviceAddressRadioButton: () => dispatch(appActions.onModalCancel(Modals.CONFIRM_SERVICE_ADDRESS)),
            }}
            secondaryAction={() => {
              dispatch(serviceabilityActions.resetServiceAddress());
              dispatch(appActions.setIsModalVisible(false));
            }}
          />
        );
      case Modals.NO_ADDRESS_FOUND:
        return (
          <NoAddressFoundModal
            primaryAction={() => dispatch(appActions.onModalConfirm(Modals.NO_ADDRESS_FOUND))}
            secondaryAction={() => dispatch(appActions.onModalCancel(Modals.DEFAULT))}
          />
        );
      case Modals.NO_AVAILABLE_PLANS:
      case Modals.NO_SERVICE:
        return (
          <NoAvailablePlansModal
            primaryAction={() => dispatch(appActions.onModalConfirm(Modals.NO_AVAILABLE_PLANS))}
            secondaryAction={() => dispatch(appActions.onModalCancel(Modals.DEFAULT))}
          />
        );
      case Modals.BACKGROUND_API_ERROR:
        return (
          <BackgroundApiErrorModal
            primaryAction={handleBackgroundAPIErrorModalPrimaryAction}
            secondaryAction={handleBackgroundAPIErrorModalSecondaryAction}
          />
        );
      case Modals.ERROR:
        return (
          <GeneralErrorModal
            primaryAction={handleGenericErrorModalPrimaryAction}
            secondaryAction={handleGenericErrorModalSecondaryAction}
          />
        );
      case Modals.UPSERT_WITH_CLOSING_OFFER_RETRY_ERROR:
        return (
          <ClosingOfferRetryErrorModal
            primaryAction={handleUpsertWithClosingOfferRetryErrorModalPrimaryAction}
            secondaryAction={handleUpsertWithClosingOfferRetryErrorModalSecondaryAction}
          />
        );
      case Modals.CLOSING_OFFER_NOT_ELIGIBLE:
        return (
          <ClosingOfferNotEligibleModal
            primaryAction={handleClosingOfferNotEligiblePrimaryAction}
            secondaryAction={handleClosingOfferNotEligibleSecondaryAction}
          />
        );
      case Modals.BILLING_ADDRESS_NOT_VERIFIED:
        return (
          <BillingAddressNotVerifiedModal
            suggestedAddressText={inlineBillingScrubAddress}
            primaryAction={() => handleModalAdressNotFound(Modals.NO_ADDRESS_FOUND)}
            secondaryAction={() => handleModalAdressNotFound('')}
          />
        );
      case Modals.SHIPPING_ADDRESS_NOT_VERIFIED:
        return (
          <ShippingAddressNotVerifiedModal
            suggestedAddressText={inlineShippingScrubAddress}
            primaryAction={() => handleModalAdressNotFound(Modals.NO_ADDRESS_FOUND)}
            secondaryAction={() => handleModalAdressNotFound('')}
          />
        );
      case Modals.CONFIRM_BILLING_ADDRESS:
        return (
          <ConfirmBillingAddressModal
            radioModalAddresses={{
              inlineScrubAddress: inlineBillingScrubAddress,
              billingAddress: billingAddressStreetAndZip,
            }}
            primaryAction={{
              scrubbedAddressRadioButton: () => dispatch(appActions.onModalConfirm(Modals.CONFIRM_BILLING_ADDRESS)),
              billingAddressStreetAndZip: () => dispatch(appActions.onModalCancel(Modals.CONFIRM_BILLING_ADDRESS)),
            }}
            secondaryAction={() => {
              dispatch(appActions.setIsCheckoutButtonDisabled(false));
              dispatch(appActions.setIsModalVisible(false));
            }}
          />
        );
      case Modals.CONFIRM_SHIPPING_ADDRESS:
        return (
          <ConfirmShippingAddressModal
            radioModalAddresses={{
              inlineScrubAddress: inlineShippingScrubAddress,
              shippingAddress: shippingAddressStreetAndZip,
            }}
            primaryAction={{
              scrubbedAddressRadioButton: () => dispatch(appActions.onModalConfirm(Modals.CONFIRM_SHIPPING_ADDRESS)),
              shippingAddressStreetAndZip: () => dispatch(appActions.onModalCancel(Modals.CONFIRM_SHIPPING_ADDRESS)),
            }}
            secondaryAction={() => {
              dispatch(appActions.setIsCheckoutButtonDisabled(false));
              dispatch(appActions.setIsModalVisible(false));
            }}
          />
        );
      case Modals.UPDATE_BILLING_ADDRESS:
        return (
          <UpdateBillingAddressModal
            primaryAction={() => dispatch(paymentInformationActions.onEditBillingAddress())}
            secondaryAction={() => dispatch(appActions.setIsModalVisible(false))}
          />
        );
      case Modals.PAYMENT_INFORMATION_ERROR:
        return (
          <PaymentErrorModal
            primaryAction={() => {
              dispatch(appActions.setIsModalVisible(false));
            }}
          />
        );
      case Modals.ACP_MODAL:
        return <ACPModal primaryAction={() => dispatch(appActions.onModalCancel(Modals.ACP_MODAL))} />;
      case Modals.CAE:
        return <CAEModal primaryAction={() => dispatch(appActions.onModalCancel(Modals.CAE))} />;
      case Modals.MANDATORY_SSN:
        return <SSNMandatoryModal primaryAction={() => dispatch(appActions.onModalCancel(Modals.MANDATORY_SSN))} />;
      case Modals.BACK_BUTTON_PROMPT:
        return (
          <BackButtonPromptModal
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.BACK_BUTTON_PROMPT));
              dispatch(navActions.routeUserTo(Routes.SERVICEABILITY));
            }}
            secondaryAction={() => dispatch(appActions.onModalCancel(Modals.BACK_BUTTON_PROMPT))}
          />
        );
      case Modals.SYSTEM_DOWN_MODAL:
        return (
          <SystemDownModal
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.SYSTEM_DOWN_MODAL));
              dispatch(navActions.next());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.SYSTEM_DOWN_MODAL))}
          />
        );
      case Modals.NONTRIBAL_NLAD_LOCATION:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.NONTRIBAL_NLAD_LOCATION}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.NONTRIBAL_NLAD_LOCATION))}
          />
        );
      case Modals.DEFICIENT_CUSTOMER_INFO:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.DEFICIENT_CUSTOMER_INFO}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.DEFICIENT_CUSTOMER_INFO))}
          />
        );
      case Modals.NONTRIBAL_CONSUMER_LOCATION:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.NONTRIBAL_CONSUMER_LOCATION}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.NONTRIBAL_CONSUMER_LOCATION))}
          />
        );
      case Modals.NOT_ELIGIBLE_ACP:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.NOT_ELIGIBLE_ACP}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.NOT_ELIGIBLE_ACP))}
          />
        );
      case Modals.ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
            }}
            secondaryAction={() =>
              dispatch(appActions.onModalConfirm(Modals.ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE))
            }
          />
        );
      case Modals.APPLICATION_NOT_COMPLETED:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.APPLICATION_NOT_COMPLETED}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.APPLICATION_NOT_COMPLETED))}
          />
        );
      case Modals.APPLICATION_PENDING:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.APPLICATION_PENDING}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.APPLICATION_PENDING))}
          />
        );
      case Modals.GENERAL_ERROR:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.GENERAL_ERROR}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.GENERAL_ERROR))}
          />
        );
      case Modals.ACP_TERMS_AND_CONDITIONS:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.ACP_TERMS_AND_CONDITIONS}
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.DEFAULT));
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.ACP_TERMS_AND_CONDITIONS))}
          />
        );
      case Modals.ACP_ILL_DO_IT_LATER:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.ACP_ILL_DO_IT_LATER}
            primaryAction={() => {
              dispatch(navActions.next());
              dispatch(subsidyProgramActions.setReceivingMonthlyDiscount(null));
              dispatch(subsidyProgramActions.setQualifiedForTribalLands(null));
              dispatch(appActions.onModalCancel(Modals.ACP_ILL_DO_IT_LATER));
            }}
            secondaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.ACP_ILL_DO_IT_LATER));
            }}
          />
        );
      case Modals.DUPLICATE_SUBSCRIBER_NLAD:
        return (
          <GeneralAcpModal
            variant={GeneralAcpModalVariant.DUPLICATE_SUBSCRIBER_NLAD}
            primaryAction={() => {
              dispatch(appActions.verifyACPInfoLater());
              dispatch(appActions.onModalCancel(Modals.DUPLICATE_SUBSCRIBER_NLAD));
            }}
            secondaryAction={() => dispatch(appActions.onModalConfirm(Modals.DUPLICATE_SUBSCRIBER_NLAD))}
          />
        );
      case Modals.ACP_SUCCESS_MODAL:
        return (
          <AcpSuccessModal
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.ACP_SUCCESS_MODAL));
              dispatch(navActions.next());
            }}
          />
        );

      case Modals.ACP_APPLICATION_NOT_FOUND_MODAL:
        return (
          <AcpApplicationNotFoundModal
            primaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.ACP_APPLICATION_NOT_FOUND_MODAL));
              dispatch(appActions.verifyACPInfoLater());
            }}
            secondaryAction={() => {
              dispatch(appActions.onModalCancel(Modals.ACP_APPLICATION_NOT_FOUND_MODAL));
            }}
          />
        );
      case Modals.SCHEDULE_LATER:
        return (
          <ScheduleLaterModal
            primaryAction={() => {
              dispatch(appActions.setIsModalVisible(false));
              if (hasVisitedOrderReview) {
                dispatch(paymentInformationActions.createVPPTransaction(spbBillingAccountId));
                dispatch(appActions.setHasVisitedOrderReview(false));
              }
              dispatch(navActions.next());
            }}
            secondaryAction={() => dispatch(appActions.setIsModalVisible(false))}
          />
        );
      case Modals.REMOVE_OPTIONAL_SERVICE:
        return (
          <RemoveOptionalServiceModal
            primaryAction={() => {
              dispatch(appActions.setIsModalVisible(false));
              dispatch(cartActions.setOptionalServicesEditMode(false));
            }}
            secondaryAction={(optionalService) => {
              dispatch(appActions.setIsModalVisible(false));
              dispatch(cartActions.removeCartItems(optionalService));
              dispatch(
                addonActions.removeSelectedAddonPrices({
                  id: optionalService.productTypeId ?? '',
                  addOnPrice: optionalService.price ?? 0,
                  addOnPromo: optionalService.promo?.price ?? 0,
                }),
              );
              dispatch(cartActions.setOptionalServicesEditMode(false));
              if (currentRoute === Routes.ADDONS) {
                dispatch(
                  cartActions.addToast({
                    translateKey: 'common.removeAddon.toast.body',
                    translateOptions: { name: optionalService.name },
                    variant: 'success',
                  }),
                );
              }
            }}
          />
        );
      default:
        return <></>;
    }
  }
};
