import CheckMark from '@vst/beam-icons/illustrative-icons/CheckMark';
import { Txt, Surface, Spacer, IllustrativeIcon } from '@vst/beam';

import styles from './modal.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal } from '@buy-viasat/react/src/components/Modal';

export type ModalFunction = () => void;

export interface AcpSuccessModalProps {
  primaryAction: () => void;
}

export const AcpSuccessModal = ({ primaryAction }: AcpSuccessModalProps) => {
  const { t } = useTranslation(['acp']);
  return (
    <Modal
      primaryLabelText={t('ACP.successModal.done')}
      alignTextToLeft={false}
      hideMarginBottomFromText={true}
      hideSecondaryButton={true}
      showFullScreenMobile={true}
      primaryAction={primaryAction}
      secondaryLabelText={''}
      headerTitle={''}
    >
      <Surface className={styles['main']} px={{ sm: '16px', md: '24px' }}>
        <IllustrativeIcon
          icon={CheckMark}
          size="84px"
          style={{ minHeight: '84px', minWidth: '84px' }}
          color="teal_600"
          data-testid="ACPCheckmarkIcon"
        />
        <Spacer y={'24px'} />
        <Txt variant="heading4" mb="16px" color="regular" data-testid="headingModal">
          {t('ACP.successModal.success')}
        </Txt>
        <Txt variant="bodyLargeRegular" color="subtle" data-testid="enrollACPModalText">
          {t('ACP.successModal.descriptionEnrollACPText')}
        </Txt>
        <Spacer y={'24px'} />
        <Txt variant="bodyLargeRegular" color="subtle" data-testid="emailConfirmationACPModalText">
          {t('ACP.successModal.descriptionEmailConfirmationACPText')}
        </Txt>
      </Surface>
    </Modal>
  );
};
