import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, Surface, IllustrativeIcon, Spacer } from '@vst/beam';
import CheckMark from '@vst/beam-icons/illustrative-icons/CheckMark';

import { Modal } from '@buy-viasat/react/src/components/Modal';

import styles from './backButtonPromptModal.module.scss';

type BackButtonPromptModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const BackButtonPromptModal: FC<BackButtonPromptModalProps> = ({ primaryAction, secondaryAction }) => {
  const { t } = useTranslation(['orderConfirmation']);

  return (
    <Modal
      headerTitle={''}
      primaryLabelText={t('common.navigator.button.cancel.back')}
      secondaryLabelText={t('common.navigator.button.close')}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      showPrimaryButtonFullWidth={true}
      data-testid="BackButtonPromptModal"
    >
      <Surface
        radius={'8px'}
        data-testid="BackButtonPromptModalContainer"
        px={{ sm: '8px', md: '24px' }}
        className={styles['wrapper']}
      >
        <IllustrativeIcon
          icon={CheckMark}
          color={'teal_600'}
          style={{ width: '84px', height: '84px' }}
          data-testid="WorkFromHomeIcon"
        />
        <Spacer y={'24px'} />
        <Txt variant={'heading4'} data-testid="BackButtonPromptModalHeaderTitle">
          {t('orderConfirmation.backButtonPromptModal.title')}
        </Txt>

        <Spacer y={'16px'} />

        <Txt variant={'bodyLargeRegular'} data-testid="BackButtonPromptModalDescription">
          {t('orderConfirmation.backButtonPromptModal.description')}
        </Txt>
      </Surface>
    </Modal>
  );
};
