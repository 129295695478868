import { createSelector } from 'reselect';
import { RootState } from '../types';
import { CartState, initialCartState } from './types';

export const selectCartDomain = (state: RootState): CartState => state?.cart || initialCartState;

export const selectCartId = createSelector([selectCartDomain], (cartState: CartState) => cartState.cartId);
export const selectCartItems = createSelector([selectCartDomain], (cartState: CartState) => cartState.cartItems);
export const selectPromoTotal = createSelector([selectCartDomain], (cartState: CartState) => cartState.promoTotal);
export const selectLease = createSelector([selectCartDomain], (cartState: CartState) => cartState.lease);
export const selectMonthlyTotalWithPromo = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.monthlyTotalWithPromo,
);
export const selectMonthlyTotalWithoutPromo = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.monthlyTotalWithoutPromo,
);
export const selectOnetimeTotalWithPromo = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.onetimeTotalWithPromo,
);
export const selectOnetimeTotalWithoutPromo = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.onetimeTotalWithoutPromo,
);
export const selectOnetimeDiscount = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.onetimeDiscount,
);
export const selectIsCartError = createSelector([selectCartDomain], (cartState: CartState) => cartState.isCartError);
export const selectIsCartAddonLoading = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.isCartAddonLoading,
);
export const selectOptionalServicesEditMode = createSelector(
  [selectCartDomain],
  (cartState: CartState) => cartState.optionalServicesEditMode,
);
export const selectPendingToast = createSelector([selectCartDomain], (state: CartState) => state.pendingToast);
