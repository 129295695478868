import { MouseEvent } from 'react';
import { ISurfaceProps, StandAloneLink, Surface, Txt } from '@vst/beam';
import styles from './loadingInstallationDates.module.scss';

export type LoadingInstallationDatesProps = Omit<ISurfaceProps, 'size' | 'ref'> & {
  skipButtonText: string;
  headingTitle: string;
  handleSkipLinkClick: (e: MouseEvent<HTMLAnchorElement>) => void;
};

export const LoadingInstallationDates = (props: LoadingInstallationDatesProps) => {
  const { handleSkipLinkClick, skipButtonText, headingTitle, children } = props;

  return (
    <Surface
      p={{ xs: '16px', md: '24px' }}
      className={styles['rootContainer']}
      data-testid="loading-installation-dates-container"
    >
      <Surface className={styles['scheduleInstallationWrapper']} data-testid="loading-installation-dates-header">
        <Txt color="regular" variant="bodyLargeBold" data-testid="loading-spinner-text">
          {headingTitle}
        </Txt>
        <StandAloneLink
          variant={'primary'}
          className={styles['link']}
          href={'#'}
          pr={{ md: '8px', sm: undefined }}
          data-testid="schedule-later-link"
          onClick={handleSkipLinkClick}
        >
          {skipButtonText}
        </StandAloneLink>
      </Surface>
      <Surface className={styles['children']} data-testid="loading-installation-dates-children">
        {children}
      </Surface>
    </Surface>
  );
};
