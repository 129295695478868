import { createSelector } from 'reselect';
import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { RootState } from '../types';
import { AddressState, initialAddressState } from './types';
import { formatServiceAddressHouseNumber } from './utils';

export const selectAddressDomain = (state: RootState): AddressState => {
  return state?.address || initialAddressState;
};

export const selectBillingAddress = createSelector([selectAddressDomain], (state) => state.billingAddress);
export const selectShippingAddress = createSelector([selectAddressDomain], (state) => state.shippingAddress);

export const selectInlineBillingAddress = createSelector(
  [selectAddressDomain],
  ({ billingAddress: { addressLines, municipality, region, postalCode } }) => {
    let address = `${addressLines.value[0]}${addressLines.value[1] && ' ' + addressLines.value[1]}`;
    municipality.value && (address += `, ${municipality.value}`);
    region.value && (address += `, ${region.value}`);
    postalCode.value && (address += ` ${postalCode.value}`);

    return address;
  },
);
export const selectInlineShippingAddress = createSelector(
  [selectAddressDomain],
  ({ shippingAddress: { addressLines, municipality, region, postalCode } }) => {
    let address = `${addressLines.value[0]}${addressLines.value[1] && ''}`;
    addressLines.value[1] &&
      (address += `${i18n.t('serviceability:common.address.appendApt') + addressLines.value[1]}`);
    municipality.value && (address += `, ${municipality.value}`);
    region.value && (address += `, ${region.value}`);
    postalCode.value && (address += ` ${postalCode.value}`);

    return address;
  },
);

export const selectBillingAddressVpp = createSelector(
  [selectAddressDomain, (state) => state.app],
  (
    { billingAddress: { addressLines, municipality, region, postalCode, countryCode, houseNumber } },
    { featureFlags: { displayHouseNumber } },
  ) => {
    return {
      addressLines: [addressLines.value[0], (displayHouseNumber ? houseNumber.value : addressLines.value[1]) ?? ''],
      municipality: municipality.value,
      region: region.value,
      postalCode: postalCode.value,
      countryCode: countryCode.value,
    };
  },
);

export const selectBillingAddressHouseNumber = createSelector(
  [selectAddressDomain],
  (state) => state.billingAddress.houseNumber.value,
);
export const selectScrubBillingAddress = createSelector([selectAddressDomain], (state) => state.scrubBillingAddress);
export const selectScrubShippingAddress = createSelector([selectAddressDomain], (state) => state.scrubShippingAddress);

export const selectShippingAddressValues = createSelector(
  [selectAddressDomain],
  ({ shippingAddress: { addressLines, municipality, region, postalCode, countryCode } }) => ({
    addressLines: addressLines.value,
    municipality: municipality.value,
    region: region.value,
    postalCode: postalCode.value,
    countryCode: countryCode.value,
  }),
);

export const selectInlineScrubBillingAddress = createSelector(
  [selectAddressDomain, (state) => state.app],
  (
    {
      scrubBillingAddress: {
        address: { addressLines, municipality, region, postalCode, houseNumber },
      },
    },
    { featureFlags: { displayHouseNumber }, shouldUseHouseNumberAddress },
  ) => {
    let [modifiedAddress, aptNumber] = addressLines[0].split(',');

    if (shouldUseHouseNumberAddress) {
      displayHouseNumber && houseNumber && (modifiedAddress += ` ${houseNumber}`);
      modifiedAddress += `, ${postalCode} ${municipality} ${region}`;
    } else {
      displayHouseNumber && houseNumber && (modifiedAddress += `${' ' + houseNumber}`);
      municipality && (modifiedAddress += `, ${municipality}`);
      aptNumber && (modifiedAddress += `${i18n.t('serviceability:common.address.appendApt') + aptNumber}`);
      region && (modifiedAddress += `, ${region}`);
      postalCode && (modifiedAddress += ` ${postalCode}`);
    }

    return modifiedAddress;
  },
);

export const selectInlineBillingStreetAndPostalCode = createSelector(
  [selectAddressDomain],
  ({ billingAddress: { addressLines, municipality, region, postalCode } }) => {
    let address = '';
    addressLines?.value[1]
      ? (address = `${addressLines.value[0]}`)
      : (address = `${addressLines.value[0].split(i18n.t('serviceability:common.address.appendApt'))[0]}${
          addressLines.value[1] && ', ' + addressLines.value[1]
        }`);
    municipality.value && (address += `, ${municipality.value}`);
    region.value && (address += `, ${region.value}`);
    postalCode.value && (address += `, ${postalCode.value} `);

    return address;
  },
);

export const selectInlineScrubShippingAddress = createSelector(
  [selectAddressDomain],
  ({
    scrubShippingAddress: {
      address: { addressLines, municipality, region, postalCode },
    },
  }) => {
    let address = `${addressLines[0]}${addressLines[1] && ' ' + addressLines[1]}`;
    municipality && (address += `, ${municipality}`);
    region && (address += `, ${region}`);
    postalCode && (address += ` ${postalCode}`);

    return address;
  },
);

export const selectInlineShippingStreetAndPostalCode = createSelector(
  [selectAddressDomain],
  ({ shippingAddress: { addressLines, municipality, region, postalCode } }) => {
    let address = '';
    addressLines?.value[1]
      ? (address = `${addressLines.value[0]}`)
      : (address = `${addressLines.value[0]}${addressLines.value[1] && ', ' + addressLines.value[1]}`);
    municipality.value && (address += `, ${municipality.value}`);
    region.value && (address += `, ${region.value}`);
    postalCode.value && (address += `, ${postalCode.value} `);

    return address;
  },
);

export const selectBillingEqualsServiceAddress = createSelector(
  [selectAddressDomain],
  (state) => state.billingAddressEqualsServiceAddress,
);
export const selectShippingEqualsServiceAddress = createSelector(
  [selectAddressDomain],
  (state) => state.shippingAddressEqualsServiceAddress,
);

export const selectIsBillingAddressValid = createSelector(
  [selectAddressDomain, (state) => state.app],
  (state, { featureFlags: { displayHouseNumber } }) => {
    if (displayHouseNumber) {
      return (
        state.billingAddress.addressLines.valid &&
        state.billingAddress.houseNumber.valid &&
        state.billingAddress.postalCode.valid
      );
    } else {
      return (
        state.billingAddress.addressLines.valid &&
        state.billingAddress.region.valid &&
        state.billingAddress.municipality.valid &&
        state.billingAddress.postalCode.valid
      );
    }
  },
);
export const selectIsShippingAddressValid = createSelector(
  [selectAddressDomain],
  (state) =>
    state.shippingAddress.addressLines.valid &&
    state.shippingAddress.region.valid &&
    state.shippingAddress.municipality.valid &&
    state.shippingAddress.postalCode.valid,
);

export const selectBillingAddressValues = createSelector(
  [selectAddressDomain, (state) => state.app],
  (
    { billingAddress: { addressLines, municipality, region, postalCode, countryCode, houseNumber } },
    { featureFlags: { displayHouseNumber } },
  ) => {
    let address = {
      addressLines: addressLines.value,
      municipality: municipality.value,
      region: region.value,
      postalCode: postalCode.value,
      countryCode: countryCode.value,
    };
    if (displayHouseNumber) {
      address = formatServiceAddressHouseNumber(address, houseNumber.value);
    }
    return address;
  },
);

export const selectPreviousBillingAddressValues = createSelector(
  [selectAddressDomain, (state) => state.app],
  (
    { previousBillingAddress: { addressLines, municipality, region, postalCode, countryCode, houseNumber } },
    { featureFlags: { displayHouseNumber } },
  ) => {
    let address = {
      addressLines: addressLines.value,
      municipality: municipality.value,
      region: region.value,
      postalCode: postalCode.value,
      countryCode: countryCode.value,
    };
    if (displayHouseNumber) {
      address = formatServiceAddressHouseNumber(address, houseNumber.value);
    }
    return address;
  },
);

export const selectPreviousShippingAddressValues = createSelector(
  [selectAddressDomain, (state) => state.app],
  (
    { previousShippingAddress: { addressLines, municipality, region, postalCode, countryCode, houseNumber } },
    { featureFlags: { displayHouseNumber } },
  ) => {
    let address = {
      addressLines: addressLines.value,
      municipality: municipality.value,
      region: region.value,
      postalCode: postalCode.value,
      countryCode: countryCode.value,
    };
    if (displayHouseNumber) {
      address = formatServiceAddressHouseNumber(address, houseNumber.value);
    }
    return address;
  },
);

export const selectPreviousBillingAddress = createSelector(
  [selectAddressDomain],
  (state) => state.previousBillingAddress,
);

export const selectAddressUrlParams = createSelector([selectAddressDomain], (state) => state.addressUrlParams);
