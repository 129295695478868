import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Txt, useBreakpoints } from '@vst/beam';
import { LocationOn } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import styles from './noAddressFound.module.scss';

type NoAddressFoundModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const NoAddressFoundModal = (props: NoAddressFoundModalProps) => {
  const { primaryAction, secondaryAction } = props;
  const { viewport } = useBreakpoints();
  const { t } = useTranslation(['serviceability']);

  return (
    <Modal
      headerIcon={LocationOn}
      colorIcon={'teal_600'}
      headerTitle={t('serviceability.modal.noAddressFound.headerTitle')}
      primaryLabelText={t('serviceability.modal.noAddressFound.primaryLabelText')}
      secondaryLabelText={t('serviceability.modal.noAddressFound.secondaryLabelText')}
      hideCloseButton={!viewport.smallerOrWithin.sm}
      hideMarginBottomFromText={true}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      data-testid="NoAddressFoundModal"
      className={styles['bodyText']}
    >
      <Txt variant={'bodySmallRegular'} data-testid="NoAddressFoundModalText">
        {t('serviceability.modal.noAddressFound.bodyText')}
      </Txt>
    </Modal>
  );
};
