import { useState } from 'react';
import { Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { LocationOn } from '@vst/beam-icons/icons';

import { Modal } from '@buy-viasat/react/src/components/Modal';
import { RadioButton } from '@buy-viasat/react/src/components/RadioButton';

type ConfirmShippingAddressModalProps = {
  radioModalAddresses: {
    inlineScrubAddress: string;
    shippingAddress: string;
  };
  primaryAction: { scrubbedAddressRadioButton: () => void; shippingAddressStreetAndZip: () => void };
  secondaryAction: () => void;
};

export const ConfirmShippingAddressModal = (args: ConfirmShippingAddressModalProps) => {
  const { radioModalAddresses, primaryAction, secondaryAction } = args;
  const { t } = useTranslation(['accountInformation']);
  const [selectedOption, setSelectedOption] = useState('scrubbedAddressRadioButton');
  const pickPrimaryAction =
    selectedOption === 'scrubbedAddressRadioButton'
      ? primaryAction.scrubbedAddressRadioButton
      : primaryAction.shippingAddressStreetAndZip;
  return (
    <Modal
      headerIcon={LocationOn}
      colorIcon={'teal_600'}
      headerTitle={t('accountInformation.common.ConfirmAddressModal.headerTitle')}
      primaryLabelText={t('accountInformation.common.ConfirmAddressModal.primaryLabelText')}
      secondaryLabelText={t('accountInformation.common.ConfirmAddressModal.secondaryLabelText')}
      primaryAction={pickPrimaryAction}
      secondaryAction={secondaryAction}
      showSecondaryButtonFullWidth={true}
      hideCloseButton={true}
      data-testid="ConfirmServiceAddressModal"
    >
      <Surface data-testid="ConfirmServiceAddressModalRadioButtonsContainer">
        <RadioButton
          labelText={t('accountInformation.common.ConfirmAddressModal.firstRadioLabelText')}
          descriptionText={radioModalAddresses?.inlineScrubAddress}
          onClick={() => setSelectedOption('scrubbedAddressRadioButton')}
          isChecked={selectedOption === 'scrubbedAddressRadioButton'}
          id="scrubbedAddress"
          data-testid="ConfirmServiceAddressModalscrubbedAddressRadioButton"
        />
        <RadioButton
          labelText={t('accountInformation.common.ConfirmAddressModal.secondRadioLabelText')}
          descriptionText={radioModalAddresses?.shippingAddress}
          onClick={() => setSelectedOption('serviceAddressRadioButton')}
          isChecked={selectedOption === 'serviceAddressRadioButton'}
          id="typedAddress"
          data-testid="serviceAddressRadioButton"
        />
      </Surface>
    </Modal>
  );
};
