import { Surface } from '@vst/beam';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationOn } from '@vst/beam-icons/icons';

import { RadioButton } from '@buy-viasat/react/src/components/RadioButton';
import { Modal } from '@buy-viasat/react';

interface ConfirmBillingAddressModal {
  radioModalAddresses: {
    inlineScrubAddress: string;
    billingAddress: string;
  };
  primaryAction: { scrubbedAddressRadioButton: () => void; billingAddressStreetAndZip: () => void };
  secondaryAction: () => void;
}

export const ConfirmBillingAddressModal: FC<ConfirmBillingAddressModal> = ({
  radioModalAddresses,
  primaryAction,
  secondaryAction,
}) => {
  const { t } = useTranslation(['accountInformation']);
  const [selectedOption, setSelectedOption] = useState('scrubbedAddressRadioButton');
  const pickPrimaryAction =
    selectedOption === 'scrubbedAddressRadioButton'
      ? primaryAction.scrubbedAddressRadioButton
      : primaryAction.billingAddressStreetAndZip;

  return (
    <Modal
      headerIcon={LocationOn}
      colorIcon={'teal_600'}
      headerTitle={t('accountInformation.common.ConfirmAddressModal.headerTitle')}
      primaryLabelText={t('accountInformation.common.ConfirmAddressModal.primaryLabelText')}
      secondaryLabelText={t('accountInformation.common.ConfirmAddressModal.secondaryLabelText')}
      primaryAction={pickPrimaryAction}
      secondaryAction={secondaryAction}
      showSecondaryButtonFullWidth={true}
      hideCloseButton={true}
      data-testid="ConfirmBillingAddressModal"
    >
      <Surface data-testid="ConfirmBillingAddressModalRadioButtonsContainer">
        <RadioButton
          labelText={t('accountInformation.common.ConfirmAddressModal.firstRadioLabelText')}
          descriptionText={radioModalAddresses?.inlineScrubAddress}
          onClick={() => setSelectedOption('scrubbedAddressRadioButton')}
          isChecked={selectedOption === 'scrubbedAddressRadioButton'}
          id="scrubbedBillingAddressModal"
          data-testid="ConfirmBillingAddressModalscrubbedAddressRadioButton"
        />
        <RadioButton
          labelText={t('accountInformation.common.ConfirmAddressModal.secondRadioLabelText')}
          descriptionText={radioModalAddresses?.billingAddress}
          onClick={() => setSelectedOption('billingAddressRadioButton')}
          isChecked={selectedOption === 'billingAddressRadioButton'}
          id="typedBillingAddressModal"
          data-testid="BillingAddressRadioButton"
        />
      </Surface>
    </Modal>
  );
};
