import { Surface, Txt } from '@vst/beam';
import { topperColor } from '@buy-viasat/utils';
import styles from './checkoutCartAvailableAddons.module.scss';

interface CheckoutCartAvailableAddonsProps {
  titleAddOns: string;
  variant: string;
  lineColor: string;
  phraseAvailableAddons: string;
}

export const CheckoutCartAvailableAddons = ({
  titleAddOns,
  variant,
  lineColor,
  phraseAvailableAddons,
}: CheckoutCartAvailableAddonsProps) => {
  const titleBg = lineColor && styles[`${topperColor[lineColor as keyof typeof topperColor]}Title`];

  return (
    <Surface pt="12px">
      <Txt
        className={`${styles['planTitleAddOns']} ${titleBg}`}
        color="subtle"
        data-testid={`${titleAddOns} - ${variant}`}
        variant="bodySmallBold"
      >
        {phraseAvailableAddons}
        {titleAddOns}
      </Txt>
    </Surface>
  );
};
