import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { createSelector } from 'reselect';
import { formatServiceAddressHouseNumber, selectAddressDomain } from '../address';
import { RootState } from '../types';
import { initialServiceabilityState } from './';
import { ServiceabilityState } from './types';
import { Country } from '@buy-viasat/types/build/bv';

export const selectApp = (state: RootState) => state.app;

export const selectServiceabilityDomain = (state: RootState): ServiceabilityState => {
  return state.serviceability || initialServiceabilityState;
};

export const selectShowMap = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.showMap,
);

export const selectIsServiceabilityLoading = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.isServiceabilityLoading,
);
export const selectCoordinates = createSelector(
  [selectServiceabilityDomain],
  ({ longitude, latitude }: ServiceabilityState) => ({ longitude, latitude }),
);

export const selectHouseNumber = createSelector(
  [selectServiceabilityDomain],
  ({ serviceAddress: { houseNumber } }) => ({
    houseNumber: houseNumber.value,
  }),
);
export const selectServiceAddressValues = createSelector(
  [selectServiceabilityDomain, selectApp],
  ({ serviceAddress }, { featureFlags: { displayHouseNumber } }) => {
    const { addressLines, municipality, region, postalCode, countryCode, houseNumber } = serviceAddress;
    let formattedAddress = {
      addressLines: addressLines.value,
      municipality: municipality.value,
      region: region.value,
      postalCode: postalCode.value,
      countryCode: countryCode.value,
    };
    if (displayHouseNumber) {
      formattedAddress = formatServiceAddressHouseNumber(formattedAddress, houseNumber.value);
    }
    return formattedAddress;
  },
);
export const selectServiceAddressPostalCode = createSelector(
  [selectServiceabilityDomain],
  ({ serviceAddress: { postalCode } }) => postalCode,
);
export const selectServiceAddressRegion = createSelector(
  [selectServiceabilityDomain],
  ({ serviceAddress: { region } }) => region,
);
export const selectScrubLocation = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.scrubbedLocation,
);
export const selectExistingCustomer = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.existingCustomer,
);
export const selectExistingContactMethod = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.existingContactMethod,
);

export const selectInlineScrubAddress = createSelector(
  [selectServiceabilityDomain, selectApp],
  (
    {
      scrubbedLocation: {
        address: { addressLines, municipality, region, postalCode, houseNumber },
      },
      serviceAddress: {
        countryCode: { value: countryCode },
      },
    },
    { featureFlags: { displayHouseNumber } },
  ) => {
    let [modifiedAddress, aptNumber] = addressLines[0].split(', ');

    if (countryCode === Country.IT) {
      displayHouseNumber && houseNumber && (modifiedAddress += ` ${houseNumber}`);
      modifiedAddress += `, ${postalCode} ${municipality} ${region}`;
    } else {
      displayHouseNumber && houseNumber && (modifiedAddress += `${' ' + houseNumber}`);
      aptNumber && (modifiedAddress += `${i18n.t('serviceability:common.address.appendApt') + aptNumber}`);
      municipality && (modifiedAddress += `, ${municipality}`);
      region && (modifiedAddress += `, ${region}`);
      postalCode && (modifiedAddress += ` ${postalCode}`);
    }

    return modifiedAddress;
  },
);
export const selectAddressLine2 = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.serviceAddress.addressLines.value[1],
);
export const selectInlineServiceAddress = createSelector(
  [selectServiceabilityDomain],
  ({ serviceAddress: { addressLines, municipality, region, postalCode } }) => {
    let address = `${addressLines.value[0]}${addressLines.value[1] && ''}`;
    municipality.value && (address += `, ${municipality.value}`);
    region.value && (address += `, ${region.value}`);
    postalCode.value && (address += ` ${postalCode.value}`);

    return address;
  },
);
export const selectInlineServiceStreetAndPostalCode = createSelector(
  [selectServiceabilityDomain, selectApp],
  (
    { serviceAddress: { addressLines, municipality, region, postalCode, houseNumber } },
    { featureFlags: { displayHouseNumber } },
  ) => {
    let address = `${addressLines.value[0]}`;
    displayHouseNumber && houseNumber.value && (address += `${' ' + houseNumber.value}`);
    addressLines.value[1] &&
      (address += `${i18n.t('serviceability:common.address.appendApt') + addressLines.value[1]}`);
    municipality.value && (address += `, ${municipality.value}`);
    region.value && (address += `, ${region.value}`);
    postalCode.value && (address += ` ${postalCode.value} `);
    return address;
  },
);
export const selectIsVermontCustomer = createSelector(
  [selectServiceabilityDomain, selectAddressDomain],
  (
    {
      serviceAddress: {
        region: { value: serviceState },
      },
    },
    {
      billingAddress: {
        region: { value: billingState },
      },
      billingAddressEqualsServiceAddress,
    },
  ) => billingState === 'VT' || (serviceState === 'VT' && billingAddressEqualsServiceAddress),
);
export const selectServiceAddressIsValid = createSelector(
  [selectServiceabilityDomain],
  ({ serviceAddress }) =>
    !!(
      serviceAddress.addressLines.value?.[0].length &&
      serviceAddress.municipality.value.length &&
      serviceAddress.region.value.length &&
      serviceAddress.postalCode.value.length &&
      serviceAddress.countryCode.value.length
    ),
);

export const selectIsServiceAdressSet = createSelector(
  [selectServiceabilityDomain],
  (serviceabilityState: ServiceabilityState) => serviceabilityState.isServiceAddressSet,
);
