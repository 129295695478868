import { createAsyncThunk } from '@reduxjs/toolkit';

import { apolloClient } from '@buy-viasat/utils';
import { GetAvailableProductsInputR0, LocationInput, Product } from '@buy-viasat/types/build/bv';
import { planActions, PlanState } from '@buy-viasat/redux/src/plan';
import { appActions, AppState, CustomerType, Modals } from '@buy-viasat/redux/src/app';
import { serviceabilityActions } from '@buy-viasat/redux/src/serviceability';
import { navActions, NavState, Routes } from '@buy-viasat/redux/src/navigator';
import { DataLayerEvent, DataLayerEventName } from '@buy-viasat/redux/src/analytics';
import { DataLayerEventType, GET_AVAILABLE_PRODUCTS, GetAvailableProductsPayload, ThunkActions } from './types';

export const getAvailableProducts = createAsyncThunk(
  ThunkActions.getAvailableProducts,
  async (input: LocationInput, thunkAPI: any) => {
    const appState: AppState = thunkAPI.getState().app;
    const planState: PlanState = thunkAPI.getState().plan;
    const navState: NavState = thunkAPI.getState().navigator;

    if (planState.preassignedPlanId && navState.maxRoute === Routes.PLAN) {
      thunkAPI.dispatch(planActions.setIsPlanLoading(true));
    } else {
      thunkAPI.dispatch(planActions.setIsLoading(true));
    }

    const { salesAgreementId } = appState;
    const { urlParams, customerType } = appState;
    const { dealerId, partnerId } = urlParams;

    const gAPInput: GetAvailableProductsInputR0 = {
      location: input,
      dealerId,
      partnerId,
      salesAgreementId,
      customerType,
    };

    if (customerType === CustomerType.BUSINESS) {
      gAPInput.productSegment = 'BUSINESS';
    } else {
      gAPInput.productSegment = 'RESIDENTIAL';
    }

    try {
      const result: GetAvailableProductsPayload = await apolloClient.query({
        query: GET_AVAILABLE_PRODUCTS,
        variables: {
          input: gAPInput,
        },
      });

      if (result?.data.getAvailableProducts.length === 0) {
        thunkAPI.dispatch(navActions.previous());
        thunkAPI.dispatch(appActions.setModalVisible(Modals.NO_AVAILABLE_PLANS));
      } else {
        thunkAPI.dispatch(planActions.setHavePlansLoaded(true));
        thunkAPI.dispatch(planActions.setAvailableProducts(result?.data.getAvailableProducts));
        thunkAPI.dispatch(
          trackEvent({
            event: DataLayerEventName.AA_EVENT,
            eventData: {
              event: DataLayerEventType.PLAN_GRID_TRIGGER_VIEW,
              plans: result.data.getAvailableProducts.map((product) => ({
                id: product.id,
                name: product.name,
                discountedPrice: product.price + (product.totalDiscount?.price ?? 0),
                price: product.price,
                discountDuration: product.totalDiscount?.duration ?? 0,
              })),
            },
          }),
        );
      }
    } catch (err) {
      if (appState.isStandAlone) {
        thunkAPI.dispatch(planActions.setIsLoading(false));
      } else {
        thunkAPI.dispatch(navActions.previous());
        thunkAPI.dispatch(
          appActions.setErrorRetryAction({
            type: serviceabilityActions.scrubServiceAddress.type,
            payload: null,
          }),
        );
      }
      thunkAPI.dispatch(appActions.setModalVisible(Modals.ERROR));
      return;
    }

    const currentProducts = thunkAPI.getState().plan.availableProducts;
    let preAssignedProduct: Product | undefined;
    if (currentProducts) {
      let onlyHasCafII = true;
      thunkAPI.dispatch(planActions.resetImageOneUrl());
      currentProducts.forEach((product: any) => {
        if (!product.isCafII) {
          onlyHasCafII = false;
        }
        if (product.characteristics.imageOneUrl) {
          thunkAPI.dispatch(planActions.setImageOneUrl(product.characteristics.imageOneUrl.trim()));
        }
        if (product.id === planState.preassignedPlanId) {
          preAssignedProduct = product;
        }
      });

      thunkAPI.dispatch(planActions.setHasOnlyCafPlans(onlyHasCafII));
    }
    if (preAssignedProduct && navState.maxRoute === Routes.PLAN) {
      thunkAPI.dispatch(
        planActions.planOnNext({
          isRegulated: preAssignedProduct.characteristics.isRegulated === 'true',
          id: preAssignedProduct.id,
          name: preAssignedProduct.name,
          planPrice: preAssignedProduct.price,
          offerId: preAssignedProduct.offerId || '',
          planPromo: preAssignedProduct.promo?.price || 0,
          isCafII: preAssignedProduct.isCafII || false,
          dataCap: preAssignedProduct.characteristics.dataCap || '',
          extensionProducts: preAssignedProduct.extensionTypes as string[],
          productFamily: preAssignedProduct.characteristics.productFamily || '',
          inflectionPointText: preAssignedProduct.characteristics.inflectionPointText || '',
        }),
      );
      thunkAPI.dispatch(planActions.setPreassignedPlanId(''));
      thunkAPI.dispatch(planActions.setSelectedPlan(preAssignedProduct));
    } else {
      thunkAPI.dispatch(planActions.setIsPlanLoading(false));
    }
    thunkAPI.dispatch(planActions.setIsLoading(false));
  },
);

export const selectPlanFromGrid = createAsyncThunk(ThunkActions.selectPlan, async (input: Product, thunkAPI: any) => {
  thunkAPI.dispatch({
    type: planActions.setSelectedPlan.type,
    payload: input,
  });

  thunkAPI.dispatch(
    trackEvent({
      event: DataLayerEventName.AA_EVENT,
      eventData: {
        event: DataLayerEventType.SELECTION_OF_PLAN,
        planGrid: { planId: input.id },
      },
    }),
  );
});

export const selectPlanFromDetails = createAsyncThunk(
  ThunkActions.selectPlan,
  async (input: Product, thunkAPI: any) => {
    thunkAPI.dispatch({
      type: planActions.setSelectedPlan.type,
      payload: input,
    });

    thunkAPI.dispatch(
      trackEvent({
        event: DataLayerEventName.AA_EVENT,
        eventData: {
          event: DataLayerEventType.SELECTION_OF_PLAN,
          planDetails: { planId: input.id },
        },
      }),
    );
  },
);

export const viewMoreDetails = createAsyncThunk(ThunkActions.viewMoreDetails, async (input: Product, thunkAPI: any) => {
  thunkAPI.dispatch({
    type: planActions.setViewDetailsPlan.type,
    payload: input,
  });

  thunkAPI.dispatch(
    trackEvent({
      event: DataLayerEventName.AA_EVENT,
      eventData: {
        event: DataLayerEventType.VIEW_MORE_DETAILS,
        planGrid: { planId: input.id },
      },
    }),
  );
});

export const plansDetailsBack = createAsyncThunk(ThunkActions.plansDetailsBack, async (_, thunkAPI: any) => {
  thunkAPI.dispatch(
    trackEvent({
      event: DataLayerEventName.AA_EVENT,
      eventData: { event: DataLayerEventType.PLANS_DETAILS_BACK },
    }),
  );
});

export const editServiceAddress = createAsyncThunk(ThunkActions.plansDetailsBack, async (_, thunkAPI: any) => {
  thunkAPI.dispatch(
    trackEvent({
      event: DataLayerEventName.AA_EVENT,
      eventData: { event: DataLayerEventType.EDIT_SERVICE_ADDRESS },
    }),
  );
});

export const trackEvent = createAsyncThunk(ThunkActions.trackEvent, async (input: DataLayerEvent) => {
  (window.adobeDataLayer || []).push(input);
});

export const loadScript = createAsyncThunk(
  ThunkActions.loadScript,
  (input: { src: string; placement: 'head' | 'body'; async?: boolean }) =>
    new Promise<{ type: string; src: string }>((resolve, reject) => {
      const alreadyLoaded = document.querySelector(`script[src="${input.src}"]`);
      if (alreadyLoaded) {
        reject(new Error(`Script at ${input.src} is already loaded.`));
        return;
      }

      const script = document.createElement('script');
      script.src = input.src;
      script.async = input.async ?? true;

      script.onload = () => {
        const customEvent = { type: 'loadScript', src: input.src };
        resolve(customEvent);
      };

      script.onerror = (error) => {
        reject(error);
      };

      document.querySelector(input.placement)?.appendChild(script);
    }).catch((err) => console.log(err)),
);
