import clientLogger from './clientLogger';
import { ScriptId } from '@buy-viasat/redux/src/app';

export const loadScript = async (script: { src: string; id: ScriptId }) => {
  const existingScript = document.getElementById(script.id);
  if (!existingScript) {
    try {
      return await new Promise<void>((resolve, reject) => {
        const s = document.createElement('script');
        s.src = script.src;
        s.id = script.id;
        s.onload = () => resolve();
        s.onerror = (error) => reject(error);
        document.body.appendChild(s);
      });
    } catch (catchError) {
      console.error('Error loading script:', catchError);
    }
  } else {
    return new Promise((resolve) => resolve(clientLogger('script already loaded', script.id)));
  }
};

export const removeScript = (id: string) => {
  const script = document.getElementById(id);
  if (script) {
    document.body.removeChild(script);
  } else {
    clientLogger(`error removing script with id: ${id}`, 'error');
  }
};
