import gql from 'graphql-tag';

export const CartProductFragment = gql`
  fragment CartProduct on CartProduct {
    categoryName
    name
    productCandidateId
    productTypeId
    price
    leaseFeePrice
    leaseFeeShortName
    promo {
      price
      duration
    }
    contractTerms
    contractTermsDisclosure
  }
`;

export const CartItemsFragment = gql`
  fragment CartItems on CartItems {
    plan {
      ...CartProduct
    }
    addons {
      ...CartProduct
    }
    closingOffers {
      ...CartProduct
    }
  }
  ${CartProductFragment}
`;

export const ShoppingCartFragment = gql`
  fragment ShoppingCart on ShoppingCart {
    id
    status
    cartItems {
      ...CartItems
    }
    leaseFeeShortName
    bundledAddons {
      id
      name
      kind
      description
      subHeading
      price
      promo {
        price
        duration
        unit
      }
      selected
      attributes
      noAddAttributes
      shortName
      annualSavings
      tag
      crossOuts
      disclosure
      offerId
      displayOrder
      isBundle
    }
    prices {
      monthly {
        totalWithPromos
        totalWithoutPromos
        lineItems {
          promo
          lease
        }
      }
      oneTime {
        lease
        totalWithPromos
        totalWithoutPromos
        lineItems {
          promo
        }
        feesAndDiscounts {
          fee
          discount
          total
          name
        }
      }
    }
  }
  ${CartItemsFragment}
`;
