import examples from 'libphonenumber-js/mobile/examples';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { CountryCode, getExampleNumber, isValidPhoneNumber } from 'libphonenumber-js';

import { TextField, validators } from '@vst/beam';

import styles from './phoneNumberInput.module.scss';
import { PhoneNumberDropdown } from './PhoneNumberDropdown';
import { CountryOption } from '@buy-viasat/utils';
import { PhoneNumberContentLeft } from './PhoneNumberContentLeft';
import { FormValue, IFormValidator } from '@vst/shared/src/lib/form';

export interface PhoneNumberInputProps extends Omit<NumberFormatProps<ComponentProps<typeof TextField>>, 'onSelect'> {
  value?: string;
  mask?: string;
  country: CountryCode;
  countryOptions: CountryOption[];
  onSelect?: (country: CountryOption) => void;
  enableCountrySelection?: boolean;
  validation?: {
    required?: string;
    invalid: string;
  };
  strictValidation: boolean;
}

const countryCodeValidationMap: Record<string, CountryCode> = {
  UK: 'GB',
};

const getCountryOptionByValue = (value: CountryCode, options: CountryOption[]) =>
  options.find((option: CountryOption) => option.value === value);

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const {
    validation,
    mask = 'x',
    country,
    countryOptions,
    onSelect,
    enableCountrySelection,
    strictValidation,
    ...textFieldProps
  } = props;

  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption>(
    getCountryOptionByValue(country, countryOptions) ?? countryOptions[0],
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedCountry(getCountryOptionByValue(country, countryOptions) ?? countryOptions[0]);
  }, [country, countryOptions]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!dropdownRef?.current?.contains(e.target as Node)) {
        setIsDropDownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const validationRules = useMemo(() => {
    const rules: IFormValidator[] = [];

    if (validation?.required) {
      rules.push(validators.required({ message: validation.required }));
    }

    if (validation?.invalid) {
      const validationFunction = (value: FormValue) => {
        if (!value) {
          return { error: true };
        }

        const countryCode = countryCodeValidationMap[selectedCountry.value] || selectedCountry.value;

        const numericValue = (value as string).replace(/\D/g, '');
        const isValid = strictValidation
          ? isValidPhoneNumber(value as string, countryCode)
          : numericValue.length === 10;

        return isValid ? { error: false } : { error: true, message: validation.invalid };
      };

      rules.push(validators.define(validationFunction));
    }

    return rules;
  }, [validation, selectedCountry.value, strictValidation]);

  const format = useMemo(() => {
    const exampleNumberCountryCode = countryCodeValidationMap[selectedCountry.value] || selectedCountry.value;
    const examplePhoneNumber = getExampleNumber(exampleNumberCountryCode, examples)?.formatNational() || '';
    return examplePhoneNumber.replace(/\d(?=\D*\d|$)/g, '#');
  }, [selectedCountry]);

  const placeholder = useMemo(() => {
    return format.replace(/#/g, mask);
  }, [format, mask]);

  const handleCountryChange = (country: CountryOption) => {
    setSelectedCountry(country);
    setIsDropDownOpen(false);
    onSelect?.(country);
  };

  return (
    <div className={styles['phoneNumberInputContainer']}>
      <NumberFormat
        type="tel"
        format={format}
        inputMode="numeric"
        customInput={TextField}
        placeholder={placeholder}
        required={!!validation?.required}
        labelProps={{ showStar: false }}
        contentLeft={
          enableCountrySelection ? (
            <PhoneNumberContentLeft
              prefix={selectedCountry.prefix}
              selectedCountry={selectedCountry}
              setIsDropdownOpen={setIsDropDownOpen}
              isDropDownOpen={isDropDownOpen}
            />
          ) : (
            ''
          )
        }
        key={selectedCountry.value}
        data-testid={textFieldProps.name}
        validationRules={validationRules}
        {...textFieldProps}
      />

      {isDropDownOpen && (
        <PhoneNumberDropdown
          options={countryOptions}
          dropdownRef={dropdownRef}
          selectedCountry={selectedCountry}
          handleCountryChange={handleCountryChange}
        />
      )}
    </div>
  );
};

export default PhoneNumberInput;
