import { LoadingSpinner } from './index';
import { Surface } from '@vst/beam';
import styles from './loadingSpinner.module.scss';

type FullPageLoadingSpinnerProps = {
  text?: string | undefined;
  testId?: string | undefined;
  heading?: string | string[] | undefined;
};

export const FullPageLoadingSpinner = ({ heading, text, testId }: FullPageLoadingSpinnerProps) => {
  return (
    <Surface className={styles['fullPageLoadingSpinner']} data-testid="full-page-loading-spinner">
      <LoadingSpinner size="large" text={text} heading={heading} data-testid={testId} />
    </Surface>
  );
};
