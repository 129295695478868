import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';

const LOG_URL = gql`
  query logUrl($input: String) {
    logUrl(input: $input)
  }
`;

export default async function logUrlAsync(input: string): Promise<boolean> {
  return client.query({
    query: LOG_URL,
    fetchPolicy: 'no-cache',
    variables: {
      input,
    },
  }) as unknown as boolean;
}
