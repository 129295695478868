import gql from 'graphql-tag';
import { ShoppingCart, ShoppingCartPlanInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';
import { ShoppingCartFragment } from './fragments';
import { FetchResult } from '@apollo/client';

export type UpsertCartPlanResponse = FetchResult<{ upsertCartPlan: ShoppingCart }>;

const UPSERT_CART_PLAN = gql`
  mutation upsertCartPlan($input: ShoppingCartPlanInput!) {
    upsertCartPlan(input: $input) {
      ...ShoppingCart
    }
  }
  ${ShoppingCartFragment}
`;

export default async function upsertCartPlanAsync({
  input,
}: {
  input: ShoppingCartPlanInput;
}): Promise<UpsertCartPlanResponse> {
  return client.mutate({
    mutation: UPSERT_CART_PLAN,
    variables: { input },
  });
}
