import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeTargetDataLayer, pnApiRun } from '@buy-viasat/utils';
import { InlineLink, Surface, Txt } from '@vst/beam';
import { ErrorOutline } from '@vst/beam-icons/icons';
import { Modal } from '@buy-viasat/react/src/components/Modal';

import styles from './noAvailablePlans.module.scss';
import { DataLayerEvents } from '@buy-viasat/redux/src/analytics';

type NoAvailablePlansModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const NoAvailablePlansModal = (props: NoAvailablePlansModalProps) => {
  const { primaryAction, secondaryAction } = props;
  const { t } = useTranslation(['serviceability']);
  const phoneNumber = t('common.header.phoneNumber.text');
  const href = `tel:${phoneNumber}`;

  useEffect(() => {
    setTimeout(() => {
      pnApiRun();
    });
    adobeTargetDataLayer.push({
      event: DataLayerEvents.AA_EVENT,
      eventData: {
        event: 'NoPlansAvailable',
      },
    });
  }, []);

  return (
    <Modal
      headerIcon={ErrorOutline}
      colorIcon={'alert_400'}
      headerTitle={t('serviceability.modal.noAvailablePlans.headerTitle')}
      primaryLabelText={t('serviceability.modal.noAvailablePlans.primaryLabelText')}
      secondaryLabelText={t('serviceability.modal.noAvailablePlans.secondaryLabelText')}
      showPrimaryButtonFullWidth={true}
      hideMarginBottomFromText={true}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      data-testid="NoAvailablePlansModalContainer"
    >
      <Surface className={styles['bodyTextSurface']} data-testid="NoAvailablePlansModalSurface">
        <Txt variant={'bodySmallRegular'} className={styles['bodyTextValue']} data-testid="NoAvailablePlansModalText">
          {t('serviceability.modal.noAvailablePlans.bodyText')}
          <InlineLink data-testid={'numberLink'} href={href} variant="primary" ml={'4px'} style={{ fontSize: '18px' }}>
            {phoneNumber}
          </InlineLink>
          .
        </Txt>
      </Surface>
    </Modal>
  );
};
