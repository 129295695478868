import { AnyAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { getAccessTokenAsync } from '@buy-viasat/utils';
import { createSlice } from '../utils';
import { RESET_AUTH_ACTION } from '../types';
import { appActions } from '@buy-viasat/redux/src/app';

export type AuthState = {
  accessToken: string;
  flowId: string;
  resetToken: boolean;
};

export const initialAuthState = {
  accessToken: '',
  flowId: '',
  resetToken: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setAccessToken(state: AuthState, action: PayloadAction<string>): void {
      state.accessToken = action.payload;
    },
    setFlowId(state: AuthState, action: PayloadAction<string>): void {
      state.flowId = action.payload;
    },
    setResetToken(state: AuthState, action: PayloadAction<boolean>): void {
      state.resetToken = action.payload;
    },
  },
  extraReducers: {
    [RESET_AUTH_ACTION.type]: (): AuthState => initialAuthState,
  },
});

export const { actions: authActions, reducer: authReducer, name: authKey } = authSlice;

type RequestAccessTokenInput = { env: string; authUrl: string; expiredSession?: boolean };

export const requestAccessToken = createAsyncThunk(
  'requestAccessToken',
  async (input: RequestAccessTokenInput, thunkAPI: any) => {
    const { token } = await getAccessTokenAsync(input.env, input.authUrl);
    const decoded = jwt_decode<JwtPayload>(token);
    const flowId: string = (decoded as any)?.flowId;
    console.log('Buy Viasat flowId: ' + flowId);
    thunkAPI.dispatch(authActions.setAccessToken(token));
    thunkAPI.dispatch(authActions.setFlowId(flowId));
    thunkAPI.dispatch(appActions.setFeatureFlags());
    thunkAPI.dispatch(authActions.setResetToken(input.expiredSession || false));
  },
) as unknown as (input: RequestAccessTokenInput) => AnyAction;
