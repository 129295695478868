import { Surface, Txt, RadioButton as BeamRadioButton } from '@vst/beam';
import { FC } from 'react';
import styles from './radioButton.module.scss';

interface RadioButtonsProp {
  labelText: string;
  descriptionText?: string;
  id: string;
  isChecked: boolean;
  onClick: () => void;
}

export const RadioButton: FC<RadioButtonsProp> = ({ labelText, descriptionText, id, onClick, isChecked }) => {
  return (
    <Surface
      variant={isChecked ? 'secondary' : 'primary'}
      className={styles['radioButton']}
      radius="16px"
      p="16px"
      data-testid="radioButtonSurface"
    >
      <BeamRadioButton
        label={labelText}
        checked={isChecked}
        readOnly
        onClick={onClick}
        id={id}
        className={styles['beamRadioFontWeight']}
        data-testid="radioButton"
      />
      <Surface variant={isChecked ? 'secondary' : 'primary'}>
        <Txt
          variant={'bodySmallRegular'}
          ml="32px"
          style={{ marginBottom: '0px' }}
          data-testid="radioButtonDescriptionText"
        >
          {descriptionText}
        </Txt>
      </Surface>
    </Surface>
  );
};
