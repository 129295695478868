/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import { rootReducers } from '@buy-viasat/redux';
import { appReducer } from '@buy-viasat/redux/src/app';
import { creditVerificationReducer } from 'shared/views/CreditVerification/slice';
import { personalInformationReducer } from 'shared/views/PersonalInformation/slice';

// Create Session Storage for Persistence
const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  blacklist: ['app', 'serviceability'],
};

// blacklist keys from app
const appPersistConfig = {
  key: 'app',
  storage: sessionStorage,
  blacklist: [
    'isCheckoutButtonDisabled',
    'isTaxCodesLoading',
    'isModalVisible',
    'isCartVisible',
    'loadedFeatureFlags',
    'smbLinkResidentialRedirect',
    'smbLinkPlansRedirect',
  ],
};

// whitelist keys from serviceability
const serviceabilityPersistConfig = {
  key: 'serviceability',
  storage: sessionStorage,
  whitelist: ['serviceAddress', 'scrubbedLocation', 'longitude', 'latitude', 'autocompleteAddress'],
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 *
 * The testReducer is needed in order to avoid the issues of injected _persist
 */
export const unpersistedReducers = {
  ...rootReducers,
  app: appReducer,
  creditVerification: creditVerificationReducer,
  personalInformation: personalInformationReducer,
};

export const testReducer = combineReducers(unpersistedReducers);

const basePersist = {
  ...unpersistedReducers,
  app: persistReducer(appPersistConfig, appReducer),
  serviceability: persistReducer(serviceabilityPersistConfig, rootReducers.serviceability),
};

const rootReducer = combineReducers(basePersist);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
