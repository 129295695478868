import { benchmark } from 'shared/containers/App/saga';
import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { GraphQLError } from 'graphql';
import { CartItems, CartProduct, AddOn, ShoppingCart } from '@buy-viasat/types/build/bv';
import { selectServiceAddressValues } from '@buy-viasat/redux/src/serviceability';
import { cartActions } from '@buy-viasat/redux/src/cart';
import removeCartItemsAsync, { RemoveCartItemsResponse } from '../../providers/requests/cart/removeCartItems';
import clientLogger from '../../utils/clientLogger';
import upsertCartAddonsAsync, { UpsertCartAddonsResponse } from '../../providers/requests/cart/upsertCartAddons';
import {
  navActions,
  Routes,
  RouteStates,
  selectCurrentAppRoute,
  selectRoutesStates,
} from '@buy-viasat/redux/src/navigator';
import { PayloadAction } from '@reduxjs/toolkit';
import { addonActions, selectAvailableAddons } from '@buy-viasat/redux/src/addons';
import { Features, appActions, Modals, selectHasVisitedOrderReview } from '@buy-viasat/redux/src/app';
import { AddressType } from '@buy-viasat/redux/src/address';
import { selectCartItems, selectCartId } from '@buy-viasat/redux/src/cart';
import { paymentInformationActions, selectSpbBillingAccountId } from '@buy-viasat/redux/src/payment-information';

export function* generateCart(data: ShoppingCart): Generator<PutEffect | SelectEffect, void, Features> {
  yield put(cartActions.setCartProperties(data));
}

export function* generateError(
  error: readonly GraphQLError[],
  apiCall: string,
): Generator<CallEffect | PutEffect, void, void> {
  yield call(clientLogger, `${apiCall}: `, error);
  yield put(cartActions.setIsCartError(true));
}

export function* upsertAddonsSaga(): Generator<
  SelectEffect | CallEffect | PutEffect,
  void,
  UpsertCartAddonsResponse & string & AddressType & CartItems & AddOn[] & Routes & RouteStates
> {
  const cartId: string = yield select(selectCartId);
  const availableAddons = (yield select(selectAvailableAddons)) as AddOn[];
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const selectedAddons = availableAddons.filter((availableAddon: AddOn) => availableAddon.selected);
  const addonProductIds = selectedAddons.map((selectedAddon: AddOn) => selectedAddon.id);
  const hasVisitedOrderReview = yield select(selectHasVisitedOrderReview);
  const billingAccountId: string = yield select(selectSpbBillingAccountId);
  const routeState: RouteStates = yield select(selectRoutesStates);
  const currentRoute: Routes = yield select(selectCurrentAppRoute);

  try {
    yield put(addonActions.setIsUpsertAddonsLoading(true));
    const { data } = yield call(upsertCartAddonsAsync, {
      input: {
        cartId,
        addonProductIds,
        postalCode: serviceAddress.postalCode,
      },
    });
    if (data?.upsertCartAddons) yield call<any>(generateCart, data.upsertCartAddons);
    yield put(addonActions.setIsUpsertAddonsLoading(false));
    if (hasVisitedOrderReview && routeState[currentRoute as Routes].Next === Routes.PAYMENT_INFORMATION) {
      yield put(paymentInformationActions.createVPPTransaction(billingAccountId));
      yield put(appActions.setHasVisitedOrderReview(false));
    }
    yield put(navActions.next());
  } catch (err: any) {
    yield call(generateError, err, 'upsertAddonsSaga');
    yield put(addonActions.setIsUpsertAddonsLoading(false));
  }
}

/**
 *  Remove Cart Item request/response handler
 */
export function* removeCartItemsSaga(
  action: PayloadAction<CartProduct>,
): Generator<SelectEffect | PutEffect | CallEffect, void, string & RemoveCartItemsResponse & CartItems> {
  yield put(cartActions.removeAddOnFromCart(action.payload.productTypeId ?? ''));
  yield put(addonActions.toggleAddon({ id: action.payload.productTypeId ?? '', selected: false }));

  if (action.payload.productCandidateId) {
    yield put(cartActions.setIsCartAddonLoading(action.payload.productTypeId ?? null));
    const cartId: string = yield select(selectCartId);
    try {
      const { data } = yield call(removeCartItemsAsync, {
        input: { cartId, productCandidateIds: [action.payload.productCandidateId] },
      });
      yield put(
        cartActions.addToast({
          translateKey: 'common.removeAddon.toast.body',
          translateOptions: { name: action.payload.name },
          variant: 'success',
        }),
      );

      if (data?.removeCartItems) {
        const cartItems = (yield select(selectCartItems)) as CartItems;
        data.removeCartItems.cartItems.addons = data.removeCartItems.cartItems.addons.concat(
          cartItems.addons.filter((addons) => !addons?.productCandidateId),
        );
        data.removeCartItems.cartItems.plan!.contractTerms = cartItems.plan?.contractTerms;
        data.removeCartItems.cartItems.plan!.contractTermsDisclosure = cartItems.plan?.contractTermsDisclosure;
        data.removeCartItems.cartItems.plan!.leaseFeePrice = cartItems.plan?.leaseFeePrice;
        data.removeCartItems.cartItems.plan!.leaseFeeShortName = cartItems.plan?.leaseFeeShortName;
        yield call<any>(generateCart, data.removeCartItems);
      }
      yield put(cartActions.setIsCartAddonLoading(null));
    } catch (err: any) {
      yield put(
        cartActions.setAddOnInCart({
          id: action.payload.productTypeId ?? '',
          name: action.payload.name ?? '',
          promo: action.payload.promo,
          price: action.payload.price,
          categoryName: action.payload.categoryName ?? '',
        }),
      );
      yield put(addonActions.toggleAddon({ id: action.payload.productTypeId ?? '', selected: false }));

      yield put(appActions.setErrorRetryAction(action));
      yield put(appActions.setModalVisible(Modals.ERROR));
      yield call(generateError, err, 'removeCartItemsSaga');
      yield put(cartActions.setIsCartAddonLoading(null));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* cartSaga(): Generator<ForkEffect, void, void> {
  yield takeLatest(
    cartActions.upsertAddons.type,
    benchmark<
      void,
      SelectEffect | CallEffect | PutEffect,
      void,
      UpsertCartAddonsResponse & string & AddressType & CartItems & AddOn[]
    >(upsertAddonsSaga),
  );
  yield takeLatest(
    cartActions.removeCartItems.type,
    benchmark<CartProduct, SelectEffect | PutEffect | CallEffect, void, string & RemoveCartItemsResponse>(
      removeCartItemsSaga,
    ),
  );
}
