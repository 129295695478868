import { isDigit } from './primitives';

export const removeCharAtIndex = (text: string, indexToDelete: number): string =>
  `${text.slice(0, indexToDelete)}${text.slice(indexToDelete + 1, text.length)}`;

export const onlyNumbers = (newValue: string, currentValue: string): string => {
  if (!newValue) return '';
  if (!isDigit(newValue[newValue.length - 1])) return currentValue;
  return newValue;
};

export const getLastFourStateFromEvent = (
  newLastFour: string,
  existingLastFour: string,
  cursorIndex: number,
): string => {
  if (newLastFour.length > 4) {
    return existingLastFour;
  } else if (newLastFour.length < existingLastFour.length) {
    return removeCharAtIndex(existingLastFour, cursorIndex);
  } else {
    return onlyNumbers(`${existingLastFour}${newLastFour.slice(-1)}`, existingLastFour);
  }
};

export const getTaxIdFromEvent = (taxId: string): string => {
  // imported Number format component has a bug that adds an extra digit to the end
  return taxId.substring(0, 14);
};
