import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { appActions } from '@buy-viasat/redux/src/app';
import { ErrorOutline, WarningOutlined } from '@vst/beam-icons/icons';
import { InlineLink, Spacer, Surface, Txt, useBreakpoints } from '@vst/beam';

import { GeneralAcpModalVariant } from './types';

const PHONE_NUMBER = '1-866-406-4990';

type UseAcpModalsParams = {
  secondaryAction?: () => void;
};

export const useAcpModals = ({ secondaryAction }: UseAcpModalsParams) => {
  const { t } = useTranslation(['acp']);
  const dispatch = useDispatch();
  const { viewport } = useBreakpoints();

  const handleCloseModal = useCallback(() => {
    dispatch(appActions.setIsModalVisible(false));
    secondaryAction?.();
  }, [dispatch, secondaryAction]);

  const getHeaderIconProps = useCallback((variant: GeneralAcpModalVariant) => {
    if (
      variant === GeneralAcpModalVariant.ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE ||
      variant === GeneralAcpModalVariant.ACP_TERMS_AND_CONDITIONS
    ) {
      return {
        icon: undefined,
        color: undefined,
      };
    }

    if (variant === GeneralAcpModalVariant.APPLICATION_PENDING) {
      return {
        icon: ErrorOutline,
        color: 'info_primary_400',
      } as const;
    }

    if (variant === GeneralAcpModalVariant.ACP_ILL_DO_IT_LATER) {
      return {
        icon: WarningOutlined,
        color: 'warning_400',
      } as const;
    }

    return {
      icon: ErrorOutline,
      color: 'alert_400',
    } as const;
  }, []);

  const getModalProps = useCallback(
    (variant: GeneralAcpModalVariant) => {
      const tribalBenefitModalContent = {
        [GeneralAcpModalVariant.NONTRIBAL_NLAD_LOCATION]: {
          headerText: t('ACP.nontribalNlandLocationModal.headerText'),
          primaryActionText: t('ACP.navigation.verifyLater'),
          handleSecondaryAction: handleCloseModal,
          secondaryActionText: null,
          hideCloseButton: false,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPNontribalNlandLocationTopText'}>
                {t('ACP.nontribalNlandLocationModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPNontribalNlandLocationMiddleText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.nontribalNlandLocationModal.contentMiddle')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPNontribalNlandLocationBottomText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.nontribalNlandLocationModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.DEFICIENT_CUSTOMER_INFO]: {
          headerText: t('ACP.deficientCustomerInfoModal.headerText'),
          primaryActionText: t('ACP.navigation.verifyLater'),
          handleSecondaryAction: handleCloseModal,
          secondaryActionText: null,
          hideCloseButton: false,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPDeficientCustomerInfoTopText'}>
                {t('ACP.deficientCustomerInfoModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPDeficientCustomerInfoMiddleText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.deficientCustomerInfoModal.contentMiddle')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPDeficientCustomerInfoBottomText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.deficientCustomerInfoModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.NONTRIBAL_CONSUMER_LOCATION]: {
          headerText: t('ACP.nontribalConsumerLocationModal.headerText'),
          primaryActionText: t('ACP.navigation.verifyLater'),
          handleSecondaryAction: handleCloseModal,
          secondaryActionText: null,
          hideCloseButton: false,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPNontribalConsumerLocationTopText'}>
                {t('ACP.nontribalConsumerLocationModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPNontribalConsumerLocationMiddleText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.nontribalConsumerLocationModal.contentMiddle')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPNontribalConsumerLocationBottomText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.nontribalConsumerLocationModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.DUPLICATE_SUBSCRIBER_NLAD]: {
          headerText: t('ACP.duplicateSubscriberNladModal.headerText'),
          primaryActionText: t('ACP.navigation.verifyLater'),
          handleSecondaryAction: handleCloseModal,
          secondaryActionText: null,
          hideCloseButton: false,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPDuplicateSubscriberNladTopText'}>
                {t('ACP.duplicateSubscriberNladModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPDuplicateSubscriberNladMiddleText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.duplicateSubscriberNladModal.contentMiddle')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPDuplicateSubscriberNladBottomText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.duplicateSubscriberNladModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.NOT_ELIGIBLE_ACP]: {
          headerText: t('ACP.notEligibleAcpModal.headerText'),
          primaryActionText: t('ACP.notEligibleAcpModal.primaryActionText'),
          handleSecondaryAction: undefined,
          secondaryActionText: null,
          hideCloseButton: true,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPNotEligibleAcpTopText'}>
                {t('ACP.notEligibleAcpModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPNotEligibleAcpBottomText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.notEligibleAcpModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE]: {
          headerText: t('ACP.disclosureApplicationNotCompleteModal.headerText'),
          primaryActionText: t('ACP.disclosureApplicationNotCompleteModal.primaryActionText'),
          handleSecondaryAction: undefined,
          secondaryActionText: null,
          hideCloseButton: false,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPDisclosureApplicationNotCompleteText'}>
                {t('ACP.disclosureApplicationNotCompleteModal.content')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.APPLICATION_NOT_COMPLETED]: {
          headerText: t('ACP.applicationNotCompletedModal.headerText'),
          primaryActionText: t('ACP.applicationNotCompletedModal.primaryActionText'),
          handleSecondaryAction: undefined,
          secondaryActionText: null,
          hideCloseButton: true,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPApplicationNotCompletedTopText'}>
                {t('ACP.applicationNotCompletedModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt variant={'bodySmallRegular'} data-testid={'ACPApplicationNotCompletedBottomText'}>
                {t('ACP.applicationNotCompletedModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.APPLICATION_PENDING]: {
          headerText: t('ACP.applicationPendingModal.headerText'),
          primaryActionText: t('ACP.applicationPendingModal.primaryActionText'),
          handleSecondaryAction: undefined,
          secondaryActionText: null,
          hideCloseButton: true,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPApplicationPendingTopText'}>
                {t('ACP.applicationPendingModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPApplicationPendingMiddleText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.applicationPendingModal.contentMiddle')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt
                variant={'bodySmallRegular'}
                data-testid={'ACPApplicationPendingBottomText'}
                style={{ flexWrap: 'wrap' }}
              >
                {t('ACP.applicationPendingModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.ACP_TERMS_AND_CONDITIONS]: {
          headerText: t('ACP.termsAndConditionsModal.headerText'),
          primaryActionText: t('ACP.termsAndConditionsModal.primaryActionText'),
          handleSecondaryAction: undefined,
          secondaryActionText: null,
          hideCloseButton: false,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPTermsAndConditionsText'}>
                {t('ACP.termsAndConditionsModal.content')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.GENERAL_ERROR]: {
          headerText: t('ACP.generalErrorModal.headerText'),
          handleSecondaryAction: undefined,
          primaryActionText: t('ACP.generalErrorModal.primaryActionText'),
          secondaryAction: undefined,
          secondaryActionText: null,
          hideCloseButton: true,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPTermsAndConditionsText'}>
                {t('ACP.generalErrorModal.contentTop')}
              </Txt>
              <Spacer y={'24px'} />
              <Txt variant={'bodySmallRegular'} data-testid={'ACPTermsAndConditionsText'}>
                <Trans i18nKey="callForAssistance" t={t}>
                  Please call{' '}
                  <InlineLink href={`tel:${PHONE_NUMBER}`} title={t('phoneNumberTitle')}>
                    {PHONE_NUMBER}
                  </InlineLink>{' '}
                  for assistance.
                </Trans>
              </Txt>
              <Spacer y={'24px'} />
              <Txt variant={'bodySmallRegular'} data-testid={'ACPTermsAndConditionsText'}>
                {t('ACP.generalErrorModal.contentBottom')}
              </Txt>
            </Surface>
          ),
        },
        [GeneralAcpModalVariant.ACP_ILL_DO_IT_LATER]: {
          headerText: t('ACP.illDoItLaterModal.headerText'),
          handleSecondaryAction: handleCloseModal,
          primaryActionText: t('ACP.illDoItLaterModal.primaryActionText'),
          secondaryActionText: t('ACP.illDoItLaterModal.secondaryActionText'),
          secondaryAction,
          hideCloseButton: true,
          content: (
            <Surface>
              <Txt variant={'bodySmallRegular'} data-testid={'ACPTermsAndConditionsText'}>
                {t('ACP.illDoItLaterModal.content')}
              </Txt>
            </Surface>
          ),
        },
      } as const;

      return (
        tribalBenefitModalContent[variant] ?? {
          headerText: t('ACP.generalErrorModal.headerText'),
          primaryActionText: t('ACP.generalErrorModal.primaryActionText'),
          handleSecondaryAction: undefined,
          content: undefined,
          hideCloseButton: !viewport.smallerOrWithin.xs,
        }
      );
    },
    [handleCloseModal, secondaryAction, t, viewport.smallerOrWithin.xs],
  );

  return {
    getModalProps,
    getHeaderIconProps,
  };
};
