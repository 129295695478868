import { useEffect, useMemo, ReactNode } from 'react';
import { Icon, IconComponentType, ModalSize, StandardModal, Txt, IModalSharedProps } from '@vst/beam';
import styles from './modal.module.scss';
import { useSelector } from 'react-redux';
import { selectIsModalVisible } from '@buy-viasat/redux/src/app';
import { allBeamColors } from '@vst/beam/src/react/common';

interface ModalProps {
  headerIcon?: IconComponentType;
  colorIcon?: allBeamColors;
  headerTitle: string | ReactNode;
  primaryLabelText: string;
  secondaryLabelText: string;
  primaryAction: () => void;
  secondaryAction?: () => void;
  onClose?: () => void;
  children?: JSX.Element;
  hideCloseButton?: boolean | null;
  hideSecondaryButton?: boolean | null;
  hideMarginBottomFromText?: boolean | null;
  showSecondaryButtonAsLink?: boolean;
  showFullScreenMobile?: boolean;
  showPrimaryButtonFullWidth?: boolean;
  showSecondaryButtonFullWidth?: boolean;
  alignTextToLeft?: boolean;
  className?: string;
  size?: ModalSize;
  noWrapText?: boolean;
  contentWrapperStyle?: React.CSSProperties;
  overlayWrapProps?: IModalSharedProps['overlayWrapProps'];
}

export const Modal = ({
  headerIcon,
  colorIcon,
  headerTitle,
  primaryLabelText,
  secondaryLabelText,
  hideCloseButton,
  hideSecondaryButton,
  hideMarginBottomFromText,
  showPrimaryButtonFullWidth,
  showSecondaryButtonFullWidth,
  showSecondaryButtonAsLink,
  showFullScreenMobile,
  primaryAction,
  secondaryAction,
  onClose,
  alignTextToLeft,
  className = '',
  size = 'medium',
  noWrapText,
  children = <></>,
  contentWrapperStyle = {},
  overlayWrapProps,
}: ModalProps) => {
  const isModalVisible = useSelector(selectIsModalVisible);

  const surfaceClassName = useMemo(() => {
    const surfaceStyles: string[] = [];

    if (className) surfaceStyles.push(className);
    if (alignTextToLeft) surfaceStyles.push(styles['alignTextToLeft']);
    if (hideCloseButton) surfaceStyles.push(styles['noCloseButton']);
    if (hideSecondaryButton) surfaceStyles.push(styles['noSecondaryButton']);
    if (hideMarginBottomFromText) surfaceStyles.push(styles['noMarginBottomFromText']);
    if (showPrimaryButtonFullWidth) surfaceStyles.push(styles['actionPrimaryFullWidth']);
    if (showSecondaryButtonFullWidth) surfaceStyles.push(styles['actionSecondaryFullWidth']);
    if (showSecondaryButtonAsLink) surfaceStyles.push(styles['linkSecondaryButton']);
    if (noWrapText) surfaceStyles.push(styles['noWrapText']);
    if (showFullScreenMobile) surfaceStyles.push(styles['fullHeightMobileModal']);
    if (!secondaryAction) surfaceStyles.push(styles['noPrimaryActionBottomMargin']);

    return surfaceStyles.join(' ');
  }, [
    className,
    alignTextToLeft,
    hideCloseButton,
    hideSecondaryButton,
    hideMarginBottomFromText,
    showPrimaryButtonFullWidth,
    showSecondaryButtonFullWidth,
    showSecondaryButtonAsLink,
    noWrapText,
    showFullScreenMobile,
    secondaryAction,
  ]);

  useEffect(() => {
    return () => {
      //this enables the page to be scrollable after the modal is closed
      document.body.classList.remove('global--limit-height');
    };
  }, []);

  return (
    <StandardModal
      size={size}
      onClose={onClose || secondaryAction || primaryAction}
      isOpen={isModalVisible}
      header={
        <Txt variant={'heading5'}>
          {headerIcon ? <Icon icon={headerIcon} mr={'12px'} color={colorIcon} /> : null}
          {headerTitle}
        </Txt>
      }
      surfaceProps={{ className: surfaceClassName }}
      overlayWrapProps={overlayWrapProps}
      actions={{
        primary: {
          label: primaryLabelText,
          onClick: primaryAction,
        },
        secondary: secondaryAction && {
          label: secondaryLabelText,
          onClick: secondaryAction,
        },
      }}
    >
      <div style={contentWrapperStyle}>{children}</div>
    </StandardModal>
  );
};
