import '@buy-viasat/utils/src/lib/config/i18n';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { LoadingSpinner } from '@buy-viasat/react';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <div style={{ marginTop: 300 }}>
          <LoadingSpinner my="112px" size="large" />
        </div>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>,
);
