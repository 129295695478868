import { FetchResult, gql } from '@apollo/client';
import { LocationInput, TaxCode } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type GetTaxCodesResponse = FetchResult<{ getTaxCodes: TaxCode[] }>;

const GET_TAX_CODES = gql`
  query getTaxCodes($input: LocationInput!) {
    getTaxCodes(input: $input) {
      taxCodeType
      taxCodeValue
      label
    }
  }
`;

export default async function getTaxCodesAsync(input: LocationInput): Promise<GetTaxCodesResponse> {
  return client.query({
    query: GET_TAX_CODES,
    variables: {
      input,
    },
  });
}
