import { createSelector } from 'reselect';
import { RootState } from '../types';
import { initialPaymentInformationState, PaymentInformationState } from './types';

// TODO We shouldn't need a default here. Initial State shouldn't be dropped.
export const selectPaymentInformationDomain = (state: RootState): PaymentInformationState => {
  return state?.paymentInformation || initialPaymentInformationState;
};

export const selectIFrameRadioButton = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.iFrameRadioButton,
);
export const selectIsPaymentInformationLoading = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.isPaymentInformationLoading,
);
export const selectIsPaymentInformationLoadingStartTime = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.isPaymentInformationLoadingStartTime,
);
export const selectIsPaymentInformationValid = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.isPaymentInformationValid,
);
export const selectTransactionId = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.transactionId,
);
export const selectSpbBillingAccountId = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.spbBillingAccountId,
);
export const selectRegulatedSpbBillingAccountId = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.regulatedSPBBillingAccountId,
);
export const selectNonRegulatedSpbBillingAccountId = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.nonRegulatedSPBBillingAccountId,
);
export const selectSmbBillingAccountId = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.smbBillingAccountId,
);
export const selectPaymentOnFileId = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.paymentOnFileId,
);
export const selectTaxCode = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.taxCode,
);
export const selectSameAsPrimaryContact = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.sameAsPrimaryContact,
);
export const selectVppCardNameIsValid = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppCardNameIsValid,
);
export const selectVppCardNumberIsValid = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppCardNumberIsValid,
);
export const selectVppCardCVVIsValid = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppCardCVVIsValid,
);
export const selectVppCardExpirationIsValid = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppCardExpirationIsValid,
);
export const selectVppCardType = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppCardType,
);
export const selectVppPaymentType = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppPaymentType,
);
export const selectVppPostMessageSuccess = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppPostMessageSuccess,
);
export const selectVppPPaymentType = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppPaymentType,
);
export const selectPaymentOnFile = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.paymentOnFile,
);
export const selectVpp3DSMode = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vpp3DSMode,
);

export const selectIsIframeLoading = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.isIframeLoading,
);

export const selectVppBankValidationSuccess = createSelector(
  [selectPaymentInformationDomain],
  (state: PaymentInformationState) => state.vppBankValidationSuccess,
);
