import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from './updateBillingAddressModal.module.scss';

type UpdateBillingAddressModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const UpdateBillingAddressModal = (props: UpdateBillingAddressModalProps) => {
  const { primaryAction, secondaryAction } = props;
  const { t } = useTranslation(['paymentInformation']);

  return (
    <Modal
      headerTitle={t('paymentInformation.modal.updateBillingAddress.headerTitle')}
      primaryLabelText={t('paymentInformation.modal.updateBillingAddress.primaryLabelText')}
      secondaryLabelText={t('paymentInformation.modal.updateBillingAddress.secondaryLabelText')}
      showPrimaryButtonFullWidth={true}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      data-testid="UpdateBillingAddressModalContainer"
    >
      <Txt variant={'bodySmallRegular'} className={styles['bodyText']} data-testid="UpdateBillingAddressModalText">
        {t('paymentInformation.modal.updateBillingAddress.bodyText')}
      </Txt>
    </Modal>
  );
};
