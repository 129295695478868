import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StandAloneLink, Surface, Txt } from '@vst/beam';
import { Close, Edit } from '@vst/beam-icons/icons';
import { priceDecimalFormat } from '@buy-viasat/utils';
import { CartProduct } from '@buy-viasat/types/build/bv';
import { appActions, Modals } from '@buy-viasat/redux/src/app';
import { cartActions, selectOptionalServicesEditMode } from '@buy-viasat/redux/src/cart';
import { addonActions } from '@buy-viasat/redux/src/addons';
import { selectLocale, selectSalesFlowDefinition } from '@buy-viasat/redux/src/app';
import { TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';

import CheckoutCartOptionalServicesItem from '../CheckoutCartOptionalServicesItem';

import { useTrackEvent } from '../../../hooks';

import { addonItem } from '../types';

import styles from './checkoutCartOptionalServices.module.scss';

export type CheckoutCartOptionalServicesProps = {
  translations: {
    edit: string;
    free: string;
    optionalServices: string;
    priceAfterPromotion: (price: string, duration: string) => string;
  };
  addonItems: Array<addonItem>;
  selectedAddonsTotal: number;
};

export const CheckoutCartOptionalServices = ({
  translations,
  addonItems,
  selectedAddonsTotal,
}: CheckoutCartOptionalServicesProps) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const locale = useSelector(selectLocale);
  const editMode = useSelector(selectOptionalServicesEditMode);
  const { currencySymbol } = useSelector(selectSalesFlowDefinition);

  const checkValue = (value: number | undefined | null) => value ?? 0;
  addonItems.sort((a, b) => checkValue(a.displayOrder) - checkValue(b.displayOrder));

  const handleEditClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(cartActions.setOptionalServicesEditMode(!editMode));
  };

  const handleRemoveClick = (addon: CartProduct) => () => {
    trackEvent(TrackingCategory.CART, TrackingActions.REMOVED_ADDON, { label: addon.name });
    if (addon.productTypeId) {
      dispatch(addonActions.setAddonIdForRemoval(addon.productTypeId));
      dispatch(appActions.setModalVisible(Modals.REMOVE_OPTIONAL_SERVICE));
    }
  };

  return (
    <Surface variant="secondary" p="16px" mt="8px" data-testid="cartOptionalServices" radius="8px">
      <Surface variant="secondary" data-testid="cartOptionalServicesHeading" className={styles['heading']}>
        <Txt variant="heading6" data-testid="cartOptionalServicesTitle">
          {translations.optionalServices}
        </Txt>
        <StandAloneLink
          href="#"
          linkSize="small"
          onClick={handleEditClick}
          className={styles['editBtn']}
          icon={editMode ? Close : Edit}
        >
          {translations.edit}
        </StandAloneLink>
      </Surface>
      <Surface variant="secondary" data-testid="cartOptionalServicesList">
        {addonItems.map((addon) => (
          <CheckoutCartOptionalServicesItem
            addon={addon}
            translations={translations}
            editMode={editMode}
            key={addon.productTypeId}
            onRemove={handleRemoveClick(addon)}
          />
        ))}
      </Surface>
      {addonItems.length > 1 && selectedAddonsTotal > 0 ? (
        <Surface
          mt="8px"
          pt="8px"
          variant="secondary"
          className={styles['total']}
          data-testid="cartOptionalServicesTotal"
        >
          <Txt variant="bodySmallBold">{priceDecimalFormat(selectedAddonsTotal, locale, currencySymbol, 2)}</Txt>
        </Surface>
      ) : null}
    </Surface>
  );
};
