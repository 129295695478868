import ContentProvider from '../ContentProvider';
import styles from './legalDisclosure.module.scss';

export type LegalDisclosureProps = {
  url: string;
  id?: string;
  isLoading?: boolean;
};

export const LegalDisclosure = ({ url, id, isLoading }: LegalDisclosureProps) => {
  return (
    <div className={styles['legalDisclosureContainer']} data-testid="legalDisclosure">
      <ContentProvider id={id} isLoading={isLoading} src={url} />
    </div>
  );
};
