import { UpdateMarketingPreferences, UpdateMarketingPreferencesResponse } from '@buy-viasat/types/build/bv';
import gql from 'graphql-tag';
import { client } from 'shared/containers/apollo';

const SEND_MARKETING_UPDATE_DETAILS = gql`
  mutation updateMarketingPreferences($input: UpdateMarketingPreferences) {
    updateMarketingPreferences(input: $input) {
      updateMarketingPreferences
    }
  }
`;

export default async function updateMarketingPreferencesAsync(
  input: UpdateMarketingPreferences,
): Promise<UpdateMarketingPreferencesResponse> {
  const { relnId, hasCustomerOptedIn } = input;
  return client.mutate({
    mutation: SEND_MARKETING_UPDATE_DETAILS,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        relnId,
        hasCustomerOptedIn,
      },
    },
  }) as UpdateMarketingPreferencesResponse;
}
