import {
  Routes,
  routesIntlMapping,
  RouteStates,
  routeStates,
  ScheduleInstallLocations,
} from '@buy-viasat/redux/src/navigator';
import { SelectedInstallation } from '@buy-viasat/redux/src/schedule-installation';
import { WorkOrderInformation } from '@buy-viasat/types/build/bv';
import { OnSiteContactInformation } from '@buy-viasat/types/src/bv';
import { arrivalWindows, ArrivalWindowType } from 'shared/views/ScheduleInstallation/types';

export const toPascalCase = (stringToConvert: string): string => {
  const routeAlias = routesIntlMapping[stringToConvert] || stringToConvert;
  return `${routeAlias.charAt(0).toUpperCase()}${routeAlias.slice(1)}`;
};

export const constructWorkOrderInformation = (
  installationDateValue: SelectedInstallation,
  onSiteContactInformation?: OnSiteContactInformation,
): WorkOrderInformation | null => {
  if (!installationDateValue.year) return null;
  const arrivalKey = arrivalWindows.find(
    (arrivalWindow: ArrivalWindowType): boolean => arrivalWindow.slot === installationDateValue.arrivalWindow,
  );
  if (!arrivalKey) return null;
  // TODO show error?
  else {
    const { isoFrom, isoTo } = arrivalKey;
    const { year, day, month, note } = installationDateValue;
    return {
      appointment: {
        startTime: `${year}-${month}-${day}T${isoFrom}`,
        endTime: `${year}-${month}-${day}T${isoTo}`,
      },
      notes: `${onSiteContactInformation?.firstName} ${onSiteContactInformation?.lastName}, ${onSiteContactInformation?.email}, ${onSiteContactInformation?.phoneNumber}, ${note}`,
      onSiteContactInformation,
    };
  }
};

const handlePersonalInformationNext = (
  scheduleInstallLocation: ScheduleInstallLocations,
  isVermontCustomer: boolean,
  shouldDisplayCreditCheck: boolean,
  displayAddOns: boolean,
  isSubsidyCheckboxChecked: boolean,
  displayAcpBeforeCreditCheck: boolean,
) => {
  if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked) return Routes.SUBSIDY_PROGRAM;
  if (!isVermontCustomer && shouldDisplayCreditCheck) return Routes.CREDIT_CHECK;
  if (displayAddOns) return Routes.ADDONS;
  if (isSubsidyCheckboxChecked) return Routes.SUBSIDY_PROGRAM;
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.SCHEDULE_INSTALLATION;
  return Routes.PAYMENT_INFORMATION;
};

const handleCreditCheckNext = (
  scheduleInstallLocation: ScheduleInstallLocations,
  displayAddOns: boolean,
  isSubsidyCheckboxChecked: boolean,
  displayAcpBeforeCreditCheck: boolean,
) => {
  if (displayAddOns) return Routes.ADDONS;
  if (isSubsidyCheckboxChecked && !displayAcpBeforeCreditCheck) return Routes.SUBSIDY_PROGRAM;
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.SCHEDULE_INSTALLATION;
  return Routes.PAYMENT_INFORMATION;
};
const handleCreditCheckPrevious = (displayAcpBeforeCreditCheck: boolean, isSubsidyCheckboxChecked: boolean) => {
  if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked) return Routes.SUBSIDY_PROGRAM;
  return Routes.PERSONAL_INFORMATION;
};

const handleAddonsNext = (
  scheduleInstallLocation: ScheduleInstallLocations,
  isSubsidyCheckboxChecked: boolean,
  displayAcpBeforeCreditCheck: boolean,
) => {
  if (isSubsidyCheckboxChecked && !displayAcpBeforeCreditCheck) return Routes.SUBSIDY_PROGRAM;
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.SCHEDULE_INSTALLATION;
  return Routes.PAYMENT_INFORMATION;
};
const handleAddonsPrevious = (
  isVermontCustomer: boolean,
  shouldDisplayCreditCheck: boolean,
  displayAcpBeforeCreditCheck: boolean,
  isSubsidyCheckboxChecked: boolean,
) => {
  if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked && isVermontCustomer) return Routes.SUBSIDY_PROGRAM;
  if (isVermontCustomer || !shouldDisplayCreditCheck) return Routes.PERSONAL_INFORMATION;
  return Routes.CREDIT_CHECK;
};

const handleSubsidyProgramNext = (
  scheduleInstallLocation: ScheduleInstallLocations,
  displayAcpBeforeCreditCheck: boolean,
  isVermontCustomer: boolean,
  displayAddOns: boolean,
) => {
  if (displayAcpBeforeCreditCheck) {
    if (isVermontCustomer) {
      if (displayAddOns) return Routes.ADDONS;
      if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.SCHEDULE_INSTALLATION;
      return Routes.PAYMENT_INFORMATION;
    }
    return Routes.CREDIT_CHECK;
  }
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.SCHEDULE_INSTALLATION;
  return Routes.PAYMENT_INFORMATION;
};
const handleSubsidyProgramPrevious = (
  isVermontCustomer: boolean,
  displayAddOns: boolean,
  shouldDisplayCreditCheck: boolean,
  displayAcpBeforeCreditCheck: boolean,
) => {
  if (displayAcpBeforeCreditCheck) return Routes.PERSONAL_INFORMATION;
  if (displayAddOns) return Routes.ADDONS;
  if (isVermontCustomer || !shouldDisplayCreditCheck) return Routes.PERSONAL_INFORMATION;
  return Routes.CREDIT_CHECK;
};

const handleScheduleInstallationNext = (scheduleInstallLocation: ScheduleInstallLocations) => {
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.PAYMENT_INFORMATION;
  if (scheduleInstallLocation === ScheduleInstallLocations.Payments) return Routes.ORDER_REVIEW;
};
const handleScheduleInstallationPrevious = (
  scheduleInstallLocation: ScheduleInstallLocations,
  isVermontCustomer: boolean,
  displayAddOns: boolean,
  isSubsidyCheckboxChecked: boolean,
  shouldDisplayCreditCheck: boolean,
  displayAcpBeforeCreditCheck: boolean,
) => {
  if (scheduleInstallLocation === ScheduleInstallLocations.OrderSummary) return Routes.ORDER_SUMMARY;
  if (scheduleInstallLocation === ScheduleInstallLocations.Payments) return Routes.PAYMENT_INFORMATION;
  if (scheduleInstallLocation === ScheduleInstallLocations.None) return;
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) {
    if (displayAcpBeforeCreditCheck && isSubsidyCheckboxChecked && displayAddOns) return Routes.ADDONS;
    if (isSubsidyCheckboxChecked) return Routes.SUBSIDY_PROGRAM;
    if (displayAddOns) return Routes.ADDONS;
    if (!isVermontCustomer && shouldDisplayCreditCheck) return Routes.CREDIT_CHECK;
    return Routes.PERSONAL_INFORMATION;
  }
};

const handlePaymentInformationNext = (scheduleInstallLocation: ScheduleInstallLocations) => {
  if (scheduleInstallLocation === ScheduleInstallLocations.Payments) return Routes.SCHEDULE_INSTALLATION;
  return Routes.ORDER_REVIEW;
};

const handlePaymentInformationPrevious = (
  scheduleInstallLocation: ScheduleInstallLocations,
  isVermontCustomer: boolean,
  shouldDisplayCreditCheck: boolean,
  displayAddOns: boolean,
  isSubsidyCheckboxChecked: boolean,
) => {
  if (scheduleInstallLocation === ScheduleInstallLocations.CreditCheck) return Routes.SCHEDULE_INSTALLATION;
  if (isSubsidyCheckboxChecked) return Routes.SUBSIDY_PROGRAM;
  if (displayAddOns) return Routes.ADDONS;
  if (isVermontCustomer || !shouldDisplayCreditCheck) return Routes.PERSONAL_INFORMATION;
  return Routes.CREDIT_CHECK;
};

const handleOrderReviewPrevious = (scheduleInstallLocation: ScheduleInstallLocations) => {
  if (scheduleInstallLocation === ScheduleInstallLocations.Payments) return Routes.SCHEDULE_INSTALLATION;
  return Routes.PAYMENT_INFORMATION;
};

const handleOrderSummaryNext = (scheduleInstallLocation: ScheduleInstallLocations) => {
  if (scheduleInstallLocation === ScheduleInstallLocations.OrderSummary) return Routes.SCHEDULE_INSTALLATION_POST_ORDER;
};

export const createRouteStates = (
  scheduleInstallLocation: ScheduleInstallLocations,
  isVermontCustomer: boolean,
  shouldDisplayCreditCheck: boolean,
  displayAddOns: boolean,
  isSubsidyCheckboxChecked: boolean,
  displayAcpBeforeCreditCheck: boolean,
): RouteStates => {
  // Create deep copy of routeStates
  const currentRouteStates = JSON.parse(JSON.stringify(routeStates));

  currentRouteStates[Routes.PERSONAL_INFORMATION].Next = handlePersonalInformationNext(
    scheduleInstallLocation,
    isVermontCustomer,
    shouldDisplayCreditCheck,
    displayAddOns,
    isSubsidyCheckboxChecked,
    displayAcpBeforeCreditCheck,
  );

  currentRouteStates[Routes.CREDIT_CHECK].Next = handleCreditCheckNext(
    scheduleInstallLocation,
    displayAddOns,
    isSubsidyCheckboxChecked,
    displayAcpBeforeCreditCheck,
  );
  currentRouteStates[Routes.CREDIT_CHECK].Previous = handleCreditCheckPrevious(
    displayAcpBeforeCreditCheck,
    isSubsidyCheckboxChecked,
  );

  currentRouteStates[Routes.ADDONS].Next = handleAddonsNext(
    scheduleInstallLocation,
    isSubsidyCheckboxChecked,
    displayAcpBeforeCreditCheck,
  );
  currentRouteStates[Routes.ADDONS].Previous = handleAddonsPrevious(
    isVermontCustomer,
    shouldDisplayCreditCheck,
    displayAcpBeforeCreditCheck,
    isSubsidyCheckboxChecked,
  );

  currentRouteStates[Routes.SUBSIDY_PROGRAM].Next = handleSubsidyProgramNext(
    scheduleInstallLocation,
    displayAcpBeforeCreditCheck,
    isVermontCustomer,
    displayAddOns,
  );
  currentRouteStates[Routes.SUBSIDY_PROGRAM].Previous = handleSubsidyProgramPrevious(
    isVermontCustomer,
    displayAddOns,
    shouldDisplayCreditCheck,
    displayAcpBeforeCreditCheck,
  );

  currentRouteStates[Routes.SCHEDULE_INSTALLATION].Next = handleScheduleInstallationNext(scheduleInstallLocation);
  currentRouteStates[Routes.SCHEDULE_INSTALLATION].Previous = handleScheduleInstallationPrevious(
    scheduleInstallLocation,
    isVermontCustomer,
    displayAddOns,
    isSubsidyCheckboxChecked,
    shouldDisplayCreditCheck,
    displayAcpBeforeCreditCheck,
  );

  currentRouteStates[Routes.PAYMENT_INFORMATION].Next = handlePaymentInformationNext(scheduleInstallLocation);
  currentRouteStates[Routes.PAYMENT_INFORMATION].Previous = handlePaymentInformationPrevious(
    scheduleInstallLocation,
    isVermontCustomer,
    shouldDisplayCreditCheck,
    displayAddOns,
    isSubsidyCheckboxChecked,
  );

  currentRouteStates[Routes.ORDER_REVIEW].Next = Routes.ORDER_SUMMARY;
  currentRouteStates[Routes.ORDER_REVIEW].Previous = handleOrderReviewPrevious(scheduleInstallLocation);

  currentRouteStates[Routes.ORDER_SUMMARY].Next = handleOrderSummaryNext(scheduleInstallLocation);
  currentRouteStates[Routes.ORDER_SUMMARY].Previous = Routes.ORDER_REVIEW;

  return currentRouteStates;
};
