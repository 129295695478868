import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { ACPContent } from './ACPContent';
import styles from './ACPModal.module.scss';

type ACPModalProps = {
  primaryAction: () => void;
};

export const ACPModal = (props: ACPModalProps) => {
  const { primaryAction } = props;
  const { t } = useTranslation(['serviceability']);

  return (
    <Modal
      size={'large'}
      headerTitle={t('ACPModal.headerTitle')}
      primaryLabelText={t('ACPModal.primaryButton')}
      secondaryLabelText={''}
      primaryAction={primaryAction}
      hideMarginBottomFromText={true}
      data-testid={'ACPModalContentainer'}
      className={styles['noSecondaryButton']}
    >
      <ACPContent />
    </Modal>
  );
};
