import { Surface } from '@vst/beam';
import { PlanCharacteristics } from '@buy-viasat/types/build/bv';
import styles from './checkoutCartNewPlansDescription.module.scss';
import { CartHeaderDownloadSpeed } from './CartHeaderDownloadSpeed';
import { CartHeaderDataCap } from './CartHeaderDataCap';
import { checkProp } from '@buy-viasat/utils';

type CheckoutCartNewPlansDescriptionProps = {
  characteristics: PlanCharacteristics | null;
  downloadSpeedPhraseKey: string;
};

export const CheckoutCartNewPlansDescription = ({
  characteristics,
  downloadSpeedPhraseKey,
}: CheckoutCartNewPlansDescriptionProps) => {
  const { dataCap, dataCapUnits, downloadSpeed, downloadUnits, textGeneral01 } = characteristics ?? {};
  const shouldDisplayDownloadSpeed = checkProp(downloadSpeed) && checkProp(downloadUnits) && checkProp(textGeneral01);

  return (
    <Surface className={`${styles['cartDescriptionContainer']}`} data-testid="cartDescriptionContainer">
      <CartHeaderDataCap
        dataCap={dataCap}
        dataCapUnits={dataCapUnits}
        textGeneral01={textGeneral01}
        shouldDisplayDownloadSpeed={Boolean(shouldDisplayDownloadSpeed)}
      />
      {shouldDisplayDownloadSpeed && (
        <CartHeaderDownloadSpeed
          downloadSpeed={downloadSpeed}
          downloadUnits={downloadUnits}
          downloadSpeedPhraseKey={downloadSpeedPhraseKey}
        />
      )}
    </Surface>
  );
};
