import { CoordinatesInput, Country } from '@buy-viasat/types/build/bv';
import { FormFieldState } from '../types';

export interface AddressType {
  addressLines: string[];
  municipality: string;
  region: string;
  postalCode: string;
  countryCode: string;
  houseNumber?: string;
}

export type AddressFieldStateType = {
  addressLines: FormFieldState<string[]>;
  municipality: FormFieldState<string>;
  region: FormFieldState<string>;
  postalCode: FormFieldState<string>;
  countryCode: FormFieldState<string>;
  houseNumber: FormFieldState<string | undefined>;
};

export type LocationType = {
  address: AddressType;
  coordinates: {
    latitude?: number | null;
    longitude?: number | null;
  };
};

export interface VPPBillingAddress {
  street: string;
  houseNumberOrName: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
  country?: string;
}

export type Coordinates = CoordinatesInput;

export const defaultAddressData = (country: Country): AddressType => ({
  addressLines: ['', ''],
  postalCode: '',
  municipality: '',
  region: '',
  countryCode: country,
  houseNumber: '',
});

export const defaultAddressForm = (country = Country.US): AddressFieldStateType => ({
  addressLines: { value: defaultAddressData(country).addressLines, valid: null, hintText: 'Address is required' },
  municipality: { value: defaultAddressData(country).municipality, valid: null, hintText: 'City is required' },
  region: { value: defaultAddressData(country).region, valid: null, hintText: 'State is required' },
  postalCode: { value: defaultAddressData(country).postalCode, valid: null, hintText: 'Zipcode is required' },
  countryCode: { value: defaultAddressData(country).countryCode, valid: null, hintText: 'CountryCode is required' },
  houseNumber: { value: '', valid: null, hintText: 'House Number is required' },
});

export const defaultAddressDataMock: AddressType = {
  addressLines: ['6155 El Camino Real', ''],
  postalCode: '92009',
  municipality: 'Carlsbad',
  region: 'CA',
  countryCode: 'US',
};

export const defaultAddressDataMockForm: AddressFieldStateType = {
  addressLines: { value: defaultAddressDataMock.addressLines, valid: null, hintText: 'Address is required' },
  municipality: { value: defaultAddressDataMock.municipality, valid: null, hintText: 'City is required' },
  region: { value: defaultAddressDataMock.region, valid: null, hintText: 'State is required' },
  postalCode: { value: defaultAddressDataMock.postalCode, valid: null, hintText: 'Zipcode is required' },
  countryCode: { value: defaultAddressDataMock.countryCode, valid: null, hintText: 'CountryCode is required' },
  houseNumber: { value: defaultAddressData(Country.IT).houseNumber, valid: null, hintText: 'House Number is required' },
};

export type AddressQueryParams = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
  municipality?: string | null;
  region?: string | null;
};

export type AddressState = {
  billingAddress: AddressFieldStateType;
  previousBillingAddress: AddressFieldStateType;
  scrubBillingAddress: LocationType;
  shippingAddress: AddressFieldStateType;
  previousShippingAddress: AddressFieldStateType;
  scrubShippingAddress: LocationType;
  billingAddressEqualsServiceAddress: boolean;
  shippingAddressEqualsServiceAddress: boolean;
  addressUrlParams: AddressQueryParams;
};

export const initialAddressState: AddressState = {
  billingAddress: defaultAddressForm(Country.US),
  previousBillingAddress: defaultAddressForm(Country.US),
  scrubBillingAddress: {
    address: defaultAddressData(Country.US),
    coordinates: {
      latitude: null,
      longitude: null,
    },
  },
  shippingAddress: defaultAddressForm(Country.US),
  previousShippingAddress: defaultAddressForm(Country.US),
  scrubShippingAddress: {
    address: defaultAddressData(Country.US),
    coordinates: {
      latitude: null,
      longitude: null,
    },
  },
  billingAddressEqualsServiceAddress: true,
  shippingAddressEqualsServiceAddress: true,
  addressUrlParams: {},
};
