import { configureStore, ConfigureStoreOptions, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import persistedReducer from './reducers';
import { Persistor } from 'redux-persist/es/types';
import { appSaga } from 'shared/containers/App/saga';
import { serviceabilitySaga } from '../views/Serviceability/saga';
import { planSaga } from '../views/Plan/saga';
import { addonSaga } from '../views/Addons/saga';
import { cartSaga } from '../views/Cart/saga';
import { routerSaga } from '../Navigator/saga';
import { personalInformationSaga } from '../views/PersonalInformation/saga';
import { creditVerificationSaga } from '../views/CreditVerification/saga';
import { scheduleInstallationSaga } from '../views/ScheduleInstallation/saga';
import { paymentInformationSaga } from '../views/PaymentInformation/saga';
import { RootState } from '@buy-viasat/redux/src/types';
import { scheduleInstallationActions } from '@buy-viasat/redux/src/schedule-installation';
import { appActions } from '@buy-viasat/redux/src/app';

const configureAppStore = (overrideState?: RootState): { store: EnhancedStore; persistor: Persistor } => {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const defaultMiddleware = getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        scheduleInstallationActions.setToday.type,
        appActions.startSession.type,
        scheduleInstallationActions.getInstallationDates.type,
      ],
    },
  });
  const configureStoreOptions: ConfigureStoreOptions = {
    reducer: persistedReducer,
    middleware: [...defaultMiddleware, sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
  };

  if (overrideState) configureStoreOptions.preloadedState = overrideState;

  const store = configureStore(configureStoreOptions);

  sagaMiddleware.run(appSaga);
  sagaMiddleware.run(serviceabilitySaga);
  sagaMiddleware.run(planSaga);
  sagaMiddleware.run(addonSaga);
  sagaMiddleware.run(cartSaga);
  sagaMiddleware.run(routerSaga);
  sagaMiddleware.run(personalInformationSaga);
  sagaMiddleware.run(creditVerificationSaga);
  sagaMiddleware.run(scheduleInstallationSaga);
  sagaMiddleware.run(paymentInformationSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo (@types/webpack-env takes care of the typings here)
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  return { store, persistor: persistStore(store) };
};

export default configureAppStore;
