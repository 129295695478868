import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import serviceabilityEnUS from './locales/en-US/serviceability.json';
import serviceabilityEnBR from './locales/en-BR/serviceability.json';
import serviceabilityPtBR from './locales/pt-BR/serviceability.json';
import serviceabilityItIT from './locales/it-IT/serviceability.json';

i18n.addResourceBundle('en-US', 'serviceability', serviceabilityEnUS);
i18n.addResourceBundle('en-BR', 'serviceability', serviceabilityEnBR);
i18n.addResourceBundle('pt-BR', 'serviceability', serviceabilityPtBR);
i18n.addResourceBundle('it-IT', 'serviceability', serviceabilityItIT);

export default i18n;
