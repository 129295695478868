import { createSelector } from 'reselect';
import { RootState } from '../types';
import { ClosingOffersState, initialClosingOffersState } from './types';

export const selectClosingOffersDomain = (state: RootState): ClosingOffersState => {
  return state?.closingOffers || initialClosingOffersState;
};

export const selectAvailableClosingOffers = createSelector(
  [selectClosingOffersDomain],
  (closingOffersState: ClosingOffersState) => closingOffersState.availableClosingOffers,
);

export const selectUpsertWithClosingOffersError = createSelector(
  [selectClosingOffersDomain],
  (closingOffersState: ClosingOffersState) => closingOffersState.isUpsertWithClosingOffersError,
);

export const selectClosingOfferError = createSelector(
  [selectClosingOffersDomain],
  (closingOffersState: ClosingOffersState) => closingOffersState.isClosingOfferError,
);

export const selectSkipClosingOffer = createSelector(
  [selectClosingOffersDomain],
  (closingOffersState: ClosingOffersState) => closingOffersState.skipClosingOffer,
);
