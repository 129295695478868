import { Surface, Txt } from '@vst/beam';
import styles from './cartHeaderDataCap.module.scss';
import { Maybe } from 'graphql-tools';

type CartHeaderDataCapProps = {
  dataCap: Maybe<string> | undefined;
  dataCapUnits: Maybe<string> | undefined;
  textGeneral01: Maybe<string> | undefined;
  shouldDisplayDownloadSpeed: boolean;
};

export const CartHeaderDataCap = ({
  dataCap,
  dataCapUnits,
  textGeneral01,
  shouldDisplayDownloadSpeed,
}: CartHeaderDataCapProps) => {
  return (
    <>
      <Surface className={styles['dataCapContainer']} data-testid="dataCapContainer">
        <Txt mr="4px" variant="heading2" data-testid={dataCap}>
          {dataCap}
        </Txt>
        <Txt className={styles['dataCap']} variant="bodySmallBold" data-testid={dataCapUnits}>
          <span className={styles['dataCapUnits']}>{dataCapUnits}</span>
        </Txt>
      </Surface>
      <Txt
        className={shouldDisplayDownloadSpeed ? styles['paddedText'] : ''}
        variant={'smallRegular'}
        data-testid={'highSpeedData'}
        color={'subtle'}
      >
        {textGeneral01}
      </Txt>
    </>
  );
};
