export enum AcpResponseView {
  SUCCESS = 'SUCCESS',
  APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND',
  NV_UNAVAILABLE = 'NV_UNAVAILABLE',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  VIASAT_SERVICE_UNAVAILABLE = 'VIASAT_SERVICE_UNAVAILABLE',
  TRIBAL_BENEFIT_FLAG_NONTRIBAL_NLAD_LOCATION = 'TRIBAL_BENEFIT_FLAG_NONTRIBAL_NLAD_LOCATION',
  TRIBAL_BENEFIT_FLAG_DEFICIENT_CONSUMER_INFO = 'TRIBAL_BENEFIT_FLAG_DEFICIENT_CONSUMER_INFO',
  TRIBAL_BENEFIT_FLAG_NONTRIBAL_CONSUMER_LOCATION = 'TRIBAL_BENEFIT_FLAG_NONTRIBAL_CONSUMER_LOCATION',
  DUPLICATE_SUBSCRIBER_NLAD = 'DUPLICATE_SUBSCRIBER_NLAD',
  NOT_ELIGIBLE_ACP = 'NOT_ELIGIBLE_ACP',
  APPLICATION_PENDING = 'APPLICATION_PENDING',
  APPLICATION_NOT_COMPLETE = 'APPLICATION_NOT_COMPLETE',
  DEFAULT = '',
}
