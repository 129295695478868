import { FC } from 'react';
import styles from './progressBar.module.scss';
import { Col, Container, Row, Surface, Txt, useBreakpoints } from '@vst/beam';

export interface ProgressBarProps {
  initialStep: number;
  title?: string;
  totalNumberOfSteps: number;
  textProgressBar?: string | ((current: number, total: number) => string);
}

export const ProgressBar: FC<ProgressBarProps> = ({ initialStep, totalNumberOfSteps, textProgressBar, title }) => {
  const { viewport } = useBreakpoints();

  return (
    <Container
      my={{ xs: '8px', sm: undefined }}
      py={{ xs: '32px', sm: '32px' }}
      px={{ sm: '16px', md: '24px' }}
      fluidUntil={'md'}
      className={styles['containerProgressBar']}
    >
      <Row>
        <Col>
          <Txt variant={'heading3'} mb={'24px'} className={styles['heading-text']} data-testid="title-progressBar">
            {title}
          </Txt>
          <Surface variant={viewport.smallerThan.md ? 'primary' : 'secondary'}>
            <Txt
              mb={'8px'}
              color="subtle"
              variant="bodySmallRegular"
              className={styles['body-text']}
              data-testid="text-progressBar"
            >
              {typeof textProgressBar === 'string'
                ? textProgressBar
                : textProgressBar?.(initialStep, totalNumberOfSteps)}
            </Txt>
            <div className={styles['progressBar']} data-testid="line-progressBar">
              <div className={styles['filled']} style={{ width: `${(initialStep / totalNumberOfSteps) * 100}%` }}></div>
            </div>
          </Surface>
        </Col>
      </Row>
    </Container>
  );
};
