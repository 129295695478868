declare global {
  interface Window {
    adobeDataLayer?: unknown[];
  }
}

export enum DataLayerEventName {
  AA_EVENT = 'aaEvent',
  AT_EVENT = 'atEvent',
}

export type DataLayerEvent = {
  event: DataLayerEventName;
  eventData: {
    event: string;
    [key: string]: unknown;
  };
};

export interface SnowplowContext {
  schema: string;
  data: Record<string, string | number>;
}

export enum DataLayerEvents {
  AA_EVENT = 'aaEvent',
}

export enum TrackingCategory {
  SERVICEABILITY_CHECK = ' Serviceability Check',
  CART = 'Cart',
  NAV = 'nav',
  REGISTER_NOW = 'Register Now',
  ORDER = 'Order',
  INSTALLATION = 'Installation',
  PERSONAL_INFORMATION = 'Personal Information',
  APP = 'App',
  LOCATION_CHANGE = 'Location Change',
  ORDER_REVIEW = 'Order Review',
  PLANS = 'plans',
  BILLING_ADDRESS_CHECK = 'Billing Address Check',
  SHIPPING_ADDRESS_CHECK = 'Shipping Address Check',
  FEATURE_FLAGS_LOADED = 'Feature Flags Loaded',
  ADDONS = 'Addons',
  SCHEDULE_INSTALLATION_LATER = 'Schedule installation',
  FIND_PLANS_CLICKED = 'Find Plans Clicked',
}

export enum TrackingActions {
  SUCCESSFUL = 'successful',
  MAYBE = 'maybe',
  ERROR = 'error',
  ADDED_PLAN = 'added_plan',
  CLICKED = 'clicked',
  PLACED = 'placed',
  SCHEDULED = 'scheduled',
  SCHEDULE_ERROR = 'schedule_error',
  EDITED_PERSONAL_INFO = 'edited_personal_info',
  EDITED_INSTALLATION = 'edited_installation',
  REMOVED_ADDON = 'removed_addon',
  SCHEDULE_LATER = 'schedule_later',
  INITIATED = 'initiated',
  EDITED_PLAN = 'edited_plan',
  EDITED_ADDON = 'edited_addon',
  SELECTED_ADDON = 'selected_addon',
  PLAN_GRID_TRIGGER_VIEW = 'planGridTriggerView',
  SKIP_AND_CONTINUE = 'skip_addons_page',
  SKIP_SCHEDULE_INSTALLATION = 'skip_schedule_installation',
}

export interface EventLogParams {
  label?: string | null;
  property?: string;
  value?: number;
  context?: SnowplowContext[];
  tstamp?: number;
}

export type LogEventFunction = (
  category: TrackingCategory,
  action: TrackingActions,
  params?: EventLogParams,
) => void | Promise<void>;
