import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Surface, Txt } from '@vst/beam';
import { ErrorOutline, Sync } from '@vst/beam-icons/icons';
import { appActions, selectErrorModalAttempts, selectErrorRetryAction } from '@buy-viasat/redux/src/app';

import { Modal } from '../Modal';

export type BackgroundApiErrorModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const BackgroundApiErrorModal: FC<BackgroundApiErrorModalProps> = ({ primaryAction, secondaryAction }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['serviceability']);
  const attempts = useSelector(selectErrorModalAttempts);
  const errorRetryAction = useSelector(selectErrorRetryAction);

  const HAS_NOT_REACHED_MAX_ATTEMPTS = attempts < 2;

  const phoneNumber = t('common.header.phoneNumber.text');

  function makePhoneCall() {
    const href = `tel:${phoneNumber}`;
    window.location.href = href;
  }

  return (
    <Modal
      headerIcon={HAS_NOT_REACHED_MAX_ATTEMPTS ? Sync : ErrorOutline}
      colorIcon={HAS_NOT_REACHED_MAX_ATTEMPTS ? 'teal_600' : 'alert_400'}
      headerTitle={t('common.backgroundApiErrorModal.headerTitle')}
      primaryLabelText={
        HAS_NOT_REACHED_MAX_ATTEMPTS
          ? t('common.generalErrorModal.firstAndSecondAttempt.primaryButton')
          : t('common.backgroundApiErrorModal.primaryLabelText')
      }
      secondaryLabelText={
        HAS_NOT_REACHED_MAX_ATTEMPTS ? '' : t('serviceability.modal.noAvailablePlans.secondaryLabelText')
      }
      alignTextToLeft={true}
      hideSecondaryButton={HAS_NOT_REACHED_MAX_ATTEMPTS}
      showPrimaryButtonFullWidth={true}
      hideMarginBottomFromText={true}
      secondaryAction={() => {
        dispatch(appActions.setErrorModalAttempts(0));
        secondaryAction();
      }}
      primaryAction={() => {
        if (HAS_NOT_REACHED_MAX_ATTEMPTS) {
          dispatch(appActions.setErrorModalAttempts(attempts + 1));
          dispatch(errorRetryAction);
        } else {
          makePhoneCall();
          dispatch(appActions.setErrorModalAttempts(0));
        }
        primaryAction();
      }}
      hideCloseButton={true}
    >
      <Surface data-testid="GeneralErrorModalAttempt1">
        <Txt variant={'bodySmallRegular'} data-testid="GeneralErrorModalAttempt1">
          {HAS_NOT_REACHED_MAX_ATTEMPTS
            ? t('common.generalErrorModal.firstAndSecondAttempt.description')
            : t('common.backgroundApiErrorModal.description')}
        </Txt>
      </Surface>
    </Modal>
  );
};
