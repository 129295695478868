import { ApolloQueryResult, gql } from '@apollo/client';
import { AvailableAppointmentsPayloadR0, GetInstallationDatesInputR0 } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type GetInstallationDatesPayload = ApolloQueryResult<{ getInstallationDates: AvailableAppointmentsPayloadR0 }>;

const GET_INSTALLATION_DATES = gql`
  query getInstallationDates($input: GetInstallationDatesInputR0!) {
    getInstallationDates(input: $input) {
      timezone
      schedules {
        from
        to
      }
    }
  }
`;

export default async function getInstallationDatesAsync(
  input: GetInstallationDatesInputR0,
): Promise<GetInstallationDatesPayload> {
  return client.query({
    query: GET_INSTALLATION_DATES,
    variables: {
      input: {
        ...input,
        to: '',
      },
    },
  });
}
