export enum RouteActions {
  Next = 'next',
  Previous = 'previous',
  Reset = 'reset',
}

export enum TransactionType {
  TRANSFER = 'Transfer',
  ENROLL = 'Enroll',
}
