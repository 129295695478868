export const ViasatTheme = {
  dark: false,
  roundness: 8,
  fonts: {
    regular: {
      fontFamily: 'uniNeueBook',
    },
    medium: {
      fontFamily: 'uniNeueBook',
    },
    light: {
      fontFamily: 'uniNeueBook',
    },
    thin: {
      fontFamily: 'uniNeueBook',
    },
  },
};
