import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, useBreakpoints } from '@vst/beam';
import { InfoOutlined } from '@vst/beam-icons/icons';
import { Modal } from '@buy-viasat/react/src/components/Modal';
import styles from './ssnMandatory.module.scss';

type SSNMandatoryModalProps = {
  primaryAction: () => void;
};

export const SSNMandatoryModal: FC<SSNMandatoryModalProps> = ({ primaryAction }) => {
  const { viewport } = useBreakpoints();
  const { t } = useTranslation(['creditVerification']);

  return (
    <Modal
      colorIcon={'teal_600'}
      headerIcon={InfoOutlined}
      data-testid="SSNMandatory"
      className={styles['modal']}
      headerTitle={t('creditVerification.modal.ssnMandatory.titleText')}
      primaryLabelText={t('creditVerification.modal.ssnMandatory.confirmButtonText')}
      secondaryLabelText={''}
      primaryAction={primaryAction}
      hideCloseButton={!viewport.smallerOrWithin.sm}
    >
      <Txt variant={'bodySmallRegular'} data-testid="SSNMandatoryModalBodyText" className={styles['bodyText']}>
        {t('creditVerification.modal.ssnMandatory.bodyText')}
      </Txt>
    </Modal>
  );
};
