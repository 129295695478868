import { createSelector } from 'reselect';
import { RootState } from '../types';
import { initialSubsidyProgramState, SubsidyProgramState } from './types';

export const selectSubsidyProgramDomain = (state: RootState): SubsidyProgramState => {
  return state?.subsidyProgram || initialSubsidyProgramState;
};

export const selectReceivingMonthlyDiscount = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isReceivingMonthlyDiscount,
);

export const selectQualifiedForTribalLands = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.qualifiedForTribalLands,
);

export const selectTermsAndConditionsChecked = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isTermsAndConditionsChecked,
);

export const selectTransferCheckboxChecked = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isTransferCheckboxChecked,
);

export const selectQuestionOneAnswer = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.questionOneAnswer,
);

export const selectQuestionTwoAnswer = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.questionTwoAnswer,
);

export const selectIsQuestionOneInvalid = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isQuestionOneInvalid,
);

export const selectIsQuestionTwoInvalid = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isQuestionTwoInvalid,
);

export const selectIsTransferAgreementInvalid = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isTransferAgreementInvalid,
);

export const selectIsTermsAndConditionsInvalid = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isTermsAndConditionsInvalid,
);

export const selectIsFormValid = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isFormValid,
);

export const selectAcpDetailsLater = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.acpDetailsLater,
);

export const selectIsVerifyButtonLoading = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.isVerifyButtonLoading,
);

export const selectResponseView = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.responseView,
);

export const selectVerifyAttempts = createSelector(
  [selectSubsidyProgramDomain],
  (state: SubsidyProgramState) => state.verifyAttempts,
);
