import { useDispatch } from 'react-redux';
import { appActions } from '@buy-viasat/redux/src/app';
import { LogEventFunction, TrackingCategory, TrackingActions, EventLogParams } from '@buy-viasat/redux/src/analytics';

export const useTrackEvent = (): LogEventFunction => {
  const dispatch = useDispatch();

  return (category: TrackingCategory, action: TrackingActions, params?: EventLogParams) => {
    dispatch(appActions.analyticsEvent({ category, action, params }));
  };
};
