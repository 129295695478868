export const numberOfSteps = (
  shouldSkipScheduleInstallInCart: boolean,
  isVermontCustomer: boolean,
  isSubsidyCheckboxChecked: boolean,
  hasAddons: boolean,
  displayCreditCheckPage: boolean,
  canPlaceMyOrderFromPaymentPage: boolean,
) => {
  let totalSteps = 7;
  if (!hasAddons) totalSteps--;
  if (!displayCreditCheckPage) totalSteps--;
  if (shouldSkipScheduleInstallInCart) totalSteps--;
  if (isVermontCustomer) totalSteps--;
  if (!isSubsidyCheckboxChecked) totalSteps--;
  if (canPlaceMyOrderFromPaymentPage) totalSteps--;
  return totalSteps;
};
