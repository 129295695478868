import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorOutline } from '@vst/beam-icons/icons';
import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Spacer, Surface, Txt, useBreakpoints } from '@vst/beam';
import { appActions, selectErrorModalAttempts, selectErrorRetryAction } from '@buy-viasat/redux/src/app';

interface SystemDownModalProps {
  primaryAction: () => void;
  secondaryAction: () => void;
}

export const SystemDownModal: FC<SystemDownModalProps> = ({ primaryAction, secondaryAction }) => {
  const attempts = useSelector(selectErrorModalAttempts);
  const errorRetryAction = useSelector(selectErrorRetryAction);
  const HAS_REACHED_MAX_ATTEMPTS = attempts < 2;

  const dispatch = useDispatch();
  const { t } = useTranslation(['acp']);
  const { viewport } = useBreakpoints();

  return (
    <Modal
      headerIcon={ErrorOutline}
      colorIcon={'alert_400'}
      headerTitle={t('ACP.systemDownModal.headerTitle')}
      primaryLabelText={t('ACP.navigation.continue')}
      secondaryLabelText={t('ACP.navigation.tryAgain')}
      alignTextToLeft={true}
      showSecondaryButtonFullWidth={true}
      hideSecondaryButton={HAS_REACHED_MAX_ATTEMPTS ? false : true}
      hideMarginBottomFromText={true}
      hideCloseButton={!viewport.smallerOrWithin.xs}
      primaryAction={() => {
        dispatch(appActions.setErrorModalAttempts(0));
        primaryAction();
      }}
      secondaryAction={() => {
        if (HAS_REACHED_MAX_ATTEMPTS) {
          dispatch(appActions.setErrorModalAttempts(attempts + 1));
          dispatch(errorRetryAction);
        } else {
          dispatch(appActions.setErrorModalAttempts(0));
        }
        secondaryAction();
      }}
    >
      <Surface data-testid="SystemDownModalContainer">
        <Txt variant={'bodySmallRegular'} data-testid={'ACPVerifyInformationText'}>
          {t('ACP.systemDownModal.verifyInformationText')}
        </Txt>
        <Spacer y={'24px'} />
        <Txt variant={'bodySmallRegular'} data-testid={'ACPContactText'} style={{ flexWrap: 'wrap' }}>
          {t('ACP.systemDownModal.contactText')}
        </Txt>
      </Surface>
    </Modal>
  );
};
