import { BVEnv } from '@buy-viasat/env-files';
import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../utils';
import { RESET_STATE_ACTION, RootState } from '../types';

export type EnvState = {
  env: BVEnv;
};

export const initialEnvState = {
  env: {} as BVEnv,
};

const envSlice = createSlice({
  name: 'env',
  initialState: initialEnvState,
  reducers: {
    setEnv(state: EnvState, action: PayloadAction<any>): void {
      state.env = action.payload;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): EnvState => initialEnvState,
  },
});

export const { actions: envActions, reducer: envReducer, name: envKey } = envSlice;

export const selectEnvDomain = (state: RootState): EnvState => {
  return state?.env || initialEnvState;
};

export const selectEnv = createSelector([selectEnvDomain], (state: EnvState) => state.env);
