import { useTranslation } from 'react-i18next';
import { Txt, useBreakpoints } from '@vst/beam';
import { ErrorOutline } from '@vst/beam-icons/icons';
import { Modal } from '@buy-viasat/react/src/components/Modal';
import styles from './paymentErrorModal.module.scss';

type PaymentErrorModalProps = {
  primaryAction: () => void;
};

export const PaymentErrorModal = (props: PaymentErrorModalProps) => {
  const { primaryAction } = props;
  const { viewport } = useBreakpoints();
  const { t } = useTranslation(['paymentInformation']);

  return (
    <Modal
      headerIcon={ErrorOutline}
      colorIcon={'alert_400'}
      headerTitle={t('paymentInformation.modal.error.headerTitle')}
      primaryLabelText={t('paymentInformation.modal.error.primaryLabelText')}
      secondaryLabelText={t('paymentInformation.modal.error.secondaryLabelText')}
      alignTextToLeft={true}
      hideSecondaryButton={true}
      hideMarginBottomFromText={true}
      showPrimaryButtonFullWidth={true}
      hideCloseButton={!viewport.smallerOrWithin.sm}
      primaryAction={primaryAction}
      data-testid="PaymentErrorModalContainer"
      className={styles['bodyModalText']}
    >
      <Txt variant={'bodySmallRegular'} className={styles['bodyText']} data-testid="PaymentErrorModalText">
        {t('paymentInformation.modal.error.bodyText')}
      </Txt>
    </Modal>
  );
};
