import { aton } from 'shared/utils';

export const convertDateString = (
  monthValue: string | undefined | null,
  dayValue: string | undefined | null,
  yearValue: string | undefined | null,
): Date => {
  const month = aton(monthValue);
  const day = aton(dayValue);
  const year = aton(yearValue);
  return new Date(year, month - 1, day);
};

export const dateToStringFormat = (
  monthValue: string | undefined | null,
  dayValue: string | undefined | null,
  yearValue: string | undefined | null,
): string => {
  return `${monthValue}/${dayValue}/${yearValue}`;
};

export const getAge = (date: Date, today: Date): number => {
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age;
};

export const isMonthValid = (value: string): boolean => {
  const month = aton(value);
  return month >= 1 && month <= 12;
};

export const isDayValid = (value: string): boolean => {
  const day = aton(value);
  return day >= 1 && day <= 31;
};

export const isYearValid = (value: string): boolean => {
  const year = aton(value);
  const currentYear = new Date().getFullYear();

  const age = currentYear - year;

  return age > 0 && age < 200;
};

export const isDateValid = (day: string, month: string, isLeapYear: boolean): boolean => {
  const dayValue = parseInt(day, 10);
  const monthValue = parseInt(month, 10);

  if (monthValue === 2 && isLeapYear) {
    return dayValue >= 1 && dayValue <= 29;
  } else if (monthValue === 2 && !isLeapYear) {
    return dayValue >= 1 && dayValue <= 28;
  } else if ([4, 6, 9, 11].includes(monthValue)) {
    return dayValue >= 1 && dayValue <= 30;
  } else {
    return dayValue >= 1 && dayValue <= 31;
  }
};

export const isLeapYear = (yearStr: string): boolean => {
  const year = parseInt(yearStr, 10);

  if (isNaN(year)) {
    return false;
  }

  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};
