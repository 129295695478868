import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@buy-viasat/react/src/components/Modal';

import { useAcpModals } from './useACPModals';
import { GeneralAcpModalVariant } from './types';

export type TribalBenefitModalProps = {
  variant: GeneralAcpModalVariant;
  primaryAction: () => void;
  secondaryAction?: () => void;
  contentSlot?: JSX.Element;
};

export const GeneralAcpModal: FC<TribalBenefitModalProps> = ({
  primaryAction,
  secondaryAction,
  variant,
  contentSlot,
}) => {
  const { t } = useTranslation(['acp', 'common']);
  const { getModalProps, getHeaderIconProps } = useAcpModals({ secondaryAction });
  const { content, headerText, primaryActionText, handleSecondaryAction, hideCloseButton, secondaryActionText } =
    getModalProps(variant);
  const iconProps = useMemo(() => getHeaderIconProps(variant), [getHeaderIconProps, variant]);

  return (
    <Modal
      headerIcon={iconProps.icon}
      colorIcon={iconProps.color ?? 'gray_400'}
      headerTitle={headerText ?? t('ACP.navigation.error')}
      primaryLabelText={primaryActionText}
      primaryAction={primaryAction}
      secondaryLabelText={secondaryActionText ?? t('common.navigator.button.close')}
      secondaryAction={handleSecondaryAction}
      alignTextToLeft={true}
      hideMarginBottomFromText={true}
      hideCloseButton={hideCloseButton}
      hideSecondaryButton={!handleSecondaryAction}
      showPrimaryButtonFullWidth
      showSecondaryButtonFullWidth
      showFullScreenMobile={variant === GeneralAcpModalVariant.ACP_TERMS_AND_CONDITIONS}
      contentWrapperStyle={variant === GeneralAcpModalVariant.ACP_TERMS_AND_CONDITIONS ? { height: '552px' } : {}}
    >
      {contentSlot ?? content}
    </Modal>
  );
};
