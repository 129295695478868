import { TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';
import { AddressType, formatScrubbedAddressHousingNumber } from '@buy-viasat/redux/src/address';
import {
  appActions,
  Modals,
  selectFeatureFlags,
  selectOrderId,
  selectSalesFlowDefinition,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import { navActions, Routes } from '@buy-viasat/redux/src/navigator';
import {
  selectAddressLine2,
  selectCoordinates,
  selectHouseNumber,
  selectScrubLocation,
  selectServiceAddressValues,
  serviceabilityActions,
} from '@buy-viasat/redux/src/serviceability';
import { LocationInput, RecommendationStatus } from '@buy-viasat/types/build/bv';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { benchmark, resetSession } from 'shared/containers/App/saga';
import scrubAddressAsync from '../../providers/requests/fulfillment/scrubAddress';
import getPartnerAccountAsync from '../../providers/requests/products/getPartnerAccount';
import clientLogger from '../../utils/clientLogger';
import { ValidateAddressAction } from './types';
import { isDefined, isDefinedString } from '@buy-viasat/utils';
import { planActions } from '@buy-viasat/redux/src/plan';

export function* addressEquality() {
  yield put(serviceabilityActions.setIsServiceabilityLoading(false));
  yield put(
    appActions.analyticsEvent({
      category: TrackingCategory.SERVICEABILITY_CHECK,
      action: TrackingActions.SUCCESSFUL,
      params: undefined,
    }),
  );
  yield put(serviceabilityActions.useSuggestedServiceAddress());
}

export function* addressInequality() {
  yield put(serviceabilityActions.setIsServiceabilityLoading(false));
  yield put(
    appActions.analyticsEvent({
      category: TrackingCategory.SERVICEABILITY_CHECK,
      action: TrackingActions.MAYBE,
      params: undefined,
    }),
  );
  yield put(appActions.setModalVisible(Modals.CONFIRM_SERVICE_ADDRESS));
}

export function* checkValidAddress({ address }: ValidateAddressAction) {
  const { hideScrubAddressModal } = yield select(selectFeatureFlags);
  yield put(
    serviceabilityActions.setScrubLocation({
      address,
      coordinates: { latitude: address.latitude, longitude: address.longitude },
    }),
  );
  if (isDefined(address.latitude) && isDefined(address.longitude) && address.invalidAddress) {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.ADDRESS_NOT_VERIFIED));
  } else if (address.invalidAddress) {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.NO_ADDRESS_FOUND));
  } else if (address.recommendation === RecommendationStatus.NEEDS_VERIFICATION) {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.CONFIRM_SERVICE_ADDRESS));
  } else if (address.recommendation === RecommendationStatus.RECOMMEND && hideScrubAddressModal) {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(serviceabilityActions.useSuggestedServiceAddress());
  } else {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.CONFIRM_SERVICE_ADDRESS));
  }
}

export function* checkBannedPostalCode({ address }: ValidateAddressAction) {
  if (address.bannedPostalCode) {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.SERVICEABILITY_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(appActions.setModalVisible(Modals.NO_SERVICE));
  } else {
    yield call<any>(checkValidAddress, { address });
  }
}

function* scrubServiceAddressSaga() {
  const { displayHouseNumber } = yield select(selectFeatureFlags);
  let address: AddressType = yield select(selectServiceAddressValues);
  const houseNumberAddress: AddressType = yield select(selectHouseNumber);
  const addressLine2: string = yield select(selectAddressLine2);
  try {
    const {
      data: { scrubAddress },
    } = yield call<any>(scrubAddressAsync, {
      address: {
        ...address,
      },
    });

    const [modifiedAddress] = scrubAddress.addressLines[0].split(', ');
    scrubAddress.addressLines[0] = `${modifiedAddress}, ${addressLine2}`;

    const result = formatScrubbedAddressHousingNumber(
      scrubAddress,
      displayHouseNumber && houseNumberAddress.houseNumber,
    );

    yield call<any>(checkBannedPostalCode, { address: result });
  } catch (err: any) {
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    clientLogger('scrubServiceAddressSaga: ', err);
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.SERVICEABILITY_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(appActions.setErrorRetryAction({ type: serviceabilityActions.scrubServiceAddress.type, payload: null }));
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

export function* onUseSuggestedServiceAddress(): Generator {
  const scrubAddress: any = yield select(selectScrubLocation);
  const { dealerId, partnerId }: any = yield select(selectUrlParams);
  yield put(appActions.setIsModalVisible(false));
  yield put(serviceabilityActions.setServiceAddress(scrubAddress.address));
  yield put(serviceabilityActions.setCoordinates(scrubAddress.coordinates));
  try {
    const location: LocationInput = {
      address: {
        addressLines: scrubAddress.address.addressLines,
        countryCode: scrubAddress.address.countryCode,
        municipality: scrubAddress.address.municipality,
        region: scrubAddress.address.region,
        postalCode: scrubAddress.address.postalCode,
      },
      coordinates: scrubAddress.coordinates,
    };
    const {
      data: { getPartnerAccount },
    }: any = yield call<any>(getPartnerAccountAsync, {
      location,
      dealerId,
      partnerIraPartyId: partnerId,
    });
    yield put(appActions.setSalesAgreementId(getPartnerAccount.salesAgreementId));
    yield put(appActions.setFulfillmentAgreementId(getPartnerAccount.fulfillmentAgreementId));
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(navActions.next());
  } catch (err: any) {
    clientLogger('scrubServiceAddressSaga: ', err);
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.SERVICEABILITY_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.NO_AVAILABLE_PLANS));
  }
}

// TODO: add types
export const pullCityAndStateFromGeocodeResults = (results: any): any => {
  const [state] = results.address_components.filter((item: any) => item.types[0] === 'administrative_area_level_1');
  let [city] = results.address_components.filter((item: any) => item.types[0] === 'locality');
  if (!isDefined(city)) {
    [city] = results.address_components.filter((item: any) => item.types[0] === 'administrative_area_level_2');
  }
  if (!isDefined(city)) {
    [city] = results.address_components.filter((item: any) => item.types[0] === 'administrative_area_level_3');
  }
  return {
    stateName: state?.short_name,
    cityName: city?.short_name,
  };
};

export function* onAutoCompletedAddressUsedSaga(): Generator {
  const address: any = yield select(selectServiceAddressValues);
  const { dealerId, partnerId }: any = yield select(selectUrlParams);
  const coordinates: any = yield select(selectCoordinates);

  try {
    const location: LocationInput = {
      address,
      coordinates,
    };
    const {
      data: { getPartnerAccount },
    }: any = yield call<any>(getPartnerAccountAsync, {
      location,
      dealerId,
      partnerIraPartyId: partnerId,
    });
    yield put(appActions.setSalesAgreementId(getPartnerAccount.salesAgreementId));
    yield put(appActions.setFulfillmentAgreementId(getPartnerAccount.fulfillmentAgreementId));
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(navActions.next());
  } catch (err: any) {
    clientLogger('scrubServiceAddressSaga: ', err);
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.SERVICEABILITY_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.NO_AVAILABLE_PLANS));
  }
}

export function* onUseInlineServiceAddress(): Generator {
  const inlineServiceAddress: any = yield select(selectServiceAddressValues);
  const { houseNumber }: any = yield select(selectHouseNumber);
  const { dealerId, partnerId }: any = yield select(selectUrlParams);
  const { geocodeSplitZipFormat }: any = yield select(selectSalesFlowDefinition);
  yield put(appActions.setIsModalVisible(false));
  try {
    const postalCode = inlineServiceAddress.postalCode.split('-');
    const geoCodeResults: any = yield call<any>(
      geocodeByAddress,
      geocodeSplitZipFormat ? postalCode[0] : inlineServiceAddress.postalCode,
    );
    const { cityName, stateName } = pullCityAndStateFromGeocodeResults(geoCodeResults[0]);
    const coordResults: any = yield call<any>(
      geocodeByAddress,
      `${inlineServiceAddress.addressLines[0]},${cityName},${stateName}+${postalCode}`,
    );
    const { lat, lng } = coordResults[0].geometry.location;

    const coordinates = { latitude: lat(), longitude: lng() };
    const formattedServiceAddress = { ...inlineServiceAddress };
    if (isDefinedString(houseNumber)) {
      formattedServiceAddress['houseNumber'] = houseNumber;
    }
    yield put(serviceabilityActions.setServiceAddress(formattedServiceAddress));
    yield put(serviceabilityActions.setServiceAddressMunicipality(cityName));
    yield put(serviceabilityActions.setServiceAddressRegion(stateName));
    yield put(serviceabilityActions.setCoordinates(coordinates));
    const location: LocationInput = {
      address: { ...inlineServiceAddress, municipality: cityName, region: stateName },
      coordinates,
    };
    const {
      data: { getPartnerAccount },
    }: any = yield call<any>(getPartnerAccountAsync, {
      location,
      dealerId,
      partnerIraPartyId: partnerId,
    });
    yield put(appActions.setSalesAgreementId(getPartnerAccount.salesAgreementId));
    yield put(appActions.setFulfillmentAgreementId(getPartnerAccount.fulfillmentAgreementId));
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(navActions.next());
  } catch (err: any) {
    clientLogger('scrubServiceAddressSaga: ', err);
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.SERVICEABILITY_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(serviceabilityActions.setIsServiceabilityLoading(false));
    yield put(appActions.setModalVisible(Modals.NO_AVAILABLE_PLANS));
  }
}

function* onServiceabilityMount() {
  yield put(serviceabilityActions.resetServiceAddress());
  yield put(appActions.setCurrentRoute(Routes.SERVICEABILITY));
  const orderId: string = yield select(selectOrderId);
  if (!!orderId) yield call(resetSession);
  yield put(planActions.setHavePlansLoaded(false));
  yield put(
    planActions.setPlanId({
      id: '',
      name: '',
      offerId: '',
      extensionProducts: [],
      planPrice: 0,
      planPromo: 0,
      inflectionPointText: '',
      productFamily: '',
      isRegulated: false,
      isCafII: false,
      dataCap: '',
    }),
  );
}

export function* serviceabilitySaga(): Generator {
  yield takeLatest(serviceabilityActions.onServiceabilityMount.type, onServiceabilityMount);
  yield takeLatest(serviceabilityActions.scrubServiceAddress.type, benchmark(scrubServiceAddressSaga));
  yield takeLatest(serviceabilityActions.useSuggestedServiceAddress.type, onUseSuggestedServiceAddress);
  yield takeLatest(serviceabilityActions.useInlineServiceAddress.type, onUseInlineServiceAddress);
  yield takeLatest(serviceabilityActions.onAutoCompletedAddressUsed.type, onAutoCompletedAddressUsedSaga);
}
