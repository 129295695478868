import { PasswordInput, TextField, validators } from '@vst/beam';
import { FormEvent, FormEventHandler, useMemo } from 'react';
import NumericFormat from 'react-number-format';

type TaxIdInputProps = {
  isInputObscured: boolean;
  regex: RegExp;
  validation?: { required?: string; invalid?: string };
  inputLabel: string;
  inputValue?: string;
  isRequired: boolean;
  placeholder: string;
  inputFormat: string;
  isAlphaNumeric?: boolean;
  hideStar?: boolean;
  onChange?: FormEventHandler<HTMLInputElement>;
};

export const TaxIdInput = (props: TaxIdInputProps) => {
  const {
    isInputObscured,
    inputLabel,
    inputValue,
    inputFormat,
    isRequired,
    placeholder,
    regex,
    validation,
    isAlphaNumeric,
    hideStar,
    onChange,
  } = props;

  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(validators.required({ message: validation.required }));
    }
    if (validation?.invalid) {
      validationRules.push(validators.pattern({ pattern: regex, message: validation.invalid }));
    }
    return validationRules;
  }, [validation, regex]);

  const handleOnChange = (event: FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.toUpperCase();
    onChange?.(event);
  };

  return isInputObscured ? (
    <PasswordInput
      fluid={true}
      name="taxId"
      labelProps={{ labelText: inputLabel, showStar: !hideStar }}
      placeholder={placeholder}
      enablePasswordShow={true}
      validationRules={validationRules}
      required={isRequired}
      maxLength={4}
      data-testid="TaxIdInput"
      value={inputValue}
      autoComplete="current-password"
      onChange={handleOnChange}
    />
  ) : isAlphaNumeric ? (
    <TextField
      name="taxId"
      required={isRequired}
      data-testid="TaxIdInput"
      validationRules={validationRules}
      placeholder={placeholder}
      value={inputValue}
      fluid={true}
      labelProps={{ labelText: inputLabel, showStar: !hideStar }}
      maxLength={inputFormat.length}
      onChange={handleOnChange}
    />
  ) : (
    <NumericFormat
      customInput={TextField}
      value={inputValue}
      fluid={true}
      format={inputFormat}
      mask="x"
      placeholder={placeholder}
      name="taxId"
      labelProps={{ labelText: inputLabel, showStar: !hideStar }}
      validationRules={validationRules}
      required={isRequired}
      data-testid="SSNInput"
      onChange={handleOnChange}
    />
  );
};
