import gql from 'graphql-tag';
import { RemoveCartItems, ShoppingCart } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';
import { ShoppingCartFragment } from './fragments';
import { FetchResult } from '@apollo/client';

export type RemoveCartItemsResponse = FetchResult<{ removeCartItems: ShoppingCart }>;

const REMOVE_CART_ITEMS = gql`
  mutation removeCartItems($input: RemoveCartItems!) {
    removeCartItems(input: $input) {
      ...ShoppingCart
    }
  }
  ${ShoppingCartFragment}
`;

export default async function removeCartItemsAsync({
  input,
}: {
  input: RemoveCartItems;
}): Promise<RemoveCartItemsResponse> {
  return client.mutate({
    mutation: REMOVE_CART_ITEMS,
    variables: { input },
  });
}
