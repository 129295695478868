import styles from './cartHeaderDownloadSpeed.module.scss';
import { Icon, Surface, Txt } from '@vst/beam';
import { SpeedOutlined } from '@vst/beam-icons/icons';
import { Maybe } from 'graphql-tools';

type CartHeaderDownloadSpeedProps = {
  downloadSpeed: Maybe<string> | undefined;
  downloadUnits: Maybe<string> | undefined;
  downloadSpeedPhraseKey: string;
};

export const CartHeaderDownloadSpeed = ({
  downloadSpeed,
  downloadUnits,
  downloadSpeedPhraseKey,
}: CartHeaderDownloadSpeedProps) => {
  return (
    <Surface className={styles['downloadSpeedContainer']} data-testid="downloadSpeedContainer">
      <Icon icon={SpeedOutlined} mr="8px" color="gray_600" data-testid="downloadSpeedIcon" />
      <Txt variant="smallBold" data-testid="downloadSpeedValue" className={styles['padding']}>
        {downloadSpeed}
      </Txt>
      <Txt variant="smallBold" className={styles['padding']} data-testid="downloadSpeedUnits">
        {downloadUnits}
      </Txt>
      <Txt variant="smallRegular" data-testid="downloadSpeedText">
        {downloadSpeedPhraseKey}
      </Txt>
    </Surface>
  );
};
