import gql from 'graphql-tag';
import { FetchResult } from '@apollo/client';
import { CreateOrganizationInput, SmbCustomer } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type CreateOrganizationResponse = FetchResult<{ createOrganization: SmbCustomer }>;

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      individualPartyId
      organizationPartyId
      relnId
    }
  }
`;

export default async function createOrganizationAsync(
  input: CreateOrganizationInput,
): Promise<CreateOrganizationResponse> {
  return client.mutate({
    mutation: CREATE_ORGANIZATION,
    variables: {
      input,
    },
  });
}
