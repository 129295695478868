import { CustomerType } from '@buy-viasat/types/build/bv';
import { supportedLngs } from '../i18n';

export function getLocaleFromPath(path: string): string {
  const defaultLocale = supportedLngs[0]; // en-US
  const match = path.match(/\w{2}-\w{2}/) || [defaultLocale];

  const queryLocales = match[0].split('-');
  const locales = `${queryLocales[0].toLowerCase()}-${queryLocales[1].toUpperCase()}`;

  if (!locales || !supportedLngs.includes(locales)) {
    return defaultLocale;
  }
  return locales;
}

export function getCustomerType(path: string): string {
  const match = path.match(/\/b\//) || path.endsWith('/b');
  if (!match) {
    return CustomerType.RESIDENTIAL;
  }
  return CustomerType.BUSINESS;
}

export async function getAccessTokenAsync(env: string, authUrl: string): Promise<{ token: string }> {
  const path = window.location.pathname;
  const locale = getLocaleFromPath(path);
  const customerType = getCustomerType(path);

  try {
    const response = await fetch(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ locale, env, customerType }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error during fetch:', error);
    throw error;
  }
}
