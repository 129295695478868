import { AddressType } from '@buy-viasat/redux/src/address';
import { Country, CustomerInfoMarketing } from '@buy-viasat/types/build/bv';
import { isDefined } from '@buy-viasat/utils';
import { adobeTargetDataLayer } from 'shared/containers/Analytics';
import { DataLayerEvents } from 'shared/containers/Analytics/types';
import { CustomerInfo } from 'shared/views/PersonalInformation/types';

/**
 * Convenience method that launches the users email client (handles mobile devices) and creates a new email
 * with the inputted recipient address.
 * @param {string} emailAddress - The recipient the user wants for their email.
 * @return { void }
 */

/* ASCII string to number */
export const aton = (val?: string | null): number => (isNaN(Number(val)) ? 0 : Number(val));

/**
 * Calculates an appropriate width for a component given a min/max width and the screenSize we are styling for.
 * @param {number} componentMinWidth - The minimum width for the component per abstract.
 * @param {number} componentMaxWidth - The maximum width for the component per abstract.
 * @param { number } screenWidth - The current width of the devices screen in pixels.
 * @return { number }
 */

export const precisionNumber = (value: number): number => {
  return parseFloat(value.toFixed(2));
};

const trimAndCaseStr = (val: string): string => val?.trim().toLowerCase();

const trimAndCaseAddress = ({
  addressLines,
  municipality,
  region,
  countryCode,
  postalCode,
}: AddressType): AddressType => {
  addressLines = addressLines.map(trimAndCaseStr);
  return {
    addressLines,
    municipality: trimAndCaseStr(municipality),
    region: trimAndCaseStr(region),
    countryCode: trimAndCaseStr(countryCode),
    postalCode: trimAndCaseStr(postalCode),
  };
};

/* abbreviateStreetSuffix
 * Reducing address comparison inequality by abbreviating common street suffix. */
const abbreviateStreetSuffix = (val: string): string => {
  const abbrevRecord: Record<string, string> = {
    avenue: 'ave',
    boulevard: 'blvd',
    drive: 'dr',
    lane: 'ln',
    parkway: 'pkwy',
    road: 'rd',
    street: 'st',
  };
  const search = Object.keys(abbrevRecord).join('|');
  const regEx = new RegExp(search);
  return val.replace(regEx, (val): string => abbrevRecord[val]);
};

const compareAddressLines = (x: string[], y: string[]): boolean => {
  if (x.length < 1 || y.length < 1) return false;
  const streetAddrX = abbreviateStreetSuffix(x[0]);
  const streetAddrY = abbreviateStreetSuffix(y[0]);
  return streetAddrX === streetAddrY;
};

export const areAddressesEqual = (addrA: AddressType, addrB: AddressType, shouldRequireFullAddress = true): boolean => {
  const {
    addressLines: addrLinesA,
    municipality: muniA,
    region: regionA,
    countryCode: countryA,
    postalCode: postalA,
  } = trimAndCaseAddress(addrA);
  const {
    addressLines: addrLinesB,
    municipality: muniB,
    region: regionB,
    countryCode: countryB,
    postalCode: postalB,
  } = trimAndCaseAddress(addrB);
  const addressLineEquality = compareAddressLines(addrLinesA, addrLinesB);
  if (shouldRequireFullAddress) {
    return (
      addressLineEquality &&
      muniA === muniB &&
      regionA === regionB &&
      countryA == countryB &&
      compareZipLength(postalA, postalB, countryA)
    );
  } else {
    return addressLineEquality && countryA == countryB && compareZipLength(postalA, postalB, countryA);
  }
};

export const isCustomerInfoEqual = (
  newCustomerInfo: CustomerInfo,
  previousCustomerInfo: CustomerInfoMarketing,
  serviceAddress: String,
): boolean => {
  return (
    newCustomerInfo.email === previousCustomerInfo.email &&
    newCustomerInfo.firstName === previousCustomerInfo.firstName &&
    newCustomerInfo.lastName === previousCustomerInfo.lastName &&
    newCustomerInfo.phoneNumber === previousCustomerInfo.phoneNumber &&
    serviceAddress === previousCustomerInfo.serviceAddress
  );
};
export const getMillisFromMinutes = (timeInMinutes: number) => 60000 * timeInMinutes;

export const structureDateOfBirth = (month: string, day: string, year: string): string => {
  return `${month}/${day}/${year}`;
};

const compareZipLength = (postalA: string, postalB: string, countryB: string): boolean => {
  switch (countryB.toUpperCase()) {
    case Country.BR:
      return postalA === postalB && postalA.replace('-', '').length === 8;
    default:
      return postalA.substring(0, 5) === postalB.substring(0, 5);
  }
};

type DeactivateBillingAccountParam = {
  nonRegulatedSPBBillingAccountId: string;
  smbBillingAccountId: string;
  regulatedSPBBillingAccountId: string;
  spbBillingAccountId: string;
};

export const getBillingAccountIdToDeactivate = ({
  nonRegulatedSPBBillingAccountId,
  smbBillingAccountId,
  regulatedSPBBillingAccountId,
  spbBillingAccountId,
}: DeactivateBillingAccountParam) => {
  const deactivateAccountIdList = new Array(
    regulatedSPBBillingAccountId,
    nonRegulatedSPBBillingAccountId,
    smbBillingAccountId,
  );
  return deactivateAccountIdList.filter((id) => id.length && id !== spbBillingAccountId);
};
