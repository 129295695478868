import { LogEventFunction, SnowplowContext } from '@buy-viasat/redux/src/analytics';

export enum TrackingLabel {
  UPDATE_ZIPCODE = 'update_zipcode',
  UPDATE_PERSONAL_INFO = 'update_personal_info',
}

export enum DataLayerEvents {
  AA_EVENT = 'aaEvent',
}

export type TrackScreenFunction = (name: string, id: string, params?: SnowplowContext[]) => void | Promise<void>;

export interface AnalyticsContainer {
  logEvents: LogEventFunction[];
  trackScreens: TrackScreenFunction[];
  startTime: Date;
}
