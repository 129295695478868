import { DataLayerEvents, TrackScreenFunction } from '../../shared/containers/Analytics/types';
import { adobeTargetDataLayer } from '../../shared/containers/Analytics';
import { LogEventFunction } from '@buy-viasat/redux/src/analytics';
import env from 'env';

export const pixel = (window as any).pixel;

export const pixelTrackScreenFunction: TrackScreenFunction = (name, id, params): void => {
  if (env.debug.showAnalytics) {
    console.log('In Pixel track screen function');
    console.log('custom url: ', name, 'referrer url: ', id, 'page name: ', params);
  }
  pixel('setCustomerUrl', name);
  pixel('setReferrerUrl', id);
  pixel('trackPageView', params);
};

export const pixelEventLogFunction: LogEventFunction = (category, action, params): void => {
  if (env.debug.showAnalytics) {
    console.log('In Pixel events functions', category, action, params);
  }
  pixel('trackStructEvent', category, action, params?.label, params?.property, params?.value);
  adobeTargetDataLayer &&
    adobeTargetDataLayer.push({
      event: DataLayerEvents.AA_EVENT,
      properties: { category, action, params },
    });
};
