import { AuthState, initialAuthState } from './authSlice';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../types';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export const selectAuthDomain = (state: RootState): AuthState => {
  return state?.auth || initialAuthState;
};

export const selectAccessToken = createSelector([selectAuthDomain], (state: AuthState) => state.accessToken);
export const selectFlowId = createSelector([selectAuthDomain], (state: AuthState) => state.flowId);
export const selectAccessTokenExpiration = createSelector([selectAuthDomain], (state: AuthState) =>
  state.accessToken ? jwt_decode<JwtPayload>(state.accessToken).exp! * 1000 : Number.MAX_VALUE,
);
export const isTokenReset = createSelector([selectAuthDomain], (state: AuthState) => state.resetToken);
