import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Txt, Surface, Spacer, InlineLink } from '@vst/beam';
import ErrorOutline from '@vst/beam-icons/icons/ErrorOutline';

import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Routes, navActions } from '@buy-viasat/redux/src/navigator';
import {
  selectFirstName,
  selectLastName,
  selectSubsidyApplicationId,
} from '@buy-viasat/redux/src/personal-information';
import { selectDateOfBirth } from '@buy-viasat/redux/src/credit-verification';

export type ModalFunction = () => void;

export interface AcpApplicationNotFoundProps {
  primaryAction: () => void;
  secondaryAction: () => void;
}

export const AcpApplicationNotFoundModal = ({ primaryAction, secondaryAction }: AcpApplicationNotFoundProps) => {
  const { t } = useTranslation(['acp', 'common']);
  const dispatch = useDispatch();
  const appId = useSelector(selectSubsidyApplicationId);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const dateOfBirth = useSelector(selectDateOfBirth);
  const dateOfBirthFormatted = [
    dateOfBirth.month.value,
    dateOfBirth.day.value,
    dateOfBirth.year.value.slice(2, 4),
  ].join('/');
  return (
    <Modal
      headerIcon={ErrorOutline}
      secondaryLabelText={t('common.navigator.button.close')}
      primaryLabelText={t('common.navigator.button.verifyLater')}
      colorIcon={'alert_400'}
      headerTitle={t('ACP.applicationNotFoundModal.headerTitle')}
      showPrimaryButtonFullWidth={true}
      alignTextToLeft={true}
      hideMarginBottomFromText={true}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
    >
      <Surface>
        <Txt variant={'bodySmallRegular'} data-testid={'ACPApplicationNotFoundHeaderDescription'}>
          {t('ACP.applicationNotFoundModal.headerDescription')}
        </Txt>
        <Spacer y={'24px'} />
        <Surface variant={'secondary'} p={'16px'} radius={'8px'} data-testid={'ACPApplicationNotFoundDetails'}>
          <Txt variant={'labelTiny'} color={'subtle'} data-testid={'ACPApplicationNotFoundAppId'}>
            {t('ACP.applicationNotFoundModal.appId')}
          </Txt>
          <Txt variant={'bodySmallBold'} data-testid={'ACPApplicationNotFoundAppIdValue'}>
            {appId.value}
            <InlineLink
              px={'8px'}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                secondaryAction();
                dispatch(navActions.routeUserTo(Routes.SUBSIDY_PROGRAM));
              }}
              style={{ display: 'inline' }}
              data-testid={'ACPApplicationNotFoundAppIdLink'}
            >
              {t('ACP.applicationNotFoundModal.edit')}
            </InlineLink>
          </Txt>
          <Spacer y={'24px'} />
          <Txt variant={'labelTiny'} color={'subtle'} data-testid={'ACPApplicationNotFoundNameDateOfBirth'}>
            {t('ACP.applicationNotFoundModal.nameDateOfBirth')}
          </Txt>
          <Txt variant={'bodySmallBold'} data-testid={'ACPApplicationNotFoundNameDateOfBirthValue'}>
            {`${firstName.value} ${lastName.value}, ${dateOfBirthFormatted}`}
            <InlineLink
              px={'8px'}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                secondaryAction();
                dispatch(navActions.onEditPersonalInformationPress());
              }}
              style={{ display: 'inline' }}
              data-testid={'ACPApplicationNotFoundAppIdLink'}
            >
              {t('ACP.applicationNotFoundModal.edit')}
            </InlineLink>
          </Txt>
          <Spacer y={'24px'} />
          <Txt variant={'tinyItalic'} color={'subtle'} data-testid={'ACPApplicationNotFoundEditRequiresCreditCheck'}>
            {t('ACP.applicationNotFoundModal.editRequiresCreditCheck')}
          </Txt>
        </Surface>
        <Spacer y={'16px'} />
        <Txt variant={'smallRegular'} color={'subtle'} data-testid={'ACPApplicationNotFoundPleaseReferEmail'}>
          {t('ACP.applicationNotFoundModal.footerDescriptionPleaseReferEmail')}
        </Txt>
        <Spacer y={'24px'} />
        <Txt variant={'smallRegular'} color={'subtle'} data-testid={'ACPApplicationNotFoundIfYouChooseToVerify'}>
          {t('ACP.applicationNotFoundModal.footerDescriptionIfYouChooseToVerify')}
        </Txt>
      </Surface>
    </Modal>
  );
};
