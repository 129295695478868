import { ApolloQueryResult, gql } from '@apollo/client';
import { Country, FeatureFlags } from '@buy-viasat/types/build/bv';
import { Routes, ScheduleInstallLocations } from '../navigator';
import { initialSalesFlowAgreement, SalesFlowDefinition } from './SalesFlowDefinition';

export enum Modals {
  ADDRESS_NOT_VERIFIED = 'addressNotVerified',
  BACK_BUTTON_PROMPT = 'backButtonPrompt',
  BILLING_ADDRESS_NOT_VERIFIED = 'billingAddressNotVerified',
  SHIPPING_ADDRESS_NOT_VERIFIED = 'shippingAddressNotVerified',
  CAE = 'cae',
  CONFIRM_SERVICE_ADDRESS = 'confirmServiceAddress',
  CONFIRM_BILLING_ADDRESS = 'confirmBillingAddress',
  CONFIRM_SHIPPING_ADDRESS = 'confirmShippingAddress',
  GET_PLANS_SERVER_ERROR = 'getPlansServerError',
  NO_ADDRESS_FOUND = 'noAddressFound',
  NO_AVAILABLE_PLANS = 'noAvailablePlans',
  NO_SERVICE = 'noService',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  ERROR = 'error',
  AUTH_ERROR = 'authError',
  DEFAULT = 'default',
  VIDEO_STREAM = 'videoStream',
  UPDATE_BILLING_ADDRESS = 'updateBillingAddress',
  MANDATORY_SSN = 'mandatorySsn',
  ACP_MODAL = 'acpModal',
  ACP_SUCCESS_MODAL = 'acpSuccessModal',
  ACP_APPLICATION_NOT_FOUND_MODAL = 'acpApplicationNotFoundModal',
  SUBSIDY_TERMS_AND_CONDITIONS = 'subsidyTermsAndConditions',
  TRANSFER_DISCLOSURE = 'transferDisclosure',
  CONFIRM_LATER = 'confirmLater',
  VERIFY_ACP_DETAILS = 'verifyAcpDetails',
  PAYMENT_INFORMATION_ERROR = 'paymentInformationError',
  SYSTEM_DOWN_MODAL = 'systemDownModal',
  BACKGROUND_API_ERROR = 'backgroundApiError',
  NONTRIBAL_NLAD_LOCATION = 'nontribalNladLocation',
  DEFICIENT_CUSTOMER_INFO = 'deficientCustomerInfo',
  NONTRIBAL_CONSUMER_LOCATION = 'nontribalConsumerLocation',
  DUPLICATE_SUBSCRIBER_NLAD = 'duplicateSubscriberNlad',
  NOT_ELIGIBLE_ACP = 'notEligibleAcp',
  ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE = 'acpTransferDisclosureApplicationNotComplete',
  APPLICATION_NOT_COMPLETED = 'applicationNotCompleted',
  APPLICATION_PENDING = 'applicationPending',
  GENERAL_ERROR = 'generalError',
  ACP_TERMS_AND_CONDITIONS = 'acpTermsAndConditions',
  ACP_ILL_DO_IT_LATER = 'acpIllDoItLater',
  SCHEDULE_LATER = 'scheduleLater',
  REMOVE_OPTIONAL_SERVICE = 'removeOptionalService',
  UPSERT_WITH_CLOSING_OFFER_RETRY_ERROR = 'upsertWithClosingOfferRetryError',
  CLOSING_OFFER_NOT_ELIGIBLE = 'closingOfferNotEligible',
}

export const CAFII = 'CAF II';

export enum CustomerType {
  RESIDENTIAL = 'r',
  BUSINESS = 'b',
}

export const initialAppState: AppState = {
  errorRetryAttempts: 0,
  errorRetryAction: null,
  billingContactEqualsAccountHolder: true,
  onSiteContactEqualsAccountHolder: true,
  creditCheckResults: null,
  currentModal: Modals.CAE,
  currentRoute: Routes.SERVICEABILITY,
  locale: 'en-US',
  customerType: CustomerType.RESIDENTIAL,
  country: Country.US,
  isAvailable: true,
  isCartVisible: false,
  isCheckoutButtonDisabled: false,
  isModalVisible: false,
  isOrderSubmitting: false,
  isPaymentInformationComplete: false,
  isPersonalInformationComplete: false,
  isScheduleInstallationComplete: false,
  isTaxCodesLoading: true,
  location: undefined,
  maxRoute: Routes.SERVICEABILITY,
  partyId: '',
  organizationPartyId: '',
  orderId: '',
  relnId: '',
  redirected: false,
  serviceabilityError: false,
  serviceabilityMaybe: false,
  showOrderReview: false,
  startTime: new Date().toString(),
  loadedFeatureFlags: false,
  featureFlagsUserKey: undefined,
  featureFlags: {
    allowChoosePlan: true,
    serviceabilityAptSte: true,
    singleFieldAddress: false,
    scheduleInstallLocation: ScheduleInstallLocations.CreditCheck,
    onSiteContactInformation: false,
    billingContactInformation: false,
    displayAddonsPage: true,
    isSSNMandatory: true,
    authAmount: 0,
    enableMarketingConsent: true,
    showShippingAddress: true,
    sellingPoint: '',
    displayViewMoreDetailsLink: false,
    hideScrubAddressModal: true,
    topBarOptions: '',
    displayPhoneNumber: false,
    allowChooseVoice: false,
    displayTooltipPlanGrid: false,
    displayCreditCheckPage: true,
    pricePlanCard: '',
    displayHouseNumber: true,
    signupMyViasat: true,
    displayRemarketingCheckbox: false,
    displayNextSteps: false,
    workOrderCharacterLimit: 500,
    dishNetworkPartnerInfo: ['abc123'],
    shouldDisplayDueToday: false,
    displaySmbLink: false,
    displayNewPlanCards: false,
    maintenanceMode: false,
    displayAcpBeforeCreditCheck: false,
    enablePlaceMyOrderFromPaymentPage: false,
    displayTotalSavingsTag: false,
    shouldLogRaygunAnalytics: false,
    skeletonLoaderForContactInformation: false,
    enableIraNewEndpoint: false,
    strictPhoneNumberValidation: false,
    hideAcpCheckbox: false,
    displayingTooltipOnBvPlanCards: false,
    enablingClosingOffersInBV: false,
    enableBroadbandLabels: false,
    displayButtonLabels: false,
    enableSubmitOrderPALTransition: false,
    enablePalApis: false,
  },
  urlParams: {},
  workOrderId: '',
  hasSubmittedScheduleInstall: false,
  salesAgreementId: '',
  fulfillmentAgreementId: '',
  errorModalOverrideHeader: undefined,
  errorModalOverrideBody: undefined,
  errorModalOverrideButtonText: undefined,
  errorModalOverrideSvg: undefined,
  salesFlowDefinition: initialSalesFlowAgreement,
  smbLinkResidentialRedirect: false,
  smbLinkPlansRedirect: false,
  buildVersion: {
    id: '',
    updateRequired: false,
    updateRetryAttempts: 0,
  },
  isEmbedded: false,
  isStandAlone: false,
  hasVisitedOrderReview: false,
  timer: 0,
  hideMfeCTA: false,
  displayCalculatedLaterCartLabel: false,
};

export interface AppState {
  errorRetryAttempts: number;
  errorRetryAction: { type: string; payload: any } | null;
  billingContactEqualsAccountHolder: boolean;
  onSiteContactEqualsAccountHolder: boolean;
  creditCheckResults: CreditCheck[] | null;
  currentModal: Modals;
  currentRoute: Routes;
  locale: string;
  customerType: CustomerType;
  country: Country;
  isAvailable: boolean;
  isCartVisible: boolean;
  isCheckoutButtonDisabled: boolean;
  isModalVisible: boolean;
  isOrderSubmitting: boolean;
  isPaymentInformationComplete: boolean;
  isPersonalInformationComplete: boolean;
  isScheduleInstallationComplete: boolean;
  isTaxCodesLoading: boolean;
  location: string | undefined;
  maxRoute: Routes;
  partyId: string;
  organizationPartyId: string;
  orderId: string;
  relnId: string;
  redirected: boolean;
  serviceabilityError: boolean;
  serviceabilityMaybe: boolean;
  showOrderReview: boolean;
  startTime: string;
  loadedFeatureFlags: boolean;
  featureFlagsUserKey: string | undefined;
  featureFlags: Features;
  urlParams: QueryParams;
  workOrderId: string;
  hasSubmittedScheduleInstall: boolean;
  salesAgreementId: string;
  fulfillmentAgreementId: string;
  errorModalOverrideHeader: string | undefined;
  errorModalOverrideBody: string | undefined;
  errorModalOverrideButtonText: string | undefined;
  errorModalOverrideSvg: string | undefined;
  salesFlowDefinition: SalesFlowDefinition;
  smbLinkResidentialRedirect: boolean;
  smbLinkPlansRedirect: boolean;
  buildVersion: AppBuildVersion;
  isStandAlone: boolean;
  isEmbedded: boolean;
  hasVisitedOrderReview: boolean;
  timer: number;
  hideMfeCTA: boolean;
  displayCalculatedLaterCartLabel: boolean;
}

export type AppBuildVersion = {
  id: string;
  updateRequired: boolean;
  updateRetryAttempts: number;
};

export type Features = {
  singleFieldAddress: boolean;
  allowChoosePlan: boolean;
  serviceabilityAptSte: boolean;
  scheduleInstallLocation: ScheduleInstallLocations;
  onSiteContactInformation: boolean;
  billingContactInformation: boolean;
  displayAddonsPage: boolean;
  isSSNMandatory: boolean;
  authAmount: number;
  enableMarketingConsent: boolean;
  showShippingAddress: boolean;
  displayViewMoreDetailsLink: boolean;
  sellingPoint: string;
  hideScrubAddressModal: boolean;
  topBarOptions: string;
  displayPhoneNumber: boolean;
  allowChooseVoice: boolean;
  displayTooltipPlanGrid: boolean;
  displayCreditCheckPage: boolean;
  pricePlanCard: string;
  displayHouseNumber: boolean;
  signupMyViasat: boolean;
  displayRemarketingCheckbox: boolean;
  displayNextSteps: boolean;
  workOrderCharacterLimit: number;
  dishNetworkPartnerInfo: [string];
  shouldDisplayDueToday: boolean;
  displaySmbLink: boolean;
  displayNewPlanCards: boolean;
  maintenanceMode: boolean;
  displayAcpBeforeCreditCheck: boolean;
  enablePlaceMyOrderFromPaymentPage: boolean;
  displayTotalSavingsTag: boolean;
  shouldLogRaygunAnalytics: boolean;
  skeletonLoaderForContactInformation: boolean;
  enableIraNewEndpoint: boolean;
  strictPhoneNumberValidation: boolean;
  hideAcpCheckbox: boolean;
  displayingTooltipOnBvPlanCards: boolean;
  enablingClosingOffersInBV: boolean;
  enableBroadbandLabels: boolean;
  displayButtonLabels: boolean;
  enableSubmitOrderPALTransition: boolean;
  enablePalApis: boolean;
};

export enum CreditTag {
  DEFAULT = 'CREDIT_TAG',
}

export const CREDIT_CHECK_ELIGIBILITY_VALUE = '61000';

export interface CreditCheck {
  name: CreditTag;
  value: string;
}

export enum ScriptId {
  ADOBE_LAUNCH = 'adobeLaunch',
  GOOGLE_PLACES = 'googlePlaces',
  ADOBE_TARGET = 'adobeTarget',
}

export type QueryParams = {
  dealerId?: string;
  partnerId?: string;
  affiliateId?: string;
  sessionId?: string;
  campaignId?: string;
  hideCta?: string;
  offerId?: string;
  plnId?: string;
};

export type AemErrorParams = {
  errorCode: string;
};

export type ErrorModalOverride = {
  overrideHeader?: string;
  overrideBody?: string;
  overrideButtonText?: string;
  overrideSvg?: string;
  modal: Modals;
};

export const FEATURE_FLAGS = gql`
  query getFeatureFlags($input: FeatureFlagsInput) {
    getFeatureFlags(input: $input) {
      singleFieldAddress
      allowChoosePlan
      allowChooseVoice
      serviceabilityAptSte
      scheduleInstallLocation
      onSiteContactInformation
      billingContactInformation
      displayAddonsPage
      isSSNMandatory
      authAmount
      pricePlanCard
      enableMarketingConsent
      showShippingAddress
      sellingPoint
      displayViewMoreDetailsLink
      topBarOptions
      displayPhoneNumber
      hideScrubAddressModal
      displayTooltipPlanGrid
      displayCreditCheckPage
      displayHouseNumber
      signupMyViasat
      displayRemarketingCheckbox
      displayNextSteps
      workOrderCharacterLimit
      dishNetworkPartnerInfo
      shouldDisplayDueToday
      displaySmbLink
      displayNewPlanCards
      maintenanceMode
      displayAcpBeforeCreditCheck
      enablePlaceMyOrderFromPaymentPage
      displayTotalSavingsTag
      shouldLogRaygunAnalytics
      skeletonLoaderForContactInformation
      enableIraNewEndpoint
      strictPhoneNumberValidation
      hideAcpCheckbox
      displayingTooltipOnBvPlanCards
      enablingClosingOffersInBV
      enableBroadbandLabels
      displayButtonLabels
    }
  }
`;

export enum ThunkActions {
  getFeatureFlags = 'getFeatureFlags',
}

export type GetFeatureFlagsPayload = ApolloQueryResult<{ getFeatureFlags: FeatureFlags }>;
