/* eslint-disable @typescript-eslint/no-empty-function */
import { QueryParams } from '@buy-viasat/types/build/bv';
import { RESET_STATE_ACTION } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../utils';
import { initialNavState, NavState, Routes, RouteStates } from './types';

const navigatorSlice = createSlice({
  name: 'navigator',
  initialState: initialNavState,
  reducers: {
    // Direct route actions
    routeUserTo({}, payload: PayloadAction<Routes>): void {},
    // CheckoutButton onPress actions
    onRouteButtonPress({}, payload: PayloadAction<Routes>): void {},
    // MISC
    onEditScheduleInstallationPress(): void {},
    onEditPersonalInformationPress(): void {},
    onEditPaymentInformationPress(): void {},
    onSubsidyProgramButtonPress(): void {},
    scrubShippingAddress(): void {},
    setupAppRoutes(): void {},
    next(): void {},
    previous(): void {},
    reset(): void {},
    setRouteStates(state: NavState, action: PayloadAction<RouteStates>) {
      state.routeStates = action.payload;
    },
    setCurrentAppRoute(state: NavState, action: PayloadAction<Routes>) {
      if (Object.values(Routes).includes(action.payload)) state.currentAppRoute = action.payload;
      else state.currentAppRoute = Routes.SERVICEABILITY;
    },
    onSessionReset(state: NavState, payload: PayloadAction<{ route: Routes; inputParams: QueryParams }>): void {},
    setMaxRoute(state: NavState, action: PayloadAction<Routes>): void {
      state.maxRoute =
        state.currentAppRoute === undefined ||
        state.routeStates[action.payload].order > state.routeStates[state.currentAppRoute].order
          ? action.payload
          : state.maxRoute;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): NavState => initialNavState,
  },
});
/* eslint-enable @typescript-eslint/no-empty-function */

export const { actions: navActions, reducer: navReducer, name: navKey } = navigatorSlice;
