import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Txt, Surface, useBreakpoints } from '@vst/beam';
import { LocationOn } from '@vst/beam-icons/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './shippingAddressNotVerifiedModal.module.scss';

type ShippingAddressNotVerifiedModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
  suggestedAddressText: string;
};

export const ShippingAddressNotVerifiedModal: FC<ShippingAddressNotVerifiedModalProps> = ({
  primaryAction,
  secondaryAction,
  suggestedAddressText,
}) => {
  const { viewport } = useBreakpoints();
  const { t } = useTranslation(['accountInformation']);

  return (
    <Modal
      headerIcon={LocationOn}
      colorIcon={'teal_600'}
      headerTitle={t('accountInformation.ShippingAddressNotVerifiedModal.titleText')}
      primaryLabelText={t('accountInformation.common.AddressNotVerifiedModal.confirmButtonText')}
      secondaryLabelText={t('accountInformation.common.AddressNotVerifiedModal.closeButtonText')}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      showSecondaryButtonAsLink={true}
      hideCloseButton={!viewport.smallerOrWithin.sm}
      data-testid="ShippingAddressNotVerifiedModal"
    >
      <>
        <Txt variant={'bodySmallRegular'} data-testid="AddressNotVerifiedModalBodyText">
          {t('accountInformation.ShippingAddressNotVerifiedModal.bodyText')}
        </Txt>
        <Surface
          variant="secondary"
          radius={'8px'}
          className={styles['suggestedAddress']}
          data-testid="AddressNotVerifiedModalSuggestedAddressContainer"
        >
          <Txt variant={'bodySmallBold'} data-testid="AddressNotVerifiedModalSuggestedAddressText">
            {suggestedAddressText}
          </Txt>
        </Surface>
      </>
    </Modal>
  );
};
