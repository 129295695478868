import { gql } from '@apollo/client';
import { client } from 'shared/containers/apollo';
import { GetWorkOrderInput } from '@buy-viasat/types/build/bv';

const GET_WORK_ORDER = gql`
  query getWorkOrder($input: GetWorkOrderInput!) {
    getWorkOrder(input: $input) {
      status
    }
  }
`;

export default async function getWorkOrderAsync(input: GetWorkOrderInput): Promise<any> {
  return client.query({
    query: GET_WORK_ORDER,
    variables: {
      input: input,
    },
  });
}
