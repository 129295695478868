import React from 'react';

import { Txt } from '@vst/beam';

import { CheckoutCartProps } from '../types';

import styles from './priceDetailPromo.module.scss';

interface Props {
  phraseKey: CheckoutCartProps['phraseKeys']['serviceDetails']['priceAfterPromotion'];
  durationClosingOffer: number;
  promoDuration: number | undefined;
  totalPrice: string;
  totalPriceWithoutPromotions: string;
}

const formatPhrase = (phrase: string, duration: number) => {
  return duration > 1 ? phrase : phrase.slice(0, -1);
};

const generateDisplayText = ({
  phraseKey,
  durationClosingOffer,
  promoDuration = 0,
  totalPrice,
  totalPriceWithoutPromotions,
}: Props) => {
  let displayText = '';
  const isClossingOfferDurationSmallerThanPromoDuration = durationClosingOffer < promoDuration;
  const isPromoDurationSmallerThanClosingOfferDuration = promoDuration < durationClosingOffer;

  const textClossingOffer = formatPhrase(phraseKey?.(`${totalPrice}`, `${durationClosingOffer}`), durationClosingOffer);
  const textPromo = formatPhrase(phraseKey?.(`${totalPriceWithoutPromotions}`, `${promoDuration}`), promoDuration);

  if (durationClosingOffer && isClossingOfferDurationSmallerThanPromoDuration && promoDuration) {
    displayText = `${textClossingOffer}, ${textPromo}`;
  } else if (promoDuration && isPromoDurationSmallerThanClosingOfferDuration && durationClosingOffer) {
    displayText = `${textPromo}, ${textClossingOffer}`;
  } else if (durationClosingOffer && !promoDuration) {
    displayText = `${textClossingOffer}`;
  } else if (promoDuration) {
    displayText = `${textPromo}`;
  }
  return displayText;
};

const PriceDetailPromo: React.FC<Props> = (props) => {
  const displayText = generateDisplayText(props);

  return (
    <Txt
      variant="tinyItalic"
      color="info"
      className={styles['priceDetailPromo']}
      data-testid="totalMonthlyPlanPriceWithoutPromo"
    >
      {displayText}
    </Txt>
  );
};

export default PriceDetailPromo;
