import { ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Operation } from '@apollo/client/link/core';
import { ErrorCode } from '@buy-viasat/types/build/bv';
import { GraphQLError } from 'graphql';
import { Refetch } from '../types';
import { store } from '../store';
import env from 'env';
import { apolloClient } from '@buy-viasat/utils';
import { requestAccessToken } from '@buy-viasat/redux/src/auth';
import { appActions } from '@buy-viasat/redux/src/app';
import { sendRaygunError, trackCustomTiming } from 'shared/utils/raygun';

const httpLink = createHttpLink({
  uri: env.serverUrls.graphql,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = store.getState().auth.accessToken;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const authenticationError = (err: Readonly<GraphQLError>): boolean =>
  err?.extensions?.code === ErrorCode.UNAUTHENTICATED;

const updateToken = (operation: Operation): void => {
  store.dispatch(requestAccessToken({ env: env.env, authUrl: env.serverUrls.auth }));

  const priorHeaders = operation.getContext().headers;
  operation.setContext({
    headers: {
      ...priorHeaders,
      authorization: store.getState().auth.accessToken,
    },
  });
};

const authError = onError(({ graphQLErrors, operation, forward }): Refetch => {
  if (graphQLErrors) {
    const identifier = store.getState().auth.flowId;
    sendRaygunError({ error: JSON.stringify(graphQLErrors), customData: { identifier } });
    const err = graphQLErrors.find(authenticationError);
    if (err) {
      updateToken(operation);
      forward(operation);
    }
  }
});

const clientVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();

    if (headers.get('X-Build-Version') !== env.buildVersion) {
      console.warn('Build version mismatch detected', {
        clientVersion: env.buildVersion,
        serverVersion: headers.get('X-Build-Version'),
      });

      store.dispatch(appActions.setBuildVersionUpdateRequired(true));
    }
    return response;
  });
});

const logTimeLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });

  return forward(operation).map((data) => {
    const context = operation.getContext();
    const time = new Date().getTime() - context['start'].getTime();
    const identifier = store.getState().auth.flowId;
    const name = `graphql_${operation.operationName}`;

    try {
      trackCustomTiming(name, time, identifier);
    } catch (error: any) {
      // Log the error to Raygun
      sendRaygunError({ error, customData: { identifier }, tags: name });
    }
    return data;
  });
});

apolloClient.setLink(ApolloLink.from([logTimeLink, authLink, authError, clientVersionLink, httpLink]));

export const client = apolloClient;
