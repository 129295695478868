import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const supportedLngs = ['en-US', 'en-BR', 'pt-BR', 'it-IT'];

const formatDecimal = (value?: string | number): string => {
  if (typeof value === 'string' || typeof value === 'undefined') {
    value = Number(value);
    if (isNaN(value)) return '--';
  }

  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
};

const setLocaleForCountry = () => {
  const url = window.location.href.split('/')[2];
  if (url === 'compre.viasat.com.br') return 'pt-BR';
  else return undefined;
};

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng.split('-')[0]);
});

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    cleanCode: true,
    supportedLngs,
    fallbackLng: 'en-US',
    lng: setLocaleForCountry(),
    ns: ['datetime', 'legal'], // to use without hooks
    interpolation: {
      escapeValue: false,
      format: (value, format): string => {
        if (format === 'currency') return formatDecimal(value);
        return value;
      },
    },
    joinArrays: '',
    parseMissingKeyHandler: (key) => `**${key}**`,
    detection: {
      order: ['querystring', 'path', 'navigator'],
      lookupFromPathIndex: 0,
      lookupQuerystring: 'locale',
    },
  });

export default i18n;
