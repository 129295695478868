import gql from 'graphql-tag';
import { NladBenefitsInput, NladBenefitsResponse } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

const VERIFY_NLAD_DETAILS = gql`
  query verifyNLAD($input: NLADBenefitsInput) {
    verifyNLAD(input: $input) {
      message
      name
      errorType
      correctiveAction
      traceId
    }
  }
`;

export default async function verifyNLADAsync(input: NladBenefitsInput): Promise<NladBenefitsResponse> {
  const { partyId, region, dateOfBirth, applicationId, transactionType, tribalBenefit } = input;

  return client.query({
    query: VERIFY_NLAD_DETAILS,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        partyId,
        region,
        dateOfBirth,
        applicationId,
        transactionType,
        tribalBenefit,
      },
    },
  }) as unknown as NladBenefitsResponse;
}
