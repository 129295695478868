import { PayloadAction } from '@reduxjs/toolkit';
import { ClosingOffersState, initialClosingOffersState } from './types';
import { createSlice } from '../utils';
import { RESET_STATE_ACTION } from '../types';
import { ClosingOffer } from '@buy-viasat/types/build/bv';

const closingOffersSlice = createSlice({
  name: 'closingOffers',
  initialState: initialClosingOffersState,
  reducers: {
    setUpsertWithClosingOffersError(state: ClosingOffersState, action: PayloadAction<boolean>): void {
      state.isUpsertWithClosingOffersError = action.payload;
    },
    setSkipClosingOffer(state: ClosingOffersState, action: PayloadAction<boolean>): void {
      state.skipClosingOffer = action.payload;
    },
    setAvailableClosingOffers(state: ClosingOffersState, action: PayloadAction<ClosingOffer[]>): void {
      state.availableClosingOffers = action.payload;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): ClosingOffersState => initialClosingOffersState,
  },
});

export const {
  actions: closingOffersActions,
  reducer: closingOffersReducer,
  name: closingOffersKey,
} = closingOffersSlice;
