import gql from 'graphql-tag';
import { UpdateRelationshipIdInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

const UPDATE_RELATIONSHIP_INSTANCE = gql`
  mutation updateRelationshipInstance($input: UpdateRelationshipIdInput) {
    updateRelationshipInstance(input: $input) {
      accountNumber
    }
  }
`;

export default async function updateRelationshipInstanceAsync(options: UpdateRelationshipIdInput): Promise<any> {
  return client.mutate({
    mutation: UPDATE_RELATIONSHIP_INSTANCE,
    variables: {
      input: options,
    },
  });
}
