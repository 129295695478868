import { ComponentProps, useMemo } from 'react';
import { TextField, validators } from '@vst/beam';

interface AddressFieldInputProps extends ComponentProps<typeof TextField> {
  validation?: {
    required: string;
  };
}

const AddressFieldInput = (props: AddressFieldInputProps) => {
  const { validation, ...textFieldProps } = props;

  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(validators.required({ message: validation.required }));
    }
    return validationRules;
  }, [validation]);

  return (
    <TextField
      data-testid={textFieldProps.name}
      labelProps={{ showStar: false }}
      validationRules={validationRules}
      required={!!validation?.required}
      {...textFieldProps}
    />
  );
};

export default AddressFieldInput;
