import { Country, ExistingContactMethod, ScrubAddressPayload } from '@buy-viasat/types/build/bv';
import { AddressFieldStateType, defaultAddressData, defaultAddressForm, LocationType } from '../address';
import { ApolloQueryResult, gql } from '@apollo/client';

export interface ServiceabilityState {
  existingContactMethod: ExistingContactMethod | null;
  existingCustomer: boolean;
  isServiceabilityLoading: boolean;
  latitude: number | null;
  longitude: number | null;
  scrubbedLocation: LocationType;
  serviceAddress: AddressFieldStateType;
  showMap: boolean;
  useScrubbedAddress: boolean;
  isServiceAddressSet: boolean;
}

export const initialServiceabilityState: ServiceabilityState = {
  existingContactMethod: null,
  existingCustomer: false,
  isServiceabilityLoading: true,
  latitude: null,
  longitude: null,
  scrubbedLocation: {
    address: defaultAddressData(Country.US),
    coordinates: {
      latitude: null,
      longitude: null,
    },
  },
  serviceAddress: defaultAddressForm(Country.US),
  showMap: false,
  useScrubbedAddress: true,
  isServiceAddressSet: false,
};

export enum ThunkActions {
  scrubAddress = 'scrubAddress',
}

export const SCRUB_ADDRESS = gql`
  query scrubAddress($input: ScrubAddressInput!) {
    scrubAddress(input: $input) {
      addressLines
      municipality
      region
      countryCode
      postalCode
      longitude
      latitude
      bannedPostalCode
      invalidAddress
      processStatus
      recommendation
    }
  }
`;

export type ScrubAddressWithCoordinatesPayload = ApolloQueryResult<{ scrubAddress: ScrubAddressPayload }>;
