import { FC, Ref } from 'react';
import { Check } from '@vst/beam-icons/icons';
import { Surface, Txt, Icon } from '@vst/beam';

import styles from './phoneNumberInput.module.scss';
import { CountryOption } from '@buy-viasat/utils';

interface DropdownProps {
  options: CountryOption[];
  dropdownRef: Ref<HTMLElement>;
  selectedCountry?: CountryOption;
  handleCountryChange: (country: CountryOption) => void;
}

export const PhoneNumberDropdown: FC<DropdownProps> = ({
  options,
  dropdownRef,
  selectedCountry,
  handleCountryChange,
}) => {
  return (
    <Surface ref={dropdownRef} className={styles['dropdown']}>
      {options
        .sort((a, b) => (a.order || 0) - (b.order || 0) || a.label.localeCompare(b.label))
        .map((country) => (
          <div key={country.value} onClick={() => handleCountryChange(country)} className={styles['dropdownContent']}>
            <div className={styles['contentWrapper']}>
              {country.value ? <div className={`${styles['flag']} ${styles[country.value.toLowerCase()]}`} /> : <></>}
              <Txt variant={'bodySmallRegular'} ml={'12px'}>
                {country.label} {country.prefix}
              </Txt>
            </div>
            {selectedCountry?.value === country.value && (
              <Icon icon={Check} data-testid="CheckIcon" mx={'12px'} my={'8px'} />
            )}
          </div>
        ))}
    </Surface>
  );
};
