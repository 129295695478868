import { FormFieldState } from '../types';
import { isDefined } from '../utils';

export const resetAddressLines = (arr: FormFieldState<string[]>) => {
  const result: string[] = [];
  result.push(arr.value[0].split(',')[0]);
  if (isDefined(arr.value[1])) {
    result.push(arr.value[1]);
  }
  return result;
};
