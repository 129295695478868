import { FetchResult, gql } from '@apollo/client';
import { UpdateOrganizationInput, UpdateOrganizationResponse } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type UpdateOrganizationPayload = FetchResult<{ updateOrganization: UpdateOrganizationResponse }>;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      success
    }
  }
`;

export default async function updateOrganizationAsync(
  input: UpdateOrganizationInput,
): Promise<UpdateOrganizationPayload> {
  return client.mutate({
    mutation: UPDATE_ORGANIZATION,
    variables: {
      input,
    },
  });
}
