import { PaymentType } from '@buy-viasat/redux/src/payment-information';

export enum CurrencySymbol {
  US = 'USD',
  BR = 'BRL',
  EUR = 'EUR',
}

export const priceDecimalFormat = (
  price: number | string,
  locale: string,
  currencySymbol: string,
  minimumFractionDigits = 0,
): string => {
  if (typeof price === 'string') {
    price = isNaN(Number(price)) ? 0 : Number(price);
  }

  const roundedPrice = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySymbol,
    minimumFractionDigits: minimumFractionDigits,
  });
  const wholePrice = Intl.NumberFormat(locale, { style: 'currency', currency: currencySymbol });

  if (price % 1 !== 0) {
    return wholePrice.format(price).replace(/\s/g, '');
  } else {
    return roundedPrice.format(price).replace(/\s/g, '');
  }
};

export const getCurrencySymbol = (locale: string, currencySymbol: string) => {
  return (
    new Intl.NumberFormat(locale, { style: 'currency', currency: currencySymbol })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value ?? ''
  );
};

export const calculateDueTodayTotal = (
  onetimeTotalWithoutPromo: number,
  paymentType: PaymentType,
  oneTimeDiscount: number,
  locale: string,
  currencySymbol: string,
) => {
  const dueTodayTotal = onetimeTotalWithoutPromo + (paymentType !== PaymentType.BOLETO ? oneTimeDiscount : 0);
  return { dueTodayTotal, dueTodayTotalValue: priceDecimalFormat(dueTodayTotal, locale, currencySymbol) };
};

export const noPriceHandler = (currencySymbol: string, locale: string) => {
  switch (currencySymbol) {
    case CurrencySymbol.EUR:
      return `--${getCurrencySymbol(locale, currencySymbol)}`;
    case CurrencySymbol.US:
    default:
      return `${getCurrencySymbol(locale, currencySymbol)}--`;
  }
};
