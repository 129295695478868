import { ComponentProps, useMemo } from 'react';
import { TextField, validators } from '@vst/beam';

interface AptUnitFieldInputProps extends ComponentProps<typeof TextField> {
  validation?: {
    required: string;
  };
}

const AptUnitFieldInput = (props: AptUnitFieldInputProps) => {
  const { validation, ...textFieldProps } = props;

  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(validators.required({ message: validation.required }));
    }
    return validationRules;
  }, [validation]);

  return <TextField data-testid={textFieldProps.name} validationRules={validationRules} {...textFieldProps} />;
};

export default AptUnitFieldInput;
