export const maskInput = (input: string, value: string) => {
  if (input.length === 0) return '';
  if (input.length === 1) {
    return (
      input
        .match(/[A-za-z]/)
        ?.join('')
        .toUpperCase() || ''
    );
  } else {
    const trimmedInput = input.replace(/\W/g, '');
    const formattedInput = trimmedInput.substring(1, trimmedInput.length).match(/^-?\d+$/);

    return formattedInput
      ? trimmedInput
          .match(/.{1,6}/g)
          ?.join('-')
          .substring(0, 12)
          .toUpperCase() || ''
      : value;
  }
};
