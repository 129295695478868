import { CountryCode } from 'libphonenumber-js';
import { AddressInput, CustomerInfoMarketing, Maybe } from '@buy-viasat/types/build/bv';
import { FormFieldState } from '../types';

export interface PersonalInformationState {
  email: FormFieldState<string>;
  firstName: FormFieldState<string>;
  lastName: FormFieldState<string>;
  phone: FormFieldState<string>;
  phoneCountryCode?: CountryCode;
  mobileNumber: FormFieldState<string>;
  subsidyApplicationId: FormFieldState<string>;
  showConfirmBillingAddress: boolean;
  isPersonalInformationLoading: boolean;
  isDisclaimerCheckboxChecked: boolean;
  isSubsidyCheckboxChecked: boolean;
  previousCustomerInfo: CustomerInfoMarketing;
  isMarketingCheckboxChecked: boolean;
  isMobileNumberChecked: boolean;
  isPersonalInformationRequestComplete: boolean;
}

export type PartyId = { partyId: string };
export interface ErrorPayload<T = string> {
  callingSaga: string;
  err: T;
  payload: any;
}

export interface NavigationGeneratorAction {
  billingAddress: Maybe<AddressInput> | undefined;
  shippingAddress: Maybe<AddressInput> | undefined;
  serviceAddress: AddressInput;
}

export const initialPersonalInformationState: PersonalInformationState = {
  email: { value: '', valid: null, hintText: 'Email is required' },
  firstName: { value: '', valid: null, hintText: 'First name is required' },
  lastName: { value: '', valid: null, hintText: 'Last name is required' },
  phone: { value: '', valid: null, hintText: 'Phone is required' },
  mobileNumber: { value: '', valid: true, hintText: '' },
  subsidyApplicationId: { value: '', valid: null, hintText: 'Application ID is required' },
  showConfirmBillingAddress: false,
  isPersonalInformationLoading: false,
  isDisclaimerCheckboxChecked: false,
  isSubsidyCheckboxChecked: false,
  previousCustomerInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    serviceAddress: '',
  },
  isMarketingCheckboxChecked: false,
  isMobileNumberChecked: false,
  isPersonalInformationRequestComplete: false,
};
