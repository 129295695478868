import { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE_ACTION } from '../types';
import { createSlice } from '../utils';
import { getLastFourStateFromEvent, getTaxIdFromEvent } from '@buy-viasat/utils';
import { initialCreditVerificationState, CreditVerificationState } from './types';
import { SetLastFourPayload, SetTaxIdPayload } from './types';

const creditVerificationSlice = createSlice({
  name: 'creditVerification',
  initialState: initialCreditVerificationState,
  reducers: {
    setLastFourOfSSN(state: CreditVerificationState, action: PayloadAction<SetLastFourPayload>): void {
      state.lastFourOfSSN.value = getLastFourStateFromEvent(
        action.payload.text,
        state.lastFourOfSSN.value,
        action.payload.cursorIndex,
      ).trim();
      state.updateCreditVerification = true;
    },
    setTaxId(state: CreditVerificationState, action: PayloadAction<SetTaxIdPayload>): void {
      state.taxId.value = action.payload.shouldConcatenateTaxId
        ? getTaxIdFromEvent(action.payload.taxId)
        : action.payload.taxId;
      state.updateCreditVerification = true;
    },
    setDobYear(state: CreditVerificationState, action: PayloadAction<string>): void {
      state.dateOfBirth.year.value = action.payload;
      state.updateCreditVerification = true;
    },
    setDobMonth(state: CreditVerificationState, action: PayloadAction<string>): void {
      state.dateOfBirth.month.value = action.payload;
      state.updateCreditVerification = true;
    },
    setDobDay(state: CreditVerificationState, action: PayloadAction<string>): void {
      state.dateOfBirth.day.value = action.payload;
      state.updateCreditVerification = true;
    },
    setCreditCheckComplete(state: CreditVerificationState, action: PayloadAction<boolean>): void {
      state.isCreditCheckComplete = action.payload;
    },
    setIsLegalCheckboxChecked(state: CreditVerificationState, action: PayloadAction<boolean>): void {
      state.isLegalCheckboxChecked = action.payload;
    },
    setIsLoading(state: CreditVerificationState, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    toggleIsLastFourObscured(state: CreditVerificationState): void {
      state.isLastFourObscured = !state.isLastFourObscured;
    },
    updateCreditVerification(state: CreditVerificationState, action: PayloadAction<boolean>): void {
      state.updateCreditVerification = action.payload;
    },
    resetDob(state: CreditVerificationState): void {
      state.dateOfBirth.day = initialCreditVerificationState.dateOfBirth.day;
      state.dateOfBirth.month = initialCreditVerificationState.dateOfBirth.month;
      state.dateOfBirth.year = initialCreditVerificationState.dateOfBirth.year;
    },
    updateSsnMandatoryDetails(
      state: CreditVerificationState,
      action: PayloadAction<{ isSsnMandatory: boolean; displaySSNMandatoryModal: boolean }>,
    ): void {
      state.isSSNMandatory = action.payload.isSsnMandatory;
      state.displaySSNMandatoryModal = action.payload.displaySSNMandatoryModal;
    },
    checkCustomerCredit(): void {},
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): CreditVerificationState => initialCreditVerificationState,
  },
});

export const {
  actions: creditVerificationActions,
  reducer: creditVerificationReducer,
  name: creditVerificationKey,
} = creditVerificationSlice;
