export const zipCodeBlacklist = [
  '78353',
  '78361',
  '78501',
  '78503',
  '78504',
  '78516',
  '78520',
  '78521',
  '78526',
  '78535',
  '78536',
  '78537',
  '78538',
  '78539',
  '78541',
  '78542',
  '78543',
  '78548',
  '78549',
  '78550',
  '78552',
  '78557',
  '78558',
  '78559',
  '78560',
  '78561',
  '78562',
  '78563',
  '78565',
  '78566',
  '78567',
  '78569',
  '78570',
  '78572',
  '78573',
  '78574',
  '78575',
  '78576',
  '78577',
  '78578',
  '78579',
  '78580',
  '78582',
  '78583',
  '78584',
  '78586',
  '78588',
  '78589',
  '78590',
  '78591',
  '78592',
  '78593',
  '78594',
  '78595',
  '78596',
  '78597',
  '78598',
];
