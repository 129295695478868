import gql from 'graphql-tag';
import { FetchResult } from '@apollo/client';
import { CreateIndividualInput, Customer } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type CreateIndividualResponse = FetchResult<{ createIndividual: Customer }>;

const CREATE_INDIVIDUAL = gql`
  mutation createIndividual($input: CreateIndividualInput!) {
    createIndividual(input: $input) {
      isCustomer
      existingContactMethod
      partyId
      relnId
    }
  }
`;

export default async function createIndividualAsync(input: CreateIndividualInput): Promise<CreateIndividualResponse> {
  return client.mutate({
    mutation: CREATE_INDIVIDUAL,
    variables: {
      input,
    },
  });
}
