import { Modal } from '@buy-viasat/react/src/components/Modal';
import { RadioButton } from '@buy-viasat/react/src/components/RadioButton';
import { Surface } from '@vst/beam';
import { LocationOn } from '@vst/beam-icons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmServiceAddressModalProps = {
  radioModalAddresses: {
    inlineScrubAddress: string;
    serviceAddress: string;
  };
  primaryAction: { scrubbedAddressRadioButton: () => void; serviceAddressRadioButton: () => void };
  secondaryAction: () => void;
};

export const ConfirmServiceAddressModal = (args: ConfirmServiceAddressModalProps) => {
  const { radioModalAddresses, primaryAction, secondaryAction } = args;
  const [selectedOption, setSelectedOption] = useState('scrubbedAddressRadioButton');
  const pickPrimaryAction =
    selectedOption === 'scrubbedAddressRadioButton'
      ? primaryAction.scrubbedAddressRadioButton
      : primaryAction.serviceAddressRadioButton;
  const { t } = useTranslation(['serviceability']);

  return (
    <Modal
      headerIcon={LocationOn}
      colorIcon={'teal_600'}
      headerTitle={t('serviceability.modal.confirmServiceAddress.headerTitle')}
      primaryLabelText={t('serviceability.modal.confirmServiceAddress.primaryLabelText')}
      secondaryLabelText={t('serviceability.modal.confirmServiceAddress.secondaryLabelText')}
      primaryAction={pickPrimaryAction}
      secondaryAction={secondaryAction}
      showSecondaryButtonFullWidth={true}
      hideCloseButton={true}
      data-testid="ConfirmServiceAddressModal"
      alignTextToLeft={true}
    >
      <Surface data-testid="ConfirmServiceAddressModalRadioButtonsContainer">
        <RadioButton
          labelText={t('serviceability.modal.confirmServiceAddress.firstRadioLabelText')}
          descriptionText={radioModalAddresses?.inlineScrubAddress}
          onClick={() => setSelectedOption('scrubbedAddressRadioButton')}
          isChecked={selectedOption === 'scrubbedAddressRadioButton'}
          id="scrubbedAddress"
          data-testid="ConfirmServiceAddressModalscrubbedAddressRadioButton"
        />
        <RadioButton
          labelText={t('serviceability.modal.confirmServiceAddress.secondRadioLabelText')}
          descriptionText={radioModalAddresses?.serviceAddress}
          onClick={() => setSelectedOption('serviceAddressRadioButton')}
          isChecked={selectedOption === 'serviceAddressRadioButton'}
          id="typedAddress"
          data-testid="serviceAddressRadioButton"
        />
      </Surface>
    </Modal>
  );
};
