import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Surface, Txt } from '@vst/beam';
import { FlagOutlined, Sync } from '@vst/beam-icons/icons';
import {
  appActions,
  selectErrorModalAttempts,
  selectErrorRetryAction,
  selectFeatureFlags,
} from '@buy-viasat/redux/src/app';

import { Modal } from '../Modal';
import styles from './generalErrorModal.module.scss';
import { Routes, selectCurrentAppRoute } from '@buy-viasat/redux/src/navigator';
import { paymentInformationActions, selectSpbBillingAccountId } from '@buy-viasat/redux/src/payment-information';

export type GeneralErrorModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const GeneralErrorModal = (props: GeneralErrorModalProps) => {
  const { primaryAction, secondaryAction } = props;
  const { t } = useTranslation(['serviceability']);
  const attempts = useSelector(selectErrorModalAttempts);
  const errorRetryAction = useSelector(selectErrorRetryAction);
  const dispatch = useDispatch();
  const { enablePlaceMyOrderFromPaymentPage } = useSelector(selectFeatureFlags);
  const currentRoute = useSelector(selectCurrentAppRoute);
  const billingAccountId = useSelector(selectSpbBillingAccountId);

  const HAS_REACHED_MAX_ATTEMPTS = attempts < 2;

  return (
    <Modal
      headerIcon={HAS_REACHED_MAX_ATTEMPTS ? Sync : FlagOutlined}
      colorIcon={'teal_600'}
      headerTitle={
        HAS_REACHED_MAX_ATTEMPTS
          ? t('common.generalErrorModal.firstAndSecondAttempt.headerTitle')
          : t('common.generalErrorModal.thirdAttempt.headerTitle')
      }
      primaryLabelText={
        HAS_REACHED_MAX_ATTEMPTS
          ? t('common.generalErrorModal.firstAndSecondAttempt.primaryButton')
          : t('common.generalErrorModal.firstAndSecondAttempt.secondaryButton')
      }
      secondaryLabelText={HAS_REACHED_MAX_ATTEMPTS ? t('serviceability.modal.noAvailablePlans.secondaryLabelText') : ''}
      alignTextToLeft={true}
      hideSecondaryButton={HAS_REACHED_MAX_ATTEMPTS ? false : true}
      showPrimaryButtonFullWidth={true}
      hideMarginBottomFromText={true}
      secondaryAction={() => {
        dispatch(appActions.setErrorModalAttempts(0));
        secondaryAction();
      }}
      primaryAction={() => {
        if (HAS_REACHED_MAX_ATTEMPTS) {
          dispatch(appActions.setErrorModalAttempts(attempts + 1));
          dispatch(errorRetryAction);
        } else {
          dispatch(appActions.setErrorModalAttempts(0));
        }
        primaryAction();
        if (currentRoute === Routes.PAYMENT_INFORMATION && enablePlaceMyOrderFromPaymentPage) {
          dispatch(paymentInformationActions.createVPPTransaction(billingAccountId));
        }
      }}
    >
      <Surface data-testid="GeneralErrorModalAttempt1">
        <Txt variant={'bodySmallRegular'} className={styles['bodyTextValue']} data-testid="GeneralErrorModalAttempt1">
          {HAS_REACHED_MAX_ATTEMPTS
            ? t('common.generalErrorModal.firstAndSecondAttempt.description')
            : t('common.generalErrorModal.thirdAttempt.description')}
        </Txt>
      </Surface>
    </Modal>
  );
};
