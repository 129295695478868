import { addonActions } from '@buy-viasat/redux/src/addons';
import {
  closingOffersActions,
  selectSkipClosingOffer,
  selectUpsertWithClosingOffersError,
} from '@buy-viasat/redux/src/closingOffers';
import { AddressType, Coordinates } from '@buy-viasat/redux/src/address';
import { DataLayerEvents, TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';
import {
  appActions,
  CustomerType,
  Modals,
  QueryParams,
  selectCustomerType,
  selectErrorModalAttempts,
  selectFeatureFlags,
  selectSalesAgreementId,
  selectSkipSkeletonLoaderForContactInformation,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import { navActions, Routes, ScheduleInstallLocations } from '@buy-viasat/redux/src/navigator';
import { scheduleInstallationActions } from '@buy-viasat/redux/src/schedule-installation';
import { planActions, selectPlanId, selectPlanOfferId, SetPlanAction } from '@buy-viasat/redux/src/plan';
import { selectCoordinates, selectServiceAddressValues } from '@buy-viasat/redux/src/serviceability';
import {
  GetAddOnsInput,
  GetClosingOffersInput,
  LocationInput,
  ProductType,
  ShoppingCartPlanInput,
} from '@buy-viasat/types/build/bv';
import { FeatureFlags } from '@buy-viasat/types/build/bv/generated/mock-schema-types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects';
import { benchmark } from 'shared/containers/App/saga';
import upsertCartPlanAsync, { UpsertCartPlanResponse } from '../../providers/requests/cart/upsertCartPlan';
import getAddonsAsync, { GetAddOnsPayload } from '../../providers/requests/products/getAddOns';
import getPlansAsync from '../../providers/requests/products/getPlans';
import clientLogger from '../../utils/clientLogger';
import { generateCart } from '../Cart/saga';
import { selectIsPersonalInformationRequestComplete } from '@buy-viasat/redux/src/personal-information';
import { cartActions } from '@buy-viasat/redux/src/cart/slice';
import getClosingOffersAsync from '../../providers/requests/products/getClosingOffers';
import { adobeTargetDataLayer, isDefined } from '@buy-viasat/utils';

/**
 *  Getting Available Products request/response handler
 *
 *  Generator typing (type of effects we yield, generator's return type, types yielded)
 *
 */
export function* getAvailableProductsSaga() {
  const { dealerId, partnerId } = yield select(selectUrlParams);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const location: LocationInput = { address: serviceAddress };
  if ([coordinates?.latitude, coordinates?.longitude].every(isDefined))
    location.coordinates = { latitude: coordinates.latitude, longitude: coordinates.longitude };
  const salesAgreementId: string = yield select(selectSalesAgreementId);
  const customerType: CustomerType = yield select(selectCustomerType);
  try {
    const { data } = yield call(getPlansAsync, { location, dealerId, partnerId, salesAgreementId, customerType });
    if (data.getAvailableProducts.length) {
      yield put(
        appActions.analyticsEvent({
          category: TrackingCategory.PLANS,
          action: TrackingActions.SUCCESSFUL,
          params: undefined,
        }),
      );
      yield put(planActions.setAvailableProducts(data.getAvailableProducts));
    } else {
      yield put(navActions.previous());
      yield put(planActions.setIsPlanLoading(false));
      yield put(appActions.setModalVisible(Modals.NO_AVAILABLE_PLANS));
    }
  } catch (err: any) {
    yield call(clientLogger, 'getAvailableProductsSaga: ', err);
    yield put(navActions.previous());
    yield put(planActions.setIsPlanLoading(false));
    yield put(appActions.setModalVisible(Modals.GET_PLANS_SERVER_ERROR));
  }
}

export function* getAvailableClosingOffersSaga() {
  const { offerId: closingOfferIdURLParam } = yield select(selectUrlParams);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const salesAgreementId: string = yield select(selectSalesAgreementId);
  const planOfferId: string = yield select(selectPlanOfferId);
  const planId: string = yield select(selectPlanId);

  const getClosingOffersInput: GetClosingOffersInput = {
    location: { address: serviceAddress, coordinates },
    offerId: planOfferId,
    rootProductId: planId,
    salesAgreementId,
    productIds: [closingOfferIdURLParam],
  };

  try {
    const {
      data: { getClosingOffers },
    } = yield call(getClosingOffersAsync, getClosingOffersInput);
    if (getClosingOffers.length) {
      adobeTargetDataLayer.push({
        event: DataLayerEvents.AA_EVENT,
        eventData: {
          event: 'closingOffersView',
          closingOffers: getClosingOffers.map((closingOffer: ProductType) => ({
            id: closingOffer.id,
            name: closingOffer.name,
          })),
        },
      });

      yield put(closingOffersActions.setAvailableClosingOffers(getClosingOffers));
      return getClosingOffers;
    } else {
      yield put(appActions.setModalVisible(Modals.CLOSING_OFFER_NOT_ELIGIBLE));
    }
  } catch (err: any) {
    yield put(appActions.setModalVisible(Modals.CLOSING_OFFER_NOT_ELIGIBLE));
    yield call(clientLogger, 'getAvailableClosingOffers: ', err);
  }
}

export function* planOnNextSaga(
  action: PayloadAction<SetPlanAction>,
): Generator<
  PutEffect | CallEffect | SelectEffect | AllEffect<CallEffect>,
  void,
  QueryParams &
    [UpsertCartPlanResponse, GetAddOnsPayload] &
    AddressType &
    Coordinates &
    FeatureFlags &
    void &
    CustomerType
> {
  const { displayAddonsPage, scheduleInstallLocation, enablingClosingOffersInBV } = yield select(selectFeatureFlags);
  const skipSkeletonLoaderForContactInformation: any = yield select(selectSkipSkeletonLoaderForContactInformation);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const salesAgreementId = yield select(selectSalesAgreementId);
  const { dealerId, partnerId, offerId: closingOfferIdURLParam } = yield select(selectUrlParams);

  try {
    const customerType: CustomerType = yield select(selectCustomerType);
    const isUpsertWithClosingOffersError: boolean = yield select(selectUpsertWithClosingOffersError);
    const attempts = yield select(selectErrorModalAttempts);

    const skipClosingOffer: boolean = yield select(selectSkipClosingOffer);

    const HAS_REACHED_MAX_RETRY_ATTEMPTS = attempts >= 3;

    const getAddOnsInput: GetAddOnsInput = {
      location: { address: serviceAddress, coordinates },
      rootProductId: action.payload.id,
      dealerId,
      partnerId,
      offerId: action.payload.offerId,
      salesAgreementId,
    };

    const inputForCart: ShoppingCartPlanInput = {
      planProductId: action.payload.id,
      location: { address: serviceAddress, coordinates },
      cartId: action.payload.offerId,
      salesAgreementId,
    };
    yield put(planActions.setIsUpsertPlanLoading(true));
    yield put(planActions.setHavePlansLoaded(false));

    customerType === CustomerType.BUSINESS
      ? (getAddOnsInput.productSegment = 'BUSINESS')
      : (getAddOnsInput.productSegment = 'RESIDENTIAL');

    if (
      !skipSkeletonLoaderForContactInformation &&
      !action.payload.retryNeeded &&
      !isUpsertWithClosingOffersError &&
      !skipClosingOffer
    ) {
      yield put(navActions.next());
    }

    yield put(cartActions.setCartId(action.payload.offerId));

    yield put(
      planActions.setPlanId({
        id: action.payload.id,
        extensionProducts: action.payload.extensionProducts,
        name: action.payload.name,
        planPrice: action.payload.planPrice,
        planPromo: action.payload.planPromo,
        inflectionPointText: action.payload.inflectionPointText,
        offerId: action.payload.offerId,
        productFamily: action.payload.productFamily,
        isRegulated: action.payload.isRegulated,
        isCafII: action.payload.isCafII,
        dataCap: action.payload.dataCap,
      }),
    );

    const planCalls = [call<any>(upsertCartPlanAsync, { input: inputForCart })];

    if (displayAddonsPage) {
      planCalls.push(call<any>(getAddonsAsync, getAddOnsInput));
    }

    if (enablingClosingOffersInBV && closingOfferIdURLParam && !HAS_REACHED_MAX_RETRY_ATTEMPTS && !skipClosingOffer) {
      const closingOfferResponse: ProductType[] = yield call<any>(getAvailableClosingOffersSaga);

      if (closingOfferResponse && closingOfferResponse?.length > 0) {
        const [firstClosingOffer] = closingOfferResponse;

        if (closingOfferIdURLParam === firstClosingOffer.id) {
          inputForCart.closingOfferProductId = firstClosingOffer.id;
        }
      } else {
        console.error('No closing offers retrieved');
      }
    }

    const result: [UpsertCartPlanResponse, GetAddOnsPayload] = yield all(planCalls);

    yield put(planActions.setIsUpsertPlanLoading(false));

    const [cart, addons] = result;

    if (!cart?.data?.upsertCartPlan) {
      yield put(planActions.setIsUpsertPlanLoading(false));
      throw new Error('Upsert cart failed');
    }
    const bundledAddons = cart?.data?.upsertCartPlan.bundledAddons ?? [];
    const addonsList = [...addons?.data?.getAddOns, ...bundledAddons];

    yield call<any>(generateCart, cart?.data?.upsertCartPlan);
    if (scheduleInstallLocation !== ScheduleInstallLocations.None) {
      yield put(scheduleInstallationActions.getInstallationDates(new Date().toString()));
    }
    yield put(addonActions.setAvailableAddons(addonsList));
    yield put(planActions.setIsPlanLoading(false));

    const isPersonalInformationRequestComplete = yield select(selectIsPersonalInformationRequestComplete);
    if (skipSkeletonLoaderForContactInformation) {
      if (addons?.data?.getAddOns.length === 0) {
        yield put(navActions.routeUserTo(Routes.PERSONAL_INFORMATION));
      } else {
        yield put(navActions.next());
      }
    } else if (isPersonalInformationRequestComplete) {
      yield put(navActions.next());
    }
  } catch (err: any) {
    if (enablingClosingOffersInBV && closingOfferIdURLParam) {
      yield put(closingOffersActions.setUpsertWithClosingOffersError(true));
      yield put(
        appActions.setErrorRetryAction({
          type: planActions.planOnNext.type,
          payload: { ...action.payload, retryNeeded: true },
        }),
      );
      yield put(closingOffersActions.setSkipClosingOffer(false));
      yield put(appActions.setModalVisible(Modals.UPSERT_WITH_CLOSING_OFFER_RETRY_ERROR));
    } else {
      clientLogger('planOnNextSaga Error: ', err);
      yield put(
        appActions.setErrorRetryAction({
          type: planActions.planOnNext.type,
          payload: { ...action.payload, retryNeeded: true },
        }),
      );
      yield put(
        appActions.setModalVisible(
          !skipSkeletonLoaderForContactInformation ? Modals.BACKGROUND_API_ERROR : Modals.ERROR,
        ),
      );
    }
    yield put(planActions.setIsUpsertPlanLoading(false));
    yield put(planActions.setIsPlanLoading(false));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* planSaga(): Generator<ForkEffect, void, void> {
  yield takeLatest(planActions.getPlans.type, benchmark(getAvailableProductsSaga));
  yield takeLatest(
    planActions.planOnNext.type,
    benchmark<
      SetPlanAction,
      PutEffect | CallEffect | SelectEffect | AllEffect<CallEffect>,
      void,
      [UpsertCartPlanResponse, GetAddOnsPayload] & AddressType & void
    >(planOnNextSaga),
  );
  yield takeLatest(planActions.getAvailableClosingOffers.type, benchmark(planOnNextSaga));
}
