import { Surface, ISurfaceProps } from '@vst/beam';

import styles from './divider.module.scss';

interface DividerProps extends Omit<ISurfaceProps, 'ref'> {
  position?: 'normal' | 'inside' | 'outside' | 'outside-2';
}

export const Divider = (props: DividerProps) => {
  const { position = 'normal', ...surfaceProps } = props;

  return (
    <Surface {...surfaceProps}>
      <hr className={`${styles['root']} ${styles[position]}`} />
    </Surface>
  );
};
