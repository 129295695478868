import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeTargetDataLayer } from '@buy-viasat/utils';
import { DataLayerEvents } from '@buy-viasat/redux/src/analytics';
import { KeyboardArrowDown, KeyboardArrowUp } from '@vst/beam-icons/icons';
import { Icon, InlineLink, Spacer, StandAloneLink, Surface, Txt } from '@vst/beam';

import styles from './ACPModal.module.scss';

export const ACPContent: FC = () => {
  const { t } = useTranslation(['serviceability']);
  const [showDetails, setShowDetails] = useState(false);

  const ACP_WEBSITE = `${t('ACPModal.FAQ.question.ACPWebsiteLink')}`;
  const LEARN_MORE = `${t('ACPModal.FAQ.learnMoreLink')}`;
  const APPLY_NOW = `${t('ACPModal.FAQ.applyNowLink')}`;

  const handleLearnMoreLink = () =>
    adobeTargetDataLayer.push({
      event: DataLayerEvents.AA_EVENT,
      eventData: {
        acpModal: {
          learnMoreLinkSelected: true,
        },
      },
    });

  const handleApplyNowLink = () =>
    adobeTargetDataLayer.push({
      event: DataLayerEvents.AA_EVENT,
      eventData: {
        acpModal: {
          applyNowLinkSelected: true,
        },
      },
    });

  return (
    <Surface>
      <Txt variant={'bodySmallRegular'} data-testid={'ACPModalHeaderTitle'}>
        {t('ACPModal.headerDescription')}
      </Txt>

      <Spacer y={'16px'} />
      <Surface variant={'secondary'} p={'16px'} radius={'8px'} data-testid={'ACPModalACPModalSlotContainer'}>
        <Surface variant={'secondary'} className={styles['FAQcontainer']}>
          <Txt variant={'heading6'} data-testid={'ACPModalFAQTitle'}>
            {t('ACPModal.FAQ.headerTitle')}
          </Txt>
          <div onClick={() => setShowDetails(!showDetails)} className={styles['AcpModalDetailsIcon']}>
            <InlineLink
              variant={'secondary'}
              href={'#'}
              onClick={(e) => e.preventDefault()}
              data-testid={'ACPModalFAQDetailsLink'}
              pr={'8px'}
              className={styles['smallFontSizeLink']}
            >
              {t('ACPModal.FAQ.headerDetails')}
            </InlineLink>
            <Icon
              icon={!showDetails ? KeyboardArrowDown : KeyboardArrowUp}
              size={'16px'}
              data-testid={'ACPModalFAQDetailsIcon'}
            />
          </div>
        </Surface>

        {showDetails && (
          <div className={styles['ACPModalShowDetailsContainer']}>
            <Spacer y={'24px'} />
            <Txt
              variant={'bodySmallBold'}
              data-testid={'ACPModalSlotQuetionsTitle'}
              className={styles['eligibleTitle']}
            >
              {t('ACPModal.FAQ.question.headerTitle')}
            </Txt>
            <Spacer y={'16px'} />
            <Txt variant={'smallRegular'} data-testid={'ACPModalSlotQuestionsDescription'}>
              {t('ACPModal.FAQ.question.description')}
              <InlineLink
                href={ACP_WEBSITE}
                target={'_blank'}
                style={{ display: 'inline' }}
                className={styles['smallFontSizeLink']}
                data-testid={'ACPModalSlotQuestionsACPWebsiteLink'}
              >
                {t('ACPModal.FAQ.question.descriptionLink')}
              </InlineLink>
            </Txt>

            <Spacer y={'24px'} />
            <StandAloneLink
              linkSize={'small'}
              href={LEARN_MORE}
              target="_blank"
              data-testid={'ACPModalFAQLearnMoreLink'}
              onClick={handleLearnMoreLink}
              underline
            >
              {t('ACPModal.FAQ.learnMoreText')}
            </StandAloneLink>
            <Spacer y={'24px'} />
            <StandAloneLink
              linkSize={'small'}
              href={APPLY_NOW}
              target={'_blank'}
              data-testid={'ACPModalFAQApplyNowLink'}
              onClick={handleApplyNowLink}
              underline
            >
              {t('ACPModal.FAQ.applyNowText')}
            </StandAloneLink>
          </div>
        )}
      </Surface>

      <Spacer y={'24px'} />
      <Txt variant={'heading6'} data-testid={'ACPModalFooterTitle'}>
        {t('ACPModal.FAQ.footerTitle')}
      </Txt>

      <Spacer y={'24px'} />
      <Txt variant={'bodySmallRegular'} data-testid={'ACPModalFooterDescription'}>
        {t('ACPModal.FAQ.footerDescription')}
      </Txt>
    </Surface>
  );
};
