import gql from 'graphql-tag';
import { FetchResult } from '@apollo/client';
import { CreateCustomerInput, CreateCustomerResponse } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type CreateCustomerResult = FetchResult<{ createCustomer: CreateCustomerResponse }>;

const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      isCustomer
      existingContactMethod
      individualPartyId
      organizationPartyId
      relnId
    }
  }
`;

export default async function createCustomer(input: CreateCustomerInput): Promise<CreateCustomerResult> {
  return client.mutate({
    mutation: CREATE_CUSTOMER,
    variables: {
      input,
    },
  });
}
