import clientLogger from './clientLogger';
import { ScriptId } from '@buy-viasat/redux/src/app';

export const loadAdobeTargetScript = async (script: { src: string; id: ScriptId; onLoad: () => void }) => {
  try {
    return await new Promise<void>((resolve, reject) => {
      const existingScript = document.getElementById(script.id);

      if (!existingScript) {
        addPreHidingSnippet();
        const scriptElement = document.createElement('script');
        scriptElement.src = script.src;
        scriptElement.id = script.id;
        scriptElement.async = true;

        scriptElement.onload = () => {
          removePreHidingSnippet();
          script.onLoad();
          resolve();
        };

        scriptElement.onerror = (error) => {
          console.error('Error loading script:', error);
          removePreHidingSnippet();
          reject(error);
        };
        document.body.appendChild(scriptElement);
      } else {
        clientLogger('script already loaded - loadAdobeTargetScript', script.id);
        script.onLoad();
        resolve();
      }
    });
  } catch (catchError) {
    console.error('Promise rejected:', catchError);
  }
};

// Pre-hiding snippet functions
const addPreHidingSnippet = () => {
  const style = document.createElement('style');
  style.id = 'at-body-style';
  style.innerHTML = 'body { visibility: hidden !important }';
  document.head.appendChild(style);
};

const removePreHidingSnippet = () => {
  const style = document.getElementById('at-body-style');
  if (style) {
    document.head.removeChild(style);
  }
};
