import { useState, useEffect, useRef } from 'react';
import { Surface, Txt, ISurfaceProps } from '@vst/beam';
import styles from './loadingSpinner.module.scss';
export * from './FullPageLoadingSpinner';

export type LoadingSpinnerProps = Omit<ISurfaceProps, 'size' | 'ref'> & {
  text?: string;
  heading?: string | string[];
  size: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  timer?: number; // in milliseconds
};

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { text, size, timer = 2000, color = 'primary', heading, ...surfaceProps } = props;
  const spinnerRef = useRef(null);

  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);

  useEffect(() => {
    if (!Array.isArray(heading)) return;
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((index) => (index + 1) % heading.length);
    }, timer);
    return () => clearInterval(intervalId);
  }, [heading, timer]);

  const headingTitle = Array.isArray(heading) ? heading[currentMessageIndex] : heading;

  return (
    <Surface
      className={`${styles['root']} ${styles[size]} ${styles[color]}`}
      data-testid="loadingSpinner"
      {...surfaceProps}
      ref={spinnerRef}
    >
      <svg viewBox="25 25 50 50" strokeWidth="5" className={styles['svg']}>
        <circle cx="50" cy="50" r="20" />
      </svg>
      {headingTitle ? (
        <Txt color="subtle" variant="bodyLargeBold" data-testid="loadingSpinnerText" className={styles['headingText']}>
          {headingTitle}
        </Txt>
      ) : null}
      {text ? (
        <Txt color="subtle" variant="bodyLargeRegular" data-testid="warningMessage" className={styles['text']}>
          {text}
        </Txt>
      ) : null}
    </Surface>
  );
};
