import { DateFormat } from '@buy-viasat/utils';
import { Country } from '@buy-viasat/types/build/bv';

export const lastFourRegex = /^\d{4}$/;
export const BrTaxIdRegex = /^\d{3}.\d{3}.\d{3}-\d{2}$/;
export const alphaRegex = /^[A-Za-z\s]+$/; // allows spaces/tabs
export const businessNameRegex = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@$#/.() ]{2,}$/;

export const emailRegex =
  /^[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+([.]?[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~])+[@]{1}[A-Za-z0-9]{2,}([.-]{1}[A-Za-z0-9]{2,})*[.]{1}[A-Za-z]{2,}([.]{1}[A-Za-z]{2,})?$/;

export const streetRegex = /^[0-9a-zA-Z.,\u0080-\u017f\- #]+$/;
export const numberRegex = /^[0-9]+$/;
export const alphaNumericRegex = /^[0-9A-Za-z]+$/;
export const dateRegexMDY = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;
export const dateRegexDMY = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
export const subsidyApplicationIdRegex = /^[A-Z]{1}[\d]{5}-[\d]{5}$/;

export const phoneNumberRegex = {
  [Country.US]: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  [Country.BR]: /^\+\d{2}[\s.-]\d{2}[\s.-](\d{4}|\d{5})[\s.-]\d{4}$/,
  [Country.IT]: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
};

export const mobileNumberRegex = {
  [Country.US]: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  [Country.BR]: /^\+\d{2}[\s.-]\d{2}[\s.-]\d{5}[\s.-]\d{4}$/,
  [Country.IT]: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
};

export const idNumberRegex = {
  [Country.US]: /^\d{4}$/,
  [Country.BR]: /^\d{3}.\d{3}.\d{3}-\d{2}$/,
  [Country.IT]: /^\d{4}$/,
};

export const zipCodeRegex = {
  [Country.US]: /^[0-9]{5}(?:-[0-9]{4})?$/,
  [Country.BR]: /^\d{5}-?\d{3}$/,
  [Country.IT]: /^[0-9]{5}$/,
};

export const houseNumberRegex = /^([a-zA-Z0-9]){1,5}$/;

export const dateRegex = (dateFormat?: DateFormat): RegExp => {
  switch (dateFormat) {
    case DateFormat.DMY:
      return dateRegexDMY;
    default:
      return dateRegexMDY;
  }
};

export const taxIdCountryRegex = {
  [Country.US]: lastFourRegex,
  [Country.BR]: BrTaxIdRegex,
  [Country.IT]: /^[A-Z]{6}[0-9]{2}[A-Z]{1}[0-9]{2}[A-Z]{1}[0-9]{3}[A-Z]{1}$/,
};

export const dobRegex = {
  day: /^(0?[0-9]|[12][0-9]|3[01])$/,
  month: /^(0?[0-9]|1[012])$/,
  year: /^\d{0,4}$/,
};

export const smbTaxIDRegex = /^[0-9]{2}\-[0-9]{7}$/;
export const smbSSNRegex = /^[0-9]{3}\-[0-9]{2}\-[0-9]{4}$/;
