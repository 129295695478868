import { useSelector } from 'react-redux';
import { selectEnv } from '@buy-viasat/redux/src/env';
import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { AemFeature, createAemUrl } from '@buy-viasat/utils';
import { selectCustomerType } from '@buy-viasat/redux/src/app';

import ContentProvider from '../ContentProvider';

export const MaintenanceMode = () => {
  const { aemUrl } = useSelector(selectEnv);
  const customerType = useSelector(selectCustomerType);
  const contentUrl = createAemUrl(aemUrl, i18n.language, AemFeature.MAINTENANCE_MODE, 'master', customerType);

  return <ContentProvider src={contentUrl} />;
};
