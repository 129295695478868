import gql from 'graphql-tag';
import { FetchResult } from '@apollo/client';
import {
  AuthAndVoidCardPaymentVerificationInput,
  VppAuthVoidResponse,
} from '@buy-viasat/types/build/bv/generated/mock-schema-types';
import { client } from 'shared/containers/apollo';
import { TransactionPayload } from '@buy-viasat/types/src/bv';

export type AuthVoidResponse = FetchResult<{ authAndVoidCardPaymentVerification: VppAuthVoidResponse }>;

const AUTH_VOID_CARD_TRANSACTION = gql`
  query authAndVoidCardPaymentVerification($input: AuthAndVoidCardPaymentVerificationInput) {
    authAndVoidCardPaymentVerification(input: $input) {
      transactionId
      authVoidSuccessful
    }
  }
`;

export default async function authAndVoidCardPaymentVerificationAsync(
  options: TransactionPayload,
): Promise<AuthVoidResponse> {
  return client.query({
    query: AUTH_VOID_CARD_TRANSACTION,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        paymentOnFileId: options.paymentOnFileId,
        customerRef: options.relnId,
        spbBillingAccountId: options.spbBillingAccountId,
        txnAmount: options.oneTimeTotal,
        userAgent: options.userAgent,
        transactionId: options.transactionId,
      } as AuthAndVoidCardPaymentVerificationInput,
    },
  });
}
