import { initialAddonsState, addonsReducer } from './addons';
import { initialAddressState, addressReducer } from './address';
import { initialAppState, appReducer } from './app';
import { initialAuthState, authReducer } from './auth';
import { initialBusinessInformationState, businessInformationReducer } from './business-information';
import { initialCartState, cartReducer } from './cart';
import { initialClosingOffersState, closingOffersReducer } from './closingOffers';
import { initialCreditVerificationState, creditVerificationReducer } from './credit-verification';
import { initialEnvState, envReducer } from './env';
import { initialNavState, navReducer } from './navigator';
import { initialPaymentInformationState, paymentInformationReducer } from './payment-information';
import { initialPersonalInformationState, personalInformationReducer } from './personal-information';
import { initialPlanState, planReducer } from './plan';
import { initialScheduleInstallationState, scheduleInstallationReducer } from './schedule-installation';
import { initialServiceabilityState, serviceabilityReducer } from './serviceability';
import { initialSubsidyProgramState, subsidyProgramReducer } from './subsidy-program';
import { RootState } from './types';

export const initialRootState: RootState = {
  addons: initialAddonsState,
  closingOffers: initialClosingOffersState,
  address: initialAddressState,
  app: initialAppState,
  auth: initialAuthState,
  businessInformation: initialBusinessInformationState,
  cart: initialCartState,
  creditVerification: initialCreditVerificationState,
  env: initialEnvState,
  navigator: initialNavState,
  paymentInformation: initialPaymentInformationState,
  personalInformation: initialPersonalInformationState,
  plan: initialPlanState,
  scheduleInstallation: initialScheduleInstallationState,
  serviceability: initialServiceabilityState,
  subsidyProgram: initialSubsidyProgramState,
};

export const rootReducers = {
  addons: addonsReducer,
  closingOffers: closingOffersReducer,
  address: addressReducer,
  app: appReducer,
  auth: authReducer,
  businessInformation: businessInformationReducer,
  cart: cartReducer,
  creditVerification: creditVerificationReducer,
  env: envReducer,
  navigator: navReducer,
  paymentInformation: paymentInformationReducer,
  personalInformation: personalInformationReducer,
  plan: planReducer,
  scheduleInstallation: scheduleInstallationReducer,
  serviceability: serviceabilityReducer,
  subsidyProgram: subsidyProgramReducer,
};
