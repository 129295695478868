import { Icon, Surface, Tooltip, Txt } from '@vst/beam';
import InfoOutline from '@vst/beam-icons/icons/InfoOutline';
import styles from './dateOfBirthInput.module.scss';

type DateOfBirthLabelProps = {
  labelText: string;
  tooltipText?: string;
};

export const DateOfBirthLabel = ({ labelText, tooltipText }: DateOfBirthLabelProps) => {
  return (
    <Surface className={styles['labelContainer']} pb="8px" data-testid="tooltipLabelTextContainer">
      <Txt variant="labelSmall" color="subtle" className={styles['labelText']} data-testid="tooltipLabelText">
        {labelText}
      </Txt>
      {tooltipText && (
        <Tooltip
          position="right"
          beakPosition="start"
          variant="description"
          bodyText={tooltipText ?? ''}
          data-testid="tooltipContainer"
        >
          <Icon icon={InfoOutline} size="16px" data-testid="tooltipIcon" />
        </Tooltip>
      )}
    </Surface>
  );
};
