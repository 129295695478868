import { GetWorkOrderInput, ScheduleR0, Maybe } from '@buy-viasat/types/build/bv';
import getWorkOrderAsync from 'shared/providers/requests/fulfillment/getWorkOrder';
import { UNSCHEDULED } from './types';
import { AvailableInstallationDates } from '@buy-viasat/redux/src/schedule-installation';

export async function isUnscheduledWorkOrderAsync(input: GetWorkOrderInput): Promise<boolean> {
  try {
    if (input.externalWorkOrderId === null) {
      throw new Error('workOrderId is null');
    }
    const { data } = await getWorkOrderAsync(input);
    if (data?.getWorkOrder?.status !== UNSCHEDULED) {
      throw new Error('Status is not unscheduled');
    } else {
      return true;
    }
  } catch (err: any) {
    return false;
  }
}

export const toTimeslot = (datetime: string, isFrom: boolean): string => {
  const hours = new Date(datetime).getHours();
  const suffix = (): string => {
    if (isFrom) return '';
    else return hours >= 12 ? ' PM' : ' AM';
  };
  return `${hours % 12 || 12}${suffix()}`;
};

export const dateStringToObj = (
  installationDates: Maybe<ScheduleR0>[],
  timezone: string,
): AvailableInstallationDates => {
  const dates: AvailableInstallationDates = {};
  installationDates.forEach((date) => {
    const fromDate: Date = new Date(date?.from ?? '');
    const month = fromDate.getMonth() + 1;
    const year = fromDate.getFullYear();
    const day = fromDate.getDate();
    const from = toTimeslot(date?.from ?? '', true);
    const to = toTimeslot(date?.to ?? '', false);
    if (
      Object.values(dates)
        .map((date) => date.day)
        .includes(day) &&
      Object.values(dates)
        .map((date) => date.month)
        .includes(month) &&
      Object.values(dates)
        .map((date) => date.year)
        .includes(year)
    ) {
      dates[`${month}-${day}-${year}`].timeSlots[`${from}-${to}`] = { from, to, selected: false };
    } else {
      dates[`${month}-${day}-${year}`] = {
        month,
        year,
        day,
        timezone,
        timeSlots: { [`${from}-${to}`]: { from, to, selected: false } },
      };
    }
  });
  return dates;
};
