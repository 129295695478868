import { ScriptId } from '@buy-viasat/redux/src/app';
import { envActions } from '@buy-viasat/redux/src/env';
import { scheduleInstallationActions } from '@buy-viasat/redux/src/schedule-installation';
import '@vst/beam/index.scss';
import env from 'env';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ViasatTheme } from 'shared/assets/styles/theme';
import { AnalyticsProvider } from 'shared/containers/Analytics';
import { appActions } from '@buy-viasat/redux/src/app';
import { Navigator } from 'shared/Navigator';
import ThemeContext from 'shared/providers/hooks/ThemeContext';
import { pixel, pixelEventLogFunction, pixelTrackScreenFunction } from 'shared/services/pixel';
import { persistor, store } from 'shared/store';
import history from 'shared/utils/history';
import { loadAdobeTargetScript } from 'shared/utils/loadAdobeTargetScript';

const App = (): JSX.Element => {
  useEffect((): void => {
    const loadAdobeTargetAndInitPixel = async () => {
      try {
        await loadAdobeTargetScript({
          src: env.adobeTargetScript,
          id: ScriptId.ADOBE_TARGET,
          onLoad: () => {
            // Pixel initialization.
            pixel('newTracker', 'cf', 'mvr.atg-sp.viasat.io', {
              appId: env.pixel.namespace,
              cookieDomain: env.pixel.cookieDomain,
              eventMethod: 'get',
              forceSecureTracker: true,
            });
          },
        });
      } catch (error) {
        console.error('Error loading script:', error);
      }
    };

    loadAdobeTargetAndInitPixel();

    // set env -- only enter necessary items here.  PLEASE AVOID ACCESS KEYS
    store.dispatch(envActions.setEnv({ aemUrl: env.aemUrl }));
    // Set build version
    store.dispatch(appActions.setBuildVersionId(env.buildVersion));
    // Collect date
    store.dispatch(scheduleInstallationActions.setToday(new Date()));
    // Log git sha
    console.log(process.env.REACT_APP_GIT_SHA);
  }, []);

  return (
    <AnalyticsProvider
      logEvents={[pixelEventLogFunction]}
      trackScreens={[pixelTrackScreenFunction]}
      startTime={new Date(store.getState().startTime)}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history as any}>
            <ThemeContext.Provider value={ViasatTheme}>
              <Navigator />
            </ThemeContext.Provider>
          </Router>
        </PersistGate>
      </Provider>
    </AnalyticsProvider>
  );
};

export default App;
