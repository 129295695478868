export enum VPPIFrameErrorMessages {
  VPS_ERROR_CARD_HOLDER_BLANK = 'Please enter the Name on card',
  VPS_ERROR_CARD_NUMBER_BLANK = 'Please enter the Credit Card Number',
  VPS_ERROR_CARD_EXP_MONTH_BLANK = 'Please enter the Card Expiration Month',
  VPS_ERROR_CARD_CVV_BLANK = 'Please enter the Security Code',
  VPS_ERROR_CARD_NUMBER_INVALID = 'Please enter a valid Credit Card Number',
  VPS_ERROR_CARD_EXP_MONTH_INVALID = 'Please enter a valid Card Expiration Month',
  VPS_ERROR_CARD_CVV_INVALID = 'Please enter a valid Security Code',
  VPS_ERROR_CARD_EXPIRED = 'Please use an active credit card',
  VPS_ERROR_ACH_FIRST_NAME_BLANK = 'Please include the account holder first name',
  VPS_ERROR_ACH_LAST_NAME_BLANK = 'Please include the account holder last name',
  VPS_ERROR_ACH_ACCT_NUMBER_BLANK = 'Please enter the account number',
  VPS_ERROR_ACH_ROUTING_NUMBER_BLANK = 'Please enter the routing number',
  VPS_ERROR_ACH_FIRST_NAME_INVALID = 'Please enter a valid first name',
  VPS_ERROR_ACH_LAST_NAME_INVALID = 'Please enter a valid last name',
  VPS_ERROR_ACH_ACCT_NUMBER_INVALID = 'Please enter a valid account number',
  VPS_ERROR_ACH_ROUTING_NUMBER_INVALID = 'Please enter a valid routing number',
  VPS_ERROR_CANNOT_PROCESS = 'Cannot process this request',
}

export enum VPPUserAgent {
  API = 'API',
}

export enum VPPCustomerRef {
  BUY_VIASAT = 'BuyViasat',
}

export enum VPPSystemName {
  US_RESIDENTIAL = 'USResidential',
  US_BUSINESS = 'USBusiness',
  BR_RESIDENTIAL = 'BrazilResidential',
  IT_Residential = 'ItalyResidential',
  EU_Residential = 'EuResidential',
}

export enum VPPTxnType {
  AUTHORIZE = 'Authorize',
  SALE = 'Sale',
  PREAUTHORIZE = 'Preauthorize',
}

export enum VPPLanguageCode {
  US_ENGLISH = 'en-US',
  BR_ENGLISH = 'en-BR',
  BR_PORTUGUESE = 'pt-BR',
  IT_ITALIAN = 'it',
}

enum VPPCurrencyCode {
  US_DOLLAR = 'USD',
}

export enum VPPUrl {
  PAYMENT_SERVICE = '/services/apexrest/paymentservice',
  PAYMENT_ON_FILE = '/services/apexrest/paymentonfile',
}

export type VPPBody = {
  systemName: VPPSystemName;
  txnType: VPPTxnType;
  txnAmount: number;
  userAgent: VPPUserAgent;
  customerRef?: VPPCustomerRef;
  returnURL: string;
  cancelURL: string;
  languageCode: VPPLanguageCode;
  currencyIsoCode: VPPCurrencyCode;
  additionalDetails?: Record<string, unknown>;
  billingAddress?: VPPBillingAddress;
  email?: string;
};

export const defaultVPPMessageBody: VPPBody = {
  userAgent: VPPUserAgent.API,
  customerRef: VPPCustomerRef.BUY_VIASAT,
  returnURL: '',
  cancelURL: '',
  systemName: VPPSystemName.US_RESIDENTIAL,
  txnAmount: 0,
  txnType: VPPTxnType.PREAUTHORIZE,
  languageCode: VPPLanguageCode.US_ENGLISH,
  currencyIsoCode: VPPCurrencyCode.US_DOLLAR,
  additionalDetails: {},
} as VPPBody;

export type VPPBillingAddress = {
  street: string;
  stateOrProvince: string;
  postalCode: string;
  houseNumberOrName: string;
  country: string;
  city: string;
};

export type VPPRetrievePaymentResponse = {
  userAgent: VPPUserAgent;
  txnType: VPPTxnType;
  txnStatus: string;
  txnId: string;
  txnAmount: number;
  systemName: VPPSystemName;
  secondaryTxns: Array<any>;
  returnURL: string;
  rescueStatus: string;
  recurringPayment: boolean;
  paymentSource: string;
  paymentProduct: string;
  paymentMethod: string;
  paymentInfoSource: string;
  parentTxn: string;
  LastModifiedDate: string;
  languageCode: VPPLanguageCode;
  email: string;
  customerRef: VPPCustomerRef;
  currencyIsoCode: VPPCurrencyCode;
  createdDate: string;
  correlationId: string;
  cancelURL: string;
  billingAddress: VPPBillingAddress;
  additionalDetails: any;
};
