import { Trans, useTranslation } from 'react-i18next';
import { Icon, Txt, Surface, Button } from '@vst/beam';
import { Modal } from '@buy-viasat/react/src/components/Modal';
import styles from './removeOptionalServiceModal.module.scss';
import { useSelector } from 'react-redux';
import { selectAddonMarkedForRemoval } from '@buy-viasat/redux/src/addons';
import { selectCartItems } from '@buy-viasat/redux/src/cart';
import { CartProduct } from '@buy-viasat/types/build/bv';
import Check from '@vst/beam-icons/icons/Check';

type PaymentErrorModalProps = {
  primaryAction: () => void;
  secondaryAction: (optionalService: CartProduct) => void;
};

export const RemoveOptionalServiceModal = (props: PaymentErrorModalProps) => {
  const { primaryAction, secondaryAction } = props;

  const { t } = useTranslation(['addons']);

  const catalogOptionalService = useSelector(selectAddonMarkedForRemoval);
  const cartOptionalServices = useSelector(selectCartItems);

  if (!catalogOptionalService || !cartOptionalServices) {
    return null;
  }

  const cartOptionalService = cartOptionalServices.addons.find(
    (addon) => addon?.productTypeId === catalogOptionalService.id,
  );
  if (!cartOptionalService) {
    return null;
  }

  const optionalServiceBenefits = catalogOptionalService.attributes?.filter((a): a is string => !!a) || [];

  const handleRemoveAddon = () => {
    secondaryAction(cartOptionalService);
  };

  return (
    <Modal
      headerTitle={
        <Trans
          t={t}
          i18nKey="common.removeOptionalServiceModal.title.text"
          values={{ ADDON_NAME: catalogOptionalService.name }}
          components={[<span className={styles['title-addon-name']} />]}
        />
      }
      primaryLabelText={t('common.removeOptionalServiceModal.primaryAction.label', {
        ADDON_NAME: catalogOptionalService.name,
      })}
      secondaryLabelText={t('common.removeOptionalServiceModal.secondaryAction.label')}
      onClose={primaryAction}
      primaryAction={primaryAction}
      secondaryAction={handleRemoveAddon}
      alignTextToLeft={true}
      className={styles['modal']}
      showPrimaryButtonFullWidth={true}
      data-testid="removeOptionalServiceModal"
      overlayWrapProps={{ className: styles['overlayWrap'] }}
    >
      {optionalServiceBenefits.length ? (
        <Surface className={styles['addonsContainer']}>
          <Txt
            variant={'bodySmallRegular'}
            className={styles['subTitle']}
            data-testid="removeOptionalServiceBenefitsTitle"
          >
            <Trans
              t={t}
              i18nKey="common.removeOptionalServiceModal.subtitle.text"
              values={{ ADDON_NAME: catalogOptionalService.name }}
              components={[<span className={styles['subtitle-addon-name']} />]}
            />
          </Txt>
          <div className={styles['benefitsList']} data-testid="removeOptionalServiceBenefitsList">
            {optionalServiceBenefits.map((benefit, index) => (
              <p key={index} className={styles['benefitsItem']} data-testid="removeOptionalServiceBenefitsItem">
                <Icon icon={Check} color="green_600" size={20} />
                <Txt component="span">
                  <Txt ml="4px" variant="smallRegular" data-testid="removeOptionalServiceModalAttribute">
                    {benefit}
                  </Txt>
                  {catalogOptionalService.crossOuts?.[index] ? (
                    <Txt variant="smallRegular">
                      &nbsp;(<span className={styles['crossoutItem']}>{catalogOptionalService.crossOuts[index]}</span>)
                    </Txt>
                  ) : null}
                </Txt>
              </p>
            ))}
          </div>
        </Surface>
      ) : (
        <></>
      )}
    </Modal>
  );
};
