import { ApolloQueryResult, gql } from '@apollo/client';
import { GetPartnerAccountInputR0 } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type PartnerAccountResponse = ApolloQueryResult<{ getAvailableProducts: PartnerAccountResponse }>;

const GET_PARTNER_ACCOUNT = gql`
  query getPartnerAccount($input: GetPartnerAccountInputR0) {
    getPartnerAccount(input: $input) {
      salesAgreementId
      fulfillmentAgreementId
    }
  }
`;

export default async function getPartnerAccountAsync({
  location,
  dealerId,
  partnerIraPartyId,
}: GetPartnerAccountInputR0): Promise<PartnerAccountResponse> {
  return client.query({
    query: GET_PARTNER_ACCOUNT,
    variables: { input: { location, dealerId, partnerIraPartyId } },
  });
}
