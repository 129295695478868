export enum COOKIE_NAME {
  dealerId = 'DID',
  partnerId = 'PAID',
  userLDKey = 'LDKEY',
}

export const getCookie = (name: string): string | undefined => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (!match) return;
  return match[2];
};

export const setCookie = (name: string, value: string, expirationInDays: number, path = '/') => {
  const date = new Date();
  date.setTime(date.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=${path}`;
};
