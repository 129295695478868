import { createSelector } from 'reselect';
import { RootState } from '../types';
import { initialScheduleInstallationState, ScheduleInstallationState } from './types';

export const selectScheduleInstallationDomain = (state: RootState): ScheduleInstallationState => {
  return state?.scheduleInstallation || initialScheduleInstallationState;
};

export const selectLoadingInstallationDates = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => state.loadingInstallationDates,
);
export const selectScheduleInstallationIsButtonDisabled = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => state.isButtonDisabled,
);
export const selectToday = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => state.today,
);
export const selectSelectedInstallation = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => state.selectedInstallation,
);
export const selectAvailableInstallationDates = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => state.availableInstallationDates,
);
export const selectNoAvailableInstallationDates = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => Object.keys(state.availableInstallationDates).length === 0,
);
export const selectCalendarDate = createSelector(
  [selectScheduleInstallationDomain],
  (state: ScheduleInstallationState) => state.calendarDate,
);
export const selectScheduleInstallationValid = createSelector(
  [selectSelectedInstallation],
  ({ value: { month, day, year, arrivalWindow } }) => month && day && year && arrivalWindow,
);
export const selectShowCalendar = createSelector([selectScheduleInstallationDomain], (state) => state.showCalendar);
export const selectHasSubmittedScheduleInstall = createSelector(
  [selectScheduleInstallationDomain],
  (state) => state.hasSubmittedScheduleInstall,
);
export const selectEditInstallationSuccess = createSelector(
  [selectScheduleInstallationDomain],
  (state) => state.editInstallationSuccess,
);
