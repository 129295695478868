import { RESET_STATE_ACTION } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../utils';
import {
  AvailableInstallationDates,
  CalendarDate,
  defaultInstallationDate,
  initialScheduleInstallationState,
  InstallationDate,
  ScheduleInstallationState,
  SelectedInstallation,
} from './types';
import { decrementCalendarMonth, incrementCalendarMonth } from './utils';

const scheduleInstallationSlice = createSlice({
  name: 'scheduleInstallation',
  initialState: initialScheduleInstallationState,
  reducers: {
    advanceMonth(state: ScheduleInstallationState): void {
      const { month, year } = state.calendarDate;
      state.calendarDate = incrementCalendarMonth(month, year, 1);
    },
    getInstallationDates(state: ScheduleInstallationState, action: PayloadAction<string>) {
      state.loadingInstallationDates = true;
    },
    getInstallationDatesError(state: ScheduleInstallationState): void {
      state.loadingInstallationDates = false;
      state.availableInstallationDates = {} as AvailableInstallationDates;
    },
    onNextButtonComplete(state: ScheduleInstallationState): void {
      state.loadingInstallationDates = false;
      state.isButtonDisabled = false;
    },
    retractMonth(state: ScheduleInstallationState): void {
      const { month, year } = state.calendarDate;
      state.calendarDate = decrementCalendarMonth(month, year, 1);
    },
    editInstallationDatesLoading(state: ScheduleInstallationState): void {
      state.loadingInstallationDates = true;
    },
    setInstallationDatesLoading(state: ScheduleInstallationState): void {
      state.loadingInstallationDates = true;
    },
    installationDatesLoadingComplete(state: ScheduleInstallationState): void {
      state.loadingInstallationDates = false;
    },
    editInstallationDates(state: ScheduleInstallationState): void {
      state.loadingInstallationDates = true;
    },
    // SETTERS START
    deselectInstallationDate(state: ScheduleInstallationState): void {
      state.selectedInstallation.value = { ...state.selectedInstallation.value, ...defaultInstallationDate };
    },
    setCalendarDate(state: ScheduleInstallationState, action: PayloadAction<CalendarDate>): void {
      state.calendarDate = action.payload;
    },
    setIsButtonDisabled(state: ScheduleInstallationState, action: PayloadAction<boolean>): void {
      state.isButtonDisabled = action.payload;
    },
    setToday(state: ScheduleInstallationState, action: PayloadAction<Date>): void {
      const day = action.payload.getDate();
      const month = action.payload.getMonth() + 1;
      const year = action.payload.getFullYear();
      state.today = { month, day, year };
      state.calendarDate = { month, year };
    },
    setSelectedInstallation(state: ScheduleInstallationState, action: PayloadAction<SelectedInstallation>): void {
      state.selectedInstallation.value = action.payload;
    },
    setSelectedInstallationDate(state: ScheduleInstallationState, action: PayloadAction<InstallationDate>): void {
      state.selectedInstallation.value = { ...state.selectedInstallation.value, ...action.payload };
    },
    setAvailableInstallationDates(
      state: ScheduleInstallationState,
      action: PayloadAction<AvailableInstallationDates>,
    ): void {
      state.loadingInstallationDates = false;
      state.availableInstallationDates = action.payload;
    },
    toggleShowCalendar(state: ScheduleInstallationState): void {
      if (state.showCalendar) {
        state.selectedInstallation = initialScheduleInstallationState.selectedInstallation;
      }
      state.showCalendar = !state.showCalendar;
    },
    setHasSubmittedScheduleInstall(state: ScheduleInstallationState, action: PayloadAction<boolean>): void {
      state.hasSubmittedScheduleInstall = action.payload;
    },
    setEditInstallationSuccess(state: ScheduleInstallationState, action: PayloadAction<boolean | null>): void {
      state.editInstallationSuccess = action.payload;
    },
    resetInstallationDates(state: ScheduleInstallationState): void {
      state.selectedInstallation = initialScheduleInstallationState.selectedInstallation;
    },
    //  SETTERS END
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): ScheduleInstallationState => initialScheduleInstallationState,
  },
});

export const {
  actions: scheduleInstallationActions,
  reducer: scheduleInstallationReducer,
  name: scheduleInstallationKey,
} = scheduleInstallationSlice;
