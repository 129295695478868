export const arrivalWindows: Array<ArrivalWindowType> = [
  { slot: '8-11 AM', selected: false, disabled: false, isoFrom: '08:00:00', isoTo: '11:00:00' },
  { slot: '11-2 PM', selected: false, disabled: false, isoFrom: '11:00:00', isoTo: '14:00:00' },
  { slot: '2-5 PM', selected: false, disabled: false, isoFrom: '14:00:00', isoTo: '17:00:00' },
];

export type TimeSlot = {
  from: string;
  to: string;
  selected: boolean;
};

export type ArrivalWindowType = {
  slot: string;
  selected: boolean;
  disabled: boolean;
  isoFrom: string;
  isoTo: string;
};

export type Appointment = Omit<TimeSlot, 'selected'> & {
  availableAppointmentId: string;
  timezone: string;
};

export enum DaysOfWeek {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export enum CarouselDirection {
  PREVIOUS,
  NEXT,
}

export const UNSCHEDULED = 'UNSCHEDULED';
export const GET_WORK_ORDER_RETRY_COUNT = 3;
