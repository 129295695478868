import { ApolloQueryResult, gql } from '@apollo/client';
import { ProductType, GetClosingOffersInput, ClosingOffer } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type GetClosingOffersPayload = ApolloQueryResult<{ getClosingOffers: ClosingOffer[] }>;

export const GET_CLOSING_OFFERS = gql`
  query getClosingOffers($input: GetClosingOffersInput!) {
    getClosingOffers(input: $input) {
      id
      name
      price
      promo {
        duration
        unit
      }
    }
  }
`;

export default async function getClosingOffersAsync(input: GetClosingOffersInput): Promise<GetClosingOffersPayload> {
  return client.query({
    query: GET_CLOSING_OFFERS,
    variables: { input },
  });
}
