import { CoordinatesInput, Country, ExistingContactMethod, Maybe } from '@buy-viasat/types/build/bv';
import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { PayloadAction } from '@reduxjs/toolkit';
import { AddressType, LocationType } from '../address';
import { RESET_STATE_ACTION } from '../types';
import { createSlice } from '../utils';
import { initialServiceabilityState } from './';
import { ServiceabilityState } from './types';
import { resetAddressLines } from './utils';

const serviceabilitySlice = createSlice({
  name: 'serviceability',
  initialState: initialServiceabilityState,
  reducers: {
    onServiceabilityMount(): void {},
    onAutoCompletedAddressUsed(state: ServiceabilityState): void {
      state.isServiceabilityLoading = true;
    },
    scrubServiceAddressRetry(): void {},
    scrubServiceAddress(state: ServiceabilityState): void {
      state.isServiceabilityLoading = true;
    },
    resetServiceAddress(state: ServiceabilityState): void {
      state.serviceAddress = {
        ...state.serviceAddress,
        addressLines: {
          ...state.serviceAddress.addressLines,
          value: resetAddressLines(state.serviceAddress.addressLines),
        },
        countryCode: state.serviceAddress.countryCode,
      };
    },
    setIsServiceabilityLoading(state: ServiceabilityState, action: PayloadAction<boolean>): void {
      state.isServiceabilityLoading = action.payload;
    },

    setShowMap(state: ServiceabilityState, action: PayloadAction<boolean>): void {
      state.showMap = !action.payload;
    },
    setCoordinates(state: ServiceabilityState, action: PayloadAction<CoordinatesInput>): void {
      state.longitude = action.payload?.longitude ?? null;
      state.latitude = action.payload?.latitude ?? null;
    },
    setServiceAddress(state: ServiceabilityState, action: PayloadAction<AddressType>): void {
      let addressLine = `${action.payload.addressLines[0].trim()}`;
      action.payload.houseNumber && (addressLine = `${' ' + addressLine.split(action.payload.houseNumber)[0]}`);
      action.payload?.addressLines[1] &&
        (addressLine += `${i18n.t('serviceability:common.address.appendApt') + action.payload.addressLines[1]}`);
      state.serviceAddress.addressLines.value[0] = addressLine.split(',')[0]?.trim();
      state.serviceAddress.addressLines.value[1] =
        action.payload.addressLines[1] || addressLine.split(',')[1]?.trim() || '';
      state.serviceAddress.municipality.value = action.payload.municipality || '';
      state.serviceAddress.region.value = action.payload.region || '';
      state.serviceAddress.postalCode.value = action.payload.postalCode;
      state.serviceAddress.countryCode.value = action.payload.countryCode;
      state.serviceAddress.houseNumber.value = action.payload.houseNumber ?? undefined;
    },
    setScrubLocation(state: ServiceabilityState, action: PayloadAction<LocationType>): void {
      state.scrubbedLocation = action.payload;
    },
    updateScrubLocationAddressLine2(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.scrubbedLocation.address.addressLines[0] = action.payload;
    },
    setScrubLocationCountryCode(state: ServiceabilityState, action: PayloadAction<Country>): void {
      state.scrubbedLocation.address.countryCode = action.payload;
    },
    setServiceAddressLine1(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.serviceAddress.addressLines.value[0] = action.payload;
    },
    setServiceAddressLine2(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.serviceAddress.addressLines.value[1] = action.payload;
    },
    setServiceAddressMunicipality(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.serviceAddress.municipality.value = action.payload;
    },
    setServiceAddressRegion(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.serviceAddress.region.value = action.payload;
    },
    setServiceAddressPostalCode(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.serviceAddress.postalCode.value = action.payload;
    },
    setServiceAddressCountryCode(state: ServiceabilityState, action: PayloadAction<Country>): void {
      state.serviceAddress.countryCode.value = action.payload;
    },
    setServiceAddressHouseNumber(state: ServiceabilityState, action: PayloadAction<string>): void {
      state.serviceAddress.houseNumber.value = action.payload;
    },
    setExistingCustomer(
      state: ServiceabilityState,
      {
        payload: { isCustomer, existingContactMethod },
      }: PayloadAction<{ isCustomer: boolean; existingContactMethod: Maybe<ExistingContactMethod> | undefined }>,
    ): void {
      state.existingCustomer = isCustomer;
      state.existingContactMethod = existingContactMethod ?? null;
    },
    useSuggestedServiceAddress(state: ServiceabilityState): void {
      state.isServiceabilityLoading = true;
    },
    useInlineServiceAddress(state: ServiceabilityState): void {
      state.isServiceabilityLoading = true;
    },
    isServiceAddressSet(state: ServiceabilityState, action: PayloadAction<boolean>): void {
      state.isServiceAddressSet = action.payload;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): ServiceabilityState => initialServiceabilityState,
  },
});

export const {
  actions: serviceabilityActions,
  reducer: serviceabilityReducer,
  name: serviceabilityKey,
} = serviceabilitySlice;
