import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Txt, useBreakpoints } from '@vst/beam';
import { InfoOutlined } from '@vst/beam-icons/icons';
import { Modal } from '@buy-viasat/react/src/components/Modal';
import styles from './cae.module.scss';

type CAEModalProps = {
  primaryAction: () => void;
};

export const CAEModal: FC<CAEModalProps> = ({ primaryAction }) => {
  const { viewport } = useBreakpoints();
  const { t } = useTranslation(['creditVerification']);

  return (
    <Modal
      colorIcon={'alert_400'}
      headerIcon={InfoOutlined}
      data-testid="CAE"
      className={styles['modal']}
      headerTitle={t('creditVerification.modal.cae.titleText')}
      primaryLabelText={t('creditVerification.modal.cae.confirmButtonText')}
      secondaryLabelText={''}
      primaryAction={primaryAction}
      hideCloseButton={!viewport.smallerOrWithin.sm}
    >
      <Txt variant={'bodySmallRegular'} data-testid="CAEModalBodyText" className={styles['bodyText']}>
        {t('creditVerification.modal.cae.bodyText')}
      </Txt>
    </Modal>
  );
};
