import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';
import { appActions } from '@buy-viasat/redux/src/app';
import { selectAccessToken } from '@buy-viasat/redux/src/auth';

const useAutoPublishViewChangesToExperimentationPlatform = (): void => {
  const location = useLocation();
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    dispatch(
      appActions.analyticsEvent({
        category: TrackingCategory.LOCATION_CHANGE,
        action: TrackingActions.SUCCESSFUL,
        params: undefined,
      }),
    );
    if (accessToken) dispatch(appActions.logURL());
  }, [location]);
};

export default useAutoPublishViewChangesToExperimentationPlatform;
