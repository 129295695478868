import { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE_ACTION } from '../types';
import { createSlice } from '../utils';
import { initialBusinessInformationState } from './';
import { ErrorPayload, BusinessInformationState } from './types';
import { CountryCode } from 'libphonenumber-js';

const businessInformationSlice = createSlice({
  name: 'businessInformation',
  initialState: initialBusinessInformationState,
  reducers: {
    createOrUpdateBusiness(): void {},
    onSagaError(state: BusinessInformationState, action: PayloadAction<ErrorPayload>): void {
      state.isBusinessInformationLoading = false;
    },

    /* eslint-enable @typescript-eslint/no-empty-function */
    // SETTERS START
    resetBusinessInformationTaxID(state: BusinessInformationState): void {
      state.taxID = initialBusinessInformationState.taxID;
    },
    resetBusinessInformationSSN(state: BusinessInformationState): void {
      state.SSN = initialBusinessInformationState.SSN;
    },
    setOrganizationPartyId(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.organizationPartyId = action.payload;
    },
    setAccountHolderEmail(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.accountHolder.email = action.payload;
    },
    setBillingContactEmail(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.billingContact.email = action.payload;
    },
    setOnSiteContactEmail(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.onSiteContact.email = action.payload;
    },

    setBusinessInformationName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.name = action.payload;
    },
    setBusinessInformationTaxID(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.taxID = action.payload;
    },
    setBusinessInformationSSN(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.SSN = action.payload;
    },
    setShouldUseTaxId(state: BusinessInformationState, action: PayloadAction<boolean>): void {
      state.shouldUseTaxid = action.payload;
    },
    setAccountHolderFirstName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.accountHolder.firstName = action.payload;
    },
    setBillingContactFirstName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.billingContact.firstName = action.payload;
    },
    setOnSiteContactFirstName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.onSiteContact.firstName = action.payload;
    },

    setAccountHolderLastName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.accountHolder.lastName = action.payload;
    },
    setBillingContactLastName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.billingContact.lastName = action.payload;
    },
    setOnSiteContactLastName(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.onSiteContact.lastName = action.payload;
    },

    setAccountHolderPhone(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.accountHolder.phone = action.payload;
    },
    setBillingContactPhone(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.billingContact.phone = action.payload;
    },
    setOnSiteContactPhone(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.onSiteContact.phone = action.payload;
    },

    setAccountHolderMobileNumber(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.accountHolder.mobileNumber = action.payload;
    },
    setBillingContactMobileNumber(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.billingContact.mobileNumber = action.payload;
    },
    setOnSiteContactMobileNumber(state: BusinessInformationState, action: PayloadAction<string>): void {
      state.onSiteContact.mobileNumber = action.payload;
    },

    setAccountHolderPhoneCountryCode(state: BusinessInformationState, action: PayloadAction<CountryCode>): void {
      state.accountHolder.phoneCountryCode = action.payload;
    },
    setBillingContactPhoneCountryCode(state: BusinessInformationState, action: PayloadAction<CountryCode>): void {
      state.billingContact.phoneCountryCode = action.payload;
    },
    setOnSiteContactPhoneCountryCode(state: BusinessInformationState, action: PayloadAction<CountryCode>): void {
      state.onSiteContact.phoneCountryCode = action.payload;
    },

    setAccountHolderIsMobileNumberChecked(state: BusinessInformationState, action: PayloadAction<boolean>): void {
      state.accountHolder.isMobileNumberChecked = action.payload;
    },
    setBillingContactIsMobileNumberChecked(state: BusinessInformationState, action: PayloadAction<boolean>): void {
      state.billingContact.isMobileNumberChecked = action.payload;
    },
    setOnSiteContactIsMobileNumberChecked(state: BusinessInformationState, action: PayloadAction<boolean>): void {
      state.onSiteContact.isMobileNumberChecked = action.payload;
    },
    setIsTaxExempt(state: BusinessInformationState, action: PayloadAction<boolean>): void {
      state.isTaxExempt = action.payload;
    },
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): BusinessInformationState => initialBusinessInformationState,
  },
});

export const {
  actions: businessInformationActions,
  reducer: businessInformationReducer,
  name: businessInformationKey,
} = businessInformationSlice;
