import gql from 'graphql-tag';
import { UpdateBillingAccountInput } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

const UPDATE_BILLING_ACCOUNT = gql`
  mutation updateBillingAccount($input: UpdateBillingAccountInput!) {
    updateBillingAccount(input: $input) {
      accountNumber
    }
  }
`;

export default async function updateBillingAccountAsync(options: UpdateBillingAccountInput): Promise<any> {
  return client.mutate({
    mutation: UPDATE_BILLING_ACCOUNT,
    variables: {
      input: options,
    },
  });
}
