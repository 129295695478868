import { isDefinedString } from '../utils';
import { AddressType, defaultAddressData } from './types';
import { Country } from '@buy-viasat/types/build/bv';

export const formatServiceAddressHouseNumber = (address: AddressType, houseNumber: string | undefined): AddressType => {
  let formattedAddress = { ...address };
  if (isDefinedString(houseNumber)) {
    const formattedAddressLines = [address.addressLines[0].trim() + ' ' + houseNumber, address.addressLines[1]];
    formattedAddress = { ...address, addressLines: formattedAddressLines, houseNumber: undefined };
  }
  return formattedAddress;
};

export const formatScrubbedAddressHousingNumber = (scrubbedAddress: AddressType, houseNumber: string): AddressType => {
  return houseNumber
    ? {
        ...scrubbedAddress,
        addressLines: [
          scrubbedAddress.addressLines[0].split(houseNumber)[0]?.trim(),
          scrubbedAddress.addressLines[1] ?? '',
        ],
        houseNumber: houseNumber,
      }
    : { ...scrubbedAddress, addressLines: [scrubbedAddress.addressLines[0], scrubbedAddress.addressLines[1] ?? ''] };
};

export const injectAddressData = ({
  addressLine1,
  addressLine2,
  municipality,
  region,
  postalCode,
  countryCode,
}: {
  addressLine1?: string;
  addressLine2?: string;
  municipality?: string;
  region?: string;
  postalCode?: string;
  countryCode?: string;
}): any => ({
  addressLines: {
    value: [
      addressLine1 ?? defaultAddressData(Country.US).addressLines[0],
      addressLine2 ?? defaultAddressData(Country.US).addressLines[1],
    ],
    valid: null,
    hintText: 'Address is required',
  },
  municipality: {
    value: municipality ?? defaultAddressData(Country.US).municipality,
    valid: null,
    hintText: 'City is required',
  },
  region: { value: region ?? defaultAddressData(Country.US).region, valid: null, hintText: 'State is required' },
  postalCode: {
    value: postalCode ?? defaultAddressData(Country.US).postalCode,
    valid: null,
    hintText: 'Zipcode is required',
  },
  countryCode: {
    value: countryCode ?? defaultAddressData(Country.US).countryCode,
    valid: null,
    hintText: 'CountryCode is required',
  },
  houseNumber: {
    value: '',
    valid: null,
    hintText: 'House Number is required',
  },
});

export const hasAddressBeenChanged = (addressA: AddressType, addressB: AddressType): boolean => {
  return (
    addressA.addressLines[0] !== addressB.addressLines[0] ||
    addressA.addressLines[1] !== addressB.addressLines[1] ||
    addressA.municipality !== addressB.municipality ||
    addressA.region !== addressB.region ||
    addressA.postalCode !== addressB.postalCode ||
    addressA.countryCode !== addressB.countryCode ||
    addressA.houseNumber !== addressB.houseNumber
  );
};
