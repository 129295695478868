import { PlanCharacteristics } from '@buy-viasat/types/build/bv';
import { CurrencySymbol, DateFormat } from '@buy-viasat/utils';

export enum FlowName {
  US_RES = 'USResidential',
  US_BUSINESS = 'USBusiness',
  BR_RES = 'BrazilResidential',
  IT_RES = 'ItalyResidential',
}

export enum VppUrlType {
  US,
  EU,
}

type TaxIdProps = {
  isTaxId: boolean;
  isTaxIdRequired: boolean;
  taxIdInputFormat: string;
  taxIdPlaceholder: string;
  taxIdIsAlphaNumeric: boolean;
};

export type PlanCharacteristicsVisibility = {
  exclusive: boolean;
  keys: {
    [k in keyof PlanCharacteristics]: boolean;
  };
};

export enum AddressLine1 {
  US = 'StreetAddress_AptUnit',
}

export enum AddressLine2 {
  US = 'City_State_Zip',
  EU = 'Zip_City_State',
}

export type SalesFlowDefinition = {
  shouldDisplayOrderReviewCheckbox: boolean;
  shouldDisplaySimpleThankYou: boolean;
  shouldCreateAuthVoidTransaction: boolean;
  shouldDisplayAcpBanner: boolean;
  shouldDisplayUnlimitedValueTitle: boolean;
  shouldDisplayDefaultBullets: boolean;
  shouldDisplayScheduleLaterText: boolean;
  hasNonCreditCardAlternatives: boolean;
  flowName: string;
  currencySymbol: string;
  hasCreditCardDiscountContainer: boolean;
  shouldDisplayInflectionPointText: boolean;
  shouldDisplayLeaseAndPromo: boolean;
  shouldDisplayCreditCheckbox: boolean;
  hasDetailedOrderReviewSubtext: boolean;
  shouldDisplayDisclaimerCheckbox: boolean;
  shouldDisplayBrazilPlanCard: boolean;
  dateFormat: DateFormat;
  zipFormat: string;
  hasExtendedSubheader: boolean;
  hasAutoPay: boolean;
  shouldDisplayCafII: boolean;
  shouldSkipDeliveryRoutes: boolean;
  shouldReduceAvailableProducts: boolean;
  simplifiedAddressEntryBillingShipping: boolean;
  vppUrlType: VppUrlType;
  taxId: TaxIdProps;
  phoneNumberCountrySelection: boolean;
  addressLine1FormatDisplay: AddressLine1;
  addressLine2FormatDisplay: AddressLine2;
  shouldFilterPlans: boolean;
  geocodeSplitZipFormat: boolean;
  shouldDisplayMarketingConsent: boolean;
  shouldDisplayTaxIdInPersonalInfo: boolean;
  shouldDisplayTaxIdDisclosuresText: boolean;
  planCharacteristicsVisibility: PlanCharacteristicsVisibility;
  shouldRequireContractSignature: boolean;
  paymentIframeURLKey: 'url' | 'euUrl';
  shouldDisplayOrderReviewSecondSentence: boolean;
  shouldDisplayCreditCardInfoAlternateTitle: boolean;
  shouldShowExtraTaxesDescriptionText: boolean;
  shouldSendTaxIdOnCreateOrUpdateIndividual: boolean;
  shouldConcatenateTaxId: boolean;
  shouldUseOneTimeTotalForVppTransaction: boolean;
  shouldUseEuPlanPriceColor: boolean;
  shouldDisplaySmbLink: boolean;
  shouldDisplaySimplifiedPlanCardDesign: boolean;
  shouldPlaceOrderFromPaymentPage: boolean;
  shouldRunGetTaxCodesAfterPersonalInfoPage: boolean;
  shouldUseHouseNumberAddress: boolean;
};

export const US_RESIDENTIAL_SALES_FLOW_DEFINITION: SalesFlowDefinition = {
  shouldDisplayOrderReviewCheckbox: false,
  shouldDisplaySimpleThankYou: false,
  shouldCreateAuthVoidTransaction: true,
  shouldDisplayAcpBanner: true,
  shouldDisplayUnlimitedValueTitle: true,
  shouldDisplayDefaultBullets: true,
  shouldDisplayScheduleLaterText: true,
  hasNonCreditCardAlternatives: false,
  flowName: FlowName.US_RES,
  currencySymbol: CurrencySymbol.US,
  hasCreditCardDiscountContainer: false,
  shouldDisplayInflectionPointText: false,
  shouldDisplayLeaseAndPromo: true,
  shouldDisplayCreditCheckbox: true,
  hasDetailedOrderReviewSubtext: false,
  shouldDisplayDisclaimerCheckbox: false,
  shouldDisplayBrazilPlanCard: false,
  dateFormat: DateFormat.MDY,
  zipFormat: '',
  hasExtendedSubheader: false,
  hasAutoPay: true,
  shouldDisplayCafII: true,
  shouldSkipDeliveryRoutes: false,
  shouldReduceAvailableProducts: true,
  simplifiedAddressEntryBillingShipping: false,
  vppUrlType: VppUrlType.US,
  taxId: {
    isTaxId: false,
    isTaxIdRequired: false,
    taxIdInputFormat: '',
    taxIdPlaceholder: '0000',
    taxIdIsAlphaNumeric: false,
  },
  phoneNumberCountrySelection: false,
  addressLine1FormatDisplay: AddressLine1.US,
  addressLine2FormatDisplay: AddressLine2.US,
  shouldFilterPlans: true,
  geocodeSplitZipFormat: true,
  shouldDisplayMarketingConsent: false,
  shouldDisplayTaxIdInPersonalInfo: false,
  shouldDisplayTaxIdDisclosuresText: true,
  planCharacteristicsVisibility: {
    exclusive: false,
    keys: {
      contractTerm: false,
      contractTermUnit: false,
      feeText: false,
    },
  },
  shouldRequireContractSignature: false,
  paymentIframeURLKey: 'url',
  shouldDisplayOrderReviewSecondSentence: false,
  shouldDisplayCreditCardInfoAlternateTitle: true,
  shouldSendTaxIdOnCreateOrUpdateIndividual: false,
  shouldShowExtraTaxesDescriptionText: true,
  shouldConcatenateTaxId: true,
  shouldUseOneTimeTotalForVppTransaction: true,
  shouldUseEuPlanPriceColor: false,
  shouldDisplaySmbLink: true,
  shouldDisplaySimplifiedPlanCardDesign: true,
  shouldPlaceOrderFromPaymentPage: true,
  shouldRunGetTaxCodesAfterPersonalInfoPage: false,
  shouldUseHouseNumberAddress: false,
};

export const US_BUSINESS_SALES_FLOW_DEFINITION: SalesFlowDefinition = {
  shouldDisplayOrderReviewCheckbox: false,
  shouldDisplaySimpleThankYou: false,
  shouldCreateAuthVoidTransaction: true,
  shouldDisplayAcpBanner: true,
  shouldDisplayUnlimitedValueTitle: true,
  shouldDisplayDefaultBullets: true,
  shouldDisplayScheduleLaterText: true,
  hasNonCreditCardAlternatives: false,
  flowName: FlowName.US_BUSINESS,
  currencySymbol: CurrencySymbol.US,
  hasCreditCardDiscountContainer: false,
  shouldDisplayInflectionPointText: false,
  shouldDisplayLeaseAndPromo: true,
  shouldDisplayCreditCheckbox: true,
  hasDetailedOrderReviewSubtext: false,
  shouldDisplayDisclaimerCheckbox: false,
  shouldDisplayBrazilPlanCard: false,
  dateFormat: DateFormat.MDY,
  zipFormat: '',
  hasExtendedSubheader: false,
  hasAutoPay: true,
  shouldDisplayCafII: true,
  shouldSkipDeliveryRoutes: false,
  shouldReduceAvailableProducts: true,
  simplifiedAddressEntryBillingShipping: false,
  vppUrlType: VppUrlType.US,
  taxId: {
    isTaxId: false,
    isTaxIdRequired: false,
    taxIdInputFormat: '',
    taxIdPlaceholder: '0000',
    taxIdIsAlphaNumeric: false,
  },
  phoneNumberCountrySelection: false,
  addressLine1FormatDisplay: AddressLine1.US,
  addressLine2FormatDisplay: AddressLine2.US,
  shouldFilterPlans: true,
  geocodeSplitZipFormat: true,
  shouldDisplayMarketingConsent: false,
  shouldDisplayTaxIdInPersonalInfo: false,
  shouldDisplayTaxIdDisclosuresText: true,
  planCharacteristicsVisibility: {
    exclusive: false,
    keys: {
      contractTerm: false,
      contractTermUnit: false,
      feeText: false,
    },
  },
  shouldRequireContractSignature: false,
  paymentIframeURLKey: 'url',
  shouldDisplayOrderReviewSecondSentence: false,
  shouldDisplayCreditCardInfoAlternateTitle: true,
  shouldShowExtraTaxesDescriptionText: true,
  shouldSendTaxIdOnCreateOrUpdateIndividual: true,
  shouldConcatenateTaxId: true,
  shouldUseOneTimeTotalForVppTransaction: true,
  shouldUseEuPlanPriceColor: false,
  shouldDisplaySmbLink: true,
  shouldDisplaySimplifiedPlanCardDesign: true,
  shouldPlaceOrderFromPaymentPage: true,
  shouldRunGetTaxCodesAfterPersonalInfoPage: true,
  shouldUseHouseNumberAddress: false,
};

export const BR_RESIDENTIAL_SALES_FLOW_DEFINITION: SalesFlowDefinition = {
  shouldDisplayOrderReviewCheckbox: true,
  shouldDisplaySimpleThankYou: true,
  shouldCreateAuthVoidTransaction: false,
  shouldDisplayAcpBanner: false,
  shouldDisplayUnlimitedValueTitle: false,
  shouldDisplayDefaultBullets: false,
  shouldDisplayScheduleLaterText: false,
  hasNonCreditCardAlternatives: true,
  flowName: FlowName.BR_RES,
  currencySymbol: CurrencySymbol.BR,
  hasCreditCardDiscountContainer: true,
  shouldDisplayInflectionPointText: true,
  shouldDisplayLeaseAndPromo: false,
  shouldDisplayCreditCheckbox: false,
  hasDetailedOrderReviewSubtext: true,
  shouldDisplayDisclaimerCheckbox: true,
  shouldDisplayBrazilPlanCard: true,
  dateFormat: DateFormat.DMY,
  zipFormat: '#####-###',
  hasExtendedSubheader: true,
  hasAutoPay: false,
  shouldDisplayCafII: false,
  shouldSkipDeliveryRoutes: true,
  shouldReduceAvailableProducts: false,
  simplifiedAddressEntryBillingShipping: false,
  vppUrlType: VppUrlType.US,
  taxId: {
    isTaxId: true,
    isTaxIdRequired: true,
    taxIdInputFormat: '###.###.###-##',
    taxIdPlaceholder: 'xxx.xxx.xxx-xx',
    taxIdIsAlphaNumeric: false,
  },
  phoneNumberCountrySelection: false,
  addressLine1FormatDisplay: AddressLine1.US,
  addressLine2FormatDisplay: AddressLine2.US,
  shouldFilterPlans: false,
  geocodeSplitZipFormat: false,
  shouldDisplayMarketingConsent: false,
  shouldDisplayTaxIdInPersonalInfo: false,
  shouldDisplayTaxIdDisclosuresText: false,
  planCharacteristicsVisibility: {
    exclusive: false,
    keys: {
      contractTerm: false,
      contractTermUnit: false,
      feeText: false,
    },
  },
  shouldRequireContractSignature: false,
  paymentIframeURLKey: 'url',
  shouldDisplayOrderReviewSecondSentence: false,
  shouldDisplayCreditCardInfoAlternateTitle: false,
  shouldShowExtraTaxesDescriptionText: true,
  shouldSendTaxIdOnCreateOrUpdateIndividual: false,
  shouldConcatenateTaxId: true,
  shouldUseOneTimeTotalForVppTransaction: false,
  shouldUseEuPlanPriceColor: false,
  shouldDisplaySmbLink: false,
  shouldDisplaySimplifiedPlanCardDesign: false,
  shouldPlaceOrderFromPaymentPage: false,
  shouldRunGetTaxCodesAfterPersonalInfoPage: false,
  shouldUseHouseNumberAddress: false,
};

export const IT_RESIDENTIAL_SALES_FLOW_DEFINITION: SalesFlowDefinition = {
  shouldDisplaySimpleThankYou: false,
  shouldDisplayAcpBanner: false,
  shouldDisplayUnlimitedValueTitle: true,
  shouldDisplayDefaultBullets: true,
  shouldDisplayScheduleLaterText: true,
  hasNonCreditCardAlternatives: false,
  flowName: FlowName.IT_RES,
  currencySymbol: CurrencySymbol.EUR,
  hasCreditCardDiscountContainer: false,
  shouldDisplayInflectionPointText: false,
  shouldDisplayLeaseAndPromo: true,
  shouldDisplayCreditCheckbox: true,
  hasDetailedOrderReviewSubtext: false,
  shouldDisplayDisclaimerCheckbox: false,
  shouldDisplayBrazilPlanCard: false,
  dateFormat: DateFormat.MDY,
  zipFormat: '#####',
  hasExtendedSubheader: false,
  hasAutoPay: true,
  shouldDisplayCafII: true,
  shouldSkipDeliveryRoutes: false,
  shouldReduceAvailableProducts: true,
  simplifiedAddressEntryBillingShipping: true,
  vppUrlType: VppUrlType.EU,
  taxId: {
    isTaxId: true,
    isTaxIdRequired: true,
    taxIdInputFormat: 'FFFNNNYYMDDRRRRC',
    taxIdPlaceholder: 'FFFNNNYYMDDRRRRC',
    taxIdIsAlphaNumeric: true,
  },
  phoneNumberCountrySelection: true,
  addressLine1FormatDisplay: AddressLine1.US,
  addressLine2FormatDisplay: AddressLine2.EU,
  shouldFilterPlans: false,
  geocodeSplitZipFormat: true,
  planCharacteristicsVisibility: {
    exclusive: true,
    keys: {
      bannerColor: true,
      contractTerm: true,
      feeText: true,
      titleAddOns: true,
    },
  },
  shouldDisplayMarketingConsent: true,
  shouldDisplayTaxIdInPersonalInfo: true,
  shouldDisplayTaxIdDisclosuresText: false,
  shouldRequireContractSignature: false,
  paymentIframeURLKey: 'euUrl',
  shouldCreateAuthVoidTransaction: true,
  shouldDisplayOrderReviewCheckbox: true,
  shouldDisplayOrderReviewSecondSentence: true,
  shouldDisplayCreditCardInfoAlternateTitle: true,
  shouldShowExtraTaxesDescriptionText: false,
  shouldSendTaxIdOnCreateOrUpdateIndividual: true,
  shouldConcatenateTaxId: false,
  shouldUseOneTimeTotalForVppTransaction: true,
  shouldUseEuPlanPriceColor: true,
  shouldDisplaySmbLink: false,
  shouldDisplaySimplifiedPlanCardDesign: false,
  shouldPlaceOrderFromPaymentPage: false,
  shouldRunGetTaxCodesAfterPersonalInfoPage: true,
  shouldUseHouseNumberAddress: true,
};

export const initialSalesFlowAgreement: SalesFlowDefinition = US_RESIDENTIAL_SALES_FLOW_DEFINITION;
