import { Surface, Txt, Icon } from '@vst/beam';
import { KeyboardArrowUp, KeyboardArrowDown } from '@vst/beam-icons/icons';
import styles from './checkoutCartDisplayServiceDetails.module.scss';

type CheckoutCartDisplayServiceDetailsProps = {
  displayServiceDetails: boolean;
  onClick: () => void;
  hideLabel: string;
  showLabel: string;
};

export const CheckoutCartDisplayServiceDetails = ({
  displayServiceDetails,
  onClick,
  hideLabel,
  showLabel,
}: CheckoutCartDisplayServiceDetailsProps) => {
  const showOrHideLabelText = !displayServiceDetails ? showLabel : hideLabel;

  // we adjust the margin-bottom prop based on whether the service details component is collapsed
  // because AEM's component comes with a margin-top which renders the Surface not clickable
  return (
    <Surface variant="primary" className={styles['container']} mt="24px" mb={displayServiceDetails ? '12px' : '20px'}>
      <Txt
        variant="bodySmallRegular"
        onClick={onClick}
        className={styles['textWrapper']}
        data-testid={`${showOrHideLabelText}Text`}
      >
        {showOrHideLabelText}
      </Txt>
      {displayServiceDetails ? (
        <Icon icon={KeyboardArrowUp} onClick={onClick} data-testid="keyboardArrowUp" />
      ) : (
        <Icon icon={KeyboardArrowDown} onClick={onClick} data-testid="keyboardArrowDown" />
      )}
    </Surface>
  );
};
