import { createSelector } from 'reselect';
import { selectAppDomain, AppState, CustomerType, selectCustomerType } from '@buy-viasat/redux/src/app';
import {
  dateOfBirthValidation,
  validateDay,
  validateEmail,
  validateMobileNumber,
  validateMonth,
  validatePhone,
  validateRequired,
  validateSubsidyApplicationId,
  validateYear,
} from '../Checkout/validations';
import { phoneNumberRegex, zipCodeRegex } from '@buy-viasat/utils';
import { selectCreditVerificationDomain } from '@buy-viasat/redux/src/credit-verification';
import { selectServiceabilityDomain, ServiceabilityState } from '@buy-viasat/redux/src/serviceability';
import { selectPersonalInformationDomain, PersonalInformationState } from '@buy-viasat/redux/src/personal-information';
import { selectAddressDomain, validateZip } from '@buy-viasat/redux/src/address';
import { selectBusinessInformationDomain } from '@buy-viasat/redux/src/business-information';

const selectSubsidyApplicationIdValid = createSelector(
  [selectPersonalInformationDomain],
  (state: PersonalInformationState) => validateSubsidyApplicationId(state.subsidyApplicationId.value).valid,
);

const selectPersonalInformationValidation = createSelector(
  [
    selectPersonalInformationDomain,
    selectBusinessInformationDomain,
    selectAppDomain,
    selectServiceabilityDomain,
    selectCreditVerificationDomain,
    selectAddressDomain,
    selectCustomerType,
  ],
  (
    { firstName, lastName, email, phone, mobileNumber, isSubsidyCheckboxChecked, subsidyApplicationId },
    { accountHolder },
    appState: AppState,
    serviceabilityState: ServiceabilityState,
    { dateOfBirth },
    addressState,
    customerType,
  ) => {
    const isOrganization = customerType === CustomerType.BUSINESS;
    let isValid;

    if (isOrganization) {
      isValid =
        validateRequired(accountHolder.firstName).valid &&
        validateRequired(accountHolder.lastName).valid &&
        validateRequired(accountHolder.email).valid &&
        validatePhone(accountHolder.phone, phoneNumberRegex[appState.country]).valid &&
        validateMobileNumber(accountHolder.mobileNumber, phoneNumberRegex[appState.country]).valid;
    } else {
      isValid =
        validateRequired(firstName.value).valid &&
        validateRequired(lastName.value).valid &&
        validateEmail(email.value).valid &&
        validatePhone(phone.value, phoneNumberRegex[appState.country]).valid &&
        validateMobileNumber(mobileNumber.value, phoneNumberRegex[appState.country]).valid;
    }

    const billingAddressValid =
      addressState.billingAddressEqualsServiceAddress ||
      (validateRequired(addressState.billingAddress.addressLines.value[0]).valid &&
        validateZip(
          addressState.billingAddress.postalCode.value,
          zipCodeRegex[appState.country],
          addressState.billingAddress.postalCode.value.length,
        ).valid);
    const shippingAddressValid =
      addressState.shippingAddressEqualsServiceAddress ||
      (validateRequired(addressState.shippingAddress.addressLines.value[0]).valid &&
        validateZip(
          addressState.shippingAddress.postalCode.value,
          zipCodeRegex[appState.country],
          addressState.shippingAddress.postalCode.value.length,
        ).valid);
    const subsidyApplicationIdValid =
      !isSubsidyCheckboxChecked || validateSubsidyApplicationId(subsidyApplicationId.value).valid;
    const dateOfBirthValid =
      !(
        isSubsidyCheckboxChecked &&
        (addressState.billingAddress.region.value === 'VT' ||
          (serviceabilityState.serviceAddress.region.value === 'VT' && addressState.billingAddressEqualsServiceAddress))
      ) ||
      (validateMonth(dateOfBirth.month.value).valid &&
        validateDay(dateOfBirth.day.value).valid &&
        validateYear(dateOfBirth.year.value).valid &&
        dateOfBirthValidation(dateOfBirth.month.value, dateOfBirth.day.value, dateOfBirth.year.value, new Date())
          .valid);

    return isValid && billingAddressValid && shippingAddressValid && subsidyApplicationIdValid && dateOfBirthValid;
  },
);

export { selectPersonalInformationValidation, selectSubsidyApplicationIdValid };
