import { Modal } from '@buy-viasat/react/src/components/Modal';
import { Txt, Surface, useBreakpoints } from '@vst/beam';
import { LocationOn } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import styles from './addressNotVerified.module.scss';

type AddressNotVerifiedModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
  suggestedAddressText: string;
};

export const AddressNotVerifiedModal = (args: AddressNotVerifiedModalProps) => {
  const { primaryAction, secondaryAction, suggestedAddressText } = args;
  const { viewport } = useBreakpoints();
  const { t } = useTranslation(['serviceability']);

  return (
    <Modal
      headerIcon={LocationOn}
      colorIcon={'teal_600'}
      headerTitle={t('serviceability.modal.addressNotVerified.headerTitle')}
      primaryLabelText={t('serviceability.modal.addressNotVerified.primaryLabelText')}
      secondaryLabelText={t('serviceability.modal.addressNotVerified.secondaryLabelText')}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      showSecondaryButtonAsLink={true}
      hideCloseButton={!viewport.smallerOrWithin.sm}
      data-testid="AddressNotVerifiedModal"
    >
      <>
        <Txt variant={'bodySmallRegular'} data-testid="AddressNotVerifiedModalBodyText">
          {t('serviceability.modal.addressNotVerified.bodyText')}
        </Txt>
        <Surface
          variant="secondary"
          radius={'8px'}
          className={styles['suggestedAddress']}
          data-testid="AddressNotVerifiedModalSuggestedAddressContainer"
        >
          <Txt
            variant={'bodySmallBold'}
            className={styles['suggestedAddressText']}
            data-testid="AddressNotVerifiedModalSuggestedAddressText"
          >
            {suggestedAddressText}
          </Txt>
        </Surface>
      </>
    </Modal>
  );
};
