import { FormFieldState } from '../types';

export type DateOfBirth<M, D, Y> = {
  month: {
    value: M;
    valid: boolean | null;
    hintText: string;
  };
  day: {
    value: D;
    valid: boolean | null;
    hintText: string;
  };
  year: {
    value: Y;
    valid: boolean | null;
    hintText: string;
  };
};

export interface CreditVerificationState {
  dateOfBirth: DateOfBirth<string, string, string>;
  lastFourOfSSN: FormFieldState<string>;
  taxId: FormFieldState<string>;
  isLegalCheckboxChecked: boolean;
  isLastFourObscured: boolean;
  isLoading: boolean;
  updateCreditVerification: boolean;
  isCreditCheckComplete: boolean;
  isSSNMandatory: boolean;
  displaySSNMandatoryModal: boolean;
}

export const initialCreditVerificationState: CreditVerificationState = {
  dateOfBirth: {
    month: {
      value: '',
      valid: null,
      hintText: '',
    },
    day: {
      value: '',
      valid: null,
      hintText: '',
    },
    year: {
      value: '',
      valid: null,
      hintText: '',
    },
  },
  lastFourOfSSN: { value: '', valid: true, hintText: '' },
  taxId: { value: '', valid: true, hintText: '' },
  isLegalCheckboxChecked: false,
  isLastFourObscured: true,
  isLoading: false,
  updateCreditVerification: false,
  isCreditCheckComplete: false,
  isSSNMandatory: false,
  displaySSNMandatoryModal: true,
};

export type SetLastFourPayload = {
  text: string;
  cursorIndex: number;
};

export type SetTaxIdPayload = {
  taxId: string;
  shouldConcatenateTaxId: boolean;
};
