import { CountryCode } from 'libphonenumber-js';

export interface BillingContactInformationState {
  firstName: string;
  lastName: string;
  phone: string;
  mobileNumber: string;
  phoneCountryCode?: CountryCode;
  email: string;
  isMobileNumberChecked: boolean;
}

export interface AccountHolderInformationState {
  firstName: string;
  lastName: string;
  phone: string;
  mobileNumber: string;
  phoneCountryCode?: CountryCode;
  email: string;
  isMobileNumberChecked: boolean;
}

export interface OnSiteContactInformationState {
  firstName: string;
  lastName: string;
  phone: string;
  mobileNumber: string;
  phoneCountryCode?: CountryCode;
  email: string;
  isMobileNumberChecked: boolean;
}

export interface BusinessInformationState {
  name: string;
  taxID: string;
  SSN: string;
  shouldUseTaxid: boolean;
  billingContact: BillingContactInformationState;
  accountHolder: AccountHolderInformationState;
  onSiteContact: OnSiteContactInformationState;
  isBusinessInformationLoading: boolean;
  organizationPartyId: string;
  isTaxExempt: boolean;
}

export interface ErrorPayload<T = string> {
  callingSaga: string;
  err: T;
  payload: any;
}

export const initialBusinessInformationState: BusinessInformationState = {
  name: '',
  taxID: '',
  SSN: '',
  shouldUseTaxid: true,
  billingContact: {
    firstName: '',
    lastName: '',
    phone: '',
    mobileNumber: '',
    email: '',
    isMobileNumberChecked: false,
  },
  accountHolder: {
    firstName: '',
    lastName: '',
    phone: '',
    mobileNumber: '',
    email: '',
    isMobileNumberChecked: false,
  },
  onSiteContact: {
    firstName: '',
    lastName: '',
    phone: '',
    mobileNumber: '',
    email: '',
    isMobileNumberChecked: false,
  },
  isBusinessInformationLoading: false,
  organizationPartyId: '',
  isTaxExempt: false,
};
