export const pnApiRun = () => {
  if (typeof window === 'undefined') return Promise.reject('window is not defined');

  const Invoca = (window as any).Invoca;
  if (!Invoca) return Promise.resolve();

  return new Promise<void>((resolve) => {
    if (!Invoca.PNAPI.currentPageSettings) return resolve();
    Invoca.PNAPI.currentPageSettings.onComplete = resolve;
    Invoca.PNAPI.currentPageSettings.numberSelector = 'a[href^="tel:"]:not([data-skip-invoca="true"])';
    Invoca.PNAPI.run();
  });
};
