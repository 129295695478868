import { Icon, Txt } from '@vst/beam';
import { ExpandLess, ExpandMore } from '@vst/beam-icons/icons';
import { Dispatch, memo, SetStateAction } from 'react';

import { CountryOption } from '@buy-viasat/utils';
import styles from './phoneNumberInput.module.scss';

interface PhoneNumberInputContentLeftProps {
  prefix?: string;
  selectedCountry?: CountryOption;
  isDropDownOpen?: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

export const PhoneNumberContentLeft = memo((props: PhoneNumberInputContentLeftProps) => {
  const { prefix, selectedCountry, isDropDownOpen, setIsDropdownOpen } = props;

  return (
    <div
      className={styles['contentLeft']}
      onClick={(e) => {
        e.preventDefault();
        setIsDropdownOpen(!isDropDownOpen);
      }}
    >
      {selectedCountry?.value ? (
        <div className={`${styles['flag']} ${styles[selectedCountry.value.toLowerCase()]}`} />
      ) : (
        <></>
      )}
      {isDropDownOpen ? (
        <Icon icon={ExpandLess} data-testid="ExpandLess" />
      ) : (
        <Icon icon={ExpandMore} data-testid="ExpandMore" />
      )}
      <div className={styles['verticalLine']}></div>

      <Txt variant="bodySmallRegular" color="regular">
        {prefix}
      </Txt>
    </div>
  );
});
