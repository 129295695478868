import React, { useContext, useState } from 'react';
import { AnalyticsContainer, TrackScreenFunction } from './types';
import env from 'env';
import clientLogger from 'shared/utils/clientLogger';
import { TrackingCategory, TrackingActions, LogEventFunction } from '@buy-viasat/redux/src/analytics';
import { trackCustomTiming } from 'shared/utils/raygun';

export const adobeTargetDataLayer = (window as any).adobeDataLayer ?? [];

export const devPlaceholder = [
  (): void => {
    if (env.debug.showAnalytics) console.error('Undefined Analytics Context Container.');
  },
];

const AnalyticsContext = React.createContext<AnalyticsContainer>({
  logEvents: devPlaceholder,
  trackScreens: devPlaceholder,
  startTime: new Date(),
});

export const logAllEvents = (loggers: LogEventFunction[]): LogEventFunction => {
  return (category, action, params): void => {
    loggers.forEach((func): void => {
      try {
        func(category, action, params);
      } catch (e) {
        if (env.debug.showAnalytics)
          console.log(`logAllEvents caught error on category: ${category}, action: ${action}, params: ${params}\n`, e);
      }
    });
  };
};

export const AnalyticsProvider = ({
  logEvents,
  trackScreens,
  startTime,
  children,
}: AnalyticsContainer & { children?: React.ReactNode }): JSX.Element => {
  useState(logAllEvents(logEvents)(TrackingCategory.APP, TrackingActions.INITIATED));
  return (
    <AnalyticsContext.Provider value={{ logEvents, trackScreens, startTime }}>{children}</AnalyticsContext.Provider>
  );
};

export const useStartTime = (): Date => {
  const { startTime } = useContext(AnalyticsContext);
  return startTime;
};

export const trackLoadTime = (startTime: number, endTime: number, sagaName: string, flowId: string): void => {
  if (startTime < endTime && startTime != 0) {
    adobeTargetDataLayer.push({ event: 'loadTime', seconds: (endTime - startTime) / 1000 });
    const duration = endTime - startTime;
    trackCustomTiming(`saga_${sagaName}`, duration, flowId);
  } else {
    clientLogger(`startTime:${startTime} endTime:${endTime}`, 'Invalid start or end time.');
  }
};

export const useTrackScreenFunction = (): TrackScreenFunction => {
  const { trackScreens } = useContext(AnalyticsContext);
  return (customUrl, referrerUrl, params): void => {
    trackScreens.forEach((func): void => {
      try {
        func(customUrl, referrerUrl, params);
      } catch (e) {
        if (env.debug.showAnalytics)
          console.log(
            `useTrackScreenFunction caught error on customUrl: ${customUrl}, referrerUrl: ${referrerUrl}, params: ${params}\n`,
            e,
          );
      }
    });
  };
};
