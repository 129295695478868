import { Modal } from '../Modal';
import { InlineLink, Surface, Txt } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, selectErrorModalAttempts, selectErrorRetryAction } from '@buy-viasat/redux/src/app';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { selectHasCafII } from '@buy-viasat/redux/src/plan';
import { pnApiRun } from '@buy-viasat/utils';

type ClosingOfferErrorModalProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const ClosingOfferRetryErrorModal = (props: ClosingOfferErrorModalProps) => {
  const { primaryAction, secondaryAction } = props;
  const errorRetryAction = useSelector(selectErrorRetryAction);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasCafII = useSelector(selectHasCafII);
  const phoneNumber = hasCafII
    ? t('common.closingOfferErrorModal.CAFIIPhoneNumber')
    : t('common.closingOfferErrorModal.residentialSupportPhoneNumber');

  const attempts = useSelector(selectErrorModalAttempts);
  const HAS_NOT_REACHED_MAX_ATTEMPTS = attempts < 3;

  const handlePrimaryAction = () => {
    if (HAS_NOT_REACHED_MAX_ATTEMPTS) {
      dispatch(appActions.setErrorModalAttempts(attempts + 1));
      dispatch(errorRetryAction);
      primaryAction();
    } else {
      secondaryAction();
      dispatch(appActions.setErrorModalAttempts(0));
    }
  };

  const handleSecondaryAction = () => {
    dispatch(appActions.setErrorModalAttempts(3));
    secondaryAction();
    dispatch(appActions.setErrorModalAttempts(0));
  };

  useEffect(() => {
    setTimeout(() => {
      pnApiRun();
    });
  }, []);

  return (
    <Modal
      headerTitle={t('common.closingOfferErrorModal.firstThreeAttempts.headerTitle')}
      showSecondaryButtonFullWidth={true}
      hideMarginBottomFromText={true}
      alignTextToLeft={true}
      primaryLabelText={
        HAS_NOT_REACHED_MAX_ATTEMPTS
          ? t('common.closingOfferErrorModal.firstThreeAttempts.primaryButton')
          : t('common.closingOfferErrorModal.firstThreeAttempts.secondaryButton')
      }
      secondaryLabelText={
        HAS_NOT_REACHED_MAX_ATTEMPTS ? t('common.closingOfferErrorModal.firstThreeAttempts.secondaryButton') : ''
      }
      hideSecondaryButton={!HAS_NOT_REACHED_MAX_ATTEMPTS}
      primaryAction={handlePrimaryAction}
      secondaryAction={handleSecondaryAction}
      onClose={handleSecondaryAction}
    >
      <Surface data-testid="ClosingOffersErrorModalTextWrapper">
        <Txt variant={'bodySmallRegular'} data-testid="ClosingOffersErrorModalText">
          {HAS_NOT_REACHED_MAX_ATTEMPTS
            ? t('common.closingOfferErrorModal.firstThreeAttempts.description')
            : t('common.closingOfferErrorModal.thirdAttempt.description')}
          {!HAS_NOT_REACHED_MAX_ATTEMPTS && (
            <>
              <InlineLink data-testid={'numberLink'} href={`tel:${phoneNumber}`} variant="primary" ml={'4px'}>
                {phoneNumber}
              </InlineLink>
              <span>&#46;</span>
            </>
          )}
        </Txt>
      </Surface>
    </Modal>
  );
};
