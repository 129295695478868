import styles from './skeletonLoader.module.scss';

export interface SkeletonLoaderProps {
  height?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fullHeight';
  radius?: number;
  padding?: number;
  characters?: number;
}

export const SkeletonLoader = (props: SkeletonLoaderProps) => {
  const height = props.height ?? 'md';
  const characterWidthPx = 10;
  const borderRadius = `${props.radius ?? 0}px`;
  const paddingBottom = `${props.padding ?? 0}px`;
  const width: string = props.characters ? `${(props.characters as number) * characterWidthPx}px` : '100%';
  return (
    <span style={{ width, borderRadius, paddingBottom }} className={`${styles['container']} ${styles[height]}`}></span>
  );
};
