import { ReactNode } from 'react';
import { Surface } from '@vst/beam';
import styles from './checkoutCartTopper.module.scss';

export interface CheckoutCartTopperProps {
  className: string;
  children?: ReactNode;
}
export function CheckoutCartTopper({ className, children }: CheckoutCartTopperProps) {
  return (
    <Surface className={`${styles['card']} ${className}`} data-testid="checkoutCartTopper">
      {children}
    </Surface>
  );
}
