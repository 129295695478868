import { FetchResult, gql } from '@apollo/client';
import { AddressInputR0, ScrubAddressInput, ScrubAddressPayload } from '@buy-viasat/types/build/bv';
import { client } from 'shared/containers/apollo';

export type ScrubAddressResponse = FetchResult<{ scrubAddress: ScrubAddressPayload }>;

const SCRUB_ADDRESS = gql`
  query scrubAddress($input: ScrubAddressInput!) {
    scrubAddress(input: $input) {
      addressLines
      municipality
      region
      countryCode
      postalCode
      longitude
      latitude
      bannedPostalCode
      invalidAddress
      processStatus
      recommendation
    }
  }
`;

const sanitizeAddressLines = (address: AddressInputR0) => {
  const { addressLines } = address;

  if (!addressLines[1]) {
    return { ...address, addressLines: [addressLines[0]] };
  }

  return address;
};

export default async function scrubAddressAsync(input: ScrubAddressInput): Promise<ScrubAddressResponse> {
  const sanitizeAddress = sanitizeAddressLines(input.address);

  const variables = {
    input: {
      ...input,
      address: {
        ...sanitizeAddress,
        municipality: sanitizeAddress.municipality || '',
        region: sanitizeAddress.region || '',
      },
    },
  };

  return client.query({
    query: SCRUB_ADDRESS,
    variables,
  });
}
