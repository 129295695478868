/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, TextField, validators } from '@vst/beam';
import { FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

import {
  selectCoordinates,
  selectServiceAddressIsValid,
  selectServiceAddressValues,
} from '@buy-viasat/redux/src/serviceability';
import { zipCodeBlacklist } from '@buy-viasat/types/build/bv';
import { serviceabilityActions } from '@buy-viasat/redux/src/serviceability/slice';
import { selectCountry, selectFeatureFlags, selectLocale, Modals } from '@buy-viasat/redux/src/app';
import { appActions } from '@buy-viasat/redux/src/app';
import { TrackingCategory, TrackingActions } from '@buy-viasat/redux/src/analytics';

import env from 'env';
import AptUnitFieldInput from '../AptUnitFieldInput';
import styles from '../SingleFieldAddress/singleFieldAddress.module.scss';

const checkBannedPostalCode = (postalCode: string, dispatch: any): void => {
  if (zipCodeBlacklist.includes(postalCode)) {
    dispatch(
      appActions.analyticsEvent({
        category: TrackingCategory.SERVICEABILITY_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    dispatch(appActions.setModalVisible(Modals.NO_SERVICE));
  } else {
    dispatch(serviceabilityActions.onAutoCompletedAddressUsed());
  }
};

const SingleFieldAddressForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('serviceability');
  const { serviceabilityAptSte } = useSelector(selectFeatureFlags);
  const { latitude, longitude } = useSelector(selectCoordinates);
  const addressIsValid = useSelector(selectServiceAddressIsValid);
  const serviceAddress = useSelector(selectServiceAddressValues);
  const country = useSelector(selectCountry);
  const locale = useSelector(selectLocale);

  const [, setInputValue] = useState('');
  const skipScrubAddress = latitude !== null && longitude !== null;

  const language = useMemo(() => {
    switch (locale) {
      case 'pt-BR':
        return 'pt-BR';
      case 'en-US':
      default:
        return 'en';
    }
  }, [locale]);

  const handleChange = async ({ label }: { label: string }, result: any) => {
    setInputValue(label);
    dispatch(serviceabilityActions.setServiceAddressLine1(label ?? ''));

    const geocodes = await geocodeByPlaceId(result?.value.place_id);
    const { lat, lng } = await getLatLng(geocodes[0]);
    dispatch(serviceabilityActions.setCoordinates({ latitude: lat, longitude: lng }));
  };

  const onPressHandler = useMemo(
    () =>
      skipScrubAddress
        ? () => checkBannedPostalCode(serviceAddress.postalCode, dispatch)
        : () => dispatch(serviceabilityActions.scrubServiceAddress()),
    [skipScrubAddress, dispatch, serviceAddress],
  );

  const pressEnterHandler = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === 'Enter' && addressIsValid) {
        onPressHandler();
      }
    },
    [onPressHandler, addressIsValid],
  );

  useEffect(() => {
    window.addEventListener('keyup', pressEnterHandler);
    return () => {
      window.removeEventListener('keyup', pressEnterHandler);
    };
  }, [addressIsValid, pressEnterHandler]);

  const handleOnAptUnitChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(serviceabilityActions.setServiceAddressLine2(e.currentTarget.value));
    },
    [dispatch],
  );

  const Input = (props: any) => {
    return (
      <TextField
        {...props}
        required
        labelProps={{ showStar: false, labelText: t('serviceability.field.streetAddress.label') }}
        fluid
        name="streetAddress"
        label={t('serviceability.field.streetAddress.label')}
        validationRules={[validators.required({ message: t('serviceability.field.streetAddress.validationRequired') })]}
      />
    );
  };

  return (
    <Container fluid data-test-id="singleFieldAddressForm" className={styles['root']}>
      <Row>
        <Col>
          <GooglePlacesAutocomplete
            apiKey={env.googleApiKey}
            debounce={300}
            autocompletionRequest={{
              componentRestrictions: {
                country: [country],
              },
            }}
            onLoadFailed={(error) => {
              console.error('AutoComplete failed to load --- ', error);
            }}
            apiOptions={{ language, region: country }}
            selectProps={{
              onChange: handleChange,
              menuPortalTarget: document.body,
              placeholder: t('components:serviceability.searchAddress'),
              noOptionsMessage: () => t('serviceability.field.dropdown.text'),
              components: {
                IndicatorsContainer: () => null,
                Input,
              },
              styles: {
                input: (provided: any) => ({
                  ...provided,
                  paddingTop: '18px',
                  paddingBottom: '11px',
                  paddingLeft: '6px',
                  border: '1px black solid',
                  position: 'relative',
                }),
                menu: (provided: any) => ({
                  ...provided,
                }),
                valueContainer: () => ({
                  padding: '0px',
                }),
                control: () => ({
                  border: 'none',
                  outline: 'none',
                  padding: '-8px',
                }),
                option: (provided: any) => ({
                  ...provided,
                }),
                placeholder: () => ({
                  display: 'none',
                }),
                loadingMessage: (provided: any) => ({
                  ...provided,
                }),
                singleValue: (provided: any, state: any) => ({
                  position: 'absolute !important',
                  top: '55%',
                  left: '16px',
                  zIndex: '1',
                  fontFamily: 'Source Sans Pro',
                  fontSize: '16px',
                  width: 'calc(100% - 32px)',
                  pointerEvents: 'none',
                  display: 'none',
                }),
              },
            }}
          />
        </Col>
        {serviceabilityAptSte && (
          <Col md={4}>
            <AptUnitFieldInput
              fluid
              mb="24px"
              name="aptAddress"
              onChange={handleOnAptUnitChange}
              data-testid={'singleFieldAptUnitFieldInput'}
              labelProps={{ labelText: t('serviceability.field.aptUnit.label') }}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default SingleFieldAddressForm;
