import { Product } from '@buy-viasat/types/build/bv';
import { ApolloQueryResult, gql } from '@apollo/client';

export interface PlanState {
  inflectionPointText: string;
  availableProducts: Array<Product>;
  extensionProducts: Array<string>;
  planId: string | null;
  planName: string | null;
  isPlanLoading: boolean;
  isUpsertPlanLoading: boolean;
  hasCafII: boolean;
  hasUnleashedPlan: boolean;
  hasUnlimitedPlans: boolean;
  hasChoicePlans: boolean;
  hasBusinessChoicePlans: boolean;
  hasDiamondPlans: boolean;
  planPrice: number | null;
  planPromo: number | null;
  offerId: string;
  isACPBannerShowing: boolean;
  productFamily: string;
  isRegulated: boolean;
  isCafII: boolean;
  dataCap: string;
  hasDiscoveryPlans: boolean;
  hasChoicePlusStream: boolean;
  preassignedPlanId: string | null;
  selectedPlan: Product | null;
  viewDetailsPlan: Product | null;
  skipApReload: boolean;
  isLoading: boolean;
  hasVS2LargePlans: boolean;
  hasImageOneUrl: boolean;
  imageOneUrl: string;
  hasOnlyCafPlans: boolean;
  displayingVoicePlans: boolean;
  hasNextGenPlans: boolean;
  hasNextGenCafIIPlans: boolean;
  uploadSpeedText: string;
  isFragmentUnpublished: {
    [id: string]: boolean;
  };
  havePlansLoaded: boolean;
}

export interface SetPlanAction {
  name: string;
  id: string;
  extensionProducts: Array<string>;
  planPrice: number;
  planPromo: number;
  inflectionPointText: string;
  offerId: string;
  productFamily: string;
  isRegulated: boolean;
  isCafII: boolean;
  dataCap: string;
  retryNeeded?: boolean;
}

export const initialPlanState: PlanState = {
  availableProducts: [],
  extensionProducts: [],
  planId: null,
  planName: null,
  isPlanLoading: false,
  isUpsertPlanLoading: false,
  hasCafII: false,
  hasUnleashedPlan: false,
  hasUnlimitedPlans: false,
  hasChoicePlans: false,
  hasBusinessChoicePlans: false,
  hasDiamondPlans: false,
  planPrice: null,
  planPromo: null,
  inflectionPointText: '',
  offerId: '',
  isACPBannerShowing: true,
  productFamily: '',
  isRegulated: false,
  isCafII: false,
  dataCap: '',
  hasDiscoveryPlans: false,
  hasChoicePlusStream: false,
  preassignedPlanId: null,
  selectedPlan: null,
  viewDetailsPlan: null,
  skipApReload: false,
  isLoading: false,
  hasVS2LargePlans: false,
  hasImageOneUrl: false,
  imageOneUrl: '',
  hasOnlyCafPlans: false,
  displayingVoicePlans: false,
  hasNextGenPlans: false,
  hasNextGenCafIIPlans: false,
  uploadSpeedText: '',
  isFragmentUnpublished: {},
  havePlansLoaded: false,
};

export enum OfferNames {
  CHOICE = 'Choice',
  CHOICE_PLUS_STREAM = 'Choice Plus Stream',
  DIAMOND = 'Diamond',
  DISCOVERY = 'Discovery',
  NEXT_GEN = 'NextGen',
  NEXT_GEN_CAFII = 'NextGenCAF',
  UNLEASHED = 'Unleashed',
  UNLIMITED = 'Unlimited',
  BUSINESS_CHOICE = 'Business Choice',
}

export enum ThunkActions {
  getAvailableProducts = 'getAvailableProducts',
  selectPlan = 'selectPlan',
  loadScript = 'loadScript',
  trackEvent = 'trackEvent',
  viewMoreDetails = 'viewMoreDetails',
  plansDetailsBack = 'plansDetailsBack',
  editServiceAddress = 'editServiceAddress',
  getPartnerAccount = 'getPartnerAccount',
}

export enum DataLayerEventType {
  SELECTION_OF_PLAN = 'selectionOfPlan',
  VIEW_MORE_DETAILS = 'viewMoreDetails',
  PLANS_DETAILS_BACK = 'plansDetailsBack',
  EDIT_SERVICE_ADDRESS = 'editServiceAddress',
  PLAN_GRID_TRIGGER_VIEW = 'planGridTriggerView',
}

export const GET_AVAILABLE_PRODUCTS = gql`
  query getAvailableProducts($input: GetAvailableProductsInputR0) {
    getAvailableProducts(input: $input) {
      id
      name
      characteristics {
        dataCap
        dataCapUnits
        uploadSpeed
        uploadUnits
        downloadSpeed
        downloadSpeedText
        downloadUnits
        displayOrder
        freeZone
        resolution
        productFamily
        dataAllowanceText
        textGeneral
        textGeneral01
        inflectionPointText
        bannerColor
        routerText
        shortName
        benefits
        attribute1
        attribute2
        titleAddOns
        serviceType
        tag
        imageOneUrl
        isRegulated
        contractTerm
        contractTermUnit
        feeText
        downloadRange
        uploadSpeedText
        typicalDownloadSpeed
        upi
        serviceAreaId
      }
      offerId
      price
      extensionTypes
      promo {
        price
        duration
      }
      bestFor
      isCafII
      totalDiscount {
        price
        duration
      }
      digitalServices {
        iconUrl
      }
    }
  }
`;

export type GetAvailableProductsPayload = ApolloQueryResult<{ getAvailableProducts: Product[] }>;
