import { createSelector } from 'reselect';
import { AppState, initialAppState } from '@buy-viasat/redux/src/app';
import { RootState } from '@buy-viasat/redux/src/types';
import { validateLastFour, validateSSN } from '../Checkout/validations';
import {
  dateOfBirthValidation,
  validateDay,
  validateMonth,
  validateTaxId,
  validateYear,
} from './../Checkout/validations/index';
import { selectCreditVerificationDomain } from '@buy-viasat/redux/src/credit-verification';

export const selectAppDomain = (state: RootState): AppState => {
  return state?.app || initialAppState;
};

const selectCreditVerificationValidation = createSelector(
  [selectCreditVerificationDomain, selectAppDomain],
  ({ dateOfBirth, lastFourOfSSN, isLegalCheckboxChecked, isSSNMandatory }, { country }) => {
    if (isSSNMandatory) {
      return (
        validateMonth(dateOfBirth.month.value).valid &&
        validateDay(dateOfBirth.day.value).valid &&
        validateYear(dateOfBirth.year.value).valid &&
        dateOfBirthValidation(dateOfBirth.month.value, dateOfBirth.day.value, dateOfBirth.year.value, new Date())
          .valid &&
        validateSSN(lastFourOfSSN.value).valid &&
        isLegalCheckboxChecked
      );
    } else {
      return (
        validateMonth(dateOfBirth.month.value).valid &&
        validateDay(dateOfBirth.day.value).valid &&
        validateYear(dateOfBirth.year.value).valid &&
        dateOfBirthValidation(dateOfBirth.month.value, dateOfBirth.day.value, dateOfBirth.year.value, new Date())
          .valid &&
        validateLastFour(lastFourOfSSN.value).valid &&
        isLegalCheckboxChecked
      );
    }
  },
);
const selectCreditVerificationValidationTaxId = createSelector(
  [selectCreditVerificationDomain, selectAppDomain],
  ({ dateOfBirth, taxId }, { country }) =>
    validateMonth(dateOfBirth.month.value).valid &&
    validateDay(dateOfBirth.day.value).valid &&
    validateYear(dateOfBirth.year.value).valid &&
    validateTaxId(taxId.value).valid &&
    dateOfBirthValidation(dateOfBirth.month.value, dateOfBirth.day.value, dateOfBirth.year.value, new Date()).valid,
);

export { selectCreditVerificationValidation, selectCreditVerificationValidationTaxId };
