/* eslint-disable @typescript-eslint/no-namespace */
import { createContext } from 'react';
import { ViasatTheme } from 'shared/assets/styles/theme';

declare global {
  namespace ReactNativePaper {
    interface ThemeAnimation {
      scale: number;
    }
    interface ThemeColors {
      primary: string;
      accent: string;
      error: string;
      background: string;
      surface: string;
      text: string;
      disabled: string;
      placeholder: string;
      backdrop: string;
      onSurface: string;
      onBackground: string;
      notification: string;
    }
    interface Theme {
      dark: boolean;
      roundness: number;
    }
  }
}

export type Theme = ReactNativePaper.Theme;
const ThemeContext = createContext(ViasatTheme);
export default ThemeContext;
