export enum GeneralAcpModalVariant {
  NONTRIBAL_NLAD_LOCATION = 'NONTRIBAL_NLAD_LOCATION',
  DEFICIENT_CUSTOMER_INFO = 'DEFICIENT_CUSTOMER_INFO',
  NONTRIBAL_CONSUMER_LOCATION = 'NONTRIBAL_CONSUMER_LOCATION',
  DUPLICATE_SUBSCRIBER_NLAD = 'DUPLICATE_SUBSCRIBER_NLAD',
  NOT_ELIGIBLE_ACP = 'NOT_ELIGIBLE_ACP',
  ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE = 'ACP_TRANSFER_DISCLOSURE_APPLICATION_NOT_COMPLETE',
  APPLICATION_NOT_COMPLETED = 'APPLICATION_NOT_COMPLETED',
  APPLICATION_PENDING = 'APPLICATION_PENDING',
  GENERAL_ERROR = 'GENERAL_ERROR',
  ACP_TERMS_AND_CONDITIONS = 'ACP_TERMS_AND_CONDITIONS',
  ACP_ILL_DO_IT_LATER = 'ACP_ILL_DO_IT_LATER',
}
